import React, {useContext, useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import "../Product.css";
import "../../Home/productcardview.css";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import Carousel from 'react-bootstrap/Carousel';

import ads_sample01 from '../../images_s/ads_sample01.png';

import { Row, Col, Card, Tabs, Tab, Spinner, Badge } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";

import product_sample01 from '../../images_s/product_sample01.jpeg';
import product_sample02 from '../../images_s/product_sample02.jpg';

import ads_testflight02 from '../../images_s/ads_testflight02.jpg';
import ads_sample03 from '../../images_s/ads_sample03.jpg';

import { firestore } from '../../../database/firebase';
import { auth } from '../../../database/firebase';
// import { AuthContext } from '../../Old_Test/Auth';

import { Link, Navigate } from "react-router-dom";

import { FaHeart, FaTruck, FaFacebookMessenger } from "react-icons/fa";
import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";
import { text } from '@fortawesome/fontawesome-svg-core';

// import MessengerCustomerChat from 'react-messenger-customer-chat';
// import ChatBot from '../Chat/FacebookChat';

function Computer() {

    /* const {currentUser} = useContext(AuthContext);

    if (!currentUser) {
        return <Navigate to="/LogIn" replace />
    } */

    //Test useParams
    // let productId = "MIGhY5tyTiuRPeSWxDXC";

    const formatter = new Intl.NumberFormat('th', {
        style: 'currency',
        currency: 'THB',
        maximumSignificantDigits: 3,
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
    
    // const [productPriceCart, setProductPriceCart] = useState("");


  <Image src={[ads_sample01, product_sample01, product_sample02]} fluid />

    //PRODUCTS NAME
    const nameProducts = "ต้นมอนสเตอร่า";
    //MERCHANT NAME
    const merchantNameStyle = {color: "gray", fontSize: "12px", marginTop: "4px"}
    //PRODUCT Rating
    const merchantRatingStyle = {color: "#F8BA08", fontSize: "12px"}
    const productRating = "5.0"
    const merchantCountRatingStyle = {color: "gray", fontSize: "12px"}
    
    //PRODUCTS PRICE
    const productPriceStyle = {color: "black", fontSize: "18px", marginTop: "8px"}
    const productPrice = "2,890 บาท"
    const merchantName = <p style={merchantNameStyle}>ร้าน Green Corner<br/>


        <span style={merchantRatingStyle}>
            {productRating}&nbsp;
            <FontAwesomeIcon icon={faStar} size="6px"/>
            <FontAwesomeIcon icon={faStar} size="6px"/>
            <FontAwesomeIcon icon={faStar} size="6px"/>
            <FontAwesomeIcon icon={faStar} size="6px"/>
            <FontAwesomeIcon icon={faStar} size="6px"/>
            <span style={merchantCountRatingStyle}>&nbsp;(587)</span><br/>
            <span style={productPriceStyle}><br/>{productPrice}</span>
        </span>
                            
    </p>;


    const [data, setData] = useState([]);
    const [productName, setProductName] = useState("");
    const [productDiscount, setProductDiscount] = useState("");

    const [loading, setLoading] = useState(false);


    var sliceProductName = "";

    var part = "";

    const [productLists, setProductLists] = useState([]);
    const [currentCursor, setCurrentCursor] = useState("");

    useEffect(() => {
        const firsPageRef = firestore
            .collection("product")
            .where("productSubCategory", "==", "คอมพิวเตอร์และเทคโนโลยี")
            // .orderBy("productSortNumber", "asc")
            .limit(4);
        firsPageRef.get().then((querySnapshot) => {
            let tempLists = [];
            querySnapshot.docs.forEach((doc) => {
                if (doc.exists) {

                    const currentProduct = {

                        productID: doc.id,
                        productID: doc.data().productID,
                        productName: doc.data().productName,
                        // .slice("0", "30")
                        productImage: doc.data().productImage,
                        productPrice: doc.data().productPrice,
                        storeName: doc.data().storeName,
                        productFullPrice: doc.data().productFullPrice,
                        productDiscount: doc.data().productDiscount, 
                        productSold: doc.data().productSold,
                        productBadge: doc.data().productBadge,

                        //RATING
                        ratingSum: doc.data().ratingSum,
                        ratingCount: doc.data().ratingCount,

                    };
                    tempLists = [...tempLists, currentProduct];
                }
            });
            setProductLists((prv) => tempLists);
            const currentLenght = querySnapshot.docs.length;
            const currentCursorFromFirstPage = querySnapshot.docs[currentLenght - 1];
            setCurrentCursor(currentCursorFromFirstPage);
        })
    }, []);

    const onMoreProducts = () => {
        const nextPageRef = firestore
            .collection("product")
            .where("productSubCategory", "==", "คอมพิวเตอร์และเทคโนโลยี")
            // .orderBy("productSortNumber", "asc")
            .limit(4);
        nextPageRef.get().then((querySnapshot) => {
            const currentLenght = querySnapshot.docs.length;
            if (!currentCursor) {
                return;
            }
            const query = nextPageRef.startAfter(currentCursor);
            query.get().then((querySnapshot) => {
                let tempNewArray = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempNewArray = [
                            ...tempNewArray,
                            {
                                productID: doc.id,
                                productID: doc.data().productID,
                                productName: doc.data().productName,
                                // slice("0", "40")
                                productImage: doc.data().productImage,
                                productPrice: doc.data().productPrice,
                                storeName: doc.data().storeName,
                                productFullPrice: doc.data().productFullPrice,
                                productDiscount: doc.data().productDiscount, 
                                productSold: doc.data().productSold,
                                productBadge: doc.data().productBadge,

                                //RATING
                                ratingSum: doc.data().ratingSum,
                                ratingCount: doc.data().ratingCount,
                            },
                        ];
                    }
                });
                setProductLists([...productLists, ...tempNewArray]);
                const currentCursorForNextPage = querySnapshot.docs[currentLenght -1];
                setCurrentCursor(currentCursorForNextPage);
            });
        });
    };
    

    return (
    <div>

    <Container>

        <br/>

        <h3><strong>คอมพิวเตอร์และเทคโนโลยี</strong></h3>
        <p>หนังสือการเขียนโปรแกรม การใช้งาน Windows and Office และฮาร์ดแวร์ รวมทั้งการทำกราฟฟิก</p>

        <Link to={'/ProductTypeCategory'} state={"Windows and Office"}><Button className="rounded-pill border-1" variant="outline-dark" style={{margin: 5, marginLeft: 0, marginRight: 5}}>Windows and Office</Button></Link>{' '}
        <Link to={'/ProductTypeCategory'} state={"Hardware and Utilities"}><Button className="rounded-pill border-1" variant="outline-dark" style={{margin: 5, marginLeft: 0, marginRight: 5}}>Hardware and Utilities</Button></Link>{' '}
        <Link to={'/ProductTypeCategory'} state={"Programming and Network"}><Button className="rounded-pill border-1" variant="outline-dark" style={{margin: 5, marginLeft: 0, marginRight: 5}}>Programming and Network</Button></Link>{' '}
        <Link to={'/ProductTypeCategory'} state={"Graphics"}><Button className="rounded-pill border-1" variant="outline-dark" style={{margin: 5, marginLeft: 0, marginRight: 5}}>Graphics</Button></Link>{' '}
        
        <Row xs={2} md={4} className="g-4">

                        

                        {productLists.map((item) => {
                            
                            return (
                               
                                <div key={item.id}>

                                <br/>    

                                    <div className='card'>
                                    <Link to={'/ProductDetail/'+ item.productID} style={{textDecoration: 'none', color: "black"}}>
                                        <Card>
                                            <Card.Img variant="top" style={{width: "100%", height: "auto"}} src={item.productImage[0]} />
                                            
                                            <Card.Body>
                                                <Card.Title>
                                                </Card.Title>
                                                {/* <p numberOfLines={2} style={{textOverflow: 'ellipsis'}}><strong>{item.productName}</strong></p> */}

                                                {/* <p><strong>{item.productName}</strong></p> */}
                                                {/* <div class='productname' style={{marginTop: -14, lineHeight: 1.5}}><p style={{fontWeight: 500}}>{item.productName}</p></div> */}
                                                <p class='productname' style={{marginTop: -14, fontWeight: 500, lineHeight: 1.4}}>{item.productName}</p>

                                                {/* <div class='price' style={{marginTop: -14, marginBottom: 0, lineHeight: 1.5}}>{item.productName}</div> */}
                                   
                                                    {/* { (item.productName).length < 30 ? (
                                                        <>
                                                            <p class='productname'><strong>{item.productName}</strong></p><br/>
                       
                                                        </>
                                                    ) : item.productName < 35 ? (
                                                        <>
                                                            <p class='productname'><strong>{item.productName}</strong></p>
                                                        </>
                                                    ) : item.productName > 40 ? (
                                                        <>
                                                            <p class='productname'><strong>{(item.productName).substring(0, 35) + '...'}</strong></p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p class='productname'><strong>{(item.productName).substring(0, 40) + '...'}</strong></p>
                                                        </>
                                                    )} */}
                              
                                                    
                                                    {/* {item.storeName} */}
                                                    {/* <br/>ขายแล้ว {item.productSold} */}
                                                    
                                                    {/* <p style={{color: "gray", fontSize: "12px", marginTop: "1px"}}>{item.storeName}</p> */}
                                                    

                                                    {/* { item.productDiscount == 5 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) :
                                                        item.productDiscount == 10 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : item.productDiscount == 15 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/> <FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : item.productDiscount == 20 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/> <FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : item.productDiscount == 25 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/> <FaHeart/> <FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : item.productDiscount == 30 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/> <FaHeart/> <FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : (
                                                        <>
                                                        <p style={{marginTop: 58}}></p>
                                                        </>
                                                    )} */}

{ (item.ratingSum / item.ratingCount) <= 0 ? (
                                                            <>
                                                            
                                                            </>
                                                        ) : (item.ratingSum / item.ratingCount) <= 1 ? (
                                                            <p style={{marginTop: 0}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStar/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 1.5 ? (
                                                            <p style={{marginTop: 0}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarHalf/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 2 ? (
                                                            <p style={{marginTop: 0}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 2.5 ? (
                                                            <p style={{marginTop: 0}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarHalf/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 3 ? (
                                                            <p style={{marginTop: 0}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 3.5 ? (
                                                            <p style={{marginTop: 0}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarHalf/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 4 ? (
                                                            <p style={{marginTop: 0, marginBottom: 20}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 4.5 ? (
                                                            <p style={{marginTop: 0}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarHalf/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) > 4.5 ? (
                                                            <p style={{marginTop: 0}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (
                                                            <p>
                                                                <br/>
                                                               
                                                            </p>
                                                        ) }

        {/* <p numberOfLines={1}>
                    {item.productName.length < 35
                        ? (item.productName)
                        : ((item.productName).substring(0, 5))}
                    </p> */}

                                                    {/* {item.productDiscount > 10 ? (
                                                        <p><span style={{fontSize: 20, color: "red"}}><FaHeart/> <FaHeart/></span> ส่วนลด {item.productDiscount}%</p>
                                                    ) : (
                                                        <>
                                                        <p style={{marginTop: 47}}></p>
                                                        </>
                                                    )} */}
                                                    


                                                    <div class='price' style={{marginTop: -14, marginBottom: -15}}>{formatter.format(item.productPrice)} <del style={{color: "gray"}}>{formatter.format(item.productFullPrice)}</del></div>

                                                    {item.productBadge ? (
                                                        <div class='bageanddiscount'> 
                                                            { item.productDiscount <= 9 ? ( 
                                                                <Badge className="rounded-0" bg="warning">{item.productBadge}</Badge> 
                                                            ) : (
                                                                <><Badge className="rounded-0" bg="warning">{item.productBadge}</Badge> <Badge className="rounded-0" bg="danger">- {item.productDiscount}%</Badge></> 
                                                            ) } <span style={{marginTop: 1}} className="float-end"><p>ขายแล้ว {item.productSold}</p></span>
                                                        </div>
                                                    ) : (
                                                       
                                                        <div class='bageanddiscount'> 
                                                            { item.productDiscount <= 9 ? ( 
                                                    
                                                                <>
                                                                </>
                                                                // <Badge className="rounded-0" bg="warning">{item.productBadge}</Badge>
                                                                
                                                            ) : (
                                                                <><Badge className="rounded-0" bg="warning">{item.productBadge}</Badge> <Badge className="rounded-0" bg="danger">- {item.productDiscount}%</Badge></> 
                                                            ) } <span style={{marginTop: 1}} className="float-end"><p>ขายแล้ว {item.productSold}</p></span>
                                                        </div>
                                                        
                                                    )}
                                                    
                                                    
                                                    
            
                                                    {/* <p><strong>{item.productName}</strong></p>
                                                    <p><strong>฿{item.productPrice} <del style={{color: "gray"}}>฿{item.productFullPrice}</del></strong></p> */}

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                    </div>
                                    
                                </div>
                            )
                        })}
                </Row>

            <br/>

            {currentCursor ? (
                <Col className='float-end'>

                    <Button variant="warning" className="rounded-0" onClick={onMoreProducts}>สินค้าเพิ่มเติม</Button>
        
                </Col>
            ) : (
                <Col className='float-end'>

                    <Button variant="outline-secondary" className="rounded-0" disabled>ไม่มีสินค้าเพิ่มเติม</Button>
        
                </Col>
            )}

        <br/>
        <br/>
        <br/>
        

    </Container>



        {/* <MessengerCustomerChat pageId="100085795429971" appId="160341680355677" /> */}

        {/* <ChatBot /> */}
    
    </div>

  );
}

export default Computer;