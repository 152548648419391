import React, { useState, useEffect } from 'react';
// import "./Home.css";
import "./productcardview.css";

import { firestore } from '../../database/firebase';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Card, Tabs, Tab, Spinner, Badge, Form, Button, Image, InputGroup } from 'react-bootstrap'

import {  BsSearch, BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";

import { FaHeart, FaTruck, FaFacebookMessenger } from "react-icons/fa";

import { Link, Navigate } from "react-router-dom";

const SearchBar = () => {

    // const [searchBy, setSearchBy] = useState("");

    const formatter = new Intl.NumberFormat('th', {
        style: 'currency',
        currency: 'THB',
        maximumSignificantDigits: 3,
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

    const [data, setData] = useState([]);

    useEffect(() => {
        const productRef = firestore.collection("product");
        const query = productRef
        
/*             .orderBy("productSortNumber")
            .startAt(queryBar.toUpperCase())
            .endAt(queryBar.toUpperCase() +"\uf8ff")
            .once("value");   */

            /* .orderBy("productSortNumber")
            .startAt(queryBar.toUpperCase())
            .endAt(queryBar.toUpperCase() +"\uf8ff"); */

            .orderBy("productName")
            .startAt(queryBar.toUpperCase())
            .endAt(queryBar.toUpperCase() + "\uf8ff")
            .limit(8);
            // .where("productName", "<=", queryBar.toUpperCase() + "\uf8ff");
            
            /* .where("productName", ">=", queryBar.toUpperCase())
            .where("productName", "<=", queryBar.toUpperCase() + "\uf8ff"); */
                query
                .get()
                .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempDataArray = [...tempDataArray,
                        {
                            // productID: doc.id,
                            productID: doc.id,
                            productID: doc.data().productID,
                            productName: doc.data().productName,
                            // .slice("0", "30")
                            productImage: doc.data().productImage,
                            productPrice: doc.data().productPrice,
                            storeName: doc.data().storeName,
                            productFullPrice: doc.data().productFullPrice,
                            productDiscount: doc.data().productDiscount, 
                            productSold: doc.data().productSold,
                            productBadge: doc.data().productBadge,
                

                        },
                    ];

                    
                    };

            });
            setData((oldDataArray) => tempDataArray);
    },
        (err) => {
            console.log(err);
        }
    );

    }, [data]);

    const [queryBar, setQueryBar] = useState("");

    function search(e){
        e.preventDefault();
        setQueryBar(e.target.value);


    }



    return (
        <Container>
        <br/>
        <br/>
        <h3><strong>ค้นหาสินค้า</strong></h3>
        <br/>

        {/* <Form.Select aria-label="Default select example">
            <option value="1">ชื่อสินค้า</option>
            <option value="2">ชื่อร้านค้า</option>
        </Form.Select> */}


        <InputGroup className="mb-3">
            
            <Form.Control
                type="text"
                placeholder= "ค้นหาสินค้า..."
                onChange={search}
                value={queryBar}
            />
            <Button variant="outline-secondary" style={{borderColor: 'darkgray', borderLeft: 0}} disabled>
                <BsSearch/>
            </Button>
        </InputGroup>

        <br/>

        <Row xs={2} md={4} className="g-4">
                        {data.map((item) => {
                            
                            return (
                                <div key={item.id}>

                                    <div className='card'>
                                    <Link to={'/ProductDetail/'+ item.productID} style={{textDecoration: 'none', color: "black"}}>
                                        <Card>
                                            <Card.Img variant="top" style={{width: "100%", height: "auto"}} src={item.productImage[0]} />
                                            
                                            <Card.Body>
                                                <Card.Title>
                                                </Card.Title>

                                                <p class='productname'><strong>{item.productName}</strong></p>

                                                 

                                                {/* { item.productDiscount == 5 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) :
                                                        item.productDiscount == 10 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : item.productDiscount == 15 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/> <FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : item.productDiscount == 20 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/> <FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : item.productDiscount == 25 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/> <FaHeart/> <FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : item.productDiscount == 30 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/> <FaHeart/> <FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : (
                                                        <>
                                                        <p style={{marginTop: 58}}></p>
                                                        </>
                                                    )} */}

                                                    { (item.ratingSum / item.ratingCount) <= 0 ? (
                                                            <>
                                                            
                                                            </>
                                                        ) : (item.ratingSum / item.ratingCount) <= 1 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStar/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 1.5 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarHalf/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 2 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 2.5 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarHalf/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 3 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 3.5 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarHalf/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 4 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 4.5 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarHalf/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) > 4.5 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (
                                                            <p>
                                                                <br/>
                                                               
                                                            </p>
                                                        ) }

        {/* <p numberOfLines={1}>
                    {item.productName.length < 35
                        ? (item.productName)
                        : ((item.productName).substring(0, 5))}
                    </p> */}

                                                    {/* {item.productDiscount > 10 ? (
                                                        <p><span style={{fontSize: 20, color: "red"}}><FaHeart/> <FaHeart/></span> ส่วนลด {item.productDiscount}%</p>
                                                    ) : (
                                                        <>
                                                        <p style={{marginTop: 47}}></p>
                                                        </>
                                                    )} */}
                                                    


                                                    <div class='price' style={{marginTop: -14, marginBottom: -15}}>{formatter.format(item.productPrice)} <del style={{color: "gray"}}>{formatter.format(item.productFullPrice)}</del></div>

                                                    {item.productBadge ? (
                                                        <div class='bageanddiscount'> 
                                                            { item.productDiscount <= 9 ? ( 
                                                                <Badge className="rounded-0" bg="warning">{item.productBadge}</Badge> 
                                                            ) : (
                                                                <><Badge className="rounded-0" bg="warning">{item.productBadge}</Badge> <Badge className="rounded-0" bg="danger">-{item.productDiscount}%</Badge></> 
                                                            ) } <span style={{marginTop: 1}} className="float-end"><p>ขายแล้ว {item.productSold}</p></span>
                                                        </div>
                                                    ) : (
                                                       
                                                        <div class='bageanddiscount'> 
                                                            { item.productDiscount <= 9 ? ( 
                                                    
                                                                <>
                                                                </>
                                                                // <Badge className="rounded-0" bg="warning">{item.productBadge}</Badge>
                                                                
                                                            ) : (
                                                                <><Badge className="rounded-0" bg="warning">{item.productBadge}</Badge> <Badge className="rounded-0" bg="danger">- {item.productDiscount}%</Badge></> 
                                                            ) } <span style={{marginTop: 1}} className="float-end"><p>ขายแล้ว {item.productSold}</p></span>
                                                        </div>
                                                        
                                                    )}
                                                    
                                                    
                                                    
            
                                                    {/* <p><strong>{item.productName}</strong></p>
                                                    <p><strong>฿{item.productPrice} <del style={{color: "gray"}}>฿{item.productFullPrice}</del></strong></p> */}

                                                <Card.Text> 
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                    </div>
                                    
                                </div>
                            )
                        })}
                </Row>


    {/* {data.map((item, index) => {

        return (
        <div key={index}>   

        <br/>

        <Card className="rounded-0">
        <Card.Img src={item.productImage[0]} style={{width: 100, height: 'auto'}} roundedCircle />
        <Card.Body>
            <strong>ชื่อสินค้า:</strong> {item.productName}<br/>
            <strong>ร้านค้า:</strong> {item.storeName}<br/>
            <strong>ราคา:</strong> ฿{item.productPrice} <del style={{color: "gray"}}>฿{item.productFullPrice}</del><br/>
            <strong>จำนวน:</strong> {item.productQuantity}<br/>

        <br/>

        </Card.Body>

        </Card>

        </div>
        );
    })} */}





        {/* <div className="w-full max-w-xl flex mx-auto p-20 text-xl">
            <input
                type="text"
                className="w-full placeholder-gray-400 text-gray-900 p-4"
                placeholder="Search"
                onChange={search}
                value={query}
            />
            <button className="bg-white p-4">🔍</button>
        </div> */}

        <br/>
        <br/>
        </Container>
        
    );
};

export default SearchBar;