import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Card, Button } from 'react-bootstrap';

function OrderHistory() {

  
  return (

    <Container>
    <br/>
    <br/>

    <h3><strong>ประวัติการซื้อ</strong></h3>
    {/* <p>จำนวนสั่งซื้อ: 3 รายการ | ยอดรวม: 1,350 บาท </p> */}
    <h3 className="pb-3 border-bottom"></h3>
    <br/>
    <Card className="rounded-0" border="success">
      <Card.Header>
        {/* <Card.Title>ข้อมูลคำสั่งซื้อ</Card.Title> */}
        <strong>คำสั่งซื้อที่:</strong> xx785dfsfsfsfsfsfsdfsd<br/>
        
        {/* <Button className="btn btn-danger rounded-0">ลบรายการ</Button><br/><br/> */}
      </Card.Header>
      <Card.Body>
        <strong>ชื่อสินค้า:</strong> ต้นมอนสเตอร่า<br/>
        <strong>รหัสผู้ขาย:</strong> gfdgfdfdhgfdfggdfgd<br/>
        <strong>จำนวนเงิน:</strong> 450 บาท<br/>
        11 - 05 - 2566 | 9.57 AM<br/><br/>
        <Button className="btn btn-success rounded-0">ซื้ออีกครั้ง</Button>{' '}
      </Card.Body>
    </Card>

    <br/>
    <br/>

    <Card className="rounded-0" border="success">
      <Card.Header>
        {/* <Card.Title>ข้อมูลคำสั่งซื้อ</Card.Title> */}
        <strong>คำสั่งซื้อที่:</strong> xx785dfsfsfsfsfsfsdfsd<br/>
        
        {/* <Button className="btn btn-danger rounded-0">ลบรายการ</Button><br/><br/> */}
      </Card.Header>
      <Card.Body>
        <strong>ชื่อสินค้า:</strong> ต้นมอนสเตอร่า<br/>
        <strong>ร้านค้า:</strong> SelPage_Store<br/> {/* ร้านค้า เป็นลิ้งค์หรือไม่ */}
        <strong>จำนวนเงิน:</strong> 450 บาท<br/>
        11 - 05 - 2566 | 9.57 AM<br/><br/>
        <Button className="btn btn-success rounded-0">ซื้ออีกครั้ง</Button>{' '}
      </Card.Body>
    </Card>

    <br/>
    <br/>

    <Card className="rounded-0" border="success">
      <Card.Header>
        {/* <Card.Title>ข้อมูลคำสั่งซื้อ</Card.Title> */}
        <strong>คำสั่งซื้อที่:</strong> xx785dfsfsfsfsfsfsdfsd<br/>
        
        {/* <Button className="btn btn-danger rounded-0">ลบรายการ</Button><br/><br/> */}
      </Card.Header>
      <Card.Body>
        <strong>ชื่อสินค้า:</strong> ต้นมอนสเตอร่า<br/>
        <strong>รหัสผู้ขาย:</strong> gfdgfdfdhgfdfggdfgd<br/>
        <strong>จำนวนเงิน:</strong> 450 บาท<br/>
        11 - 05 - 2566 | 9.57 AM<br/><br/>
        <Button className="btn btn-success rounded-0">ซื้ออีกครั้ง</Button>{' '}
      </Card.Body>
    </Card>

    <br/>
    <br/>
    </Container>
  );
}

export default OrderHistory;