import React, { useState, useEffect, useContext } from "react";
import { Container, Tabs, Tab, Form, Col, Row, Button, Card, InputGroup,Accordion, Modal } from "react-bootstrap";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";

import { FaSketch } from "react-icons/fa";
import { AiOutlineSketch } from "react-icons/ai";

import { auth, firestore } from "../../database/firebase";
import { storage } from "../../database/firebase";


import { AuthContext } from "../../component_s/Authentication/AuthProvider";

import { BsStarFill, BsStarHalf, BsStar, BsPatchCheck, BsPlayBtn, BsPhone, BsFolder2Open, BsInfinity, BsCheckLg, BsPeopleFill, BsFillPlayBtnFill, BsChatLeftDotsFill } from "react-icons/bs";
import UploadVideo from "./UploadVideo";

// import "./UploadVideo.css";

import product_sample01 from '../../component_s/images_s/product_sample01.jpeg';
import product_sample02 from '../../component_s/images_s/product_sample02.jpg';
import product_sample03 from '../../component_s/images_s/product_sample03.jpg';


function CreateCourse() {


    // let { courseId } = useParams();

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    /* TEST QUERY DATA WITH productID */

    // const [uid, setUid] = useState("");

    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

   //const [uid, setUid] = useState("");

    var user = auth.currentUser;
    // var uid = "";
    var uid = "";

    if (user != null) {
        //email = user.email;
        uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                         // this value to authenticate with your backend server, if
                         // you have one. Use User.getToken() instead.
        console.log(uid);
        
        //TEST AUTH
        // refreshPage();
    } else {
        navigate("/");
    }
    



    

    // const [emailDefault, setEmailDefault] = useState("");

    // const [loading, setLoading] = useState(false);
    const [section, setSection] = useState([""]);
    const [about, setAbout] = useState([""]);


    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [address, setAddress] = useState("");

    const [role, setRole] = useState("");
    const [sPoint, setSPoint] = useState("");

    const [displayName, setDisplayName] = useState("");

    const [data, setData] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
    
        .where("uid", "==", uid);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            uid: doc.id,
                            name: doc.data().name,
                            email: doc.data().email,
                            phonenumber: doc.data().phonenumber,
                            address: doc.data().address,
                            sPoint: doc.data().sPoint,   
                            role: doc.data().role,

                            displayName: doc.data().displayName,
                            
                        },
                        
                    ];

                    setName(doc.data().name);
                    setPhonenumber(doc.data().phonenumber);
                    setEmail(doc.data().email);
                    setAddress(doc.data().address);

                    setRole(doc.data().role)
                    setSPoint(doc.data().sPoint);

                    setDisplayName(doc.data().displayName);
                    

                    };

                });

            setData((oldDataArray) => tempDataArray);
            

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [uid]); 


    const [merchantID, setMerchantID] = useState("");
    const [storeName, setStoreName] = useState("");
    const [courseCategory, setCourseCategory] = useState("");
    const [courseSubCategory, setCourseSubCategory] = useState("");
    const [courseTypeCategory, setCourseTypeCategory] = useState("");
    const [courseCaption, setCourseCaption] = useState("");
    const [courseID, setCourseID] = useState("");
    const [courseName, setCourseName] = useState("");
    const [courseBadge, setCourseBadge] = useState("");
    const [courseFullPrice, setCourseFullPrice] = useState("");
    const [coursePrice, setCoursePrice] = useState("");
    const [courseImage, setCourseImage] = useState("");

    //
    const [courseIntro, setCourseIntro] = useState("");

    const [courseSectionExVideo, setCourseSectionExVideo] = useState("");
    const [videoSrc, setVideoSrc] = useState("");

    const [courseExVideo, setCourseExVideo] = useState("");
    const [courseDate, setCourseDate] = useState("");
    const [courseTopic, setCourseTopic] = useState("");
    const [courseStatus, setCourseStatus] = useState("");
    //
    const [courseDetail, setCourseDetail] = useState("");
    const [courseDiscount, setCourseDiscount] = useState("");

    const [courseSold, setCourseSold] = useState("");
    const [courseDoc, setCourseDoc] = useState("");

    //RATING
    const [ratingSum, setRatingSum] = useState(0);
    const [ratingCount, setRatingCount] = useState(0);

    /* useEffect(() => {
        const productRef = firestore.collection("course");
        const query = productRef
        
            .where("courseID", "==", courseId);
                query
                .get()
                .then((querySnapshot) => {
                    let tempDataArray =[];
                    querySnapshot.forEach((doc) => {
                        if (doc.exists) {
                            tempDataArray = [...tempDataArray,
                            {
                                id: doc.id,
                                merchantID: doc.data().merchantID,
                                storeName: doc.data().storeName,
                                courseCategory: doc.data().courseCategory,
                                courseSubCategoty: doc.data().courseSubCategory,
                                courseTypeCategory: doc.data().courseTypeCategory,

                                courseName: doc.data().courseName,
                                courseCaption: doc.data().courseCaption,
                                courseTopic: doc.data().courseTopic,
                                courseBadge: doc.data().courseBadge,
                                courseFullPrice: doc.data().courseFullPrice,
                                coursePrice: doc.data().coursePrice,   
                                courseImage: doc.data().courseImage,
                                courseDetail: doc.data().courseDetail,
                                courseDiscount: doc.data().courseDiscount,
                                courseSold: doc.data().courseSold,
                                courseDoc: doc.data().courseDoc,

                                courseIntro: doc.data().courseIntro,
                                courseExVideo : doc.data().courseExVideo,
                                courseSectionExVideo : doc.data().courseSectionExVideo,
                                courseStatus: doc.data().courseStatus,

              
                                ratingSum: doc.data().ratingSum,
                                ratingCount: doc.data().ratingCount,
                            },
                        ];


                        setMerchantID(doc.data().merchantID);
                        setStoreName(doc.data().storeName);
                        setCourseCategory(doc.data().courseCategory);
                        setCourseSubCategory(doc.data().courseSubCategory);
                        setCourseTypeCategory(doc.data().courseTypeCategory);
                        setCourseName(doc.data().courseName);
                        setCourseCaption(doc.data().courseCaption);

                        setCourseTopic(doc.data().courseTopic);
                        setCourseBadge(doc.data().courseBadge);
                        setCourseFullPrice(doc.data().courseFullPrice);
                        setCoursePrice(doc.data().coursePrice);
                        setCourseImage(doc.data().courseImage);
                        setCourseDetail(doc.data().courseDetail);
                        setCourseDiscount(doc.data().courseDiscount);
                        setCourseSold(doc.data().courseSold);
                        setCourseDoc(doc.data().courseDoc);

                        setCourseIntro(doc.data().courseIntro);
                        setCourseExVideo(doc.data().courseExVideo);
                        setVideoSrc(doc.data().courseExVideo[0]);
                        setCourseSectionExVideo(doc.data().courseSectionExVideo);
                        setCourseStatus(doc.data().courseStatus);

 
                        setRatingSum(doc.data().ratingSum);
                        setRatingCount(doc.data().ratingCount);
                        };

                    });

                setData((oldDataArray) => tempDataArray);

            },

                (err) => {
                    console.log(err);
                }
                
            );

        }, []); */



    const [courseData, setCourseData] = useState([]);
    var courseSectionArray = [""];
    var courseSectionSrcArray = [""];
    // const [exVideo, setExvideo] = useState("");

   /*  const [courseSectionArrayToString, setCourseSectionArrayToString] = useState("");
        useEffect(() => {
            const userRef = firestore.collection("course").doc(courseId).collection("courseContent").orderBy("sortTopic", "asc");
            userRef.onSnapshot((snapshot) => {

                
                let tempDataArray =[];
                snapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempDataArray = [...tempDataArray,
                        {
                            id: doc.id,
                            topicID: doc.data().topicID,
                            courseTopic: doc.data().courseTopic,
                            courseSrc : doc.data().courseSrc,

                            courseSection : doc.data().courseSection,

                            courseSectionSrc : doc.data().courseSectionSrc,

                            courseSectionType: doc.data().courseSectionType,

                            sortTopic: doc.data().sortTopic,
                            

                        },

                        
                    ];

                    console.log(doc.data());

                    };

        });
            setCourseData((oldDataArray) => tempDataArray);

    },
        (err) => {
            console.log(err);
        }
    );

    }, []); */



    const myStore = () => {
        if (role == "merchant") {
            navigate("/MerchantView")
        } else {
            alert("ร้านค้าของฉันพร้อมเปิดให้บริการเร็วๆ นี้, ขออภัยในความไม่สะดวก");
        }
    }

    const signOutHandler = () => {
        auth.signOut().then(() => {
            console.log("Log Out Ok");
            setCurrentUser(null);
            navigate("/");
        })
        .catch((err) => {
            console.log("Log Out Not Ok." + err);
        })
    };


    //////////////////////////////////// ADD/REMOVE TEXT FIELD /////////////////////////////////////////////////////

    

    /* const [formValues, setFormValues] = useState([{ section: "", links : ""}])

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
      }
    
    let addFormFields = () => {
        setFormValues([...formValues, { section: "", links: "" }])
      }
    
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    } */
    
    //////////////////////////////////////// UPLOAD DATA ///////////////////////////////////////////////////////////
    /* const [courseTopic, setCourseTopic] = useState("");*/

    //TEST Upload Video/Doc
    const [file, setFile] = useState("");
    const [link, setLink] = useState("");

    const subBucketRef = storage.child("mainBucket/course");
    const uploadHandler = (e) => {

        e. preventDefault();

        if (!!file) {

            const fileName = file.name;
            const targetRef = subBucketRef.child(fileName);

            targetRef.put(file).then((response) => {
                console.log(response);
                response.ref.getDownloadURL().then((photoURL) => {
                    console.log(photoURL);
                    setLink(photoURL);
                });

            });

        } else {
                console.log("no file upload!!");
        }

    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // alert(JSON.stringify(formValues));
 
        const obj = {

            
            
        };


        
            ////////////////////////////////////////////////////////////////////
            uploadContentHandler(obj);

    }

    const uploadContentHandler = (obj) => {
           
        const ref = firestore.collection("course").doc("ce0J3diaDdF1glsCbc0N").collection("courseContent");
        ref
            // .doc()
            .add(obj)
            .then(() => {
                console.log("เพิ่มข้อมูลสำเร็จ");
                refreshPage();
            })
            .catch((err) => console.log(err));
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////


    ///////////////////////////////////////////// EXAMPLE VIEW COURSE ///////////////////////////////////////////////////////////
    const [showExVideo, setShowExVideo] = useState(false);

    const handleClose = () => setShowExVideo(false);
    const handleShow = () => setShowExVideo(true);

    const exVideoHandler = () => {
          setShowExVideo(true);
    }

    return (
        <>
        <br/>

        {data.map((item, index) => {

            return (
                            
                <div key={index}>    

                {/* <form onSubmit={submitHandler}> */}
                                
                    <Container>
                        
                        {/* <Link to="/MerchantView" style={{textDecoration: 'none', color: "black"}}> <Button variant="outline-secondary">ร้านค้าของฉัน</Button>{' '}</Link> */}
                        <Button onClick={myStore} variant="outline-secondary">ร้านค้าของฉัน</Button>{' '}
                        <Button onClick={signOutHandler} variant="outline-danger" type="submit">ออกจากระบบ</Button>
                        <br/>
                        <br/>
                        <br/>
                        <h3><strong>สร้างคอร์สเรียน</strong></h3>
                        <h3 className="pb-3 border-bottom"></h3>
                      
                    </Container>

                {/* </form> */}
             
                </div>
              
            );
        })}


        <Container style={{ maxWidth: 450 }}>

        <br/>

        <Row>

            <Col>

                <Col style={{textAlign: "center"}}><img src={product_sample01} alt="John Doe" className="mr-3 mt-3 rounded-circle" style={{width: 150}} /> </Col>
                {/* <h3 className="pb-3 border-bottom"></h3> */}

                <br/>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>ชื่อผู้สอน</strong></Form.Label>
                        <Form.Control className="rounded-0" type="email" placeholder="ชื่อผู้สอน" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>อาชีพ</strong></Form.Label>
                        <Form.Control className="rounded-0" type="email" placeholder="อาชีพ" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>เกี่ยวกับผู้สอน</strong></Form.Label>
                        <Form.Control className="rounded-0" as="textarea" rows={3} placeholder="เกี่ยวกับผู้สอน"/>
                    </Form.Group>

                    <br/>
                    <h3 className="pb-3 border-bottom"></h3>
                    <br/>
                    <Form.Label><strong>ลิ้งค์โซเชียล (ไม่บังคับ)</strong></Form.Label>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>Facebook</strong></Form.Label>
                        <Form.Control className="rounded-0" type="email" placeholder="Facebook" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>Youtube</strong></Form.Label>
                        <Form.Control className="rounded-0" type="email" placeholder="Youtube" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label><strong>TikTok</strong></Form.Label>
                        <Form.Control className="rounded-0" type="email" placeholder="TikTok" />
                    </Form.Group>
                </Form>

                <br/>
                <span className="rounded-0 float-end"><Link to={"/CreateCourse"}><Button variant="outline-success" style={{textDecoration: 'none'}} >
                <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>&nbsp;&nbsp;&nbsp;สร้างคอร์สเรียน</Button></Link>{' '}</span>
                <br/>

            </Col>

        </Row>

        </Container>


        <br/>
        <br/>
      

        </>
    );
}

export default CreateCourse;