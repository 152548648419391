import React, { useState, useEffect, useContext } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Image, InputGroup } from "react-bootstrap";

import { firestore } from "../../database/firebase";
import { storage } from "../../database/firebase";

import { useParams, Link, redirect, useNavigate, useLocation } from "react-router-dom";

import { auth } from "../../database/firebase";
import { AuthContext } from "../Authentication/AuthProvider";

import { FaPencilAlt, FaDiaspora, FaPlus } from "react-icons/fa";
import { AiOutlineSketch } from "react-icons/ai";
import QrPayment01 from '../images_s/QrPayment01.jpg';
import QrPayment02 from '../images_s/QrPayment02.jpg';

function CheckoutCart() {

    // <Image src={[QrPayment01]} />

    //Test useParams
    let { productId } = useParams();

    // RECIEVE PRODUCT QUANTITY
    /* const location = useLocation();
    const state = location.state; */
    // console.log("THIS IS FROM CHECKOUR CART" +(state));
    // console.log(location.state);
    // const [productAmount, setProductAmount] = useState(location.state);

    //TEST Upload OrderPayin Image
    const [file, setFile] = useState("");
    const [link, setLink] = useState("");
    const subBucketRef = storage.child("mainBucket/transferSlip");

    const uploadHandler = (e) => {

        e. preventDefault();

        if (!!file) {

            const fileName = file.name;
            const targetRef = subBucketRef.child(fileName);

            targetRef.put(file).then((response) => {
                console.log(response);
                response.ref.getDownloadURL().then((photoURL) => {
                    console.log(photoURL);
                    setLink(photoURL);
                });

            });

        } else {
            console.log("no file upload!!");
        }

    };


    /* TEST QUERY USER-DATA WITH productID */
    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    const signUpPage = () => {
        navigate("/SignUp");
    }

    /* TEST QUERY DATA WITH productID */
    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

    var user = auth.currentUser;
    var uid = "";

    if (user != null) {
        
        uid = user.uid;  
        console.log(uid);
        
    } else {
        signUpPage();
    }

   
    const [loading, setLoading] = useState(false);

    const [userID, setUserId] = useState("");
    const [usernameOrder, setUsernameOrder] = useState("");
    const [userphonenumberOrder, setUserphonenumberOrder] = useState("");
    const [useraddressOrder, setUseraddressOrder] = useState("");


    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [address, setAddress] = useState("");

    const [sPoint, setSPoint] = useState("");

    const [sPointUse, setSPointUse] = useState(0);

    const [dataUser, setDataUser] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
    
        .where("uid", "==", uid);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            id: doc.id,
                            uid: doc.data().uid,
                            name: doc.data().name,
                            email: doc.data().email,
                            phonenumber: doc.data().phonenumber,
                            address: doc.data().address,
                            sPoint: doc.data().sPoint,   
                            
                            
                        },
                        
                    ];


                    setUserId(doc.data().uid)
                    setUsernameOrder(doc.data().name);
                    setUserphonenumberOrder(doc.data().phonenumber);
                    setUseraddressOrder(doc.data().address);

                    setEmail(doc.data().email);

                    setSPoint(doc.data().sPoint);
                    

                    };

                });

            setDataUser((oldDataArray) => tempDataArray);
            

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [uid]); 

        /* const [productFullPrice, setProductFullPrice] = useState("");
        const [productPrice, setProductPrice] = useState(""); */

        const [merchantID, setMerchantID] = useState("");
        const [storeName, setStoreName] = useState("");

        const [productFullPrice, setProductFullPrice] = useState("");
        const [productPrice, setProductPrice] = useState("");
        const [productQuantity, setProductQuantity] = useState("");

        const [productShippingPrice, setProductShippingPrice] = useState("");

        const [productWeightSum, setProductWeightSum] = useState("");
        const [productWeightCost, setProductWeightCost] = useState("");
        // const [productPriceSum, setProductPriceSum] = useState("");

        //?????????????????????????????????????????????????????????????????????
         //MERCHANT ID ARRAY
         const [merchantIdToString, setMerchantIdToString] = useState("");
         //STORE NAME ARRAY
         const [storeNameToString, setStoreNameToString] = useState("");
         //?????????????????????????????????????????????????????????????????????

        //PRODUCT ID ARRAY
        const [productIdToString, setProductIdToString] = useState("");
        //PRODUCT NAME ARRAY
        const [productArrayToString, setProductArrayToString] = useState("");
        //PRODUCT QUANTITY ARRAY
        const [productQuantityToString, setProductQuantityToString] = useState("");

        // ADD ARRAY TO STRING DATA/////////////////////////////
        var productIdArray = [""];
        var merchantIdArray = [""];
        var storeNameArray = [""];
        var productArray = [""];
        var productQuantityArray = [""];
        ////////////////////////////////////////////////////////
    
        var productCartFullPrice = "";

        var productPriceCart = "";

        var productWeight = "";

        const formatter = new Intl.NumberFormat('th', {
            style: 'currency',
            currency: 'THB',
          
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          });
        
        // const [productPriceCart, setProductPriceCart] = useState("");


        const [productShippingCost, setProductShippingCost] = useState("");
    
        const [data, setData] = useState([]);
        useEffect(() => {
        const productRef = firestore.collection("cart");
        const query = productRef
        
            .where("userID", "==", uid);
                query
                .get()
                .then((querySnapshot) => {
                    let tempDataArray =[];
                    querySnapshot.forEach((doc) => {
                        if (doc.exists) {

                            productIdArray.push(doc.data().productID);
                            setProductIdToString(productIdArray.toString());

                            productArray.push(doc.data().productName);
                            setProductArrayToString(productArray.toString());

                            productQuantityArray.push(doc.data().productQuantity);
                            setProductQuantityToString(productQuantityArray.toString());


                            tempDataArray = [...tempDataArray,
                            {
                                id: doc.id,
                                merchantID: doc.data().merchantID,
                                storeName: doc.data().storeName,
                                productName: doc.data().productName,
                                productID: doc.data().productID,
                                productPrice: doc.data().productPrice,
                                productQuantity: doc.data().productQuantity,
                                productWeight: doc.data().productWeight,
                                
                                productFullPrice: doc.data().productFullPrice,
                                productShippingCost: doc.data().productShippingCost,
                                // productPriceTotal: doc.data().productPrice * doc.data().productQuantity,
                            },
                        ];

                        // setProductFullPrice(doc.data().productFullPrice);

                        setMerchantID(doc.data().merchantID);
                        setStoreName(doc.data().storeName);

                        setProductPrice(doc.data().productPrice);
                        // setProductShippingCost(doc.data().productShippingCost);
                        setProductQuantity(doc.data().productQuantity);

                        /////////////////////////////////////////////////////////////////////////////////////////////////
                        //PRODUCT ID ARRAY
                        

                        //MERCHANT ID ARRAY
                        /* merchantIdArray.push(doc.data().merchantID);
                        setMerchantIdToString(merchantIdArray.toString()); */

                        //STORENAME ARRAY
                        /* storeNameArray.push(doc.data().storeName);
                        setStoreNameToString(storeNameArray.toString()); */

                        // PRODUCTNAME ARRAY
                        

                        // PRODUCT QUANTITY ARRAY
                        


                        // console.log("THIS IS PRODUCT NAME ARRAY TO STRING" + (productArrayToString));
                        // console.log("THIS IS PRODUCT QUANTITY ARRAY TO STRING" + (productQuantityToString));
                        ////////////////////////////////////////////////////////////////////////////////////////////////////


                        
                        productCartFullPrice = tempDataArray.reduce((totalPrices, products) => totalPrices + parseFloat(products.productFullPrice * products.productQuantity, 10), 0);
                        productPriceCart = tempDataArray.reduce((totalPrice, product) => totalPrice + parseFloat(product.productPrice * product.productQuantity, 10), 0);
                        productWeight = tempDataArray.reduce((totalPriceS, productweight) => totalPriceS + parseFloat(productweight.productWeight, 10), 0);
                        /////////////////////////////
                        setProductFullPrice(productCartFullPrice);
                        setProductPrice(productPriceCart);
                        setProductWeightSum(productWeight);
                        console.log("THIS IS Weight" + (productWeightSum));
                        /////////////////////////////
                        /* console.log("THIS IS PriceCart" + (productPriceCart));
                        console.log("THIS IS PRODUCT SHIPPING COST" + (productShippingCost));
                        console.log("THIS IS PriceCart WITH SHIPPING COST" + (parseInt(productPriceCart) + parseInt(productShippingCost))); */
                        /////////////////////////////
                        
                        };

                    });

                setData((oldDataArray) => tempDataArray);

            },

                (err) => {
                    console.log(err);
                }
                
            );

        }, [uid]); 

        console.log(productArray);

        //TEST ADD PRODUCT-DATA WITH Button AND Form
        const [productID, setProductID] = useState("");

        //const [merchantID, setMerchantID] = useState("");
        //const [storeName, setStoreName] = useState("");

        const [productName, setproductName] = useState("");
        // const [productPrice, setproductPrice] = useState("");
        const [productPriceTotal, setProductPriceTotal] = useState("");
        const [orderPayin, setorderPayin] = useState("");

        const addOrderHandler = (obj) => {
            const ref = firestore.collection("orderCart");
            ref
                .add(obj)
                .then(() => {
                    console.log("เพิ่มข้อมูลสำเร็จ");
                    navigate("/CheckoutSuccess");
                })
                .catch((err) => console.log(err));
        };

        const editUserHandler = (obj) => {
        
            const ref = firestore.collection("user");
            ref
                .doc(uid)
                .update(obj)
                .then(() => {
                    console.log("แก้ไขข้อมูลสำเร็จ");
                    refreshPage();
                })
                .catch((err) => console.log(err));
        };

        const submitHandler = (e) => {
            e.preventDefault();
            const obj = {
                productID : productIdToString,

                merchantID: merchantID,
                storeName: storeName,

                productName : productArrayToString, //ARRAY [productName] ????
                productPrice : productPrice,
                productQuantity: productQuantityToString, //????
                productShippingCost: productWeightCost,
                productPriceTotal : parseFloat(productPrice) + parseFloat(productWeightCost) - parseFloat(sPointUse),
                orderPayin : link,

                orderDate: new Date(),
                orderStatus: "กำลังดำเนินการ",

                userID: userID,
                usernameOrder: usernameOrder,
                userphonenumberOrder: userphonenumberOrder,
                useraddressOrder: useraddressOrder,

                sPointUse: sPointUse,


            };
            setProductID("");

            setMerchantID("");
            setStoreName("");

            setproductName("");
            setProductPrice("");
            setProductPriceTotal("");

            setSPointUse("");

            /* setUsernameOrder("");
            setUserphonenumberOrder("");
            setUseraddressOrder(""); */


            setorderPayin("");

            addOrderHandler(obj);


            const objUser = {

                name : usernameOrder,
                phonenumber : userphonenumberOrder,
                address: useraddressOrder,
                sPoint: parseFloat(sPoint) - parseFloat(sPointUse),

            };

            editUserHandler(objUser);

        };


        //productWeight Cost
        // *DDC J&T EXPRESS AND KERRY
        useEffect(() => {
                //J&T EXPRESS RATE
            if (productWeightSum <= 2000) {
                setProductWeightCost(38);
                // console.log("This Is Product Weight Cost" + productWeightCost);
            } else if (productWeightSum <= 3000) {
                setProductWeightCost(58);
            } else if (productWeightSum <= 4000) {
                setProductWeightCost(64);
            } else if (productWeightSum <= 5000) {
                setProductWeightCost(74);
            } else if (productWeightSum <= 6000) {
                setProductWeightCost(84);
            } else if (productWeightSum <= 7000) {
                setProductWeightCost(94);
            } else if (productWeightSum <= 8000) {
                setProductWeightCost(104);
            } else if (productWeightSum <= 9000) {
                setProductWeightCost(110);
            } else if (productWeightSum <= 10000) {
                setProductWeightCost(125);
            } else if (productWeightSum <= 11000) {
                setProductWeightCost(150);
            } else if (productWeightSum <= 12000) {
                setProductWeightCost(150);
            } else if (productWeightSum <= 15000) {
                //KERRY RATE
                setProductWeightCost(220);
            } else if (productWeightSum <= 20000) {
                setProductWeightCost(350);
            } else {
                //IDC RATE
                setProductWeightCost(0);
            }
          }, [productWeightSum]);



        useEffect(() => {
        
            if (sPointUse == "") {
                // setSPointUse("");
            } else if (sPointUse >= sPoint) {
                setSPointUse(sPoint);
            } else {
                setSPointUse(sPointUse);
            }
            /* if (sPointUse >= 0) {
                setproductPriceTotal(() => formatter.format(parseFloat(productPrice) * parseFloat(productAmount) + parseFloat(productShippingCost) - 0));
            } else if (sPointUse >= sPoint) {
                setSPointUse(sPoint);
                setproductPriceTotal(() => formatter.format(parseFloat(productPrice) * parseFloat(productAmount) + parseFloat(productShippingCost) - parseFloat(sPoint)));
            } else {
                setSPointUse(sPointUse);
                setproductPriceTotal(() => formatter.format(parseFloat(productPrice) * parseFloat(productAmount) + parseFloat(productShippingCost) - parseFloat(sPointUse)));
            } */
    
        }, [sPointUse]);


    return (
        <>

        {/* Row 2  */}
        <br/>
        <br/>
        <Container className="">
            
            {/* Row 0 Header Checkout */}
            <h3><strong>ชำระเงินตะกร้าสินค้า</strong></h3>
            {/* Test useParams */}
            {/* <h3>This is Params {productId}</h3> */}
            <h3 className="pb-3 border-bottom"></h3>
            <br/>

            <Row>

            <Col sm={7}>
            <h4><strong>ที่อยู่จัดส่งสินค้า</strong></h4>

            {dataUser.map((itemUser, index) => {
                return (
                            
                <div key={index}>  

                <br/>

                    <h5><strong>ชื่อ - นามสกุล</strong></h5>
                    {/* <FaDiaspora/> */}
                    <Form.Control 
                        type="text"
                        className="rounded-0" 
                        style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                        value={usernameOrder}
                        onChange={(e) => {
                            setUsernameOrder(e.target.value);
                        }}
                        placeholder={"*กรุณากรอกข้อมูล"}
                    />
                    <br />
                                                                        
                    <h5><strong>หมายเลขโทรศัพท์</strong></h5>
                    {/* <FaDiaspora/> */}
                    <Form.Control 
                        type="text" 
                        className="rounded-0"
                        style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                        value={userphonenumberOrder}
                        onChange={(e) => {
                            setUserphonenumberOrder(e.target.value);
                        }}
                        placeholder={"*กรุณากรอกข้อมูล"}                               
                    />
                    <br />

                    <h5><strong>ที่อยู่ผู้รับสินค้า</strong></h5>
                    {/* <FaDiaspora/> */}
                    <Form.Control 
                        as="textarea" 
                        className="rounded-0"
                        rows={3}  
                        style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                        value={useraddressOrder}
                        onChange={(e) => {
                            setUseraddressOrder(e.target.value);
                        }}
                        placeholder={"*กรุณากรอกข้อมูล"}         
                    />
                                        
                    <h4><strong>วิธีการชำระเงิน</strong></h4>
        
                    <p>บัญชีธนาคารไทยพาณิชย์ นายวิรัช  วิสาขศาสตร์ เลขที่ 4150799367</p>
                                    
                    <Image src={QrPayment01} fluid width={300} height={400}/>
                            <br/>
                            <br/>
                            <br/>

                </div>

                );
            })}


            </Col>

                <Col className="bg-light p-3" sm={5}>
                    <h4><strong>ยอดชำระเงินทั้งหมด</strong></h4>
                    <br/>

                    {data.map((item, index) => {
                        return (
                            
                            <div key={index}>   

                                <form onSubmit={submitHandler} className="was-validated">
                                สินค้า: <span className="float-end">{item.productName}</span><br/>
                                ราคา: <span className="float-end">฿{item.productPrice} <del>฿{item.productFullPrice}</del></span><br/>
                                จำนวน: <span className="float-end">{item.productQuantity}</span><br/>
                                ราคารวม: <span className="float-end">{formatter.format(parseFloat(item.productPrice) * parseFloat(item.productQuantity)) }</span><br/>
                                
                                <br/>
                            <div>

                                </div>

                                </form>

                            </div>

                        );
                    })}
                                ราคาสินค้ารวม: <span className="float-end">{formatter.format(productPrice)}</span><br/>
                                {/* <del style={{color: "gray"}}>฿{productFullPrice}</del> */}
                                ค่าจัดส่งรวม: <span className="float-end">{formatter.format(parseFloat(productWeightCost))}</span><br/>

                                <h3 className="pb-3 border-bottom"></h3>
                                <br/>
                                {/* <p>ยอดชำระเงินทั้งหมด: <span className="float-end"> {formatter.format(parseFloat(productPrice) * parseFloat(productAmount) + parseFloat(productShippingCost) - parseFloat(sPointUse))}</span> 
                                <br/><Link style={{textDecoration: 'none', color: "yellowgreen"}} onClick={submitSPointHandler}> ใช้คะแนนเป็นส่วนลดหรือไม่?</Link></p> */}
                                
                                {/* <p><span style={{color: "skyblue", fontSize: 30}}><AiOutlineSketch/></span> : {sPoint} ใช้คะแนนเป็นส่วนลด</p> */}
                                    {/* <Form>
                                        <Form.Control 
                                            type="text" 
                                            className="rounded-0"
                                            placeholder="กรอกคะแนนสะสม"
                                            value={sPointUse}
                                            onChange={(e) => {
                                                setSPointUse(e.target.value);
                                            }}
                                        />
                                    </Form> */} 
                                    <InputGroup size="sm" className="mb-3">
                                                <InputGroup.Text><span style={{color: "skyblue", fontSize: 18}}><AiOutlineSketch/></span> : {parseInt(sPoint)} ใช้ส่วนลด</InputGroup.Text>
                                                    <Form.Control 
                                                        type="text" 
                                                        className="rounded-0"
                                                        placeholder="กรอกคะแนนสะสม"
                                                        value={sPointUse}
                                                        onChange={(e) => {
                                                            setSPointUse(e.target.value);
                                                        }}
                                                    />
                                                <InputGroup.Text>฿</InputGroup.Text>
                                    </InputGroup>

                                    <br/>

                                <p><strong>ยอดชำระเงินทั้งหมด: <span className="float-end"> {formatter.format(parseFloat(productPrice) + parseFloat(productWeightCost) - parseFloat(sPointUse))}</span></strong></p>
                                {/* <p>{productShippingCost}</p> */}
                                <br/>

                                <Form.Label>อัพโหลดหลักฐานการชำระเงิน</Form.Label>
                                <InputGroup className="mb-3 rounded-0 was-validated">
                                    <Form.Control
                                        aria-label="Example text with button addon"
                                        aria-describedby="basic-addon1"
                                        type="file"
                                        className="custom-file-label rounded-0"  
                                        id="customFile" 
                                        placeholder="" 
                                        name="customFile" 
                                        onChange={ (e) => {
                                            const file = e.target.files[0];
                                            setFile(file);
                                            setLink("");
                                        }}
                                        required
                                    />
                                   
                                    <Button 
                                    type="submit"
                                    onClick={uploadHandler}
                                    className="btn btn-success rounded-0"
                                    >
                                    อัลโหลด
                                    </Button>
                                </InputGroup>

                                {/* </form> */}

                                {!!link ? (

                                <div className="text-center mt-4">

                                    <hr/>

                                    <a className="text-secondary" href={link} target="_blank" rel="noreferrer">
                                        คลิกไฟล์ {file.name}
                                    </a>

                                <img src={link} width={350} height={450} fluid/>

                                </div>

                                ) : null}

                                <br/>
                                <br/>

                        {!link ? 
                            <>
                                <div className="d-grid gap-2">
                                    <Button  variant="outline-success" className="btn-block rounded-0" disabled>สั่งซื้อสินค้า</Button>
                                </div>
                                <br/> 
                            </>
                                :
                            <>
                                <div className="d-grid gap-2">
                                    <Button onClick={submitHandler} className="btn btn-success btn-block rounded-0" type="submit">สั่งซื้อสินค้า</Button>
                                </div>
                                <br/>
                                <p>การันตีรับสินค้าภายใน 1-2 วัน</p>
                            </>
                        }


                    <br/>
                    
                </Col>

            </Row>
            
        </Container>

        <br/>
        <br/>

        </>
    );

}  

export default CheckoutCart;