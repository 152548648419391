import React, { useState, useEffect, useContext } from "react";
import { Container, Tabs, Tab, Form, Col, Row, Button, Card } from "react-bootstrap";
import { Link, redirect, useNavigate } from "react-router-dom";

import { FaSketch } from "react-icons/fa";

import { auth, firestore } from "../../database/firebase";

import AddProduct from "./AddProduct";
import ProductExpress from "./ProductExpress";

import { AuthContext } from "../../component_s/Authentication/AuthProvider";
import Order from "../Order/Order";
import { parse } from "@fortawesome/fontawesome-svg-core";

import { FaPlusCircle } from "react-icons/fa";

function OrderCart() {

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    /* TEST QUERY DATA WITH productID */

    // const [uid, setUid] = useState("");

    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

   //const [uid, setUid] = useState("");

    var user = auth.currentUser;
    // var uid = "";
    var uid = "";

    if (user != null) {
        //email = user.email;
        uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                         // this value to authenticate with your backend server, if
                         // you have one. Use User.getToken() instead.
        console.log(uid);
        
        //TEST AUTH
        // refreshPage();
    }
    
    // const [emailDefault, setEmailDefault] = useState("");

    const [loading, setLoading] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [address, setAddress] = useState("");

    const [userId, setUserId] = useState("");

    const [spoint, setSpoint] = useState("");

    
    //THIS IS MERCHANT DATA
    const [dataUser, setDataUser] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
    
        .where("uid", "==", uid);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            id: doc.id,
                            uid: doc.data().uid,
                            name: doc.data().name,
                            email: doc.data().email,
                            phonenumber: doc.data().phonenumber,
                            address: doc.data().address,

                            sPoint: doc.data().sPoint,
                            
                        },
                        
                    ];

                    setUserId(doc.data().uid);
                    setSpoint(doc.data().sPoint);
                    // setSPoint(doc.data().sPoint);


                    /* setUsernameOrder(doc.data().name);
                    setUserphonenumberOrder(doc.data().phonenumber);
                    setUseraddressOrder(doc.data().address); */
                    // setEmail(doc.data().email);
                    
                    };

                });

            setDataUser((oldDataArray) => tempDataArray);
            

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [uid]); 



    const [data, setData] = useState([]);

    const [orderID, setOrderID] = useState("");

    const [priceTotal, setPriceTotal] = useState("");
    const [productPriceTotal, setProductPriceTotal] = useState("");

    const [sPointUse, setSPointUse] = useState("");

    const [userID, setUserID] = useState("");
    

    useEffect(() => {
        const orderRef = firestore.collection("orderCart");
        const query = orderRef
            
            .where("merchantID", "==", uid)
            .where("orderStatus", "==", "กำลังดำเนินการ");
                query
                .get()
                .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempDataArray = [...tempDataArray,
                        {
                            // productID: doc.id,
                            id: doc.id,
                            productID: doc.data().productID,
                            storeName: doc.data().storeName,
                            productName: doc.data().productName,
                            // productImage: doc.data().productImage,
                            productPrice: doc.data().productPrice,
                            productQuantity: doc.data().productQuantity,
                            productPriceTotal: doc.data().productPriceTotal,
                            productShippingCost: doc.data().productShippingCost,
                            
                            orderStatus: doc.data().orderStatus,
                            orderDate: Date(doc.data().orderDate),

                            userID: doc.data().userID,
                            usernameOrder: doc.data().usernameOrder,
                            userphonenumberOrder: doc.data().userphonenumberOrder,
                            useraddressOrder: doc.data().useraddressOrder,

                            sPointUse: doc.data().sPointUse,

                            //priceTotal: priceTotal += priceTotal,
                

                        },
                    ];

                    setUserID(doc.data().userID);
                    setProductPriceTotal(doc.data().productPriceTotal);

                    setSPointUse(doc.data().sPointUse);

                    setOrderID(doc.id);

                    
                    };

            });
            setData((oldDataArray) => tempDataArray);
        
    },
        (err) => {
            console.log(err);
        }
    );

    }, [uid]);



    const [customerID, setCustomerID] = useState("");
    const [sPoint, setSPoint] =  useState("");

    const [payCount, setPayCount] = useState("");
    const [payAmount, setPayAmount] = useState("");

    useEffect(() => {
        const customerRef = firestore.collection("user");
        const query = customerRef
            
            .where("uid", "==", userID)
            // .where("orderStatus", "==", "กำลังดำเนินการ");
                query
                .get()
                .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempDataArray = [...tempDataArray,
                        {
                            // productID: doc.id,
                            id: doc.id,
                            
                            uid: doc.data().uid,
                            name: doc.data().name,
                            email: doc.data().email,
                            phonenumber: doc.data().phonenumber,
                            address: doc.data().address,

                            sPoint: doc.data().sPoint,

                            payCount: doc.data().payCount,
                            payAmount: doc.data().payAmount,
                    
                

                        },
                    ];

                    setSPoint(doc.data().sPoint);
                    setPayCount(doc.data().payCount);
                    setPayAmount(doc.data().payAmount);

                   
                    
                    };

            });
            setCustomerID((oldDataArray) => tempDataArray);
        
    },
        (err) => {
            console.log(err);
        }
    );

    }, [userID]);


    console.log(sPoint);
    console.log(userID);



    const addSPointHandler = () => {

        // ADD FUNCTION UPDATE +SPOINT TO USER -COLLECTION
        const objSpoint = {
            
            //orderDate: new Date(),
            sPoint: (parseInt(productPriceTotal) / 100) + parseInt(sPoint),

            //PayCount
            payCount: parseInt(1) + parseInt(payCount),
            //PayAmount
            payAmount: parseInt(productPriceTotal) + parseInt(payAmount)

        };
        
        const userref = firestore.collection("user");
        userref
            .doc(userID)
            .update(objSpoint)
            .then(() => {
                console.log("แก้ไขข้อมูลสำเร็จ");
                refreshPage();
            })
            .catch((err) => console.log(err));

    }

    

    const editHandler = (id) => {
        // e.preventDefault();
        const obj = {
            
            //orderDate: new Date(),
            orderStatus: "จัดส่งแล้ว",

        };
        
        const ref = firestore.collection("orderCart");
        ref
            .doc(id)
            .update(obj)
            .then(() => {
                addSPointHandler();
                console.log("แก้ไขข้อมูลสำเร็จ");
                refreshPage();
            })
            .catch((err) => console.log(err));


    };

    
    return (
        <>
        <br/>
 
                <form>
                                
                    

                            

                            {!!orderID ? (
                                <>
                                    <br/>
                                    <br/>

                                    <h3><strong>รายการสั่งซื้อสินค้าในตะกร้า</strong></h3>

                                    <Row>
                                        {data.map((item, index) => {
                                            return (
                                                <div key={index}>     
                                                <h3 className="pb-3 border-bottom"></h3>
                                                    <br/>
                                                <Card className="rounded-0">
                                                        <Card.Header>
                                                            <strong>คำสั่งซื้อที่: {item.id}</strong>
                                                        </Card.Header>


                                                    <Card.Body>
                                            
                                                        <Card.Text>
                                                            <strong>ร้านค้า:</strong> {item.storeName}<br/>
                                                            <strong>ชื่อสินค้า:</strong> {item.productName}<br/>
                                                            <strong>ราคา:</strong> {item.productPrice}<br/>
                                                            <strong>จำนวน:</strong> {item.productQuantity}<br/>
                                                            <strong>ค่าจัดส่ง:</strong> {item.productShippingCost}<br/>
                                                            <strong>ส่วนลด:</strong> {item.sPointUse}<br/><br/>
                                                            <strong>จำนวนเงินทั้งสิ้น:</strong> {item.productPriceTotal} บาท<br/>
                                                            {item.orderDate}<br/><br/>
                                                            <strong>สถานะ: <span style={{color: "lightsalmon"}}>{item.orderStatus}</span></strong>
                                                            </Card.Text>

                                                            <h3 className="pb-3 border-bottom"></h3>
                                                            <strong>ชื่อผู้รับ:</strong> {item.usernameOrder}<br/>
                                                            <strong>หมายเลขโทรศัพท์:</strong> {item.userphonenumberOrder}<br/>
                                                            <strong>ที่อยู่จัดส่งสินค้า:</strong> {item.useraddressOrder}<br/>

                                                            <br/>

                                                            <Button onClick={ () => (editHandler(item.id))} className="btn btn-success rounded-0">จัดส่งแล้ว</Button>{' '}
                                                            {/* <Button onClick={ () => (deleteHandler(item.id))} className="btn btn-danger rounded-0">ลบรายการ</Button><br/><br/> */}
                                                    </Card.Body>

                                                </Card>

                                                </div>
                                                
                                            );
                                        })}

                                    </Row>

                                </>
                            ) : (
                                <>

                                    <h3><strong>รายการสั่งซื้อสินค้าในตะกร้า</strong></h3>
                                    <h3 className="pb-3 border-bottom"></h3>
                                    <p>ยังไม่มีการสั่งซื้อสินค้าในตะกร้า</p>

                                </>
                            )}
                        
                            
           
                    

                </form>
                    

        <br/>
 
      

        </>
    );
}

export default OrderCart;