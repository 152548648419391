import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from "react-bootstrap";

import { NavLink } from "react-router-dom";

import { FaFacebookF, FaInstagram, FaPinterest, FaTwitter, FaYoutube, FaCheck, FaSketch, FaOpencart } from "react-icons/fa";
import { BsSendCheck, BsFill1CircleFill, BsFill2CircleFill, BsFill3CircleFill, BsFill4CircleFill, BsFill5CircleFill, BsCheckLg, BsFillPatchCheckFill } from "react-icons/bs";

import ads_aboutus_01 from '../images_s/ads_aboutus_01.jpg';

function AboutEnqueX() {

    return (

        <Container>

            <br/>

            <h3><strong><BsFillPatchCheckFill/> เกี่ยวกับเรา</strong></h3>
       
            <h3 className="pb-3 border-bottom"></h3>
            <br/>

            {/* <img style={{borderRadius: 5}}
            className="d-block w-100"
            src={ads_aboutus_01} fluid
            alt="First slide" /> */}
            {/* <h4 style={{fontSize:80, color: 'black'}}>❝</h4> */}
            <h5 style={{marginTop: 5}}>EnqueX (เอ็น-คิว-เอ็กซ์) คือ บริการเพื่อร่วมบันทึกและแบ่งปันความรู้ ประสบการณ์ เรื่องราว หรือแลกเปลี่ยนไอเดียต่าง ๆ ออกแบบโดยรองรับการเขียนบทความและบันทึกเก็บไว้ของผู้ใช้งาน<br/><br/>หากมีข้อสงสัยอย่างลังเลที่จะติดต่อเรา ทีมงานพร้อมตอบคำถามในทันที</h5>

            {/* <h4 style={{fontSize:150, color: 'black'}}>❝</h4>
            <h4 style={{marginTop: -70}}>พวกไม่หยุดที่จะสรรหาสินค้าที่ดีเพื่อคุณ</h4>

            <h4 style={{fontSize:150, color: 'black'}}>❝</h4>
            <h4 style={{marginTop: -70}}>พวกไม่หยุดที่จะสรรหาสินค้าที่ดีเพื่อคุณ</h4> */}

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            

        </Container>

    );

}

export default AboutEnqueX;