import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from "react-bootstrap";

import { NavLink, Link } from "react-router-dom";

import { FaFacebookF, FaInstagram, FaPinterest, FaTwitter, FaYoutube, FaCheck, FaSketch } from "react-icons/fa";
import { BsSendCheck, BsFill1CircleFill, BsFill2CircleFill, BsCheckLg, BsFillRecordFill, BsFacebook, BsInstagram, BsTelephone } from "react-icons/bs";


function Tracking() {

    return (

        <Container>

            <br/>

            <h3><strong>ติดตามสถานะสินค้า</strong></h3>
        
            <h3 className="pb-3 border-bottom"></h3>
            <br/>
            <h4><strong>ตรวจสอบสถานะการซื้อ</strong></h4>
            <p>สามารถตรวจสอบได้ที่ช่องทาง <Link to="/User/Profile" style={{textDecoration: 'none'}}>การซื้อของฉัน</Link> </p><br/>

            <h4><strong>เกี่ยวกับสถานะการซื้อ</strong></h4>
            <p><span style={{color: "lightsalmon"}}> กำลังดำเนินการ</span> - ผู้ขายกำลังดำเนินการตรวจสอบคำสั่งซื้อ <br/>
            <span style={{color: "limegreen"}}> จัดส่งแล้ว</span> - ผู้ขายกำลังดำเนินการจัดส่งสินค้า</p><br/>

            <h4><strong>ช่องทางติดตามสถานะสินค้าอื่น ๆ</strong></h4>
            <p>Facebook Messenger  <a href="https://www.facebook.com/profile.php?id=61552083667050" target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}><BsFacebook/> EnqueX</a><br/>
            Instagram Direct Messages <a href="https://www.instagram.com/enquex.official/" target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}><BsInstagram/>EnqueX</a><br/>
            </p>
            {/* โทรศัพท์ <BsTelephone/> 0805514821</p> */}

            
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

        </Container>

    );

}

export default Tracking;