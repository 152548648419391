import React, { useState, useEffect, useContext } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Image, InputGroup } from "react-bootstrap";

import { firestore } from "../../database/firebase";
import { storage } from "../../database/firebase";

import { useParams, Link, redirect, useNavigate } from "react-router-dom";

import { auth } from "../../database/firebase";
import { AuthContext } from "../../component_s/Authentication/AuthProvider";

import { FaPencilAlt, FaDiaspora, FaPlus, FaSketch } from "react-icons/fa";
import { AiOutlineSketch } from "react-icons/ai";




function AddProduct() {

    // <Image src={[QrPayment01]} />
    
    //Test useParams
    let { productId } = useParams();


    //TEST Upload OrderPayin Image
    const [file, setFile] = useState("");
    const [link, setLink] = useState("");

    const [filePdf, setFilePdf] = useState("");
    const [linkPdf, setLinkPdf] = useState("");

    const [fileImg01, setFileImg01] = useState("");
    const [linkImg01, setLinkImg01] = useState("");

    const [fileImg02, setFileImg02] = useState("");
    const [linkImg02, setLinkImg02] = useState("");

    const [fileImg03, setFileImg03] = useState("");
    const [linkImg03, setLinkImg03] = useState("");

    const [fileImg04, setFileImg04] = useState("");
    const [linkImg04, setLinkImg04] = useState("");

    const [fileImg05, setFileImg05] = useState("");
    const [linkImg05, setLinkImg05] = useState("");


    const subBucketRef = storage.child("mainBucket/productImage");

    const uploadHandler = (e) => {

        e. preventDefault();

        if (!!file) {

            const fileName = file.name;
            const targetRef = subBucketRef.child(fileName);

            targetRef.put(file).then((response) => {
                console.log(response);
                response.ref.getDownloadURL().then((photoURL) => {
                    console.log(photoURL);
                    setLink(photoURL);
                });

            });

        } else {
                console.log("no file upload!!");
        }

    };

    const uploadDetailImg01Handler = (e) => {

        e. preventDefault();

        if (!!fileImg01) {

            const fileName = fileImg01.name;
            const targetRef = subBucketRef.child(fileName);

            targetRef.put(fileImg01).then((response) => {
                console.log(response);
                response.ref.getDownloadURL().then((photoURL) => {
                    console.log(photoURL);
                    setLinkImg01(photoURL);
                });

            });

        } else {
                console.log("no file upload!!");
        }

    };

    const uploadDetailImg02Handler = (e) => {

        e. preventDefault();

        if (!!fileImg02) {

            const fileName = fileImg02.name;
            const targetRef = subBucketRef.child(fileName);

            targetRef.put(fileImg02).then((response) => {
                console.log(response);
                response.ref.getDownloadURL().then((photoURL) => {
                    console.log(photoURL);
                    setLinkImg02(photoURL);
                });

            });

        } else {
                console.log("no file upload!!");
        }

    };

    const uploadDetailImg03Handler = (e) => {

        e. preventDefault();

        if (!!fileImg03) {

            const fileName = fileImg03.name;
            const targetRef = subBucketRef.child(fileName);

            targetRef.put(fileImg03).then((response) => {
                console.log(response);
                response.ref.getDownloadURL().then((photoURL) => {
                    console.log(photoURL);
                    setLinkImg03(photoURL);
                });

            });

        } else {
                console.log("no file upload!!");
        }

    };

    const uploadDetailImg04Handler = (e) => {

        e. preventDefault();

        if (!!fileImg04) {

            const fileName = fileImg04.name;
            const targetRef = subBucketRef.child(fileName);

            targetRef.put(fileImg04).then((response) => {
                console.log(response);
                response.ref.getDownloadURL().then((photoURL) => {
                    console.log(photoURL);
                    setLinkImg04(photoURL);
                });

            });

        } else {
                console.log("no file upload!!");
        }

    };

    const uploadDetailImg05Handler = (e) => {

        e. preventDefault();

        if (!!fileImg05) {

            const fileName = fileImg05.name;
            const targetRef = subBucketRef.child(fileName);

            targetRef.put(fileImg05).then((response) => {
                console.log(response);
                response.ref.getDownloadURL().then((photoURL) => {
                    console.log(photoURL);
                    setLinkImg05(photoURL);
                });

            });

        } else {
                console.log("no file upload!!");
        }

    };


    const uploadPdfHandler = (e) => {

        e. preventDefault();

        if (!!filePdf) {

            const fileName = filePdf.name;
            const targetRef = subBucketRef.child(fileName);

            targetRef.put(filePdf).then((response) => {
                console.log(response);
                response.ref.getDownloadURL().then((photoURL) => {
                    console.log(photoURL);
                    setLinkPdf(photoURL);
                });

            });

        } else {
                console.log("no file upload!!");
        }

    };



   
    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    const signUpPage = () => {
        navigate("/SignUp");
    }


    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

    var user = auth.currentUser;
    var uid = "";

    if (user != null) {
      
        uid = user.uid;  
        console.log(uid);
        
    } else {
        // signUpPage();
    }

   
    const [loading, setLoading] = useState(false);

    const [userID, setUserId] = useState("");
    const [usernameOrder, setUsernameOrder] = useState("");
    const [userphonenumberOrder, setUserphonenumberOrder] = useState("");
    const [useraddressOrder, setUseraddressOrder] = useState("");


    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [address, setAddress] = useState("");

    const [sPoint, setSPoint] =  useState("");



    const [dataUser, setDataUser] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
    
        .where("uid", "==", uid);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            id: doc.id,
                            uid: doc.data().uid,
                            name: doc.data().name,
                            email: doc.data().email,
                            phonenumber: doc.data().phonenumber,
                            address: doc.data().address,

                            sPoint: doc.data().sPoint,
                            
                            storeName: doc.data().storeName,

                            
                        },
                        
                    ];

                    setUserId(doc.data().uid);
                    setSPoint(doc.data().sPoint);

                    setStoreName(doc.data().storeName);


                    /* setUsernameOrder(doc.data().name);
                    setUserphonenumberOrder(doc.data().phonenumber);
                    setUseraddressOrder(doc.data().address); */
                    // setEmail(doc.data().email);
                    
                    };

                });

            setDataUser((oldDataArray) => tempDataArray);
            

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [uid]); 


    
       /*  const [data, setData] = useState([]);
        useEffect(() => {
        const productRef = firestore.collection("product");
        const query = productRef
        
            .where("productID", "==", productId);
                query
                .get()
                .then((querySnapshot) => {
                    let tempDataArray =[];
                    querySnapshot.forEach((doc) => {
                        if (doc.exists) {
                            tempDataArray = [...tempDataArray,
                            {
                                id: doc.id,
                                productName: doc.data().productName,
                                productPrice: doc.data().productPrice,   
                            },
                        ];
                        
                        };

                    });

                setData((oldDataArray) => tempDataArray);

            },

                (err) => {
                    console.log(err);
                }
                
            );

        }, []);  */

        //TEST ADD PRODUCT-DATA WITH Button AND Form
        const [productID, setProductID] = useState("");
        // const [productSortNumber, setProductSortNumber] = useState(0);


        // const id = ("");

        // const [id, setId] = useState("");
        const [productName, setProductName] = useState("");
        const [productBadge, setProductBadge] = useState("ใหม่");
        const [productPrice, setProductPrice] = useState("");
        const [productFullPrice, setProductFullPrice] = useState("");
        const [productDetail, setProductDetail] = useState("");
        const [productQuantity, setProductQuantity] = useState("");
        const [productWeight, setProductWeight] = useState("");

        const [merchantID, setMerchantID] = useState("");
        const [storeName, setStoreName] = useState("");

        const [productCategory, setProductCategory] = useState("หนังสือ");
        const [productSubCategory, setProductSubCategory] = useState("");

        //++ 25.6.66
        const [productTypeCategory, setProductTypeCategory] = useState("");
        const [productRelease, setProductRelease] = useState("");
        //Book
        const [numberOfPages, setNumberOfPages] = useState("");

        

        const [productDiscount, setProductDiscount] = useState("10");
        const [productCondition, setProductCondition] = useState("มือหนึ่ง");
        const [productSold, setProductSold] = useState("0");
        const [productShippingCost, setProductShippingCost] = useState("38");

        const [productImage, setProductImage] = useState([""]);
        const [productDoc, setProductDoc] = useState([""]);
        // const [productDoc, setProductDoc] = useState([""]);

        //const ref = firestore.collection("product").doc();
        
        const id = "pid" + Math.random(999).toString();
        // const sortNumber = 9999;

        const [ratingSum, setRatingSum] = useState(0);
        const [ratingCount, setRatingCount] = useState(0);

        // const [usernameOrder, setUsernameOrder] = useState("");
        /* const [userphonenumberOrder, setUserphonenumberOrder] = useState("");
        const [useraddressOrder, setUseraddressOrder] = useState(""); */

        const addProductHandler = (obj) => {
           
            //ref = firestore.collection("product").doc().id;
            // setProductID(ref.doc().id);
            const ref = firestore.collection("product");
            ref
                //.doc(ref)
                .doc(id)
                .set(obj)
                .then(() => {
                    console.log("เพิ่มข้อมูลสำเร็จ");
                    refreshPage();
                    //console.log(ref.doc().id)
                    // refreshPage();
                })
                .catch((err) => console.log(err));
        };

        // CHARACTER RANDOM /////////////////////////////////////////////////////////
        /* function genRandonString(length) {
        var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charLength = chars.length;
        var result = '';
        for ( var i = 0; i < length; i++ ) {
            result += chars.charAt(Math.floor(Math.random() * charLength));
        }
        return result;
        }
 
        console.log(genRandonString(20)); */
        //////////////////////////////////////////////////////////////////////////////


        const submitHandler = (e) => {
            
            e.preventDefault();
            //const id = firestore.collection("product").doc();
            
            const obj = {

                productID : id,
                productSortNumber : 9999,

                productName : productName,
                productBadge: productBadge,
                productPrice : productPrice,
                productFullPrice : productFullPrice,
                productDetail: productDetail,
                productQuantity: productQuantity,
                productWeight: productWeight,
                numberOfPages: numberOfPages,

                productDiscount: productDiscount,
                productCondition: productCondition,
                productSold: productSold,
                productShippingCost: productShippingCost,

                productDoc: productDoc,

                productRelease: productRelease,

                productCategory: productCategory,
                productSubCategory: productSubCategory,
                productTypeCategory: productTypeCategory,

                merchantID: uid,
                storeName: storeName,

                productImage : productImage,
                productDoc : productDoc,

                productDate: new Date(),

                ratingSum: ratingSum,
                ratingCount: ratingCount,


            };



            setProductID("");
            // setProductSortNumber("");

            setProductName("");
            // setProductBadge("");
            setProductPrice("");
            setProductFullPrice("");
            setProductDetail("");
            setProductQuantity("");
            setProductWeight("");
            setNumberOfPages("");

            // setProductDiscount("");
            // setProductCondition("");
            // setProductSold("");
            // setProductShippingCost("");

            setProductDoc([""]);

            setProductRelease("");

            // setProductCategory("");
            setProductSubCategory("");
            setProductTypeCategory("");

            setMerchantID("");
            // setStoreName("");

            setProductImage([""]);
            setProductDoc([""]);

           /*  setUsernameOrder("");
            setUserphonenumberOrder("");
            setUseraddressOrder(""); */

            // setorderPayin("");

            addProductHandler(obj);


        };

        const signOutHandler = () => {
            auth.signOut().then(() => {
                console.log("Log Out Ok");
                setCurrentUser(null);
                navigate("/");
            })
            .catch((err) => {
                console.log("Log Out Not Ok." + err);
            })
        };

        useEffect(() => {
        setProductPrice(() => parseInt(productFullPrice / 100 * (100 - productDiscount)));
      }, [productDiscount]); // <- add the count variable here


    return (
        <>

        {/* Row 2  */}
       
        <br/>
        <Container className="">
            <form onSubmit={submitHandler}>
            
            <Link to="/User/Profile" style={{textDecoration: 'none', color: "black"}}> <Button variant="outline-secondary">ข้อมูลของฉัน</Button>{' '}</Link>            
            <Button onClick={signOutHandler} variant="outline-danger" type="submit">ออกจากระบบ</Button>
            <br/>
            <br/>
            <br/>
            <h3><strong>เพิ่มสินค้า |<Link to= "/MerchantView" style={{textDecoration: 'none'}}> ร้านค้าของฉัน</Link> </strong></h3>
            <br/>
            <Link to="/AboutUs/SelpagePoint" style={{textDecoration: 'none', color: "black"}}><h4><span style={{fontSize: 35 ,color: "skyblue"}}><AiOutlineSketch/></span> คะแนนสะสม: <span style={{color: "limegreen"}}>{parseInt(sPoint)}</span></h4></Link>
            <h3 className="pb-3 border-bottom"></h3>
            <br/>

            <Row>

                <Col sm={7}>
                <h4><strong>ข้อมูลทั่วไป</strong></h4>

                    <br/>

                    <h5><strong>ชื่อร้านค้า</strong></h5>
                    
                        <Form.Control 
                            type="text"
                            className="rounded-0" 
                            style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                            value={storeName}
                            onChange={(e) => {
                                setStoreName(e.target.value);
                            }}
                            placeholder={"*กรุณากรอกข้อมูล"}
                        />
                        <br />

                        <h5><strong>หมวดหมู่สินค้า</strong></h5>
                        {/* <FaDiaspora/> */}
                        <Form.Control 
                            type="text"
                            className="rounded-0" 
                            style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                            value={productCategory}
                            onChange={(e) => {
                                setProductCategory(e.target.value);
                            }}
                            placeholder={"*กรุณากรอกข้อมูล"}
                        />
                        <br />

                        <h5><strong>หมวดหมู่ย่อยสินค้า</strong></h5>
                        {/* <FaDiaspora/> */}
                        <Form.Control 
                            type="text"
                            className="rounded-0" 
                            style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                            value={productSubCategory}
                            onChange={(e) => {
                                setProductSubCategory(e.target.value);
                            }}
                            placeholder={"*กรุณากรอกข้อมูล"}
                        />
                        <br />

                        <h5><strong>หมวดหมู่ประเภทสินค้า</strong></h5>
                        {/* <FaDiaspora/> */}
                        <Form.Control 
                            type="text"
                            className="rounded-0" 
                            style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                            value={productTypeCategory}
                            onChange={(e) => {
                                setProductTypeCategory(e.target.value);
                            }}
                            placeholder={"*กรุณากรอกข้อมูล"}
                        />
                        <br />

                        <h3 className="pb-3 border-bottom"></h3>
                        <br/>

                        <h4><strong>ข้อมูลสินค้า</strong></h4>

                        <br/>

                        <h5><strong>ชื่อสินค้า</strong></h5>
                        {/* <FaDiaspora/> */}
                        <Form.Control 
                            type="text"
                            className="rounded-0" 
                            style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                            value={productName}
                            onChange={(e) => {
                                setProductName(e.target.value);
                            }}
                            placeholder={"*กรุณากรอกข้อมูล"}
                        />
                        <br />

                        <h5><strong>วันที่ผลิต/จำหน่าย</strong></h5>
                        {/* <FaDiaspora/> */}
                        <Form.Control 
                            type="text"
                            className="rounded-0" 
                            style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                            value={productRelease}
                            onChange={(e) => {
                                setProductRelease(e.target.value);
                            }}
                            placeholder={"*กรุณากรอกข้อมูล"}
                        />
                        <br />

                        <h5><strong>ป้ายกำกับ (ใหม่/ขายดี)</strong></h5>
                        {/* <FaDiaspora/> */}
                        <Form.Control 
                            type="text"
                            className="rounded-0" 
                            style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                            value={productBadge}
                            onChange={(e) => {
                                setProductBadge(e.target.value);
                            }}
                            placeholder={"*กรุณากรอกข้อมูล"}
                        />
                        <br />

                        <h5><strong>สภาพสินค้า (มือหนึ่ง/มือสอง)</strong></h5>
                        {/* <FaDiaspora/> */}
                        <Form.Control 
                            type="text" 
                            className="rounded-0"
                            style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                            value={productCondition}
                            onChange={(e) => {
                                setProductCondition(e.target.value);
                            }}
                            placeholder={"*กรุณากรอกข้อมูล"}                               
                        />
                        <br />

                        <h5><strong>น้ำหนัก (กรัม)</strong></h5>
                        {/* <FaDiaspora/> */}
                        <Form.Control 
                            type="text" 
                            className="rounded-0"
                            style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                            value={productWeight}
                            onChange={(e) => {
                                setProductWeight(e.target.value);
                            }}
                            placeholder={"*กรุณากรอกข้อมูล"}                               
                        />
                        <br />

                        <h5><strong>จำนวนหน้า (หนังสือ)</strong></h5>
                        {/* <FaDiaspora/> */}
                        <Form.Control 
                            type="text" 
                            className="rounded-0"
                            style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                            value={numberOfPages}
                            onChange={(e) => {
                                setNumberOfPages(e.target.value);
                            }}
                            placeholder={"*กรณีสินค้าหมวดหมู่หนังสือ"}                               
                        />
                        <br />

                        <h5><strong>จำนวนที่ลงขาย</strong></h5>
                        {/* <FaDiaspora/> */}
                        <Form.Control 
                            type="text" 
                            className="rounded-0"
                            style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                            value={productQuantity}
                            onChange={(e) => {
                                setProductQuantity(e.target.value);
                            }}
                            placeholder={"*กรุณากรอกข้อมูล"}                               
                        />
                        <br />

                        <h5><strong>ขายแล้ว (จำนวน)</strong></h5>
                        {/* <FaDiaspora/> */}
                        <Form.Control 
                            type="text" 
                            className="rounded-0"
                            style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                            value={productSold}
                            onChange={(e) => {
                                setProductSold(e.target.value);
                            }}
                            placeholder={"*กรุณากรอกข้อมูล"}                               
                        />
                        <br />
                                                                            
                        <h5><strong>ราคาเต็มสินค้า</strong></h5>
                        {/* <FaDiaspora/> */}
                        <Form.Control 
                            type="text" 
                            className="rounded-0"
                            style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                            value={productFullPrice}
                            onChange={(e) => {
                                setProductFullPrice(e.target.value);
                            }}
                            placeholder={"*กรุณากรอกข้อมูล"}                               
                        />
                        <br />

                        <h5><strong>ส่วนลด (%)</strong></h5>
                        {/* <FaDiaspora/> */}
                        <Form.Control 
                            type="text" 
                            className="rounded-0"
                            style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                            value={productDiscount}
                            onChange={(e) => {
                                setProductDiscount(e.target.value);
                            }}
                            placeholder={"*กรุณากรอกข้อมูล"}                               
                        />
                        <br />

                        <h5><strong>ราคาสินค้าที่ขายจริง</strong></h5>
                        {/* <FaDiaspora/> */}
                        <Form.Control 
                            type="text" 
                            className="rounded-0"
                            style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                            value={productPrice}
                            onChange={(e) => {
                                setProductPrice(e.target.value);
                            }}
                            placeholder={"*กรุณากรอกข้อมูล"}                               
                        />
                        <br />

                        <h5><strong>ค่าจัดส่ง</strong></h5>
                        {/* <FaDiaspora/> */}
                        <Form.Control 
                            type="text" 
                            className="rounded-0"
                            style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                            value={productShippingCost}
                            onChange={(e) => {
                                setProductShippingCost(e.target.value);
                            }}
                            placeholder={"*กรุณากรอกข้อมูล"}                               
                        />
                        <br />

                        <h5><strong>รายละเอียดสินค้า</strong></h5>
                        {/* <FaDiaspora/> */}
                        <Form.Control 
                            as="textarea" 
                            className="rounded-0"
                            rows={3}  
                            style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0, whiteSpace: 'pre-line' }}
                            value={productDetail}
                            onChange={(e) => {
                                setProductDetail(e.target.value);
                            }}
                            placeholder={"*กรุณากรอกข้อมูล"}         
                        />
                                            

                                <br/>
                                <br/>
                                <br/>

                </Col>

                <Col className="bg-light p-3" sm={5}>
                        <h4><strong>รูปภาพสินค้า</strong></h4>
                        <br/>
        
                            <div>

                                <Form.Label>อัพโหลดรูปภาพสินค้าหน้าแรก</Form.Label>
                                    <InputGroup className="mb-3 rounded-0 was-validated">
                                        <Form.Control
                                            aria-label="Example text with button addon"
                                            aria-describedby="basic-addon1"
                                            type="file"
                                            className="custom-file-label rounded-0"  
                                            id="customFile" 
                                            placeholder="" 
                                            name="customFile" 
                                            onChange={ (e) => {
                                                const file = e.target.files[0];
                                                setFile(file);
                                                setLink("");
                                            }}
                                            required
                                        />

                                        <Button 
                                        type="submit"
                                        onClick={uploadHandler}
                                        className="btn btn-success rounded-0"
                                        >
                                        อัลโหลด
                                        </Button>
                                    </InputGroup>


                                    {!!link ? (

                                    <div className="text-center mt-4">

                                        <hr/>

                                        <a className="text-secondary" href={link} target="_blank" rel="noreferrer">
                                            คลิกไฟล์ {file.name}
                                        </a>

                                    <img src={link} width={350} height={450} fluid/>

                                    </div>

                                    ) : null}







                                    <br/>
                                    <br/>

                                    <h4><strong>เอกสารสินค้า</strong></h4>
                                    <br/>

                                    <Form.Label>อัพโหลดเอกสารสินค้า</Form.Label>
                                    <InputGroup className="mb-3 rounded-0 was-validated">
                                        <Form.Control
                                            aria-label="Example text with button addon"
                                            aria-describedby="basic-addon1"
                                            type="file"
                                            className="custom-file-label rounded-0"  
                                            id="customFile" 
                                            placeholder="" 
                                            name="customFile" 
                                            onChange={ (e) => {
                                                const file = e.target.files[0];
                                                setFilePdf(file);
                                                setLinkPdf("");
                                            }}
                                     
                                        />

                                        <Button 
                                        type="submit"
                                        onClick={uploadPdfHandler}
                                        className="btn btn-success rounded-0"
                                        >
                                        อัลโหลด
                                        </Button>
                                    </InputGroup>


                                    {!!linkPdf ? (

                                    <div className="text-center mt-4">

                                        <hr/>

                                        <a className="text-secondary" href={linkPdf} target="_blank" rel="noreferrer">
                                            คลิกไฟล์ {filePdf.name}
                                        </a>

                                    {/* <img src={linkPdf} width={350} height={450} fluid/> */}

                                    </div>

                                    ) : null}






                                    <br/>
                                    <br/>

                                    <h4><strong>รูปภาพรายละเอียดสินค้า</strong></h4>
                                    <br/>

                                    <Form.Label>อัพโหลดรูปภาพรายละเอียดสินค้า 01</Form.Label>
                                    <InputGroup className="mb-3 rounded-0 was-validated">
                                        <Form.Control
                                            aria-label="Example text with button addon"
                                            aria-describedby="basic-addon1"
                                            type="file"
                                            className="custom-file-label rounded-0"  
                                            id="customFile" 
                                            placeholder="" 
                                            name="customFile" 
                                            onChange={ (e) => {
                                                const file = e.target.files[0];
                                                setFileImg01(file);
                                                setLinkImg01("");
                                            }}
                                            
                                        />

                                        <Button 
                                        type="submit"
                                        onClick={uploadDetailImg01Handler}
                                        className="btn btn-success rounded-0"
                                        >
                                        อัลโหลด
                                        </Button>
                                    </InputGroup>


                                    {!!linkImg01 ? (

                                    <div className="text-center mt-4">

                                        <hr/>

                                        <a className="text-secondary" href={linkImg01} target="_blank" rel="noreferrer">
                                            คลิกไฟล์ {fileImg01.name}
                                        </a>

                                    <img src={linkImg01} width={450} height={350} fluid/>

                                    </div>

                                    ) : null}



                                    <br/>

                                    <Form.Label>อัพโหลดรูปภาพรายละเอียดสินค้า 02</Form.Label>
                                    <InputGroup className="mb-3 rounded-0 was-validated">
                                        <Form.Control
                                            aria-label="Example text with button addon"
                                            aria-describedby="basic-addon1"
                                            type="file"
                                            className="custom-file-label rounded-0"  
                                            id="customFile" 
                                            placeholder="" 
                                            name="customFile" 
                                            onChange={ (e) => {
                                                const file = e.target.files[0];
                                                setFileImg02(file);
                                                setLinkImg02("");
                                            }}
                                            
                                        />

                                        <Button 
                                        type="submit"
                                        onClick={uploadDetailImg02Handler}
                                        className="btn btn-success rounded-0"
                                        >
                                        อัลโหลด
                                        </Button>
                                    </InputGroup>


                                    {!!linkImg02 ? (

                                    <div className="text-center mt-4">

                                        <hr/>

                                        <a className="text-secondary" href={linkImg02} target="_blank" rel="noreferrer">
                                            คลิกไฟล์ {fileImg02.name}
                                        </a>

                                    <img src={linkImg02} width={450} height={350} fluid/>

                                    </div>

                                    ) : null}



                                    <br/>

                                    <Form.Label>อัพโหลดรูปภาพรายละเอียดสินค้า 03</Form.Label>
                                    <InputGroup className="mb-3 rounded-0 was-validated">
                                        <Form.Control
                                            aria-label="Example text with button addon"
                                            aria-describedby="basic-addon1"
                                            type="file"
                                            className="custom-file-label rounded-0"  
                                            id="customFile" 
                                            placeholder="" 
                                            name="customFile" 
                                            onChange={ (e) => {
                                                const file = e.target.files[0];
                                                setFileImg03(file);
                                                setLinkImg03("");
                                            }}
                                            
                                        />

                                        <Button 
                                        type="submit"
                                        onClick={uploadDetailImg03Handler}
                                        className="btn btn-success rounded-0"
                                        >
                                        อัลโหลด
                                        </Button>
                                    </InputGroup>


                                    {!!linkImg03 ? (

                                    <div className="text-center mt-4">

                                        <hr/>

                                        <a className="text-secondary" href={linkImg03} target="_blank" rel="noreferrer">
                                            คลิกไฟล์ {fileImg03.name}
                                        </a>

                                    <img src={linkImg03} width={450} height={350} fluid/>

                                    </div>

                                    ) : null}



                                    <br/>

                                    <Form.Label>อัพโหลดรูปภาพรายละเอียดสินค้า 04</Form.Label>
                                    <InputGroup className="mb-3 rounded-0 was-validated">
                                        <Form.Control
                                            aria-label="Example text with button addon"
                                            aria-describedby="basic-addon1"
                                            type="file"
                                            className="custom-file-label rounded-0"  
                                            id="customFile" 
                                            placeholder="" 
                                            name="customFile" 
                                            onChange={ (e) => {
                                                const file = e.target.files[0];
                                                setFileImg04(file);
                                                setLinkImg04("");
                                            }}
                                            
                                        />

                                        <Button 
                                        type="submit"
                                        onClick={uploadDetailImg04Handler}
                                        className="btn btn-success rounded-0"
                                        >
                                        อัลโหลด
                                        </Button>
                                    </InputGroup>


                                    {!!linkImg04 ? (

                                    <div className="text-center mt-4">

                                        <hr/>

                                        <a className="text-secondary" href={linkImg04} target="_blank" rel="noreferrer">
                                            คลิกไฟล์ {fileImg04.name}
                                        </a>

                                    <img src={linkImg04} width={450} height={350} fluid/>

                                    </div>

                                    ) : null}


                                    <br/>

                                    <Form.Label>อัพโหลดรูปภาพรายละเอียดสินค้า 05</Form.Label>
                                    <InputGroup className="mb-3 rounded-0 was-validated">
                                        <Form.Control
                                            aria-label="Example text with button addon"
                                            aria-describedby="basic-addon1"
                                            type="file"
                                            className="custom-file-label rounded-0"  
                                            id="customFile" 
                                            placeholder="" 
                                            name="customFile" 
                                            onChange={ (e) => {
                                                const file = e.target.files[0];
                                                setFileImg05(file);
                                                setLinkImg05("");
                                            }}
                                            
                                        />

                                        <Button 
                                        type="submit"
                                        onClick={uploadDetailImg05Handler}
                                        className="btn btn-success rounded-0"
                                        >
                                        อัลโหลด
                                        </Button>
                                    </InputGroup>


                                    {!!linkImg05 ? (

                                    <div className="text-center mt-4">

                                        <hr/>

                                        <a className="text-secondary" href={linkImg05} target="_blank" rel="noreferrer">
                                            คลิกไฟล์ {fileImg05.name}
                                        </a>

                                    <img src={linkImg05} width={450} height={350} fluid/>

                                    </div>

                                    ) : null}






                                    <br/>
                                    <br/>

                                    </div>

                                    {!link ? 
                                        <>
                                        <div className="d-grid gap-2">
                                            <Button variant="outline-success" className="btn-block rounded-0" disabled>เพิ่มสินค้า</Button>
                                        </div>
                                        <br/> 
                                        </>
                                    :
                                    <>
                                        <div className="d-grid gap-2">
                                            <Button onClick={(e) => (setStoreName(storeName), setMerchantID(uid), setProductName(productName), setProductRelease(productRelease), setProductCondition(productCondition), setProductQuantity(productQuantity), setProductWeight(productWeight), setNumberOfPages(numberOfPages), setProductDoc([""]), setProductSold(productSold), setProductCategory(productCategory), setProductSubCategory(productSubCategory), setProductTypeCategory(productTypeCategory), setProductFullPrice(productFullPrice), setProductShippingCost(productShippingCost), setProductDiscount(productDiscount), setProductPrice(productPrice), setProductDetail(productDetail), setProductImage([link,linkImg01,linkImg02,linkImg03,linkImg04,linkImg05]), setProductDoc([linkPdf]))} className="btn btn-success btn-block rounded-0" type="submit">เพิ่มสินค้า</Button>
                                        </div>
                                        <br/>
                                        
                                    </>
                                    }

                        <br/>
                        
                </Col>

            </Row>


                </form>


        </Container>

        <br/>
        <br/>

        </>
    );

}  

export default AddProduct;

















               {/* <Col sm={7}>
                    <h4><strong>ที่อยู่จัดส่งสินค้า</strong></h4>
                    <p>นายวิรัช วิสาขศาสตร์, 0805514821 <br/>199/288 แขวงสีกัน เขตดอนเมือง กรุงเทพมหานคร 10210</p>
                    <br/>
                    <h4><strong>วิธีการชำระเงิน</strong></h4>
                    <p>• โอนผ่านบัญชีธนาคาร<br/>• Qr Code</p>
                </Col> */}


                 {/* <p>{item.productName}: <span className="float-sm-end">฿{item.productPrice}</span></p>
                               

                                <p>ค่าจัดส่ง: <span className="float-sm-end">฿50</span></p>
                                <h3 className="pb-3 border-bottom"></h3>
                                <p>ยอดชำระเงินทั้งหมด: <span className="float-sm-end">฿500</span></p>
                                <br/> */}
