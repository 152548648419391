import React, { useContext, useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Tabs, Tab, Form, Col, Row, Button, Card, InputGroup, ProgressBar } from "react-bootstrap";

import { Link, redirect, useNavigate } from "react-router-dom";

import { AuthContext } from "../Authentication/AuthProvider";
import { auth, firestore } from "../../database/firebase";
import OrderCustomer from "./OrderCustomer";
import OrderCustomerCart from "./OrderCustomerCart";
import OrderHistory from "./OrderHistory";
import OrderHistoryCart from "./OrderHistoryCart";
import Cart from "./Cart";

import { AiOutlineSketch } from "react-icons/ai";

// import OrderHistory from "./OrderHistory";

function MyOrder() {

    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

    const formatter = new Intl.NumberFormat('th', {
        style: 'currency',
        currency: 'THB',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

   //const [uid, setUid] = useState("");

    var user = auth.currentUser;
    // var uid = "";
    var uid = "";

    if (user != null) {
        //email = user.email;
        uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                         // this value to authenticate with your backend server, if
                         // you have one. Use User.getToken() instead.
        console.log(uid);
        
        //TEST AUTH
        // refreshPage();
    }



    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [address, setAddress] = useState("");

    const [role, setRole] = useState("");
    const [sPoint, setSPoint] = useState("");

    const [displayName, setDisplayName] = useState("");

    var displayname = displayName;

    const [profession, setProfession] = useState("");
    const [description, setDescription] = useState("");

    const [socialPublic, setSocialPublic] = useState("");

    const socialPublicOption = ["สาธารณะ", "เฉพาะฉัน"];
    const [socialPublicString, setSocialPublicString] = useState(socialPublicOption[0]);


    const [facebook, setFacebook] = useState("");
    const [youtube, setYoutube] = useState("");
    const [instagram, setInstagram] = useState("");
    const [tiktok, setTiktok] = useState("");

    const [profileImg, setProfileImg] = useState("");

    const [file, setFile] = useState("");
    const [link, setLink] = useState("");

    const [resizefileUrl, setResizefileUrl] = useState("");

    const [data, setData] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
    
        .where("uid", "==", uid);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            uid: doc.id,
                            name: doc.data().name,
                            email: doc.data().email,
                            phonenumber: doc.data().phonenumber,
                            address: doc.data().address,
                            sPoint: doc.data().sPoint,   
                            role: doc.data().role,

                            displayName: doc.data().displayName,

                            profession: doc.data().profession,
                            description: doc.data().description,

                            socialPublic: doc.data().socialPublic,
                            facebook: doc.data().facebook,
                            youtube: doc.data().youtube,
                            instagram: doc.data().instagram,
                            tiktok: doc.data().tiktok,

                            profileImg: doc.data().profileImg,

                            
                        },
                        
                    ];

                    setName(doc.data().name);
                    setPhonenumber(doc.data().phonenumber);
                    setEmail(doc.data().email);
                    setAddress(doc.data().address);

                    setRole(doc.data().role)
                    setSPoint(doc.data().sPoint);

                    setDisplayName(doc.data().displayName);

                    setProfession(doc.data().profession);
                    setDescription(doc.data().description);

                    setSocialPublic(doc.data().socialPublic);
                    setFacebook(doc.data().facebook);
                    setYoutube(doc.data().youtube);
                    setInstagram(doc.data().instagram);
                    setTiktok(doc.data().tiktok);

                    setProfileImg(doc.data().profileImg);

                    setResizefileUrl(doc.data().profileImg);
                    

                    };

                });

            setData((oldDataArray) => tempDataArray);
            

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [uid]); 

    const myStore = () => {
        if (role == "merchant") {
            navigate("/MerchantView")
        } else {
            alert("ร้านค้าของฉันพร้อมเปิดให้บริการเร็วๆ นี้, ขออภัยในความไม่สะดวก");
        }
    }

    

    return (
  
      <Container>

       <br/>
        <Link to={"/User/Profile"} style={{textDecoration: 'none', color: "black"}}><Button className="rounded-0" variant="outline-secondary" size="sm">โปรไฟล์ของฉัน</Button></Link>{' '}
        <Button onClick={myStore} className="rounded-0" variant="outline-secondary" size="sm">ร้านค้าของฉัน</Button>{' '}
                        
            <br/>
            <br/>
            <br/>
                       
        <h3><strong>การซื้อของฉัน</strong></h3>
                        
            <br/>
        <Link to="/AboutUs/SelpagePoint" style={{textDecoration: 'none', color: "black"}}><h4><span style={{fontSize: 35 ,color: "skyblue"}}><AiOutlineSketch/></span> คะแนนสะสม: <span style={{color: "limegreen"}}>{parseInt(sPoint)}</span></h4></Link>
                        
      

        <br/>



        <Tabs defaultActiveKey="order" id="uncontrolled-tab-example" className="mb-3">

        <Tab eventKey="order" title="การซื้อของฉัน">

            <OrderCustomer />
            <OrderCustomerCart />
            <OrderHistory />
            <OrderHistoryCart />
                                
        </Tab>

        <Tab eventKey="cart" title="ตะกร้าสินค้า">
            <Cart />
        </Tab> 

    </Tabs>
  
        
    
        <br/>

      </Container>
    );
  }
  
  export default MyOrder;