import React, { useContext, useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Card, Button, Dropdown } from 'react-bootstrap';

import { Link, redirect, useNavigate } from "react-router-dom";

import { AuthContext } from "../Authentication/AuthProvider";
import { auth, firestore } from "../../database/firebase";

// import OrderHistory from "./OrderHistory";

function OrderCustomerCart() {

    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

    const formatter = new Intl.NumberFormat('th', {
        style: 'currency',
        currency: 'THB',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

   //const [uid, setUid] = useState("");

    var user = auth.currentUser;
    // var uid = "";
    var uid = "";

    if (user != null) {
        //email = user.email;
        uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                         // this value to authenticate with your backend server, if
                         // you have one. Use User.getToken() instead.
        console.log(uid);
        
        //TEST AUTH
        // refreshPage();
    }

    const [order, setOrder] = useState("");
    
    const [data, setData] = useState([]);

    useEffect(() => {
        const orderRef = firestore.collection("orderCart");
        const query = orderRef
            
            .where("userID", "==", uid)
            .where("orderStatus", "==", "กำลังดำเนินการ");
                query
                .get()
                .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempDataArray = [...tempDataArray,
                        {
                            // productID: doc.id,
                            id: doc.id,
                            productID: doc.data().productID,
                            storeName: doc.data().storeName,
                            productName: doc.data().productName,
                            productQuantity: doc.data().productQuantity,
                            // productImage: doc.data().productImage,
                            productPrice: doc.data().productPrice,
                            productPriceTotal: doc.data().productPriceTotal,
                            
                            orderStatus: doc.data().orderStatus,
                            orderDate: Date(doc.data().orderDate),
                

                        },
                    ];

                    setOrder(doc.id);
                    
                    };

            });
            setData((oldDataArray) => tempDataArray);
    },
        (err) => {
            console.log(err);
        }
    );

    }, []);

  
    return (
  
      <Container>

        {!!order ? 
        (
            <>

                <br/>
                <h3><strong>การสั่งซื้อตะกร้าสินค้า</strong></h3>
                {/* <p>จำนวนสั่งซื้อ: 3 รายการ | ยอดรวม: 1,350 บาท </p> */}
                <Row>

                    {data.map((item, index) => {
                        return (
                            <div key={index}>     
                            <h3 className="pb-3 border-bottom"></h3>
                                <br/>
                            <Card className="rounded-0">
                                    <Card.Header>
                                        <strong>คำสั่งซื้อที่: {item.id}</strong>
                                    </Card.Header>
                                <Card.Body>
                        
                                    <Card.Text>
                                        <strong>ชื่อสินค้า:</strong> {item.productName}<br/>
                                        {/* <strong>ร้านค้า:</strong> {item.storeName}<br/> */}
                                        <strong>จำนวน:</strong> {item.productQuantity}<br/>
                                        <strong>จำนวนเงินทั้งสิ้น:</strong> {formatter.format(item.productPriceTotal)} บาท<br/>
                                        {item.orderDate}<br/><br/>
                                        <strong>สถานะ: <span style={{color: "lightsalmon"}}>{item.orderStatus}</span></strong>
                                        </Card.Text>
                                </Card.Body>
                            </Card>

                            </div>
                        );
                    })}

                <br/>

                {/* <OrderHistory /> */}
            

                </Row>

            </>
        ) : (
            <>
                <br/>
                <h3><strong>การสั่งซื้อตะกร้าสินค้า</strong></h3>
                <h3 className="pb-3 border-bottom"></h3>
                <br/>
                <p>ยังไม่มีการสั่งซื้อตะกร้าสินค้า</p>
            </>
        )}
  
        
    
        <br/>

      </Container>
    );
  }
  
  export default OrderCustomerCart;