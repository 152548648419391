import React, { useState, useEffect, useContext } from "react";
import { Container, Tabs, Tab, Form, Col, Row, Button, Card, InputGroup,Accordion, Modal } from "react-bootstrap";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";

import { FaSketch } from "react-icons/fa";
import { AiOutlineSketch } from "react-icons/ai";

import { auth, firestore } from "../../database/firebase";
import { storage } from "../../database/firebase";


import { AuthContext } from "../../component_s/Authentication/AuthProvider";

import { BsStarFill, BsStarHalf, BsStar, BsPatchCheck, BsPlayBtn, BsPhone, BsFolder2Open, BsInfinity, BsCheckLg, BsPeopleFill, BsFillPlayBtnFill, BsChatLeftDotsFill, BsRecordFill, BsPersonCircle } from "react-icons/bs";
import UploadVideo from "./UploadVideo";

// import "./UploadVideo.css";

import product_sample01 from '../../component_s/images_s/product_sample01.jpeg';
import product_sample02 from '../../component_s/images_s/product_sample02.jpg';
import product_sample03 from '../../component_s/images_s/product_sample03.jpg';


function CourseInfo() {


    let { courseId } = useParams();

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    /* TEST QUERY DATA WITH productID */

    // const [uid, setUid] = useState("");

    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

   //const [uid, setUid] = useState("");

    var user = auth.currentUser;
    // var uid = "";
    var uid = "";

    if (user != null) {
        //email = user.email;
        uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                         // this value to authenticate with your backend server, if
                         // you have one. Use User.getToken() instead.
        console.log(uid);
        
        //TEST AUTH
        // refreshPage();
    } else {
        navigate("/");
    }
    



    

    // const [emailDefault, setEmailDefault] = useState("");

    // const [loading, setLoading] = useState(false);
    const [section, setSection] = useState([""]);
    const [about, setAbout] = useState([""]);


    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [address, setAddress] = useState("");

    const [role, setRole] = useState("");
    const [sPoint, setSPoint] = useState("");

    const [displayName, setDisplayName] = useState("");

    const [profession, setProfession] = useState("");
    const [description, setDescription] = useState("");

    const [facebook, setFacebook] = useState("");
    const [youtube, setYoutube] = useState("");
    const [instagram, setInstagram] = useState("");

    const [profileImg, setProfileImg] = useState("");

    const [file, setFile] = useState("");
    const [link, setLink] = useState("");

    const [fileIntro, setFileIntro] = useState("");
    const [linkIntro, setLinkIntro] = useState("");

    const [data, setData] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
    
        .where("uid", "==", uid);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            uid: doc.id,
                            name: doc.data().name,
                            email: doc.data().email,
                            phonenumber: doc.data().phonenumber,
                            address: doc.data().address,
                            sPoint: doc.data().sPoint,   
                            role: doc.data().role,

                            displayName: doc.data().displayName,

                            profession: doc.data().profession,
                            description: doc.data().description,

                            facebook: doc.data().facebook,
                            youtube: doc.data().youtube,
                            instagram: doc.data().instagram,

                            profileImg: doc.data().profileImg,
                            
                        },
                        
                    ];

                    setName(doc.data().name);
                    setPhonenumber(doc.data().phonenumber);
                    setEmail(doc.data().email);
                    setAddress(doc.data().address);

                    setRole(doc.data().role)
                    setSPoint(doc.data().sPoint);

                    setDisplayName(doc.data().displayName);

                    setProfession(doc.data().profession);
                    setDescription(doc.data().description);

                    setFacebook(doc.data().facebook);
                    setYoutube(doc.data().youtube);
                    setInstagram(doc.data().instagram);

                    setProfileImg(doc.data().profileImg);
                    

                    };

                });

            setData((oldDataArray) => tempDataArray);
            

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [uid]); 


    const [courseData, setCourseData] = useState([]);

    const [merchantID, setMerchantID] = useState("");
    const [storeName, setStoreName] = useState("");
    const [courseCategory, setCourseCategory] = useState("");
    const [courseSubCategory, setCourseSubCategory] = useState("");
    const [courseTypeCategory, setCourseTypeCategory] = useState("");
    const [courseCaption, setCourseCaption] = useState("");
    const [courseID, setCourseID] = useState("");
    const [courseName, setCourseName] = useState("");
    const [courseBadge, setCourseBadge] = useState("");
    const [courseFullPrice, setCourseFullPrice] = useState("");
    const [coursePrice, setCoursePrice] = useState("");
    const [courseImage, setCourseImage] = useState("");

    const [courseRequirement, setCourseRequirement] = useState("");

    //
    const [courseIntro, setCourseIntro] = useState("");

    const [courseSectionExVideo, setCourseSectionExVideo] = useState("");
    const [videoSrc, setVideoSrc] = useState("");

    const [courseExVideo, setCourseExVideo] = useState("");
    const [courseDate, setCourseDate] = useState("");
    const [courseTopic, setCourseTopic] = useState("");
    const [courseStatus, setCourseStatus] = useState("");
    //
    const [courseDetail, setCourseDetail] = useState("");
    const [courseDiscount, setCourseDiscount] = useState("");

    const [courseSold, setCourseSold] = useState("");
    const [courseDoc, setCourseDoc] = useState("");

    //RATING
    const [ratingSum, setRatingSum] = useState(0);
    const [ratingCount, setRatingCount] = useState(0);

    useEffect(() => {
        const productRef = firestore.collection("course");
        const query = productRef
        
            .where("courseID", "==", courseId);
                query
                .get()
                .then((querySnapshot) => {
                    let tempDataArray =[];
                    querySnapshot.forEach((doc) => {
                        if (doc.exists) {
                            tempDataArray = [...tempDataArray,
                            {
                                id: doc.id,
                                merchantID: doc.data().merchantID,
                                storeName: doc.data().storeName,

                                courseID: doc.data().courseID,
                                courseCategory: doc.data().courseCategory,
                                courseSubCategoty: doc.data().courseSubCategory,
                                courseTypeCategory: doc.data().courseTypeCategory,

                                courseName: doc.data().courseName,
                                courseCaption: doc.data().courseCaption,
                                courseTopic: doc.data().courseTopic,
                                courseBadge: doc.data().courseBadge,
                                courseFullPrice: doc.data().courseFullPrice,
                                coursePrice: doc.data().coursePrice,   
                                courseImage: doc.data().courseImage,
                                courseDetail: doc.data().courseDetail,
                                courseDiscount: doc.data().courseDiscount,
                                courseSold: doc.data().courseSold,
                                courseDoc: doc.data().courseDoc,

                                courseRequirement: doc.data().courseRequirement,

                                courseIntro: doc.data().courseIntro,
                                courseExVideo : doc.data().courseExVideo,
                                courseSectionExVideo : doc.data().courseSectionExVideo,
                                courseStatus: doc.data().courseStatus,

              
                                ratingSum: doc.data().ratingSum,
                                ratingCount: doc.data().ratingCount,
                            },
                        ];


                        setMerchantID(doc.data().merchantID);
                        setStoreName(doc.data().storeName);

                        setCourseID(doc.data().courseID);
                        setCourseCategory(doc.data().courseCategory);
                        setCourseSubCategory(doc.data().courseSubCategory);
                        setCourseTypeCategory(doc.data().courseTypeCategory);
                        setCourseName(doc.data().courseName);
                        setCourseCaption(doc.data().courseCaption);

                        setCourseTopic(doc.data().courseTopic);
                        setCourseBadge(doc.data().courseBadge);
                        setCourseFullPrice(doc.data().courseFullPrice);
                        setCoursePrice(doc.data().coursePrice);
                        setCourseImage(doc.data().courseImage);
                        setCourseDetail(doc.data().courseDetail);
                        setCourseDiscount(doc.data().courseDiscount);
                        setCourseSold(doc.data().courseSold);
                        setCourseDoc(doc.data().courseDoc);

                        setCourseRequirement(doc.data().courseRequirement);

                        setCourseIntro(doc.data().courseIntro);
                        setCourseExVideo(doc.data().courseExVideo);
                        setVideoSrc(doc.data().courseExVideo[0]);
                        setCourseSectionExVideo(doc.data().courseSectionExVideo);
                        setCourseStatus(doc.data().courseStatus);

 
                        setRatingSum(doc.data().ratingSum);
                        setRatingCount(doc.data().ratingCount);
                        };

                    });

                setCourseData((oldDataArray) => tempDataArray);

            },

                (err) => {
                    console.log(err);
                }
                
            );

        }, []);




    const myStore = () => {
        if (role == "merchant") {
            navigate("/MerchantView")
        } else {
            alert("ร้านค้าของฉันพร้อมเปิดให้บริการเร็วๆ นี้, ขออภัยในความไม่สะดวก");
        }
    }

    const signOutHandler = () => {
        auth.signOut().then(() => {
            console.log("Log Out Ok");
            setCurrentUser(null);
            navigate("/");
        })
        .catch((err) => {
            console.log("Log Out Not Ok." + err);
        })
    };


    //////////////////////////////////// ADD/REMOVE TEXT FIELD /////////////////////////////////////////////////////

    

    /* const [formValues, setFormValues] = useState([{ section: "", links : ""}])

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
      }
    
    let addFormFields = () => {
        setFormValues([...formValues, { section: "", links: "" }])
      }
    
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    } */
    
    //////////////////////////////////////// UPLOAD DATA ///////////////////////////////////////////////////////////
    /* const [courseTopic, setCourseTopic] = useState("");*/

    //TEST Upload Video/Doc
    /* const [file, setFile] = useState("");
    const [link, setLink] = useState(""); */

    const subBucketRef = storage.child("mainBucket/course");

    const uploadHandler = (e) => {

        e. preventDefault();

        if (!!file) {

            const fileName = file.name;
            const targetRef = subBucketRef.child(fileName);

            targetRef.put(file).then((response) => {
                console.log(response);
                response.ref.getDownloadURL().then((photoURL) => {
                    console.log(photoURL);
                    setLink(photoURL);
                    setCourseImage(photoURL);
                });

            });

        } else {
                console.log("no file upload!!");
        }

    };

    const uploadIntroHandler = (e) => {

        e. preventDefault();

        if (!!fileIntro) {

            const fileName = fileIntro.name;
            const targetRef = subBucketRef.child(fileName);

            targetRef.put(fileIntro).then((response) => {
                console.log(response);
                response.ref.getDownloadURL().then((photoURL) => {
                    console.log(photoURL);
                    setLink(photoURL);
                    setCourseIntro(photoURL);
                });

            });

        } else {
                console.log("no file upload!!");
        }

    };

    const editHandler = (obj) => {
        
        const ref = firestore.collection("user");
        ref
            .doc(uid)
            .update(obj)
            .then(() => {
                console.log("แก้ไขข้อมูลสำเร็จ");
                refreshPage();
            })
            .catch((err) => console.log(err));
    };

    const submitHandler = (e) => {
        e.preventDefault();
        const obj = {
            
            name : name,
            email : email,
            phonenumber : phonenumber,
            address: address,
            displayName: displayName,

            profession: profession,
            description: description,

            facebook: facebook,
            youtube: youtube,
            instagram: instagram,

            profileImg: profileImg,

        };
      
        setName("");
        setEmail("");
        setPhonenumber("");
        setAddress("");
        setDisplayName("");

        setProfession("");
        setDescription("");

        setFacebook("");
        setYoutube("");
        setInstagram("");

        editHandler(obj);
    };

    ///////////////////////////////////////////// GO TO CREATE COURSE /////////////////////////////////////////////

    const createCourseHandler = (e) => {
        submitHandler();
        navigate("/CourseInfo");
    };


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////


    ///////////////////////////////////////////// EXAMPLE VIEW COURSE ///////////////////////////////////////////////////////////
    const [showExVideo, setShowExVideo] = useState(false);

    const handleClose = () => setShowExVideo(false);
    const handleShow = () => setShowExVideo(true);

    const exVideoHandler = () => {
          setShowExVideo(true);
    }

    return (
        <>
        <br/>
        

        {data.map((item, index) => {

            return (
                            
                <div key={index}>    

                {/* <form onSubmit={submitHandler}> */}
                                
                    <Container>
                        
                        {/* <Link to="/MerchantView" style={{textDecoration: 'none', color: "black"}}> <Button variant="outline-secondary">ร้านค้าของฉัน</Button>{' '}</Link> */}
                       
                        <Button onClick={myStore} variant="outline-secondary">ร้านค้าของฉัน</Button>{' '}
                        <Button onClick={signOutHandler} variant="outline-danger" type="submit">ออกจากระบบ</Button>
                        <br/>
                        <br/>
                        <br/>
                        <p><strong><Link to={"/ProductCategory"} state={courseCategory} style={{textDecoration: 'none', color: "darkblue"}}>{courseCategory}</Link> {">"} <Link to={"/ProductSubCategory"} state={courseSubCategory} style={{textDecoration: 'none', color: "darkblue"}}>{courseSubCategory}</Link> {">"}  <Link to={"/ProductTypeCategory"} state={courseTypeCategory} style={{textDecoration: 'none', color: "darkblue"}}>{courseTypeCategory}</Link></strong></p>
                        
                        <h3 className="pb-3 border-bottom"></h3>
                        <br/>
                        <h3><strong>ข้อมูลทั่วไปคอร์ส {courseName}</strong> <span className="float-end"><span style={{color: 'green'}}><BsRecordFill/><BsRecordFill/></span><span style={{color: 'lightgray'}}><BsRecordFill/><BsRecordFill/><BsRecordFill/></span></span></h3>
                      
                    </Container>

                {/* </form> */}
             
                </div>
              
            );
        })}

        <br/>

        <form onSubmit={submitHandler}>

        <Container>
            <Row>
                                        
                                        <Col sm={6}>

                                            <h4>หมวดหมู่</h4>
                                            <Form.Control 
                                                type="text"
                                                className="rounded-0" 
                                                value={courseSubCategory}
                                                onChange={(e) => {
                                                    setCourseSubCategory(e.target.value);
                                                }}
                                              
                                            />
                                            <br />

                                            <h4>หมวดหมู่ย่อย</h4>
                                            <Form.Control 
                                                type="text"
                                                className="rounded-0" 
                                                value={courseTypeCategory}
                                                onChange={(e) => {
                                                    setCourseTypeCategory(e.target.value);
                                                }}
                                              
                                            />
                                            <br />
                                            
                                            <h4>ชื่อคอร์ส</h4>
                                            <Form.Control 
                                                type="text"
                                                className="rounded-0" 
                                                value={courseName}
                                                onChange={(e) => {
                                                    setCourseName(e.target.value);
                                                }}
                                              
                                            />
                                            <br />
    
                                            <h4>คำอธิบายคอร์สแบบสั้น</h4>
                                            <Form.Control 
                                                type="text"
                                                className="rounded-0" 
                                                value={courseCaption}
                                                onChange={(e) => {
                                                    setCourseCaption(e.target.value);
                                                }}
                                         
                                            />
                                            <br />
                                            
                                            <h4>ราคาเต็ม</h4>
                                            <Form.Control 
                                                type="text" 
                                                className="rounded-0"
                                                value={courseFullPrice}
                                                onChange={(e) => {
                                                    setCourseFullPrice(e.target.value);
                                                }}
                                             
                                                
                                            />
                                            <br />
    
                                            <h4>ส่วนลด (%)</h4>
                                            <Form.Control 
                                                type="text" 
                                                className="rounded-0"
                                                value={courseDiscount}
                                                onChange={(e) => {
                                                    setCourseDiscount(e.target.value);
                                                }}
                                           
                                            />
                                            <br />
    
                                            <h4>ราคาขายจริง</h4>
                                            <Form.Control 
                                                type="text" 
                                                className="rounded-0"
                                                value={coursePrice}
                                                onChange={(e) => {
                                                    setCoursePrice(e.target.value);
                                                }}
                                             
                                            />
                                            <br />

                                        </Col>
    
                                        <Col sm={6}>
    
                                        <h4>รูปโฆษณาคอร์ส</h4>
    
                                        {!!link ? 
                                        (
                                            <>
                                            {!!courseImage ? (
                                                <Col style={{textAlign: "center"}}><img src={courseImage} style={{width: 635, height: 300}} /> </Col>
                                            ) : (
                                                <Col style={{textAlign: "center"}}><img src={link} style={{width: 155, height: 155}} /> </Col>
                                            )}
                                            </>
                                        ) : (


                                            <>
                                            {!!courseImage ? (
                                                <Col style={{textAlign: "center"}}><img src={courseImage} style={{width: 635, height: 300}} /> </Col>
                                            ) : (
                                                <Col style={{textAlign: "center", fontSize: 115, color: 'gray'}}><BsPersonCircle/></Col>
                                            )}
                                            </>


                                        )}
                                        
    
                                        <br/>
                                        <InputGroup className="mb-3 rounded-0">
                                            <Form.Control
                                                aria-label="Example text with button addon"
                                                aria-describedby="basic-addon1"
                                                type="file"
                                                className="custom-file-label rounded-0"  
                                                id="customFile" 
                                                placeholder="" 
                                                name="customFile" 
                                                onChange={ (e) => {
                                                    const file = e.target.files[0];
                                                    setFile(file);
                                                    setLink("");
                                                }}
    
                                            />
    
                                            <Button 
                                            type="submit"
                                            onClick={uploadHandler}
                                            className="btn btn-success rounded-0"
                                            >
                                            อัลโหลด
                                            </Button>
                                        </InputGroup>

                                        <br/>






                                        <h4>วิดีโอแนะนำ</h4>
    
                                        {!!linkIntro ? 
                                        (
                                            <>
                                            {!!courseIntro ? (
                                                <Col style={{textAlign: "center"}}><video src={courseIntro} style={{width: '100%'}} controls autoPlay controlsList="nodownload" /> </Col>
                                            ) : (
                                                <Col style={{textAlign: "center"}}><img src={link} style={{width: 155, height: 155}} /> </Col>
                                            )}
                                            </>
                                        ) : (


                                            <>
                                            {!!courseIntro ? (
                                                <Col style={{textAlign: "center"}}><video src={courseIntro} style={{width: '100%'}} controls autoPlay controlsList="nodownload" /> </Col>
                                            ) : (
                                                <Col style={{textAlign: "center", fontSize: 115, color: 'gray'}}><BsPersonCircle/></Col>
                                            )}
                                            </>


                                        )}
                                        
    
                                        <br/>
                                        <InputGroup className="mb-3 rounded-0">
                                            <Form.Control
                                                aria-label="Example text with button addon"
                                                aria-describedby="basic-addon1"
                                                type="file"
                                                className="custom-file-label rounded-0"  
                                                id="customFile" 
                                                placeholder="" 
                                                name="customFile" 
                                                onChange={ (e) => {
                                                    const file = e.target.files[0];
                                                    setFile(file);
                                                    setLink("");
                                                }}
    
                                            />
    
                                            <Button 
                                            type="submit"
                                            onClick={uploadIntroHandler}
                                            className="btn btn-success rounded-0"
                                            >
                                            อัลโหลด
                                            </Button>
                                        </InputGroup>
    
                                        
    
                          
                                        <br/>
                                        <br/>
    
                                        </Col>
    
                                        
    
            </Row>

            <br/>
                
                <span className="float-end"><Button className="btn btn-success rounded-0" type="submit">บันทึก</Button>&nbsp;&nbsp;&nbsp; 
                <Link to={"/CreateCourse"}><Button onClick={createCourseHandler} className="rounded-0" variant="success" style={{textDecoration: 'none'}} >
                <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>&nbsp;&nbsp;&nbsp;บันทึกและเพิ่มรายละเอียด</Button></Link>{' '}</span>
            <br/>

    </Container>

    </form>


        <br/>
        <br/>
      

        </>
    );
}

export default CourseInfo;