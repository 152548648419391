import React, {useContext, useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { Row, Col, Card, Tabs, Tab, Spinner, Badge, Modal, InputGroup } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel';



import { auth, firestore } from "../../database/firebase";
// import { AuthContext } from '../../Old_Test/Auth';

import { Link, Navigate, useNavigate } from "react-router-dom";

import { FaHeart, FaTruck, FaFacebookMessenger, FaSketch, FaRegHeart, FaRegCommentAlt, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { BsStarFill, BsStarHalf, BsStar, BsEye, BsBookmark, BsChatSquareQuote, BsSearch } from "react-icons/bs";
import { text } from '@fortawesome/fontawesome-svg-core';
import { FcFlashOn } from "react-icons/fc";

import "./ArticleCategory/ArticleForm.css";

const SearchArticle = () => {

    

    const [data, setData] = useState([]);

    useEffect(() => {
        const ArticleRef = firestore.collection("article");
        const query = ArticleRef
        
            .orderBy("articleName")
            .where("publicStatus", "==", "เผยแพร่")
            .where("status", "==", "public") 
            .startAt(queryBar.toUpperCase())
            .endAt(queryBar.toUpperCase() + "\uf8ff")
            .limit(8);
          /*   .where("publicStatus", "==", "เผยแพร่")
            .where("status", "==", "public"); */
            // .limit(8);
            // .where("productName", "<=", queryBar.toUpperCase() + "\uf8ff");
            
            /* .where("productName", ">=", queryBar.toUpperCase())
            .where("productName", "<=", queryBar.toUpperCase() + "\uf8ff"); */
                query
                .get()
                .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempDataArray = [...tempDataArray,
                        {

                            articleID: doc.id,
                            articleID: doc.data().articleID,
                        
                            writerID: doc.data().writerID,
                            writerName: doc.data().writerName,
                            articletName: doc.data().articleName,
                            articleDate: doc.data().articleDate,

                            articleCategory: doc.data().articleCategory,
                            articleSubCategory: doc.data().articleSubCategory,
                            articleTypeCategory: doc.data().articleTypeCategory,
                            aeticleType : doc.data().articleType,

                            articleHeader: doc.data().articleHeader,
                            articleContent: doc.data().articleContent,
                            articleContentType: doc.data().articleContentType,
                            articleSection: doc.data().articleSection,

                            articleBadge: doc.data().articleBadge,

                            updateCount: doc.data().updateCount,
                            viewCount: doc.data().viewCount,

                            likeCount: doc.data().likeCount,
                            bookmarkCount: doc.data().bookmarkCount,
                

                        },
                    ];

                    
                    };

            });
            setData((oldDataArray) => tempDataArray);
    },
        (err) => {
            console.log(err);
        }
    );

    }, [data]);

    const [queryBar, setQueryBar] = useState("");

    function search(e){
        e.preventDefault();
        setQueryBar(e.target.value);


    }



    return (
        <Container>
      
        <br/>
        <h3><strong><BsSearch/> ค้นหาบทความ</strong></h3>
        <br/>


        <InputGroup className="mb-3">
            
            <Form.Control
                type="text"
                placeholder= "ค้นหาบทความ..."
                onChange={search}
                value={queryBar}
            />
            <Button variant="outline-secondary" style={{borderColor: 'darkgray', borderLeft: 0}} disabled>
                <BsSearch/>
            </Button>
        </InputGroup>

        <br/>

        <Row xs={2} md={4} className="g-4">

            {data.map((item) => {
                            
                return (
                               
                    <div key={item.id}>



                                <div className='card'>
                                    <Link to={'/ReadArticle/'+ item.articleID} style={{textDecoration: 'none', color: "black"}}>
                                        <Card className="cardarticle">

                                            {!!item.articleContent[0] ? (
                                                <Card.Img variant="top" class='cardarticledetailimg' src={item.articleContent[0]} />
                                            ) : (
                                                <Col style={{backgroundColor: '#FFFAFA'}}><div class='cardarticledetailnoimg'><strong>{(item.writerName)}</strong></div></Col>
                                            )}
                                            
                                            
                                            <Card.Body class='cardarticledetail'>
                                                <Card.Title>
                                                </Card.Title>

                                                <p class='articlename' style={{fontWeight: 500}}>{item.articletName}</p>
                                           
                                                <p class='cardarticledetailnamedatetime'>{item.writerName} <br/> {(item.articleDate).toDate().toLocaleDateString('th-TH', {year: 'numeric', month: 'short', day: 'numeric', weekday: 'short',})} | {(item.articleDate).toDate().toLocaleTimeString()} 
                                                <br/>
                                             
                                                {/* <span class='cardarticledetaillikebookmark'><strong><FaRegHeart/> {item.likeCount}</strong> &nbsp;&nbsp;&nbsp;<strong><BsBookmark/> {item.bookmarkCount} &nbsp;&nbsp;&nbsp;<BsEye/> {item.viewCount + 1}</strong></span></p> */}
                                                <span class='cardarticledetaillikebookmark'><strong><FaRegHeart/> {item.likeCount}</strong><strong>&nbsp;&nbsp;<BsEye/> {item.viewCount + 1}</strong> <Badge style={{fontWeight: 'bold', marginTop: 5}} className="float-end rounded-pill" bg="light text-secondary">{item.articleCategory}</Badge></span></p>
   
                                                    

                                            </Card.Body>
                                        </Card>
                                    </Link> 
                                </div>

                                    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                    </div>

                )
            })}

            </Row>


    

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        </Container>
        
    );
};

export default SearchArticle;