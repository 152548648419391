import React, { useEffect, useState, useContext } from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Offcanvas, Form, Button, Nav, Navbar, NavDropdown, Carousel, Modal } from "react-bootstrap";

import { auth, firebase, firestore } from "../../database/firebase";

import ads_sample01 from '../images_s/ads_sample01.png';

import { Row, Col, Card, Tabs, Tab } from 'react-bootstrap'

import { BrowserRouter, Routes, Route, NavLink, useParams, useNavigate, Link } from "react-router-dom";
import Home from "../Router_TestFlight/Home";

// import DefaultView from "../HomeTest";
import About from "../Router_TestFlight/About";

import "./Header.css";
import HomeView from "../Home/Home";
import Footer from "../Footer/Footer";
/* import StorageTest from "../../database/TestStorage";

import SignUpForm from "../Authentication/SignUp";
import LogInForm from "../Authentication/LogInForm";
import GoogleLogIn from "../Authentication/GoogleLogIn"; */

// import Book from "../Product/Book";
import ProductDetail from "../Product/ProductDetail";


import CheckoutSuccess from "../Checkout/CheckoutSuccess";
import Profile from "../User/Profile";
import Order from "../../component_m/Order/Order";

import Error from "../Router_TestFlight/Error";

//IMAGE
import selpage_logo_02 from '../images_s/selpage_logo_02.jpg';
import enquex_logo_01 from '../images_s/enquex_logo_01.jpg';

import ads_testflight02 from '../images_s/ads_testflight02.jpg';

import OrderCustomer from "../User/OrderCustomer";
import OrderHistory from "../../component_m/Order/OrderHistory";

import SignUp from "../Authentication/Email/SignUp";
import LogIn from "../Authentication/Email/LogIn";

import { AuthContext } from "../Authentication/AuthProvider";
import Test_Home from "../Home/Test_Home";

import CheckoutDetail from "../Checkout/CheckoutDetail";
import AddProduct from "../../component_m/Merchant/AddProduct";
import MerchantView from "../../component_m/Merchant/MerchantView";

import ProductList from "../../component_m/Merchant/ProductList";

import TestFlight from "../Home/TestFlight";
import AddDataFirestore from "../../database/TestAddData";
import CheckoutCart from "../Checkout/CheckoutCart";
import Tracking from "../AboutUs/Tracking";
import HowPurchase from "../AboutUs/HowPurchase";
import Contact from "../AboutUs/Contact";
import Law from "../Product/Book/Law";
import Computer from "../Product/Book/Computer";
import Business from "../Product/Book/Business";
import CivilExamination from "../Product/Book/CivilExamination";
import Education from "../Product/Book/Education";

import SearchBar from "../Home/SearchBar";

import { BsSearch } from "react-icons/bs";
import ProductCategory from "../Product/ProductCategory";
import ProductSubCategory from "../Product/ProductSubCategory";
import ProductTypeCategory from "../Product/ProductTypeCategory";
import Linguistics from "../Product/Book/Linguistics";
import EditProductDetail from "../../component_m/Merchant/EditProductDetail";
import Course from "../Course/Course";
import UploadVideo from "../../component_m/Instructor/UploadVideo";
import UploadContent from "../../component_m/Instructor/UploadContent";
import InstructorView from "../../component_m/Instructor/InstructorView";
import CreateCourse from "../../component_m/Instructor/CreateCourse";
import InstructorProfile from "../../component_m/Instructor/InstructorProfile";
import Instructor from "../../component_m/Instructor/Instructor";
import CoursePreview from "../../component_m/Instructor/CoursePreview";
import CourseInfo from "../../component_m/Instructor/CourseInfo";
import CourseDetail from "../../component_m/Instructor/CourseDetail";
import UploadExampleVideo from "../../component_m/Instructor/UploadExampleVideo";
import UploadTopic from "../../component_m/Instructor/UploadTopic";
import ReadArticle from "../../component_m/Article/ReadArticle";
import CreateArticle from "../../component_m/Article/CreateArticle";
import MyArticle from "../../component_m/Article/MyArticle";
import BookmarkArticle from "../../component_m/Article/BookmarkArticle";
import SignUpDetail from "../Authentication/Email/SignUpDetail";
import ArticleSubCategory from "../../component_m/Article/ArticleSubCategory/ArticleSubCategory";
import ArticleCategory from "../../component_m/Article/ArticleCategory/ArticleCategory";
import WriterPage from "../../component_m/Article/WriterPage";
import ArticleTypeCategory from "../../component_m/Article/ArticleTypeCategory/ArticleTypeCategory";
import ComputerArticleCategory from "../../component_m/Article/ArticleCategory/ComputerArticleCategory";
import FoodArticleCategory from "../../component_m/Article/ArticleCategory/FoodArticleCategory";
import GameArticleCategory from "../../component_m/Article/ArticleCategory/GameArticleCategory";
import PlantArticleCategory from "../../component_m/Article/ArticleCategory/PlantArticleCategory";
import TravelArticleCategory from "../../component_m/Article/ArticleCategory/TravelArticleCategory";
import GeneralArticleCategory from "../../component_m/Article/ArticleCategory/GeneralArticleCategory";
import MyOrder from "../User/MyOrder";
import AboutEnqueX from "../AboutUs/AboutEnqueX";
import QPoint from "../AboutUs/QPoint";
import AllArticle from "../../component_m/Article/AllArticle";
import BookArticleCategory from "../../component_m/Article/ArticleCategory/BookArticleCategory";
import TermsOfService from "../AboutUs/TermsOfService";
import SearchArticle from "../../component_m/Article/SearchArticle";

function Header() {

    let activeHeaderButtonClassName = "nav-active";
    let nonActiveHeaderButtonClassName = "nav-nonaction"

    /* const navigate = useNavigate();

    const refreshPage = () => {
        navigate(1);
    }
 */
    //Test useParams
    // let { productId } = useParams();


   
    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

    const [displayName, setDisplayName] = useState("");

    
    var user = auth.currentUser;
    var email, uid;

    if (user != null) {
        
        email = user.email;
        uid = user.uid;  
        console.log(uid);
        console.log(user.displayName);

    }

    /* const docRef = firestore.collection("user");
      const query = docRef
          .where("uid", "==", uid);
      query
          .get()
          .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                  if (doc.exists) {
                      console.log(doc.data().displayName);
                      setDisplayName(doc.data().displayName);
                  }
              });
          })
          .catch((err) => {
              console.error(err);
          }); */

   /*  useEffect(() => {
        if(user) {
            console.log(user.displayName)
        }
    }, [user]); */

    const [showMyArticleNotlogin, setShowMyArticleNotlogin] = useState(false);
    const handleCloseMyArticleNotlogin = () => setShowMyArticleNotlogin(false);

  return (

    <div>

        <Navbar expand="lg" className="mb-3">
            <Container bor>

                <Navbar.Brand href="/">
                    <img
                        src={enquex_logo_01}
                        width="100"
                        height="30"
                        className="d-inline-block align-top"
                        alt=""
                    />
                </Navbar.Brand>

                    <Navbar.Toggle expand="lg" />

                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-lg`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`} style={{fontFamily: 'Kanit'}}>
                            EnqueX.com
                            </Offcanvas.Title>
                        </Offcanvas.Header>

                        <Offcanvas.Body>
                            
                            <Nav className="me-auto" style={{marginTop: 6}}>
                            {/* <Nav.Link href="#features"><strong>สินค้าขายดี</strong></Nav.Link> */}
                            <NavLink onClick={() => {window.location.href="/"}} style={{ color: "black", marginRight: 8, marginTop: 8, textDecoration: 'none', fontFamily: 'Kanit' }}><strong>หน้าหลัก</strong></NavLink>
                            {/* Search Bar Test Flight */}
                            
                            {/* <NavLink onClick={() => {window.location.href='/AboutUs/HowPurchase'}} style={{ marginTop: 8, marginRight: 8, color: "black", textDecoration: 'none', fontFamily: 'Kanit' }}><strong>วิธีการซื้อ</strong></NavLink>
                            <NavLink onClick={() => {window.location.href='/AboutUs/Tracking'}} style={{ marginTop: 8, marginRight: 8, color: "black", textDecoration: 'none', fontFamily: 'Kanit' }}><strong>ติดตามสินค้า</strong></NavLink>
                            <NavLink onClick={() => {window.location.href="/SearchBar"}} style={{ marginTop: 8, marginRight: 8, color: "black", textDecoration: 'none', fontFamily: 'Kanit' }}><strong>ค้นหาสินค้า</strong></NavLink> */}

                            <NavLink onClick={() => {window.location.href='/TravelArticleCategory'}} style={{ marginTop: 8, marginRight: 8, color: "black", textDecoration: 'none', fontFamily: 'Kanit' }}><strong>ท่องเที่ยว</strong></NavLink>
                            <NavLink onClick={() => {window.location.href='/FoodArticleCategory'}} style={{ marginTop: 8, marginRight: 8, color: "black", textDecoration: 'none', fontFamily: 'Kanit' }}><strong>อาหาร</strong></NavLink>
                            <NavLink onClick={() => {window.location.href='/PlantArticleCategory'}} style={{ marginTop: 8, marginRight: 8, color: "black", textDecoration: 'none', fontFamily: 'Kanit' }}><strong>ต้นไม้</strong></NavLink>
                            <NavLink onClick={() => {window.location.href='/GameArticleCategory'}} style={{ marginTop: 8, marginRight: 8, color: "black", textDecoration: 'none', fontFamily: 'Kanit' }}><strong>วิดีโอเกม</strong></NavLink>
                            <NavLink onClick={() => {window.location.href='/BookArticleCategory'}} style={{ marginTop: 8, marginRight: 8, color: "black", textDecoration: 'none', fontFamily: 'Kanit' }}><strong>หนังสือ</strong></NavLink>
                            <NavLink onClick={() => {window.location.href='/ComputerArticleCategory'}} style={{ marginTop: 8, marginRight: 8, color: "black", textDecoration: 'none', fontFamily: 'Kanit' }}><strong>คอมพิวเตอร์</strong></NavLink>
                            <NavLink onClick={() => {window.location.href='/GeneralArticleCategory'}} style={{ marginTop: 8, marginRight: 8, color: "black", textDecoration: 'none', fontFamily: 'Kanit' }}><strong>บทความทั่วไป</strong></NavLink>
                            <NavLink onClick={() => {window.location.href='/AllArticle'}} style={{ marginTop: 8, marginRight: 8, color: "black", textDecoration: 'none', fontFamily: 'Kanit' }}><strong>บทความทั้งหมด</strong></NavLink>
                            <NavLink onClick={() => {window.location.href='/SearchArticle'}} style={{ marginTop: 8, marginRight: 8, color: "black", textDecoration: 'none', fontFamily: 'Kanit' }}><strong>ค้นหาบทความ</strong></NavLink>


                            
                            {/* <NavLink onClick={() => {window.location.href="/Computer"}} style={{ color: "black", marginRight: 8, marginTop: 8, textDecoration: 'none' }}><strong>คอมพิวเตอร์และเทคโนโลยี</strong></NavLink>
                            <NavLink onClick={() => {window.location.href="/Business"}} style={{ color: "black", marginRight: 8, marginTop: 8, textDecoration: 'none' }}><strong>ธุรกิจและการลงทุน</strong></NavLink>
                            <NavLink onClick={() => {window.location.href="/Law"}} style={{ color: "black", marginRight: 8, marginTop: 8, textDecoration: 'none' }}><strong>กฎหมาย</strong></NavLink> */}
                                {/* <NavDropdown title="หมวดหมู่สินค้า" id="collasible-nav-dropdown" style={{ color: "black", textDecoration: 'none', fontFamily: 'Kanit'}}>
                                        <NavDropdown.Item onClick={() => {window.location.href="/Computer"}}>คอมพิวเตอร์และเทคโนโลยี</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => {window.location.href="/Business"}}>ธุรกิจและการตลาด</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => {window.location.href="/Linguistics"}}>ภาษาศาสตร์</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => {window.location.href="/Law"}}>กฎหมาย</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => {window.location.href="/CivilExamination"}}>สอบบรรจุเข้าทำงาน</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => {window.location.href="/Education"}}>การศึกษา</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                                </NavDropdown> */}
                                
                                {/* <NavDropdown title="บทความ" id="collapsible-nav-dropdown" style={{color: "black", textDecoration: 'none', fontFamily: 'Kanit'}}>
                                        <NavDropdown.Item onClick={() => {window.location.href="/TravelArticleCategory"}}>ท่องเที่ยว</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => {window.location.href="/FoodArticleCategory"}}>อาหาร</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => {window.location.href="/PlantArticleCategory"}}>ต้นไม้</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => {window.location.href="/GameArticleCategory"}}>วิดีโอเกม</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => {window.location.href="/BookArticleCategory"}}>หนังสือ</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => {window.location.href="/ComputerArticleCategory"}}>คอมพิวเตอร์</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => {window.location.href="/GeneralArticleCategory"}}>บทความทั่วไป</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item onClick={() => {window.location.href="/AllArticle"}}>บทความทั้งหมด</NavDropdown.Item>
                                </NavDropdown> */}

                                <NavDropdown title="เกี่ยวกับเรา" id="collapsible-nav-dropdown" style={{color: "black", textDecoration: 'none', fontFamily: 'Kanit'}}>
                                        <NavDropdown.Item onClick={() => {window.location.href="/AboutUs/AboutEnqueX"}}>เกียวกับ EnqueX</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => {window.location.href="/AboutUs/TermsOfService"}}>ข้อกำหนดการให้บริการ</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item onClick={() => {window.location.href="/AboutUs/Contact"}}>ติดต่อเรา</NavDropdown.Item>
                                </NavDropdown>

                                
                                
                            </Nav>

                            <Nav className="justify-content-end flex-grow-1">

                                {/* <Nav.Link onClick={() => {window.location.href="/AddDataFirestore"}} style={{marginTop: 8, marginRight: 8, color: "black"}} ><strong>TestAdd Array</strong></Nav.Link>
                                <Nav.Link onClick={() => {window.location.href="/TestFlight"}} style={{marginTop: 8, marginRight: 8, color: "black"}} ><strong>Test Flight</strong></Nav.Link>
                                 */}
                                 {/* <Nav.Link onClick={() => {window.location.href="/Test_Home"}} style={{marginTop: 8, marginRight: 8, color: "black"}} ><strong>Test LoadMoreProduct</strong></Nav.Link> */}
                                {user ? (
                                    <>
                                    {/* <Nav.Link onClick={() => {window.location.href="/User/Profile"}} style={{marginTop: 8, marginRight: 8, color: "black", fontFamily: 'Kanit'}} ><strong>ตะกร้าสินค้า</strong></Nav.Link> */}


                                    <Nav.Link onClick={() => {window.location.href="/MyArticle/" + uid}} style={{marginTop: 8, marginRight: 8, color: "black", fontFamily: 'Kanit'}} ><strong>บทความของฉัน</strong></Nav.Link>



                                    <NavLink onClick={() => {window.location.href="/User/Profile"}} style={{ marginTop: 8, marginRight: 8, color: "black", textDecoration: 'none', fontFamily: 'Kanit' }}><Button variant="warning"><strong>{email}</strong></Button></NavLink>&nbsp;&nbsp;
                                    
                                    {/* Example RoundButton Name LogIn */}
                                    {/* <NavLink onClick={() => {window.location.href="/User/Profile"}} style={{ marginTop: 8, marginRight: 8, color: "black", textDecoration: 'none' }}><Button variant="warning" style={{width: 50, height: 50, fontSize: 20}} className="rounded-circle"><strong>{(email).slice("0", "1")}</strong></Button></NavLink>&nbsp;&nbsp; */}
                                    </>
                                ) : (
                                    <>

                                    

                                    <Nav.Link onClick={() => setShowMyArticleNotlogin(true)} style={{marginTop: 8, marginRight: 8, color: "black", fontFamily: 'Kanit'}} ><strong>บทความของฉัน</strong></Nav.Link>

                                    <NavLink onClick={() => {window.location.href="/LogIn"}} style={{ marginTop: 8, marginRight: 8, color: "black", textDecoration: 'none', fontFamily: 'Kanit' }}><Button className="rounded-0" variant="outline-secondary" style={{color: "black"}}><strong>เข้าสู่ระบบ</strong></Button></NavLink>
                                    <NavLink onClick={() => {window.location.href="/SignUp"}} style={{ marginTop: 8, color: "black", textDecoration: 'none', fontFamily: 'Kanit' }}><Button className="rounded-0" variant="dark"><strong>สมัครใหม่</strong></Button></NavLink>
                                    </>
                                )}
                            </Nav>

                        </Offcanvas.Body>

                    </Navbar.Offcanvas>
            </Container>
        </Navbar>

        {/* <h3 style={{marginTop: -20, boxShadow: 5}} className="pb-3 border-bottom"></h3> */}

                                    <Modal 
                                        show={showMyArticleNotlogin} 
                                        onHide={handleCloseMyArticleNotlogin} 
                                        animation={false} 
                                        size="sm"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered>
                                        <Modal.Header>
                                        <Modal.Title>เข้าสู่ระบบ/สมัครสมาชิก</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body><Link onClick={() => (window.location.href="/LogIn", setShowMyArticleNotlogin(false))} style={{textDecoration: 'none'}}>เข้าสู่ระบบ</Link> หรือ <Link onClick={() => (window.location.href="/SignUp", setShowMyArticleNotlogin(false))} style={{textDecoration: 'none'}}>สมัครสมาชิก</Link> เพื่อใช้งานบทความของฉัน</Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCloseMyArticleNotlogin}>
                                                ปิด
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
             
                

                <Routes>
                    {/* VIEW */}
                    <Route path='/' element={<HomeView/>} />
                    {/* <Route path='/Book' element={<Book/>} /> */}

                    <Route path='/SearchBar' element={<SearchBar/>} />

                    {/* TESTFLIGHT */}
                    {/* <Route path='/AddDataFirestore' element={<AddDataFirestore/>} />
                    <Route path='/TestFlight' element={<TestFlight/>} /> 
                    <Route path='/Test_Home' element={<Test_Home/>} /> */}
 
                    {/* EnqueX */}
                    {/* <Route path='/AboutUs/AboutUs' element={<AboutUs/>} />
                    <Route path='/AboutUs/SelpagePoint' element={<SelpagePoint/>} /> */}
                    
                    <Route path='/AboutUs/AboutEnqueX' element={<AboutEnqueX/>} />
                    <Route path='/AboutUs/QPoint' element={<QPoint/>} />
                    <Route path='/AboutUs/Contact' element={<Contact/>} />
                    <Route path='/AboutUs/HowPurchase' element={<HowPurchase/>} />
                    <Route path='/AboutUs/Tracking' element={<Tracking/>} />

                    <Route path='/AboutUs/TermsOfService' element={<TermsOfService/>} />
                    

                    {/* USER */}
                    <Route path='/User/Profile' element={<Profile/>} />

                    {/* <Route path="/UploadVideo" element={<UploadVideo/>} /> */}
                    {/* <Route path="/UploadVideo" element={<UploadContent/>} /> */}

                    {/* ******************* ARTICLE ***************************************** */}

                    <Route path='/articleCategory' element={<ArticleCategory/>} />
                    <Route path='/articleSubCategory' element={<ArticleSubCategory/>} />
                    <Route path='/articleTypeCategory' element={<ArticleTypeCategory/>} />

                    <Route path='/BookArticleCategory' element={<BookArticleCategory/>} />
                    <Route path='/ComputerArticleCategory' element={<ComputerArticleCategory/>} />
                    <Route path='/FoodArticleCategory' element={<FoodArticleCategory/>} />
                    <Route path='/GameArticleCategory' element={<GameArticleCategory/>} />
                    <Route path='/PlantArticleCategory' element={<PlantArticleCategory/>} />
                    <Route path='/TravelArticleCategory' element={<TravelArticleCategory/>} />
                    <Route path='/GeneralArticleCategory' element={<GeneralArticleCategory/>} />
                    <Route path='/AllArticle' element={<AllArticle/>} />
                    
                    
                    {/* <Route path='/ProductTypeCategory' element={<ProductTypeCategory/>} /> */}

                    <Route path='/BookmarkArticle'>
                        <Route path=":userId" element={<BookmarkArticle/>} />
                    </Route>

                    <Route path='/MyArticle'>
                        <Route path=":uid" element={<MyArticle/>} />
                    </Route>


                    <Route path='/ReadArticle'>
                        <Route path=":articleId" element={<ReadArticle/>} />
                    </Route>

                    <Route path='/CreateArticle'>
                        <Route path=":articleId" element={<CreateArticle/>} />
                    </Route>

                    {/* <Route path='/WriterPage' element={<WriterPage/>} /> */}

                    <Route path='/WriterPage'>
                        <Route path=":writerId" element={<WriterPage/>} />
                    </Route>

                    <Route path='/SearchArticle' element={<SearchArticle/>} />


                    {/* ******************* COURSE ****************************************** */}

                    {/* COURSE */}
                    <Route path='/Course'>
                        <Route path=":courseId" element={<Course/>} />
                    </Route>

                    {/* COURSE PREVIEW */}
                    <Route path='/CoursePreview'>
                        <Route path=":courseId" element={<CoursePreview/>} />
                    </Route>

                    <Route path='/Instructor' element={<Instructor/>} />

                    <Route path='/InstructorView'>
                        <Route path=":courseId" element={<InstructorView/>} />
                    </Route>

                    <Route path='/InstructorProfile'>
                        <Route path=":courseId" element={<InstructorProfile/>} />
                    </Route>

                    <Route path='/CourseInfo'>
                        <Route path=":courseId" element={<CourseInfo/>} />
                    </Route>

                    <Route path='/CourseDetail'>
                        <Route path=":courseId" element={<CourseDetail/>} />
                    </Route>

                    <Route path='/UploadExampleVideo'>
                        <Route path=":courseId" element={<UploadExampleVideo/>} />
                    </Route>

                    <Route path='/UploadTopic'>
                        <Route path=":courseId" element={<UploadTopic/>} />
                    </Route>

                    <Route path='/UploadContent'>
                        <Route path=":courseId" element={<UploadContent/>} />
                    </Route>


                    {/* ????????????????????????????????????????????????????????? */}
                    <Route path='/CreateCourse'>
                        <Route path=":courseId" element={<CreateCourse/>} />
                    </Route>
                    {/* ????????????????????????????????????????????????????????? */}

                    {/* <Route path='/InstructorProfile' element={<InstructorProfile/>} />
                    <Route path='/CreateCourse' element={<CreateCourse/>} /> */}

                    {/* UPLOAD Video */}
                    <Route path='/UploadContent'>
                        <Route path=":courseId" element={<UploadContent/>} />
                    </Route>

                    <Route path='/UploadVideo' element={<UploadVideo/>} />

                    {/* ******************* COURSE ****************************************** */}
                    
                    {/* Merchant */}
                    <Route path='/AddProduct' element={<AddProduct/>} />
                    <Route path='/MerchantView' element={<MerchantView/>} />
                    <Route path="/Order" element={<Order/>} />
                    <Route path="/ProductList" element={<ProductList/>} />
                    <Route path="/EditProductDetail" element={<EditProductDetail/>} />

                    <Route path="/MyOrder" element={<MyOrder/>} />
                    



                    {/* AUTH */}
                    <Route path='/LogIn' element={<LogIn/>} />
                    <Route path='/SignUp' element={<SignUp/>} />
                    <Route path='/SignUpDetail' element={<SignUpDetail/>} />

                    {/* PRODUCT */}
                    <Route path='/ProductDetail'>
                        <Route path=":productId" element={<ProductDetail/>} />
                    </Route>

                    

                    <Route path='/ProductCategory' element={<ProductCategory/>} />
                    <Route path='/ProductSubCategory' element={<ProductSubCategory/>} />
                    <Route path='/ProductTypeCategory' element={<ProductTypeCategory/>} />



                    {/* Category Product */}
                    <Route path='/Linguistics' element={<Linguistics/>} />
                    <Route path='/Law' element={<Law/>} />
                    <Route path='/Computer' element={<Computer/>} />
                    <Route path='/Business' element={<Business/>} />
                    <Route path='/CivilExamination' element={<CivilExamination/>} />
                    <Route path='/Education' element={<Education/>} />


                    {/* CHECKOUT PRODUCT */}
                    
                    <Route path='/checkoutCart' element={<CheckoutCart/>} />
                    <Route path='/CheckoutDetail/Cart/'>
                        <Route path=":productId" element={<CheckoutDetail/>} />
                    </Route>
                    <Route path='/CheckoutSuccess' element={<CheckoutSuccess/>} />

                    <Route path='*' element={<Error/>} />



                   
                </Routes>

                 <Footer/>

    

    </div>

    

    );
}

export default Header;