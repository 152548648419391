import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from "react-bootstrap";

import { NavLink } from "react-router-dom";

import { FaFacebookF, FaInstagram, FaPinterest, FaTwitter, FaYoutube, FaCheck, FaSketch, FaOpencart } from "react-icons/fa";
import { BsSendCheck, BsFill1CircleFill, BsFill2CircleFill, BsFill3CircleFill, BsFill4CircleFill, BsFill5CircleFill, BsCheckLg, BsFillPatchCheckFill, BsClipboardCheck } from "react-icons/bs";

function TermsOfService() {

    return (

        <Container>

            <br/>

            <h3><strong><BsClipboardCheck/> ข้อกำหนดการให้บริการ</strong></h3>
       
            <h3 className="pb-3 border-bottom"></h3>
            <br/>
            {/* <h4 style={{fontSize:80, color: 'black'}}>❝</h4> */}
            <h5 style={{marginTop: 5}}>1. EnqueX (เอ็น-คิว-เอ็กซ์) มีความยินดีที่จะให้บริการเพื่อร่วมบันทึกและแบ่งปันความรู้ ประสบการณ์ เรื่องราว หรือแลกเปลี่ยนไอเดียต่าง ๆ ออกแบบโดยรองรับการเขียนบทความและบันทึก และคำนึงถึงความเป็นส่วนตัวของข้อมูลผู้ใช้บริการ<br/><br/></h5>
            <h5 style={{marginTop: 5}}>2. ทางทีมงานขอสงวนสิทธิในการลบเนื้อหา รูปภาพ หรือข้อความ ที่มีลักษณะขัดต่อความสงบเรียบร้อยหรือศีลธรรมอันดีของประชาชน<br/><br/></h5>
            <h5 style={{marginTop: 5}}>3. หากมีข้อสงสัยอย่างลังเลที่จะติดต่อเรา<br/></h5>

            {/* <h4 style={{fontSize:150, color: 'black'}}>❝</h4>
            <h4 style={{marginTop: -70}}>พวกไม่หยุดที่จะสรรหาสินค้าที่ดีเพื่อคุณ</h4>

            <h4 style={{fontSize:150, color: 'black'}}>❝</h4>
            <h4 style={{marginTop: -70}}>พวกไม่หยุดที่จะสรรหาสินค้าที่ดีเพื่อคุณ</h4> */}

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
           
            

        </Container>

    );

}

export default TermsOfService;