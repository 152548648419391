import React, { useContext, useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Card, Button, ButtonGroup, Dropdown } from 'react-bootstrap';

import { Link, redirect, useNavigate } from "react-router-dom";

import { AuthContext } from "../Authentication/AuthProvider";
import { auth, firestore } from "../../database/firebase";

function Cart() {

    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

   //const [uid, setUid] = useState("");

    var user = auth.currentUser;
    // var uid = "";
    var uid = "";

    if (user != null) {
        //email = user.email;
        uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                         // this value to authenticate with your backend server, if
                         // you have one. Use User.getToken() instead.
        console.log(uid);
        
        //TEST AUTH
        // refreshPage();
    } else {

      navigate("/");

    }

    const [orderID, setOrderID] = useState("");

    const [data, setData] = useState([]);

    ///////////////////////////////////////////////////////////////////////
    const [productFullPrice, setProductFullPrice] = useState("");
    const [productPrice, setProductPrice] = useState("");

    // const [count, setCount] = useState([""]); 

    var productCartFullPrice = "";
    var productPriceCart = "";

    /* var productNameArray = [""];
    var storeNameArray = [""];
    var productIdArray = [""]; */
    ////////////////////////////////////////////////////////////////////////

    const formatter = new Intl.NumberFormat('th', {
      style: 'currency',
      currency: 'THB',
    
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    
    var productArray = [""];
    const [productName, setProductName] = useState();

    useEffect(() => {
        const cartRef = firestore.collection("cart");
        const query = cartRef
            
            .where("userID", "==", uid);
                query
                .get()
                .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        
                        tempDataArray = [...tempDataArray,
                        {
                            // productID: doc.id,
                            id: doc.id,
                            productID: doc.data().productID,
                            storeName: doc.data().storeName,
                            productName: doc.data().productName,
                            // productImage: doc.data().productImage,
                            productFullPrice: doc.data().productFullPrice,
                            productPrice: doc.data().productPrice,
                            productQuantity: doc.data().productQuantity,
                            // productPriceTotal: doc.data().productPriceTotal,
                            
                            orderDate: Date(doc.data().orderDate),
                

                        },
                    ];

                    setOrderID(doc.id);

                    

                    // setCount(doc.data().productQuantity);


                    // STORE NAME ARRAY
                    /* storeNameArray.push(doc.data().storeName);
                    console.log(storeNameArray); */

                    // PRODUCT ID ARRAY
                    /* productIdArray.push(doc.data().productID);
                    console.log(productIdArray); */

                    // PRODUCT NAME ARRAY
                    /* productNameArray.push(doc.data().productName);
                    console.log(productNameArray); */
                  
                    // SUM PRODUCT FLIGHT OF CART
                    productCartFullPrice = tempDataArray.reduce((totalPrices, products) => totalPrices + parseInt(products.productFullPrice * products.productQuantity, 10), 0);
                    productPriceCart = tempDataArray.reduce((totalPrice, product) => totalPrice + parseInt(product.productPrice * product.productQuantity, 10), 0);
                    setProductFullPrice(productCartFullPrice);
                    setProductPrice(productPriceCart);
                    /////////////////////////////

                    productArray.push(doc.data().productName);
                    console.log("This Is Array 2" + (productArray));

                    /* setProductName(productName);
                    console.log( "This Is Array" + (productName)); */
                    
                    };

            });
            setData((oldDataArray) => tempDataArray);
    },
        (err) => {
            console.log(err);
        }
    );

    }, [uid]);

    const navigate = useNavigate();

        const refreshPage = () => {
            navigate(0);
        }
    
        const signUpPage = () => {
            navigate("/SignUp");
        }

    const deleteHandler = (id) => {
      const cartRef = firestore.collection("cart");
      cartRef
        .doc(id)
        .delete()
        .then(() => {
          console.log("DELETE");
          alert("ลบสินค้าจากตะกร้าสำเร็จ");
          refreshPage();
          
        })
        .catch ((err) => {
          console.log(err);
        });
    }


    //BUY ALL PRODUCT IN CART

  



    // SELECT PRODUCT QUANTITY
    /* useEffect(() => {
        
      if (count <= 0) {
          setCount(1);
      } else {
          setCount(count);
      }
      // setCalculation(() => count * 2);
    }, [count]); // <- add the count variable here */



    return (
  
      <Container>

      {!!orderID ? 
      (
        <>

          <h3><strong>ตะกร้าสินค้า</strong></h3>
          <h3 className="pb-3 border-bottom"></h3>
          {/* {!!data ? (
                        <p>You are logged in - <Link to="/dashboard">View Dashboard</Link></p>
                    ) : (
                        <p></p>
                    )} */}

          {data.map((item, index) => {

            return (
              <div key={index}>   

          {/* <p>จำนวนสินค้าในตะกร้า: 3 รายการ | ยอดรวม: 1,350 บาท </p> */}
          
          <br/>

          {/* { item == null ? (

              <p>ยังไม่มีสินค้าในตะกร้า</p>

          ) : (<p></p>)} */}


            <Card className="rounded-0">
              {/* <Card.Header>
                <strong>ชื่อสินค้า:</strong> ต้นมอนสเตอร่า<br/>
                <strong>ร้านค้า:</strong> SelPage_Store<br/> 
                <strong>ราคา:</strong> 450 บาท<br/>
              </Card.Header> */}
              <Card.Body>
                <strong>ชื่อสินค้า:</strong> {item.productName}<br/>
                <strong>ร้านค้า:</strong> {item.storeName}<br/> {/* ร้านค้า เป็นลิ้งค์หรือไม่ */}
                <strong>ราคา:</strong> ฿{item.productPrice} <del style={{color: "gray"}}>฿{item.productFullPrice}</del><br/>
                <strong>จำนวน:</strong> {item.productQuantity}<br/>

              <br/>
              {/* <strong>จำนวน:</strong><br/>
              <ButtonGroup className="mb-2">
                  
                  <Button onClick={() => setCount((c) => c - 1)} variant="light" className="rounded-0"><strong> - </strong></Button>
                  <Button variant="" className="rounded-0" style={{width: 80}}>{count}</Button>
                  <Button onClick={() => setCount((c) => c + 1)} variant="light" className="rounded-0"><strong> + </strong></Button>
              </ButtonGroup>
              <br/> */}
              
              <Link to={'/CheckoutDetail/Cart/'+ item.productID} state={item.productQuantity} style={{textDecoration: 'none', color: "black"}}><Button className="btn btn-success rounded-0">ซื้อสินค้า</Button></Link>{' '}
              <Button onClick={ () => (deleteHandler(item.id))} className="btn btn-danger rounded-0">ลบรายการ</Button><br/>
              </Card.Body>

            </Card>

          </div>
            );
          })}

        </>
      ) : (
        <>

          <br/>
          <h3><strong>ตะกร้าสินค้า</strong></h3>
          <h3 className="pb-3 border-bottom"></h3>
          <br/>
          <p>ยังไม่มีสินค้าในตะกร้า</p>

        </>
      )}
  
      
  
      
      <br/>
      <br/>

      <p className="float-end"><strong>จำนวนเงินทั้งสิ้น:</strong> {formatter.format(productPrice)} <del style={{color: "gray"}}>฿{productFullPrice}</del></p><br/><br/>

      <Link to="/CheckoutCart"><Button className="btn btn-success rounded-0 float-end" type="submit">ซื้อสินค้าทั้งหมด</Button></Link>
  
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      
      
      </Container>
    );
  }
  
  export default Cart;