import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from "react-bootstrap";

import { NavLink } from "react-router-dom";

import selpage_logo_dark02 from '../images_s/selpage_logo_dark02.jpg';
import enquex_logo_dark_01 from '../images_s/enquex_logo_dark_01.png';

import { FaFacebookF, FaInstagram, FaPinterest, FaTwitter, FaYoutube } from "react-icons/fa";


function Footer() {

    return (

        <Container className='bg-dark' style={{marginBottom: 0}} fluid>

      <br/>

        <Container className='bg-dark'>
            <Row className="text-white">
                <Col sm style={{ marginBottom: 30}}>
                    <p><strong>บริการลูกค้า</strong></p>
                    
                    <NavLink to="/AboutUs/HowPurchase" style={{ color: "gray", textDecoration: 'none' }}>ขั้นตอนการซื้อ</NavLink> 
                    <br/>
                    <NavLink to="/AboutUs/Tracking" style={{ color: "gray", textDecoration: 'none' }}>ติดตามสถานะสินค้า</NavLink>
                    <br/>
                    <NavLink to="/AboutUs/QPoint" style={{ color: "gray", textDecoration: 'none' }}>คะแนนสะสม</NavLink>
                </Col>

                <Col sm style={{ marginBottom: 30 }}>
                    <p><strong>เกี่ยวกับเรา</strong></p>
                    
                    <NavLink to="/AboutUs/Contact" style={{ color: "gray", textDecoration: 'none' }}>ติดต่อเรา</NavLink>
                    <br/>
                    <NavLink to="/AboutUs/TermsOfService" style={{ color: "gray", textDecoration: 'none' }}>ข้อกำหนดการให้บริการ</NavLink> 
                    <br/>
                    <NavLink to="/AboutUs/AboutEnqueX" style={{ color: "gray", textDecoration: 'none' }}>เกียวกับ EnqueX</NavLink> 
      
                    
                    {/* <br/>
                    <NavLink to="/" style={{ color: "gray", textDecoration: 'none' }}>???ขายของกับเรา</NavLink> */}
                </Col>

                <Col sm style={{ marginBottom: 30 }}>
                    
                </Col>

                <Col sm style={{marginTop: 8, display:'flex', justifyContent:'right'}}>
                  {/* <FaFacebookF/>&nbsp;&nbsp;&nbsp;
                  <FaInstagram/>&nbsp;&nbsp;&nbsp;
                  <FaPinterest/>&nbsp;&nbsp;&nbsp;
                  <FaYoutube/>&nbsp;&nbsp;&nbsp;
                  <FaTwitter/> */}
              </Col>
            </Row>
        </Container>

        <Container className='bg-dark' fluid>

        <Container>
            
        <br/>
        <Row className="text-white">
          <Col sm>
            <a href="/">
            <img
                src={enquex_logo_dark_01}
                width="160"
                height="40"
                className="d-inline-block"
                alt=""           
            />
            </a>
          </Col>
          <Col sm style={{marginTop: 8}}>
            <p>© 2023 EnqueX. All Rights Reserved.</p>
          </Col>
          <Col>
          </Col>
          <Col sm className="justify-content-end" style={{marginTop: 8, display:'flex', justifyContent:'right'}}>
            <a href="https://www.facebook.com/profile.php?id=61552083667050" target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: "white"}}><FaFacebookF/></a>&nbsp;&nbsp;&nbsp;
            <a href="https://www.instagram.com/enquex.official/" target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: "white"}}><FaInstagram/></a>&nbsp;&nbsp;&nbsp;
            <a href="https://www.pinterest.com/enquexofficial/" target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: "white"}}><FaPinterest/></a>&nbsp;&nbsp;&nbsp;
            <a href="https://www.youtube.com/channel/UC0WH6pCsd6t4puoKOxY1zHg" target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: "white"}}><FaYoutube/></a>&nbsp;&nbsp;&nbsp;
            <a href="https://twitter.com/enquex_official" target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: "white"}}><FaTwitter/></a>
          
          </Col>
        </Row>
            <br/>

        </Container>
            
      </Container>

      </Container>

    );

}

export default Footer;