import React, {useContext, useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { Row, Col, Card, Tabs, Tab, Spinner, Badge, Modal } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel';

// import ads_sample01 from '../../images_s/ads_sample01.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

import product_sample01 from '../../../component_s/images_s/product_sample01.jpeg';
import product_sample02 from '../../../component_s/images_s/product_sample02.jpg';

/* import ads_testflight02 from '../../images_s/ads_testflight02.jpg';
import ads_sample03 from '../../images_s/ads_sample03.jpg'; */

import { firestore } from '../../../database/firebase';
import { auth } from '../../../database/firebase';
// import { AuthContext } from '../../Old_Test/Auth';

import { Link, Navigate, useNavigate } from "react-router-dom";

import { FaHeart, FaTruck, FaFacebookMessenger, FaSketch, FaRegHeart, FaRegCommentAlt, FaRegEye, FaRegEyeSlash, FaMicrochip } from "react-icons/fa";
import { BsStarFill, BsStarHalf, BsStar, BsEye, BsBookmark, BsTerminal, BsCpu } from "react-icons/bs";
import { text } from '@fortawesome/fontawesome-svg-core';
import { TbClover } from "react-icons/tb";

import "./ArticleForm.css";

// import MessengerCustomerChat from 'react-messenger-customer-chat';
// import ChatBot from '../Chat/FacebookChat';

function PlantArticleCategory() {

    /* const {currentUser} = useContext(AuthContext);

    if (!currentUser) {
        return <Navigate to="/LogIn" replace />
    } */

    //Test useParams
    // let productId = "MIGhY5tyTiuRPeSWxDXC";

    
    // const [productPriceCart, setProductPriceCart] = useState("");

    var user = auth.currentUser;
    // var uid = "";
    var uid = "";

    if (user != null) {
       
        uid = user.uid;  
                     
        console.log(uid);

    } else {
        // navigate("/");
    }


//   <Image src={[product_sample01, product_sample02]} fluid />

    ////////////////// Create Article //////////////////////////////

    const categoryOption = ["ต้นไม้"];
    const [categoryString, setCategoryString] = useState(categoryOption[0]);
    const [categoryType, setCategoryType] = useState("");

    const [subCategoryOption, setSubCategoryOption] = useState(["ไม้ประดับ", "พืชเศรษฐกิจ", "ต้นไม้อื่น ๆ"]);
    const [subCategoryString, setSubCategoryString] = useState(subCategoryOption[0]);
    const [subCategoryType, setSubCategoryType] = useState("");

    const [typeCategoryOption, setTypeCategoryOption] = useState(["ไม้ประดับภายในอาคาร", "ไม้ประดับร่มรำไร", "ไม้ประดับกลางแจ้ง", "การจัดสวนขวดแก้ว", "การจัดตู้ไม้น้ำ", "ไม้ยืนต้น", "ไม้พุ่ม", "ไม้ล้มลุก", "ไม้เลื้อย", "ไม้ประดับอื่น ๆ"]);
    const [typeCategoryString, setTypeCategoryString] = useState(typeCategoryOption[0]);
    const [typeCategoryType, setTypeCategoryType] = useState("");

    const [articleType, setArticleType] = useState(["ทั่วไป", "รีวิว", "คำถาม"]);
    const [articleTypeString, setArticleTypeString] = useState(articleType[0]);


    ///////////////// Create Article ///////////////////////////////


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);

    const [writerID, setWriterID] = useState("");

    const [articleLists, setArticleLists] = useState([]);
    const [currentCursor, setCurrentCursor] = useState("");

    useEffect(() => {
        const firsPageRef = firestore
            .collection("article")
            .orderBy("articleDate", "desc")
            .where("articleCategory", "==", "ต้นไม้")
            .where("publicStatus", "==", "เผยแพร่")
            .where("status", "==", "public")
            // .orderBy("productSortNumber", "asc")
            .limit(8);
        firsPageRef.get().then((querySnapshot) => {
            let tempLists = [];
            querySnapshot.docs.forEach((doc) => {
                if (doc.exists) {

                    const currentArticle = {

                        articleID: doc.id,
                        articleID: doc.data().articleID,
                       
                        writerID: doc.data().writerID,
                        writerName: doc.data().writerName,
                        articletName: doc.data().articleName,
                        articleDate: doc.data().articleDate,

                        articleHeader: doc.data().articleHeader,
                        articleContent: doc.data().articleContent,
                        articleContentType: doc.data().articleContentType,
                        articleSection: doc.data().articleSection,

                        articleBadge: doc.data().articleBadge,

                        updateCount: doc.data().updateCount,
                        viewCount: doc.data().viewCount,

                        likeCount: doc.data().likeCount,
                        bookmarkCount: doc.data().bookmarkCount,


                        //RATING
                        /* ratingSum: doc.data().ratingSum,
                        ratingCount: doc.data().ratingCount, */

                    };
                    tempLists = [...tempLists, currentArticle];
                    setWriterID(doc.data().writerID);
                }
            });


            setArticleLists((prv) => tempLists);
            const currentLenght = querySnapshot.docs.length;
            const currentCursorFromFirstPage = querySnapshot.docs[currentLenght - 1];
            setCurrentCursor(currentCursorFromFirstPage);
        })
    }, []);

    const onMoreArticles = () => {
        const nextPageRef = firestore
            .collection("article")
            .orderBy("articleDate", "desc")
            .where("articleCategory", "==", "ต้นไม้")
            .where("publicStatus", "==", "เผยแพร่")
            .where("status", "==", "public")
            // .orderBy("productSortNumber", "asc")
            .limit(8);
        nextPageRef.get().then((querySnapshot) => {
            const currentLenght = querySnapshot.docs.length;
            if (!currentCursor) {
                return;
            }
            const query = nextPageRef.startAfter(currentCursor);
            query.get().then((querySnapshot) => {
                let tempNewArray = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempNewArray = [
                            ...tempNewArray,
                            {
                                articleID: doc.id,
                                articleID: doc.data().articleID,

                                writerID: doc.data().writerID,
                                writerName: doc.data().writerName,
                                articletName: doc.data().articleName,
                                articleDate: doc.data().articleDate,

                                articleHeader: doc.data().articleHeader,
                                articleContent: doc.data().articleContent,
                                articleContentType: doc.data().articleContentType,
                                articleSection: doc.data().articleSection,

                                articleBadge: doc.data().articleBadge,

                                updateCount: doc.data().updateCount,
                                viewCount: doc.data().viewCount,

                                likeCount: doc.data().likeCount,
                                bookmarkCount: doc.data().bookmarkCount,

                                //RATING
                                /* ratingSum: doc.data().ratingSum,
                                ratingCount: doc.data().ratingCount, */
                            },
                        ];

                        setWriterID(doc.data().writerID);
                    }
                });


                setArticleLists([...articleLists, ...tempNewArray]);
                const currentCursorForNextPage = querySnapshot.docs[currentLenght -1];
                setCurrentCursor(currentCursorForNextPage);
            });
        });
    };


    ///////////////////////////////////////// Create Article //////////////////////////////////////////
    const articleID = "arid" + Math.random(999).toString();

    // const [merchantID, setMerchantID] = useState("");
    const [storeName, setStoreName] = useState("");
    //const [writerName, setWriterName] = useState("");

    const [articleName, setArticleName] = useState("");
    const [articleDate, setArticleDate] = useState("");

    const [articleCategory, setArticleCategory] = useState("");
    const [articleSubCategory, setArticleSubCategory] = useState("");
    const [articleTypeCategory, setArticleTypeCategory] = useState("");

    const [articleHeader, setArticleHeader] = useState([""]);
    const [articleContent, setArticleContent] = useState([""]);
    const [articleContentType, setArticleContentType] = useState([""]);
    const [articleSection, setArticleSection] = useState([""]);

    //RATING
    const [ratingSum, setRatingSum] = useState(0);
    const [ratingCount, setRatingCount] = useState(0);

   


    const navigate = useNavigate();

    /////////////////////////////////////// WRITER DATA
    const [writerName, setWriterName] = useState("");
    const [writerDescription, setWriterDescription] = useState("");
    const [writerImg, setWriterImg] = useState("");

    

    const [dataWriter, setDataWriter] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
    
        .where("uid", "==", uid);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            uid: doc.id,

                            displayName: doc.data().displayName,
                            description: doc.data().description,
                            profileImg: doc.data().profileImg,
                            
                        },
                        
                    ];


                    setWriterName(doc.data().displayName);
                    setWriterDescription(doc.data().description);
                    setWriterImg(doc.data().profileImg);

                    

                    };

                    

                });


            setDataWriter((oldDataArray) => tempDataArray);

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [uid]);











     ////////////////ADD ARTICLE NOT LOG IN
    const [showAddArticleNotlogin, setShowAddArticleNotlogin] = useState(false);
    const handleCloseAddArticleNotlogin = () => setShowAddArticleNotlogin(false);

    const [showMyArticleNotlogin, setShowMyArticleNotlogin] = useState(false);
    const handleCloseMyArticleNotlogin = () => setShowMyArticleNotlogin(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        // alert(JSON.stringify(formValues));

        if (user == null) {
           
            setShowAddArticleNotlogin(true);
            setShow(false);

        } else {
            const obj = {

                writerID: uid,
                storeName: storeName,

                writerName: writerName,
                writerImg: writerImg,
                writerDescription: writerDescription,
    
                articleID: articleID,
                articleDate: new Date(),
                articleName: articleName,
    
                articleCategory: categoryString,
                articleSubCategory: subCategoryString,
                articleTypeCategory: typeCategoryString,
    
                articleHeader: [""],
                articleContent: [""],
                articleContentType: [""],
                articleSection: [""],

                articleRefText: [""],
                articleLinkRefText: [""],
    
                updateCount: 0,
                viewCount: 1,
    
                likeCount: 1,
                userIDLike: [""],
                profileUserImgLike: [""],
                descriptionUserLike: [""],
                displayNameUserLike: [""],
    
                bookmarkCount: 1,
                userIDBookmark: [""],
                profileUserImgBookmark: [""],
                descriptionUserBookmark: [""],
                displayNameUserBookmark: [""],
                
                articleType: articleTypeString,
    
                status: "inprogress",
                publicStatus: "กำลังดำเนินการ",
    
            };
    
            createArticleHandler(obj);

        }
        

 
        
    }


    const createArticleHandler = (obj) => {
           
        const ref = firestore.collection("article");
        ref
            .doc(articleID)
            .set(obj)
            .then(() => {
                console.log("เพิ่มข้อมูลสำเร็จ");
                navigate("/CreateArticle/" + articleID)
            })
            .catch((err) => console.log(err));
    };
    ///////////////////////////////////////// Dynamic TypeCategory ////////////////////////////////////

    useEffect(() => {

        if (subCategoryString == "ไม้ประดับ") {

            setTypeCategoryOption(["ไม้ประดับภายในอาคาร", "ไม้ประดับร่มรำไร", "ไม้ประดับกลางแจ้ง", "การจัดสวนขวดแก้ว", "การจัดตู้ไม้น้ำ", "ไม้ยืนต้น", "ไม้พุ่ม", "ไม้ล้มลุก", "ไม้เลื้อย", "ไม้ประดับอื่น ๆ"]);
            setTypeCategoryString(typeCategoryOption[0]);
            
        } else if (subCategoryString == "พืชเศรษฐกิจ") {

            setTypeCategoryOption(["ธัญพืช", "พืชน้ำมัน", "พืชน้ำตาล", "พืชเส้นใย", "พืชใช้ใบ", "พืชหัว", "พืชให้ผล", "พืชอาหารสัตว์", "พืชอื่นเศรษฐกิจ ๆ"]);
            setTypeCategoryString(typeCategoryOption[0]);
            
        }

    }, [subCategoryString]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////

    

    return (
  
    <div>


    <Container>

        <br/>


        <h3><strong><TbClover/> ต้นไม้และพืชเศรษฐกิจ</strong></h3>
        <p>บทความต้นไม้ประดับ พืชเศรษฐกิจ และอื่น ๆ</p>

        <Link to={'/ArticleSubCategory'} state={"ไม้ประดับ"}><Button className="rounded-pill border-1" variant="outline-dark" style={{margin: 5, marginLeft: 0, marginRight: 5}}>ไม้ประดับ</Button></Link>{' '}
        <Link to={'/ArticleSubCategory'} state={"พืชเศรษฐกิจ"}><Button className="rounded-pill border-1" variant="outline-dark" style={{margin: 5, marginLeft: 0, marginRight: 5}}>พืชเศรษฐกิจ</Button></Link>{' '}
        <Link to={'/ArticleSubCategory'} state={"ต้นไม้อื่น ๆ"}><Button className="rounded-pill border-1" variant="outline-dark" style={{margin: 5, marginLeft: 0, marginRight: 5}}>อื่น ๆ</Button></Link>{' '}
        {/* <Link to={'/ArticleSubCategory'} state={"Graphics"}><Button className="rounded-pill border-1" variant="outline-dark" style={{margin: 5, marginLeft: 0, marginRight: 5}}>Graphics</Button></Link>{' '} */}
        
        <br/>
        <br/>

        {(writerID == "") ? (
                <>
                    <br/>
                    <h4>ยังไม่มีบทความส่วนนี้</h4>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </>
                ) : (
                <></>
            )}


            <Row xs={2} md={4} className="g-4">

                
            

            {articleLists.map((item) => {
                            
                return (
                               
                    <div key={item.id}>



                                <div className='card'>
                                    <Link to={'/ReadArticle/'+ item.articleID} style={{textDecoration: 'none', color: "black"}}>
                                        <Card className="cardarticle">

                                            {!!item.articleContent[0] ? (
                                                <Card.Img variant="top" class='cardarticledetailimg' src={item.articleContent[0]} />
                                            ) : (
                                                <Col style={{backgroundColor: '#FFFAFA'}}><div class='cardarticledetailnoimg'><strong>{(item.writerName)}</strong></div></Col>
                                            )}
                                            
                                            
                                            <Card.Body class='cardarticledetail'>
                                                <Card.Title>
                                                </Card.Title>

                                                <p class='articlename' style={{fontWeight: 500}}>{item.articletName}</p>
                                           
                                                <p class='cardarticledetailnamedatetime'>{item.writerName} <br/> {(item.articleDate).toDate().toLocaleDateString('th-TH', {year: 'numeric', month: 'short', day: 'numeric', weekday: 'short',})} | {(item.articleDate).toDate().toLocaleTimeString()} 
                                                <br/>
                                             
                                                <span class='cardarticledetaillikebookmark'><strong><FaRegHeart/> {item.likeCount}</strong> &nbsp;&nbsp;&nbsp;<strong><BsBookmark/> {item.bookmarkCount} &nbsp;&nbsp;&nbsp;<BsEye/> {item.viewCount + 1}</strong></span></p>

   
                                                    

                                            </Card.Body>
                                        </Card>
                                    </Link> 
                                </div>

                                    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}



                        {/* <Col>
                            <Link to={'/ReadArticle/'+ item.articleID} style={{textDecoration: 'none', color: "black"}}>
                            <Card className="cardarticle">
                                <Card.Body> 
                                    <Row xs='auto'>
                                    <Col xs={4} md={2}>
                                        <img src={item.articleContent[0]} style={{width: 90, height: 90}} />
                                    </Col>
                                    <Col  xs={8} md={10}>
                                        <p>{item.articletName} ประโยชน์ของ JavaScript มีอะไรบ้าง ประโยชน์ของ JavaScript มีอะไรบ้าง
                                        <br/>
                                        </p>
                     
                                       
                                     
                                        <p style={{fontSize: 12}}>{item.writerName} | {(item.articleDate).toDate().toLocaleDateString('th-TH', {year: 'numeric', month: 'long', day: 'numeric', weekday: 'long',})} | {(item.articleDate).toDate().toLocaleTimeString()} <span className="float-end" style={{fontSize: 16}}><strong><FaRegHeart/> 125</strong> &nbsp;&nbsp;&nbsp;<strong><BsBookmark/> 63 &nbsp;&nbsp;&nbsp;<BsEye/> {item.viewCount + 1}</strong></span></p>
         
                                    </Col>
                               </Row>
                                </Card.Body>
                            </Card>
                            </Link>
                        </Col> */}
                        

                    </div>

                )
            })}

            </Row>

          
            <Modal
                show={show} 
                onHide={handleClose} 
                animation={false} 
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Form onSubmit={handleSubmit}>

                <Modal.Header closeButton>
                <Modal.Title>ต้นไม้และพืชเศรษฐกิจ</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                
                    <Form.Group className="mb-3">
                    <Form.Label>ชื่อบทความ</Form.Label>

                    <Form.Control 
                        type="text"
                        className="rounded-0" 
                        style={{ paddingLeft: 10 }}
                        value={articleName}
                        onChange={(e) => {
                        setArticleName(e.target.value);
                        }}
                        placeholder={"*กรุณากรอกชื่อบทความ"}
                        required
                                            
                        />
                        <Form.Control.Feedback type="invalid">
                            ชื่อบทความ
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>หมวดหมู่</Form.Label>
                        {/* <Form.Select className="rounded-0" aria-label="Default select example">
                            <option value="video">วิดีโอ</option>
                            <option value="pdf">ไฟล์เอกสาร pdf</option>
                        </Form.Select> */}

                        <Form.Control
                            className="rounded-0"
                            type="text"
                            placeholder="ต้นไม้"
                            aria-label="Disabled input example"
                            readOnly
                        />

                        {/* <Form.Select className="rounded-0" aria-label="Default select example"
                            onChange={(e) => setCategoryString(e.target.value)}
                            defaultValue={categoryString}
                        >
                            {categoryOption.map((option, index) => (
                            <option key={index}>{option}</option>
                            ))}
                        </Form.Select> */}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>หมวดหมู่ย่อย</Form.Label>
                        {/* <Form.Select className="rounded-0" aria-label="Default select example">
                            <option value="video">วิดีโอ</option>
                            <option value="pdf">ไฟล์เอกสาร pdf</option>
                        </Form.Select> */}

                        <Form.Select className="rounded-0" aria-label="Default select example"
                            onChange={(e) => setSubCategoryString(e.target.value)}
                            defaultValue={subCategoryString}
                        >
                            {subCategoryOption.map((option, index) => (
                            <option key={index}>{option}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>ประเภทหมวดหมู่</Form.Label>
                        {/* <Form.Select className="rounded-0" aria-label="Default select example">
                            <option value="video">วิดีโอ</option>
                            <option value="pdf">ไฟล์เอกสาร pdf</option>
                        </Form.Select> */}

                        <Form.Select className="rounded-0" aria-label="Default select example"
                            onChange={(e) => setTypeCategoryString(e.target.value)}
                            defaultValue={typeCategoryString}
                        >
                            {typeCategoryOption.map((option, index) => (
                            <option key={index}>{option}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>ประเภทบทความ</Form.Label>
                                           
                            <Form.Select className="rounded-0" aria-label="Default select example"
                                onChange={(e) => setArticleTypeString(e.target.value)}
                                defaultValue={articleTypeString}
                            >
                            {articleType.map((option, index) => (
                                <option key={index}>{option}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    {/* <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                    >
                    <Form.Label>Example textarea</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                    </Form.Group> */}
                
                </Modal.Body>

                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleClose}>
                        ปิด
                    </Button> */}
                    {user ? (

                        <Link to={'/MyArticle/'+ uid} style={{textDecoration: 'none', color: "black"}}>
                            <Button variant="secondary">
                                บทความของฉัน
                            </Button>
                        </Link>

                        ) : (

                        <Button variant="secondary" onClick={() => setShowMyArticleNotlogin(true)}>
                                บทความของฉัน
                        </Button>

                    )}

                    <Button variant="success" type="submit">
                        เขียนบทความใหม่
                    </Button>
                </Modal.Footer>

                </Form>

            </Modal>
        

            <Modal 
                show={showAddArticleNotlogin} 
                onHide={handleCloseAddArticleNotlogin} 
                animation={false} 
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header>
                <Modal.Title>เข้าสู่ระบบ/สมัครสมาชิก</Modal.Title>
                </Modal.Header>
                <Modal.Body><Link to={"/LogIn"} style={{textDecoration: 'none'}}>เข้าสู่ระบบ</Link> หรือ <Link to={"/SignUp"} style={{textDecoration: 'none'}}>สมัครสมาชิก</Link> เพื่อเขียนบทความ</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddArticleNotlogin}>
                        ปิด
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal 
                show={showMyArticleNotlogin} 
                onHide={handleCloseMyArticleNotlogin} 
                animation={false} 
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header>
                <Modal.Title>เข้าสู่ระบบ/สมัครสมาชิก</Modal.Title>
                </Modal.Header>
                <Modal.Body><Link to={"/LogIn"} style={{textDecoration: 'none'}}>เข้าสู่ระบบ</Link> หรือ <Link to={"/SignUp"} style={{textDecoration: 'none'}}>สมัครสมาชิก</Link> เพื่อใช้งานบทความของฉัน</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseMyArticleNotlogin}>
                        ปิด
                    </Button>
                </Modal.Footer>
            </Modal>

            <br/>
                     

            {currentCursor ? (
                <Col className='float-end'>

                    <Button onClick={() => setShow(true)} className="rounded-0" variant="outline-primary">+ เขียน</Button>{' '} <Button variant="primary" className="rounded-0" onClick={onMoreArticles}>แสดงเพิ่มเติม</Button>
        
                </Col>
            ) : (
                <Col className='float-end'>

                    <Button onClick={() => setShow(true)} className="rounded-0" variant="outline-primary">+ เขียน</Button>{' '} <Button variant="outline-secondary" className="rounded-0" disabled>ไม่มีบทความเพิ่มเติม</Button>
        
                </Col>
            )}

        <br/>
        <br/>
        <br/>

        

       
        

    </Container>

  
    

    </div>
    

  );
}

export default PlantArticleCategory;