import React, {useContext, useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { Row, Col, Card, Tabs, Tab, Spinner, Badge, Modal } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel';

// import ads_sample01 from '../../images_s/ads_sample01.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

/* import ads_testflight02 from '../../images_s/ads_testflight02.jpg';
import ads_sample03 from '../../images_s/ads_sample03.jpg'; */

import { auth, firestore } from "../../database/firebase";

// import { AuthContext } from '../../Old_Test/Auth';

import { Link, Navigate, useNavigate, useLocation, useParams } from "react-router-dom";

import { FaHeart, FaTruck, FaFacebookMessenger, FaSketch, FaRegHeart, FaRegCommentAlt, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { BsStarFill, BsStarHalf, BsStar, BsEye, BsBookmark, BsChatRightQuote, BsFacebook, BsInstagram, BsYoutube, BsTiktok } from "react-icons/bs";
import { text } from '@fortawesome/fontawesome-svg-core';

import "./ArticleCategory/ArticleForm.css";

// import MessengerCustomerChat from 'react-messenger-customer-chat';
// import ChatBot from '../Chat/FacebookChat';

function WriterPage() {

  

    //let location = useLocation();

    let {writerId} = useParams();



    var user = auth.currentUser;
    
    var uid = "";

    if (user != null) {
       
        uid = user.uid;  
                     
        console.log(uid);

    } else {
        
    }



    ////////////////// Create Article //////////////////////////////

    const [articleCategory, setArticleCategory] = useState("");
    const [articleSubCategory, setArticleSubCategory] = useState("");
    const [articleTypeCategory, setArticleTypeCategory] = useState("");


    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);

    const [writerID, setWriterID] = useState("");

    const [articleLists, setArticleLists] = useState([]);
    const [currentCursor, setCurrentCursor] = useState("");

    useEffect(() => {
        const firsPageRef = firestore
            .collection("article")
            .orderBy("articleDate", "desc")
            .where("writerID", "==", writerId)
            .where("publicStatus", "==", "เผยแพร่")
            .where("status", "==", "public")
            // .orderBy("productSortNumber", "asc")
            .limit(4);
        firsPageRef.get().then((querySnapshot) => {
            let tempLists = [];
            querySnapshot.docs.forEach((doc) => {
                if (doc.exists) {

                    const currentArticle = {

                        articleID: doc.id,
                        articleID: doc.data().articleID,

                        articleCategory: doc.data().articleCategory,
                        articleSubCategory: doc.data().articleSubCategory,
                       
                        writerID: doc.data().writerID,
                        writerName: doc.data().writerName,
                        articletName: doc.data().articleName,
                        articleDate: doc.data().articleDate,

                        articleHeader: doc.data().articleHeader,
                        articleContent: doc.data().articleContent,
                        articleContentType: doc.data().articleContentType,
                        articleSection: doc.data().articleSection,

                        articleBadge: doc.data().articleBadge,

                        updateCount: doc.data().updateCount,
                        viewCount: doc.data().viewCount,

                        likeCount: doc.data().likeCount,
                        bookmarkCount: doc.data().bookmarkCount,


                    };
                    tempLists = [...tempLists, currentArticle];
                    setWriterID(doc.data().writerID);

                    setArticleCategory(doc.data().articleCategory);
                    setArticleSubCategory(doc.data().articleSubCategory);
                }
            });


            setArticleLists((prv) => tempLists);
            const currentLenght = querySnapshot.docs.length;
            const currentCursorFromFirstPage = querySnapshot.docs[currentLenght - 1];
            setCurrentCursor(currentCursorFromFirstPage);
        })
    }, []);

    const onMoreArticles = () => {
        const nextPageRef = firestore
            .collection("article")
            .orderBy("articleDate", "desc")
            .where("writerID", "==", writerId)
            .where("publicStatus", "==", "เผยแพร่")
            .where("status", "==", "public")
            // .orderBy("productSortNumber", "asc")
            .limit(4);
        nextPageRef.get().then((querySnapshot) => {
            const currentLenght = querySnapshot.docs.length;
            if (!currentCursor) {
                return;
            }
            const query = nextPageRef.startAfter(currentCursor);
            query.get().then((querySnapshot) => {
                let tempNewArray = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempNewArray = [
                            ...tempNewArray,
                            {
                                articleID: doc.id,
                                articleID: doc.data().articleID,

                                articleCategory: doc.data().articleCategory,
                                articleSubCategory: doc.data().articleSubCategory,

                                writerID: doc.data().writerID,
                                writerName: doc.data().writerName,
                                articletName: doc.data().articleName,
                                articleDate: doc.data().articleDate,

                                articleHeader: doc.data().articleHeader,
                                articleContent: doc.data().articleContent,
                                articleContentType: doc.data().articleContentType,
                                articleSection: doc.data().articleSection,

                                articleBadge: doc.data().articleBadge,

                                updateCount: doc.data().updateCount,
                                viewCount: doc.data().viewCount,

                                likeCount: doc.data().likeCount,
                                bookmarkCount: doc.data().bookmarkCount,

                            },
                        ];

                        setWriterID(doc.data().writerID);

                        setArticleCategory(doc.data().articleCategory);
                        setArticleSubCategory(doc.data().articleSubCategory);
                    }
                });


                setArticleLists([...articleLists, ...tempNewArray]);
                const currentCursorForNextPage = querySnapshot.docs[currentLenght -1];
                setCurrentCursor(currentCursorForNextPage);
            });
        });
    };


    ///////////////////////////////////////// Create Article //////////////////////////////////////////


   


    const navigate = useNavigate();

    /////////////////////////////////////// WRITER DATA
    const [writerName, setWriterName] = useState("");
    const [writerDescription, setWriterDescription] = useState("");
    const [writerImg, setWriterImg] = useState("");

    const [socialPublic, setSocialPublic] = useState("");
    const [facebook, setFacebook] = useState("");
    const [instagram, setInstagram] = useState("");
    const [youtube, setYoutube] = useState("");
    const [tiktok, setTiktok] = useState("");
    

    const [dataWriter, setDataWriter] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
        
        .where("uid", "==", writerId);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            uid: doc.id,

                            displayName: doc.data().displayName,
                            description: doc.data().description,
                            profileImg: doc.data().profileImg,

                            socialPublic: doc.data().socialPublic,
                            facebook: doc.data().facebook,
                            instagram: doc.data().instagram,
                            youtube: doc.data().youtube,
                            tiktok: doc.data().tiktok,
                            
                        },
                        
                    ];


                    setWriterName(doc.data().displayName);
                    setWriterDescription(doc.data().description);
                    setWriterImg(doc.data().profileImg);

                    setSocialPublic(doc.data().socialPublic);
                    setFacebook(doc.data().facebook);
                    setInstagram(doc.data().instagram);
                    setYoutube(doc.data().youtube);
                    setTiktok(doc.data().tiktok);

                    

                    };

                    

                });


            setDataWriter((oldDataArray) => tempDataArray);


        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [writerID]);


     


   

    ///////////////////////////////////////////////////////////////////////////////////////////////////

    

    return (
    <div>

    {/* <div id="grad1" class="line"></div> */}

   

    <Container>

       

        {/* <p><strong><Link to={"/articleCategory"} state={articleCategory} style={{textDecoration: 'none', color: "darkblue"}}>{articleCategory}</Link> {">"} <Link to={"/articleSubCategory"} state={articleSubCategory} style={{textDecoration: 'none', color: "darkblue"}}>{articleSubCategory}</Link></strong></p> */}

        {/* <h3><strong>{writerName}</strong></h3> */}
        {/* <p>บทความการเขียนโปรแกรม การใช้งาน Windows and Office และฮาร์ดแวร์ รวมทั้งการทำกราฟฟิก</p> */}

        <br/>
        <h3>บทความของ {writerName}</h3><br/>
     


          

                        
                        <Row className="borderwriter">

                            <Col md={8}>

                            <Row xs='auto'>
                                       
                                        {!!writerImg ? (
                                            <Col md="auto"><img src={writerImg} alt="profile Image" className="mr-3 mt-3 rounded-circle" style={{ borderRadius: '50%', objectFit: "cover", objectPosition: 'center', width: 60, height: 60, marginTop: 7, marginLeft: -20}} /> </Col>
                                        ) : (
                                            <Col><Button variant="dark" style={{width: 50, height: 50, marginTop: 20, marginLeft: -20, fontSize: 20}} className="rounded-circle"><strong>{(writerName).slice("0", "1")}</strong></Button></Col>
                                        )}
                                      
                                        <Col xs={9} md={7} style={{marginTop: 18}}>
                                            <strong>{writerName}</strong>
                                            <p style={{lineHeight: 1.5}}>{writerDescription}</p>
                               
      
                                       </Col>
                            </Row>


                            </Col>
                            <Col md={4}>

                                { socialPublic == "สาธารณะ" ? (
                                     <div className="float-end" style={{marginTop: 20}}>

                                        { facebook != "" ? (
                                            <>
                                                <a href={facebook} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'gray', fontSize: 25}}><BsFacebook/></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                            </>
                                        ) : (
                                            <></>
                                        ) }

                                        { instagram != "" ? (
                                            <>
                                                <a href={instagram} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'gray', fontSize: 25}}><BsInstagram/></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                            </>
                                        ) : (
                                            <></>
                                        ) }

                                        { youtube != "" ? (
                                            <>
                                                <a href={youtube} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'gray', fontSize: 25}}><BsYoutube/></a>
                                            </>
                                        ) : (
                                            <></>
                                        ) }

                                        { tiktok != "" ? (
                                            <>
                                                <a href={tiktok} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'gray', fontSize: 25, marginLeft: 12}}><BsTiktok/></a>
                                            </>
                                        ) :  (
                                            <></>
                                        ) }
                                        

                                        

                                        
                                        {/* <Link to={facebook}><strong><BsFacebook/>&nbsp;&nbsp;&nbsp;{writerName}</strong><br/></Link> */}
                                    </div>
                                ) : (
                                <>
                                
                                </>
                                ) }



                                {/*  <div className="float-end" style={{marginTop: 10}}>
                                    <strong><BsChatRightQuote/>&nbsp;&nbsp;&nbsp;15 บทความ</strong><br/>
                                </div>  */}
                                
                            </Col>
                        </Row>
      
            
                        <br/>

            

                        <Row xs={2} md={4} className="g-4">

                            {articleLists.map((item) => {
                                            
                                return (
                                            
                                    <div key={item.id}>

                                    <div className='card'>
                                        <Link to={'/ReadArticle/'+ item.articleID} style={{textDecoration: 'none', color: "black"}}>
                                            <Card className="cardarticle">

                                                {!!item.articleContent[0] ? (
                                                    <Card.Img variant="top" class='cardarticledetailimg' src={item.articleContent[0]} />
                                                ) : (
                                                    <Col style={{backgroundColor: '#FFFAFA'}}><div class='cardarticledetailnoimg'><strong>{(item.writerName)}</strong></div></Col>
                                                )}
                                                
                                                
                                                <Card.Body class='cardarticledetail'>
                                                    <Card.Title>
                                                    </Card.Title>

                                                    <p class='articlename' style={{fontWeight: 500}}>{item.articletName}</p>
                                            
                                                    <p class='cardarticledetailnamedatetime'>{item.writerName} <br/> {(item.articleDate).toDate().toLocaleDateString('th-TH', {year: 'numeric', month: 'short', day: 'numeric', weekday: 'short',})} | {(item.articleDate).toDate().toLocaleTimeString()} 
                                                    <br/>
                                                
                                                    <span class='cardarticledetaillikebookmark'><strong><FaRegHeart/> {item.likeCount}</strong> &nbsp;&nbsp;&nbsp;<strong><BsBookmark/> {item.bookmarkCount} &nbsp;&nbsp;&nbsp;<BsEye/> {item.viewCount + 1}</strong></span></p>


                                                        

                                                </Card.Body>
                                            </Card>
                                        </Link> 
                                    </div>

                            {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}



        
                                            <br/>

                                        </div>

                                    )
                            })}

                        </Row>

            

            

        <br/>
        <br/>

        {currentCursor ? (
                <Col className='float-end'>

                    <Button variant="primary" className="rounded-0" onClick={onMoreArticles}>แสดงเพิ่มเติม</Button>
        
                </Col>
            ) : (
                <Col className='float-end'>

                    <Button variant="outline-secondary" className="rounded-0" disabled>ไม่มีบทความเพิ่มเติม</Button>
        
                </Col>
        )}

        <br/>
        <br/>
        <br/>
        <br/>
     
        

    </Container>

    
    </div>

  );
}

export default WriterPage;