import React, { useState, useEffect, useContext } from "react";
import { Container, Tabs, Tab, Form, Col, Row, Button, Card, InputGroup, Accordion, Modal, ProgressBar } from "react-bootstrap";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";

import { FaSketch } from "react-icons/fa";
import { AiOutlineSketch } from "react-icons/ai";

import { auth, firestore } from "../../database/firebase";
import { storage } from "../../database/firebase";


import { AuthContext } from "../../component_s/Authentication/AuthProvider";

import { BsStarFill, BsStarHalf, BsStar, BsPatchCheck, BsPlayBtn, BsPhone, BsFolder2Open, BsInfinity, BsCheckLg, BsPeopleFill, BsFillPlayBtnFill, BsChatLeftDotsFill, BsPersonCircle, BsCameraReels, BsRecordFill } from "react-icons/bs";
import UploadVideo from "./UploadVideo";
import InstructorView from "./InstructorView";
import CreateCourse from "./CreateCourse";

// import "./UploadVideo.css";


function UploadExampleVideo() {


    let { courseId } = useParams();

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    /* TEST QUERY DATA WITH productID */

    // const [uid, setUid] = useState("");

    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

   //const [uid, setUid] = useState("");

    var user = auth.currentUser;
    // var uid = "";
    var uid = "";

    if (user != null) {
        //email = user.email;
        uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                         // this value to authenticate with your backend server, if
                         // you have one. Use User.getToken() instead.
        console.log(uid);
        
        //TEST AUTH
        // refreshPage();
    } else {
        navigate("/");
    }
    



    

    // const [emailDefault, setEmailDefault] = useState("");

    // const [loading, setLoading] = useState(false);
    const [section, setSection] = useState([""]);
    const [about, setAbout] = useState([""]);


    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [address, setAddress] = useState("");

    const [role, setRole] = useState("");
    const [sPoint, setSPoint] = useState("");

    const [displayName, setDisplayName] = useState("");

    const [data, setData] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
    
        .where("uid", "==", uid);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            uid: doc.id,
                            name: doc.data().name,
                            email: doc.data().email,
                            phonenumber: doc.data().phonenumber,
                            address: doc.data().address,
                            sPoint: doc.data().sPoint,   
                            role: doc.data().role,

                            displayName: doc.data().displayName,
                            
                        },
                        
                    ];

                    setName(doc.data().name);
                    setPhonenumber(doc.data().phonenumber);
                    setEmail(doc.data().email);
                    setAddress(doc.data().address);

                    setRole(doc.data().role)
                    setSPoint(doc.data().sPoint);

                    setDisplayName(doc.data().displayName);
                    

                    };

                });

            setData((oldDataArray) => tempDataArray);
            

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [uid]); 


    const [courseData, setCourseData] = useState([]);

    const [merchantID, setMerchantID] = useState("");
    const [storeName, setStoreName] = useState("");
    const [courseCategory, setCourseCategory] = useState("");
    const [courseSubCategory, setCourseSubCategory] = useState("");
    const [courseTypeCategory, setCourseTypeCategory] = useState("");
    const [courseCaption, setCourseCaption] = useState("");
    const [courseID, setCourseID] = useState("");
    const [courseName, setCourseName] = useState("");
    const [courseBadge, setCourseBadge] = useState("");
    const [courseFullPrice, setCourseFullPrice] = useState("");
    const [coursePrice, setCoursePrice] = useState("");
    const [courseImage, setCourseImage] = useState("");

    const [courseRequirement, setCourseRequirement] = useState("");

    const [courseLearning01, setCourseLearning01] = useState("");
    const [courseLearning02, setCourseLearning02] = useState("");
    const [courseLearning03, setCourseLearning03] = useState("");
    const [courseLearning04, setCourseLearning04] = useState("");
    const [courseLearning05, setCourseLearning05] = useState("");
    const [courseLearning06, setCourseLearning06] = useState("");
    const [courseLearning07, setCourseLearning07] = useState("");
    const [courseLearning08, setCourseLearning08] = useState("");
    const [courseLearning09, setCourseLearning09] = useState("");
    const [courseLearning10, setCourseLearning10] = useState("");

    //
    const [courseIntro, setCourseIntro] = useState("");

    const [courseSectionExVideo, setCourseSectionExVideo] = useState([""]);
    const [videoSrc, setVideoSrc] = useState("");


    const [courseExVideo, setCourseExVideo] = useState([""]);

    const ExVideoOption = ["video", "pdf"];
    const [courseExTypeString, setCourseExTypeString] = useState(ExVideoOption[0]);
    const [courseExType, setCourseExType] = useState([""]);

    const [courseDate, setCourseDate] = useState("");
    const [courseTopic, setCourseTopic] = useState("");
    const [courseStatus, setCourseStatus] = useState("");
    //
    const [courseDetail, setCourseDetail] = useState("");
    const [courseDiscount, setCourseDiscount] = useState("");

    const [courseSold, setCourseSold] = useState("");
    const [courseDoc, setCourseDoc] = useState("");

    //RATING
    const [ratingSum, setRatingSum] = useState(0);
    const [ratingCount, setRatingCount] = useState(0);

    //////////////////////////////////////////////////////
    var courseSectionExArray = [""];

    useEffect(() => {
        const productRef = firestore.collection("course");
        const query = productRef
        
            .where("courseID", "==", courseId);
                query
                .get()
                .then((querySnapshot) => {
                    let tempDataArray =[];
                    querySnapshot.forEach((doc) => {
                        if (doc.exists) {
                            tempDataArray = [...tempDataArray,
                            {
                                id: doc.id,
                                merchantID: doc.data().merchantID,
                                storeName: doc.data().storeName,

                                courseID: doc.data().courseID,
                                courseCategory: doc.data().courseCategory,
                                courseSubCategoty: doc.data().courseSubCategory,
                                courseTypeCategory: doc.data().courseTypeCategory,

                                courseName: doc.data().courseName,
                                courseCaption: doc.data().courseCaption,
                                courseTopic: doc.data().courseTopic,
                                courseBadge: doc.data().courseBadge,
                                courseFullPrice: doc.data().courseFullPrice,
                                coursePrice: doc.data().coursePrice,   
                                courseImage: doc.data().courseImage,
                                courseDetail: doc.data().courseDetail,
                                courseDiscount: doc.data().courseDiscount,
                                courseSold: doc.data().courseSold,
                                courseDoc: doc.data().courseDoc,

                                courseRequirement: doc.data().courseRequirement,

                                courseLearning01: doc.data().courseLearning01,
                                courseLearning02: doc.data().courseLearning02,
                                courseLearning03: doc.data().courseLearning03,
                                courseLearning04: doc.data().courseLearning04,
                                courseLearning05: doc.data().courseLearning05,
                                courseLearning06: doc.data().courseLearning06,
                                courseLearning07: doc.data().courseLearning07,
                                courseLearning08: doc.data().courseLearning08,
                                courseLearning09: doc.data().courseLearning09,
                                courseLearning10: doc.data().courseLearning10,

                                courseIntro: doc.data().courseIntro,
                                courseExVideo : doc.data().courseExVideo,
                                courseSectionExVideo : doc.data().courseSectionExVideo,

                                courseExType: doc.data().courseExType,

                                courseStatus: doc.data().courseStatus,

              
                                ratingSum: doc.data().ratingSum,
                                ratingCount: doc.data().ratingCount,
                            },
                        ];


                        setMerchantID(doc.data().merchantID);
                        setStoreName(doc.data().storeName);

                        setCourseID(doc.data().courseID);
                        setCourseCategory(doc.data().courseCategory);
                        setCourseSubCategory(doc.data().courseSubCategory);
                        setCourseTypeCategory(doc.data().courseTypeCategory);
                        setCourseName(doc.data().courseName);
                        setCourseCaption(doc.data().courseCaption);

                        setCourseTopic(doc.data().courseTopic);
                        setCourseBadge(doc.data().courseBadge);
                        setCourseFullPrice(doc.data().courseFullPrice);
                        setCoursePrice(doc.data().coursePrice);
                        setCourseImage(doc.data().courseImage);
                        setCourseDetail(doc.data().courseDetail);
                        setCourseDiscount(doc.data().courseDiscount);
                        setCourseSold(doc.data().courseSold);
                        setCourseDoc(doc.data().courseDoc);

                        setCourseRequirement(doc.data().courseRequirement);

                        setCourseLearning01(doc.data().courseLearning01);
                        setCourseLearning02(doc.data().courseLearning02);
                        setCourseLearning03(doc.data().courseLearning03);
                        setCourseLearning04(doc.data().courseLearning04);
                        setCourseLearning05(doc.data().courseLearning05);
                        setCourseLearning06(doc.data().courseLearning06);
                        setCourseLearning07(doc.data().courseLearning07);
                        setCourseLearning08(doc.data().courseLearning08);
                        setCourseLearning09(doc.data().courseLearning09);
                        setCourseLearning10(doc.data().courseLearning10);

                        setCourseIntro(doc.data().courseIntro);
                        setCourseExVideo(doc.data().courseExVideo);
                        setVideoSrc(doc.data().courseExVideo[0]);
                        setCourseSectionExVideo(doc.data().courseSectionExVideo);

                        setCourseExType(doc.data().courseExType);

                        setCourseStatus(doc.data().courseStatus);

 
                        setRatingSum(doc.data().ratingSum);
                        setRatingCount(doc.data().ratingCount);
                        };

                        courseSectionExArray.push(doc.data().courseSectionExVideo);
                        console.log(courseSectionExArray);


                    });

                setCourseData((oldDataArray) => tempDataArray);

                
            },

                (err) => {
                    console.log(err);
                }
                
            );

        }, []);



    /* const [courseData, setCourseData] = useState([]);
    var courseSectionArray = [""];
    var courseSectionSrcArray = [""];
  

    const [courseSectionArrayToString, setCourseSectionArrayToString] = useState("");
        useEffect(() => {
            const userRef = firestore.collection("course").doc(courseId).collection("courseContent").orderBy("sortTopic", "asc");
            userRef.onSnapshot((snapshot) => {

                
                let tempDataArray =[];
                snapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempDataArray = [...tempDataArray,
                        {
                            id: doc.id,
                            topicID: doc.data().topicID,
                        
                            courseTopic: doc.data().courseTopic,
                            courseSrc : doc.data().courseSrc,

                            courseSection : doc.data().courseSection,

                            courseSectionSrc : doc.data().courseSectionSrc,

                            courseSectionType: doc.data().courseSectionType,

                            sortTopic: doc.data().sortTopic,
                            

                        },

                        
                    ];


                  
                    console.log(doc.data());

                    };

        });
            setCourseData((oldDataArray) => tempDataArray);
           
    },
        (err) => {
            console.log(err);
        }
    );

    }, []); */



    const myStore = () => {
        if (role == "merchant") {
            navigate("/MerchantView")
        } else {
            alert("ร้านค้าของฉันพร้อมเปิดให้บริการเร็วๆ นี้, ขออภัยในความไม่สะดวก");
        }
    }

    const signOutHandler = () => {
        auth.signOut().then(() => {
            console.log("Log Out Ok");
            setCurrentUser(null);
            navigate("/");
        })
        .catch((err) => {
            console.log("Log Out Not Ok." + err);
        })
    };


    //////////////////////////////////// ADD/REMOVE TEXT FIELD /////////////////////////////////////////////////////

    

    /* const [formValues, setFormValues] = useState([{ section: "", links : ""}])

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
      }
    
    let addFormFields = () => {
        setFormValues([...formValues, { section: "", links: "" }])
      }
    
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    } */
    
    //////////////////////////////////////// UPLOAD DATA ///////////////////////////////////////////////////////////
    /* const [courseTopic, setCourseTopic] = useState("");*/

    //TEST Upload Video/Doc
    const [file, setFile] = useState("");
    const [link, setLink] = useState("");

    const [progress, setProgress] = useState(1);
    const [progressStatus, setProgressStatus] = useState(false);

    const subBucketRef = storage.child("mainBucket/course");
    const uploadHandler = (e) => {

        e. preventDefault();

        if (!!file) {

            const fileName = file.name;
            const targetRef = subBucketRef.child(fileName);

            const uploadTask = targetRef.put(file);
            setProgressStatus(true);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                },
                (error) => {
                    console.log(error);
                },
                () => {
                    setProgressStatus(false);
                    uploadTask.snapshot.ref.getDownloadURL().then((photoURL) => {
                        console.log(photoURL);
                        setLink(photoURL);
                    });
                }
            );

            /* targetRef.put(file).then((response) => {
                console.log(response);
                response.ref.getDownloadURL().then((photoURL) => {
                    console.log(photoURL);
                    setLink(photoURL);
                }); */

/*             });

        } */

        } else {
                console.log("no file upload!!");
        }

    };

      ///////////////////////////////////// TEST PUSH DATA TO ARRAY /////////////////////////////////////////////////
      const [courseSectionExString, setCourseSectionExString] = useState([""]);
    //   const [courseExTypeString, setCourseExTypeString] = useState([""]);

    const handleSubmit = (event) => {
        event.preventDefault();
        // alert(JSON.stringify(formValues));

        courseSectionExVideo.push(courseSectionExString);
        courseExVideo.push(link);
        courseExType.push(courseExTypeString)

        const obj = {

            courseSectionExVideo: courseSectionExVideo,
            courseExVideo: courseExVideo,
            courseExType: courseExType,
            
        };

            // setCourseSectionExString("");

        
            ////////////////////////////////////////////////////////////////////
            uploadContentHandler(obj);

    }

    const deleteSelectExVideoHandler = (index) => {
        // index.preventDefault();

        courseSectionExVideo.splice(index, 1);
        courseExVideo.splice(index, 1);
        courseExType.splice(index, 1);

        const obj = {
            courseSectionExVideo : courseSectionExVideo,
            courseExVideo : courseExVideo,
            courseExType : courseExType,
        }

        uploadContentHandler(obj);


    }

    const uploadContentHandler = (obj) => {
           
        const ref = firestore.collection("course");
        ref
          
            .doc(courseId)
            .update(obj)
            .then(() => {
                console.log("แก้ไขข้อมูลสำเร็จ");
                refreshPage();
            })
            .catch((err) => console.log(err));
    };

    
    return (
        <>
        <br/>

        {data.map((item, index) => {

            return (
                            
                <div key={index}>    

                {/* <form onSubmit={submitHandler}> */}
                                
                    <Container>
                        
                        {/* <Link to="/MerchantView" style={{textDecoration: 'none', color: "black"}}> <Button variant="outline-secondary">ร้านค้าของฉัน</Button>{' '}</Link> */}
                        <Button onClick={myStore} variant="outline-secondary">ร้านค้าของฉัน</Button>{' '}
                        <Button onClick={signOutHandler} variant="outline-danger" type="submit">ออกจากระบบ</Button>
                        <br/>
                        <br/>
                        <br/>
                        <h3><strong>อัพโหลดตัวอย่างการสอน {courseName}</strong> <span className="float-end"><span style={{color: 'green'}}><BsRecordFill/><BsRecordFill/><BsRecordFill/><BsRecordFill/></span><span style={{color: 'lightgray'}}><BsRecordFill/></span></span></h3>

                        <h3 className="pb-3 border-bottom"></h3>
                      
                    </Container>

                {/* </form> */}
             
                </div>
              
            );
        })}




        {/* /////////////////////////////////////////////////// Display TOPIC/SECTION //////////////////////////////////////////////////////////////////// */}
        <Container>

        <br/>

        <Row>

        <Col sm={7}>
        <h3><strong>อัพโหลดตัวอย่างการสอน</strong></h3>
    

  
                {!!link ? (
                    
                    <Col style={{textAlign: "center"}}><video src={link} style={{width: '100%'}} controls autoPlay controlsList="nodownload" /> </Col>
                ) : (
                    <Col style={{textAlign: "center", fontSize: 120, color: 'gray'}}><BsCameraReels/></Col>
                )}
            
        <br/>

        {progressStatus ? (
            
    
            <ProgressBar striped variant="success" now={progress} />
            
        ) : null}

        <br/>
            

        <form>

            <Form.Label>ชื่อเนื้อหา</Form.Label>
            <Form.Control 
                type="text"
                className="rounded-0" 
                style={{ paddingLeft: 10 }}

                value={courseSectionExString}

                onChange={(e) => {
                setCourseSectionExString(e.target.value);
                }}

                placeholder={"*กรุณากรอกชื่อเนื้อหา"}
                required
            />
            <br />

            <Form.Label>ประเภทไฟล์</Form.Label>
            {/* <Form.Select className="rounded-0" aria-label="Default select example">
                <option value="video">วิดีโอ</option>
                <option value="pdf">ไฟล์เอกสาร pdf</option>
            </Form.Select> */}

            <Form.Select className="rounded-0" aria-label="Default select example"
                onChange={(e) => setCourseExTypeString(e.target.value)}
                defaultValue={courseExTypeString}
            >
                {ExVideoOption.map((option, index) => (
                <option key={index}>{option}</option>
                ))}
            </Form.Select>
            {/* <h2>
                {" "}
                You selected{" "}
                <span style={{ backgroundColor: "yellow" }}>{courseExTypeString}</span>
            </h2> */}
            

            <br/>

            <Form.Label>อัพโหลดไฟล์วิดีโอตัวอย่างการสอน</Form.Label>
                <InputGroup className="mb-3 rounded-0 was-validated">
                    <Form.Control
                        aria-label="Example text with button addon"
                        aria-describedby="basic-addon1"
                        type="file"
                        className="custom-file-label rounded-0"  
                        id="customFile" 
                        placeholder="" 
                        name="customFile" 
                        onChange={ (e) => {
                        const file = e.target.files[0];
                            setFile(file);
                            setLink(link);
                        }}
                        required
                    />

                    <Button 
                        type="submit"
                        onClick={uploadHandler}
                        className="btn btn-success rounded-0"
                    >
                        อัลโหลด
                    </Button>
                </InputGroup>

            

                <br/>
                {/* <Button onClick={handleSubmit} variant="outline-success" className="btn rounded-0" type="submit">เพิ่มเนื้อหา</Button> */}

                {!link ? 
                        <>
                            <div>
                                <Button variant="outline-success" className="btn rounded-0" disabled>เพิ่มเนื้อหา</Button>
                            </div>
                            <br/> 
                        </>
                    :
                        <>
                            <div>
                                <Button onClick={handleSubmit} className="btn btn-success btn rounded-0" type="submit">เพิ่มเนื้อหา</Button>
                            </div>
                            <br/>
                                            
                        </>
                }

        </form>



        </Col>

        <Col sm={5}>
        <h3><strong>ตัวอย่างการสอน</strong></h3> 
 
            {courseData.map((item, index) => {
                
                            
                return (
                    
                    <div>
                        <video src={videoSrc} style={{width: '100%'}} controls autoPlay controlsList="nodownload" />
                        
                        <Accordion style={{lineHeight: 2.5}} defaultActiveKey={['0']} alwaysOpen>
                            <Accordion.Item className="rounded-0" eventKey="0">
                                <Accordion.Header><strong>ตัวอย่างการสอน</strong></Accordion.Header>
                                <Accordion.Body>

                                { (courseSectionExVideo) 
                                    ? (courseSectionExVideo).map((itemexvideo, keyexvideo) =>
                                        <>

                                            <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>
                                            &nbsp;&nbsp;&nbsp;<Link onClick={() => setVideoSrc(courseExVideo[keyexvideo])}>{itemexvideo}</Link><span className="float-end">6.50&nbsp;&nbsp;&nbsp;<Button onClick={() => deleteSelectExVideoHandler(keyexvideo)} className="float-end" variant="light" size="sm" style={{marginTop: 5}}>X</Button></span>
                                            <br/>

                                        </>
                                    ) 
                                    : <>- ไม่มีเนื้อหา -</>
                                }
                                
                                {/* <Link to={uploadHandler} className="float-end">แก้ไขเพิ่มเติม</Link> */}
                                <br/>

                                </Accordion.Body>
                            </Accordion.Item> 
                        </Accordion>

                        </div>
                        
                );


                })}


                <br/>
                <br/>

                </Col>
            </Row>

            <br/>
                
                <span className="float-end"><Button className="btn btn-success rounded-0" type="submit">บันทึก</Button>&nbsp;&nbsp;&nbsp; 
                <Link to={"/UploadTopic/" + courseId}><Button className="rounded-0" variant="success" style={{textDecoration: 'none'}} >
                <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>&nbsp;&nbsp;&nbsp;บันทึกและอัพโหลดเนื้อหา</Button></Link>{' '}</span>

            <br/>

        </Container>
         {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}


        <br/>
        <br/>
      

        </>
    );
}

export default UploadExampleVideo;