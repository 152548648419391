import React, {useContext, useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Home/productcardview.css";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import Carousel from 'react-bootstrap/Carousel';

import { Row, Col, Card, Tabs, Tab, Spinner, Badge } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";


import { firestore } from '../../database/firebase';
import { auth } from '../../database/firebase';
// import { AuthContext } from '../../Old_Test/Auth';

import { Link, Navigate, useParams, useLocation } from "react-router-dom";

import { FaHeart, FaTruck, FaFacebookMessenger } from "react-icons/fa";

import { text } from '@fortawesome/fontawesome-svg-core';

import { FcFlashOn } from "react-icons/fc";

// import MessengerCustomerChat from 'react-messenger-customer-chat';
// import ChatBot from '../Chat/FacebookChat';

function FlashSale() {

    // let { productCategory } = useParams();
    let location = useLocation();

    const formatter = new Intl.NumberFormat('th', {
        style: 'currency',
        currency: 'THB',
        maximumSignificantDigits: 3,

      });

    // const [producrFlashSale, setProducrFlashSale] = useState("");
    
    const [data, setData] = useState([]);
    const [productName, setProductName] = useState("");
    const [productDiscount, setProductDiscount] = useState("");

    const [flashSaleOn, setFlashSaleOn] = useState("");

    const [loading, setLoading] = useState(false);


    var sliceProductName = "";

    var part = "";

    const [productLists, setProductLists] = useState([]);
    const [currentCursor, setCurrentCursor] = useState("");

    useEffect(() => {

        const productRef = firestore.collection("product")
        .where("productDiscount", ">=", "15");
        
        productRef.onSnapshot((snapshot) => {

            let tempDataArray =[];
            snapshot.forEach((doc) => {
                //LOADING ...
                // setLoading(true);
                    if (doc.exists) {
                        tempDataArray = [...tempDataArray,
                        {
                            productID: doc.id,
                            productID: doc.data().productID,
                            productName: doc.data().productName,
                            productImage: doc.data().productImage,
                            productPrice: doc.data().productPrice,
                            storeName: doc.data().storeName,
                            productFullPrice: doc.data().productFullPrice,
                            productDiscount: doc.data().productDiscount, 
                            productSold: doc.data().productSold,
                            productBadge: doc.data().productBadge,

                            //RATING
                            ratingSum: doc.data().ratingSum,
                            ratingCount: doc.data().ratingCount,
                            
                        },
                    ];

                    setProductName(doc.data().productName);

                    // setProductDiscount(doc.data().productDiscount);
   
                    /* sliceProductName = productName.slice("0", "5");

                    console.log(productName.slice("0", "5"));  */

                    
                    };

            });
            
            setData((oldDataArray) => tempDataArray);
            // setLoading(false);
            
    },
        (err) => {
            console.log(err);
        }
    );
    }, [productName]);


    /* useEffect(() => {
        
        if (productName != null) {
            setFlashSaleOn(<FcFlashOn /> + "Flash Sale");
        } else {
            setFlashSaleOn("");
        }
        // setCalculation(() => count * 2);
      }, [productName]); */
    

    return (


    <>

        {/* {!!productName ? (

            <>
            <br /><h3><strong><FcFlashOn />Flash Sale</strong></h3>
            <p style={{color: 'yellowgreen'}}>11 กรกฎาคม 2566 12.01 PM</p>
            
            </>

        ) : (
            <>
            
            </>
        )} */}

        {/* <br /><h3><strong><FcFlashOn />Flash Sale</strong></h3><br />
            <p>ติดตามสินค้าราคาพิเศษอีกครั้งทุกวันที่ 11 ของทุกเดือน</p> */}

    {!!productName ? 
      (
        <><br /><h3><strong><FcFlashOn />Flash Sale</strong></h3><br/>
        {/* <p style={{color: 'goldenrod'}}><strong>โปรหมดเขต 11-12 กรกฎาคม 2566, 12.00 AM</strong></p> */}


        <Row xs={2} md={4} className="g-4">

                        

                        {data.map((item) => {
                            
                            return (
                               
                                <div key={item.id}>

                                    <div className='card'>
                                    <Link to={'/ProductDetail/'+ item.productID} style={{textDecoration: 'none', color: "black"}}>
                                        <Card>
                                            <Card.Img variant="top" style={{width: "100%", height: "auto"}} src={item.productImage[0]} />
                                            
                                            <Card.Body>
                                                <Card.Title>
                                                </Card.Title>
                                                {/* <p numberOfLines={2} style={{textOverflow: 'ellipsis'}}><strong>{item.productName}</strong></p> */}

                                                {/* <p><strong>{item.productName}</strong></p> */}
                                                {/* <div class='productname' style={{marginTop: -14, lineHeight: 1.5}}><p style={{fontWeight: 500}}>{item.productName}</p></div> */}
                                                <p class='productname' style={{marginTop: -14, fontWeight: 500}}>{item.productName}</p>

                                                {/* <div class='price' style={{marginTop: -14, marginBottom: 0, lineHeight: 1.5}}>{item.productName}</div> */}
                                   
                                                    {/* { (item.productName).length < 30 ? (
                                                        <>
                                                            <p class='productname'><strong>{item.productName}</strong></p><br/>
                       
                                                        </>
                                                    ) : item.productName < 35 ? (
                                                        <>
                                                            <p class='productname'><strong>{item.productName}</strong></p>
                                                        </>
                                                    ) : item.productName > 40 ? (
                                                        <>
                                                            <p class='productname'><strong>{(item.productName).substring(0, 35) + '...'}</strong></p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p class='productname'><strong>{(item.productName).substring(0, 40) + '...'}</strong></p>
                                                        </>
                                                    )} */}
                              
                                                    
                                                    {/* {item.storeName} */}
                                                    {/* <br/>ขายแล้ว {item.productSold} */}
                                                    
                                                    {/* <p style={{color: "gray", fontSize: "12px", marginTop: "1px"}}>{item.storeName}</p> */}
                                                    

                                                    {/* { item.productDiscount == 5 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) :
                                                        item.productDiscount == 10 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : item.productDiscount == 15 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/> <FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : item.productDiscount == 20 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/> <FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : item.productDiscount == 25 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/> <FaHeart/> <FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : item.productDiscount == 30 ? (
                                                        <p class='productdiscount'><span style={{fontSize: 16, color: "red"}}><FaHeart/> <FaHeart/> <FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : (
                                                        <>
                                                        <p style={{marginTop: 58}}></p>
                                                        </>
                                                    )} */}

                                                    { (item.ratingSum / item.ratingCount) <= 0 ? (
                                                            <>
                                                            
                                                            </>
                                                        ) : (item.ratingSum / item.ratingCount) <= 1 ? (
                                                            <p style={{marginTop: 0}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStar/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 1.5 ? (
                                                            <p style={{marginTop: 0}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarHalf/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 2 ? (
                                                            <p style={{marginTop: 0}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 2.5 ? (
                                                            <p style={{marginTop: 0}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarHalf/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 3 ? (
                                                            <p style={{marginTop: 0}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 3.5 ? (
                                                            <p style={{marginTop: 0}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarHalf/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 4 ? (
                                                            <p style={{marginTop: 0, marginBottom: 20}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 4.5 ? (
                                                            <p style={{marginTop: 0}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarHalf/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) > 4.5 ? (
                                                            <p style={{marginTop: 0}}>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (
                                                            <p>
                                                                <br/>
                                                               
                                                            </p>
                                                        ) }

        {/* <p numberOfLines={1}>
                    {item.productName.length < 35
                        ? (item.productName)
                        : ((item.productName).substring(0, 5))}
                    </p> */}

                                                    {/* {item.productDiscount > 10 ? (
                                                        <p><span style={{fontSize: 20, color: "red"}}><FaHeart/> <FaHeart/></span> ส่วนลด {item.productDiscount}%</p>
                                                    ) : (
                                                        <>
                                                        <p style={{marginTop: 47}}></p>
                                                        </>
                                                    )} */}
                                                    


                                                    <div class='price' style={{marginTop: -14, marginBottom: -15}}>{formatter.format(item.productPrice)} <del style={{color: "gray"}}>{formatter.format(item.productFullPrice)}</del></div>

                                                    {item.productBadge ? (
                                                        <div class='bageanddiscount'> 
                                                            { item.productDiscount <= 9 ? ( 
                                                                <Badge className="rounded-0" bg="warning">{item.productBadge}</Badge> 
                                                            ) : (
                                                                <><Badge className="rounded-0" bg="warning">{item.productBadge}</Badge> <Badge className="rounded-0" bg="danger">- {item.productDiscount}%</Badge></> 
                                                            ) } <span style={{marginTop: 3}} className="float-end"><p>ขายแล้ว {item.productSold}</p></span>
                                                        </div>
                                                    ) : (
                                                       
                                                        <div class='bageanddiscount'> 
                                                            { item.productDiscount <= 9 ? ( 
                                                    
                                                                <>
                                                                </>
                                                                // <Badge className="rounded-0" bg="warning">{item.productBadge}</Badge>
                                                                
                                                            ) : (
                                                                <><Badge className="rounded-0" bg="warning">{item.productBadge}</Badge> <Badge className="rounded-0" bg="danger">- {item.productDiscount}%</Badge></> 
                                                            ) } <span style={{marginTop: 3}} className="float-end"><p>ขายแล้ว {item.productSold}</p></span>
                                                        </div>
                                                        
                                                    )}
                                                    
                                                    
                                                    
            
                                                    {/* <p><strong>{item.productName}</strong></p>
                                                    <p><strong>฿{item.productPrice} <del style={{color: "gray"}}>฿{item.productFullPrice}</del></strong></p> */}

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                    </div>
                                    
                                </div>
                            )
                        })}
                </Row>

        <br/>
                    
    </>

                    


 

      ) : (

        <>

        </>

      )}

        
    </>
    


  );
}

export default FlashSale;