import React, { useState, useEffect, useContext } from "react";
import { Container, Tabs, Tab, Form, Col, Row, Button, Card } from "react-bootstrap";
import { Link, redirect, useNavigate } from "react-router-dom";

import { FaSketch } from "react-icons/fa";

import { auth, firestore } from "../../database/firebase";

import AddProduct from "./AddProduct";


import { AuthContext } from "../../component_s/Authentication/AuthProvider";
import Order from "../Order/Order";

function ProductExpress() {

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    /* TEST QUERY DATA WITH productID */

    // const [uid, setUid] = useState("");

    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

   //const [uid, setUid] = useState("");

    var user = auth.currentUser;
    // var uid = "";
    var uid = "";

    if (user != null) {
        //email = user.email;
        uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                         // this value to authenticate with your backend server, if
                         // you have one. Use User.getToken() instead.
        console.log(uid);
        
        //TEST AUTH
        // refreshPage();
    }
    
    // const [emailDefault, setEmailDefault] = useState("");

    const [orderID, setOrderID] = useState("");
    
    const [data, setData] = useState([]);

    useEffect(() => {
        const orderRef = firestore.collection("order");
        const query = orderRef
            
            .where("merchantID", "==", uid)
            .where("orderStatus", "==", "จัดส่งแล้ว");
                query
                .get()
                .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempDataArray = [...tempDataArray,
                        {
                            // productID: doc.id,
                            id: doc.id,
                            productID: doc.data().productID,
                            storeName: doc.data().storeName,
                            productName: doc.data().productName,
                            // productImage: doc.data().productImage,
                            productPrice: doc.data().productPrice,
                            productQuantity: doc.data().productQuantity,
                            productPriceTotal: doc.data().productPriceTotal,
                            
                            orderStatus: doc.data().orderStatus,
                            orderDate: Date(doc.data().orderDate),

                            usernameOrder: doc.data().usernameOrder,
                            userphonenumberOrder: doc.data().userphonenumberOrder,
                            useraddressOrder: doc.data().useraddressOrder,
                

                        },
                    ];

                    setOrderID(doc.id);
                    
                    };

            });
            setData((oldDataArray) => tempDataArray);
    },
        (err) => {
            console.log(err);
        }
    );

    }, [uid]);


    return (
        <>

            {!!orderID ? (
                <>

                    <br/>
                    <h3><strong>การจัดส่งสินค้า</strong></h3>
                    <h3 className="pb-3 border-bottom"></h3>
                        <Row>
                            {data.map((item, index) => {
                                return (
                                    <div key={index}>     
                                            
                                    <br/>
                                        <Card className="rounded-0">
                                                <Card.Header>
                                                    <strong>คำสั่งซื้อที่: {item.id}</strong>
                                                </Card.Header>
                                            <Card.Body>
                                                    
                                                <Card.Text>
                                                    <strong>ร้านค้า:</strong> {item.storeName}<br/>
                                                    <strong>ชื่อสินค้า:</strong> {item.productName}<br/>
                                                    <strong>ราคา:</strong> {item.productPrice}<br/>
                                                    <strong>จำนวน:</strong> {item.productQuantity}<br/>
                                                    <strong>ค่าจัดส่ง:</strong> {item.productShippingCost}<br/><br/>
                                                    <strong>จำนวนเงินทั้งสิ้น:</strong> {item.productPriceTotal} บาท<br/>
                                                    {item.orderDate}<br/><br/>
                                                    <strong>สถานะ: <span style={{color: "green"}}>{item.orderStatus}</span></strong>

                                                    <h3 className="pb-3 border-bottom"></h3>
                                                    <strong>ชื่อผู้รับ:</strong> {item.usernameOrder}<br/>
                                                    <strong>หมายเลขโทรศัพท์:</strong> {item.userphonenumberOrder}<br/>
                                                    <strong>ที่อยู่จัดส่งสินค้า:</strong> {item.useraddressOrder}<br/>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>

                                    </div>
                                        );
                            })}


                        </Row>

                </>
            ) : (
                <>

                    <br/>
                    <h3><strong>การจัดส่งสินค้า</strong></h3>
                    <h3 className="pb-3 border-bottom"></h3>
                    <p>ยังไม่มีสินค้าจัดส่ง</p>

                </>
            )}
            

        <br/>
        <br/>
      

        </>
    );
}

export default ProductExpress;