import React, { useState, useEffect, useContext } from "react";
import { Container, Tabs, Tab, Form, Col, Row, Button, Card, InputGroup, Accordion, Modal, ProgressBar, ButtonGroup, Badge } from "react-bootstrap";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";

import { FaSketch, FaHeart, FaRegHeart, FaRegCommentAlt, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { AiOutlineSketch } from "react-icons/ai";

import { auth, firestore } from "../../database/firebase";
import { storage } from "../../database/firebase";


import { AuthContext } from "../../component_s/Authentication/AuthProvider";

import { BsStarFill, BsStarHalf, BsStar, BsPatchCheck, BsPlayBtn, BsPhone, BsFolder2Open, BsInfinity, BsCheckLg, BsPeopleFill, BsFillPlayBtnFill, BsChatLeftDotsFill, BsPersonCircle, BsCameraReels, BsRecordFill, BsEye, BsBookmarkPlus, BsBookmark, BsJournalCheck, BsChatRightQuote, BsBookmarkFill, BsFacebook, BsInstagram, BsYoutube, BsTiktok } from "react-icons/bs";


import product_sample01 from '../../component_s/images_s/product_sample01.jpeg';

import "./ReadArticle.css";

function ReadArticle() {


    let { articleId } = useParams();

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    /* TEST QUERY DATA WITH productID */

    // const [uid, setUid] = useState("");

    // const [emailDefault, setEmailDefault] = useState("");
   
    const [displayNameShown, setDisplayNameShown] = useState(true);
    var hiddenDisplayName;

    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

   //const [uid, setUid] = useState("");

    var user = auth.currentUser;
    // var displayname = "";
    var uid = "";

    if (user != null) {

        user.reload();
       
        uid = user.uid;
       /*  email = user.email;
        name = user.displayName; */
        // console.log(name);
        // displayname = user.displayName;
       
        

    } else {
        // navigate("/");
    }

    /* useEffect(() => {
        
        if (!!displayName) {
            setDisplayName(displayName);
        } else {
            
        }
     
      }, [user]); */


    




    

    ////////////////////// READER DATA ///////////////////////////////////////////////////////////////////
    // const [loading, setLoading] = useState(false);
    const [section, setSection] = useState([""]);
    const [about, setAbout] = useState([""]);

    const [displayName, setDisplayName] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [address, setAddress] = useState("");

    const [role, setRole] = useState("");
    const [sPoint, setSPoint] = useState("");

    
    const [description, setDescription] = useState("");
    const [profileImg, setProfileImg] = useState("");

    

    const [data, setData] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
    
        .where("uid", "==", uid);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            uid: doc.id,
                            name: doc.data().name,
                            email: doc.data().email,
                            phonenumber: doc.data().phonenumber,
                            address: doc.data().address,
                            sPoint: doc.data().sPoint,   
                            role: doc.data().role,

                            displayName: doc.data().displayName,
                            description: doc.data().description,
                            profileImg: doc.data().profileImg,
                            
                        },
                        
                    ];

                    setName(doc.data().name);
                    setPhonenumber(doc.data().phonenumber);
                    setEmail(doc.data().email);
                    setAddress(doc.data().address);

                    setRole(doc.data().role)
                    setSPoint(doc.data().sPoint);

                    setDisplayName(doc.data().displayName);
                    setDescription(doc.data().description);
                    setProfileImg(doc.data().profileImg);

                    

                    /*
                    if (description != "") {
                        setDescription(doc.data().description);
                    } else {
                        setDescription(uid);
                    } */
                    

                    };

                    

                });
                // hiddenDisplayName = displayName.charAt(0) + displayName.replace(/./g, '*') + displayName.charAt(displayName.length-1);

            setData((oldDataArray) => tempDataArray);

            

            console.log(description);
            
            

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [uid]); 

    

    const [articleData, setArticleData] = useState([]);
    const [articleID, setArticleID] = useState("");
    // const [merchantID, setMerchantID] = useState("");
    const [writerID, setWriterID] = useState("");
    const [storeName, setStoreName] = useState("");

/*     const [writerName, setWriterName] = useState("");

    const [writerImg, setWriterImg] = useState(""); */

    const [articleName, setArticleName] = useState("");
    const [articleDate, setArticleDate] = useState("");

    const [articleHeader, setArticleHeader] = useState([""]);
    const [articleContent, setArticleContent] = useState([""]);
    const [articleContentType, setArticleContentType] = useState([""]);
    const [articleSection, setArticleSection] = useState([""]);

    const [articleRefText, setArticleRefText] = useState([""]);
    const [articleLinkRefText, setArticleLinkRefText] = useState([""]);

    const [articleCategory, setArticleCategory] = useState("");
    const [articleSubCategory, setArticleSubCategory] = useState("");
    const [articleTypeCategory, setArticleTypeCategory] = useState("");


    const [updateCount, setUpdateCount] = useState("");
    const [viewCount, setViewCount] = useState(0);
    // const [addViewCount, setAddViewCount] = useState(0);


    //LIKE
    const [likeCount, setLikeCount] = useState(0);
    const [userIDLike, setUserIDLike] = useState([""]);
    const [checkUserLike, setCheckUserLike] = useState(false);
    const [displayNameUserLike, setDisplayNameUserLike] = useState([""]);
    const [descriptionUserLike, setDescriptionUserLike] = useState([""]);
    const [profileUserImgLike, setProfileUserImgLike] = useState([""]);

    //BOOKMARK
    const [bookmarkCount, setBookmarkCount] = useState(0);
    const [userIDBookmark, setUserIDBookmark] = useState([""]);
    const [checkUserBookmark, setCheckUserBookmark] = useState(false);
    const [displayNameUserBookmark, setDisplayNameUserBookmark] = useState([""]);
    const [descriptionUserBookmark, setDescriptionUserBookmark] = useState([""]);
    const [profileUserImgBookmark, setProfileUserImgBookmark] = useState([""]);

    
    //RATING
    /* const [ratingSum, setRatingSum] = useState(0);
    const [ratingCount, setRatingCount] = useState(0); */
   

    //////////////////////////////////////////////////////
    // var courseSectionExArray = [""];

    

    useEffect(() => {
        const productRef = firestore.collection("article");
        const query = productRef
        
            .where("articleID", "==", articleId);
                query
                .get()
                .then((querySnapshot) => {
                    let tempDataArray =[];
                    querySnapshot.forEach((doc) => {
                        if (doc.exists) {
                            tempDataArray = [...tempDataArray,
                            {
                                

                                articleID: doc.id,
                                articleID: doc.data().articleID,
                                writerID: doc.data().writerID,

                        
                                writerName: doc.data().writerName,

                                //writerImg: doc.data().writerImg,

                                articletName: doc.data().articleName,
                                articleDate: doc.data().articleDate,

                                articleCategory: doc.data().articleCategory,
                                articleSubCategory: doc.data().articleSubCategory,
                                articleTypeCategory: doc.data().articleTypeCategory,

                                articleHeader: doc.data().articleHeader,
                                articleContent: doc.data().articleContent,
                                articleContentType: doc.data().articleContentType,
                                articleSection: doc.data().articleSection,

                                articleRefText: doc.data().articleRefText,
                                articleLinkRefText: doc.data().articleLinkRefText,

                                articleBadge: doc.data().articleBadge,

                                updateCount: doc.data().updateCount,
                                viewCount: doc.data().viewCount,

                                likeCount: doc.data().likeCount,
                                userIDLike: doc.data().userIDLike,
                                displayNameUserLike: doc.data().displayNameUserLike,
                                descriptionUserLike: doc.data().descriptionUserLike,
                                profileUserImgLike: doc.data().profileUserImgLike,


                                bookmarkCount: doc.data().bookmarkCount,
                                userIDBookmark: doc.data().userIDBookmark,
                                displayNameUserBookmark: doc.data().displayNameUserBookmark,
                                descriptionUserBookmark: doc.data().descriptionUserBookmark,
                                profileUserImgBookmark: doc.data().profileUserImgBookmark,

                                

                                //RATING
                                /* ratingSum: doc.data().ratingSum,
                                ratingCount: doc.data().ratingCount, */


                            },
                        ]; 


                        setArticleID(doc.data().articleID);
                        // setMerchantID(doc.data().merchantID);

                        setWriterID(doc.data().writerID);

                        setWriterName(doc.data().writerName);

                        //setWriterImg(doc.data().writerImg);

                        setStoreName(doc.data().storeName);

                        setArticleCategory(doc.data().articleCategory);
                        setArticleSubCategory(doc.data().articleSubCategory);
                        setArticleTypeCategory(doc.data().articleTypeCategory);

                        setArticleName(doc.data().articleName);
                        
                        setArticleHeader(doc.data().articleHeader);
                        setArticleContent(doc.data().articleContent);
                        setArticleContentType(doc.data().articleContentType);
                        setArticleSection(doc.data().articleSection);

                        setArticleRefText(doc.data().articleRefText);
                        setArticleLinkRefText(doc.data().articleLinkRefText);

                        setUpdateCount(doc.data().updateCount);
                        setViewCount(doc.data().viewCount);

                        setLikeCount(doc.data().likeCount);
                        setUserIDLike(doc.data().userIDLike);
                        setDisplayNameUserLike(doc.data().displayNameUserLike);
                        setDescriptionUserLike(doc.data().descriptionUserLike);
                        setProfileUserImgLike(doc.data().profileUserImgLike);
                        
                        setBookmarkCount(doc.data().bookmarkCount);
                        setUserIDBookmark(doc.data().userIDBookmark);
                        setDisplayNameUserBookmark(doc.data().displayNameUserBookmark);
                        setDescriptionUserBookmark(doc.data().descriptionUserBookmark);
                        setProfileUserImgBookmark(doc.data().profileUserImgBookmark);

                        

                        };

                        

                        setCheckUserLike(userIDLike.includes(uid));
                        setCheckUserBookmark(userIDBookmark.includes(uid));
                        // console.log(userIDLike.includes(uid));

                        // courseSectionExArray.push(doc.data().courseSectionExVideo);

                        

                    });

                setArticleData((oldDataArray) => tempDataArray); 



                ///////////////////////////////////////////////////////////
                
                
                ///////////////////////////////////////////////////////////

                
            },

                (err) => {
                    console.log(err);    
                }
                
            );

            

        }, []);   
        
        

    /////////////////////// WRITER DATA //////////////////////////////////////////////////////////////////
   
    const [writerName, setWriterName] = useState("");
    const [writerDescription, setWriterDescription] = useState("");
    const [writerImg, setWriterImg] = useState("");

    const [socialPublic, setSocialPublic] = useState("");
    const [facebook, setFacebook] = useState("");
    const [instagram, setInstagram] = useState("");
    const [youtube, setYoutube] = useState("");
    const [tiktok, setTiktok] = useState("");

    const [dataWriter, setDataWriter] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
    
        .where("uid", "==", writerID);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            uid: doc.id,

                            displayName: doc.data().displayName,
                            description: doc.data().description,
                            profileImg: doc.data().profileImg,

                            socialPublic: doc.data().socialPublic,
                            facebook: doc.data().facebook,
                            instagram: doc.data().instagram,
                            youtube: doc.data().youtube,
                            tiktok: doc.data().tiktok,

                            
                        },
                        
                    ];


                    setWriterName(doc.data().displayName);
                    setWriterDescription(doc.data().description);
                    setWriterImg(doc.data().profileImg);

                    setSocialPublic(doc.data().socialPublic);
                    setFacebook(doc.data().facebook);
                    setInstagram(doc.data().instagram);
                    setYoutube(doc.data().youtube);
                    setTiktok(doc.data().tiktok);

                    };

                    

                });


            setDataWriter((oldDataArray) => tempDataArray);

            console.log(description);

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [writerID]); 






























        //////////////////////////////////////////////////// ADD VIEW COUNT
        useEffect(() => {
            


            if (viewCount != 0) {
                const obj = {
                viewCount: viewCount + 1,
            }
            addViewCountHandler(obj);
            } 
           
        }, [viewCount]);

        const addViewCountHandler = (obj) => {
           
            const ref = firestore.collection("article");
            ref
              
                .doc(articleId)
                .update(obj)
                .then(() => {
                    
                })
                .catch((err) => console.log(err));
        };


    ///////////////////////////////////// TEST PUSH DATA TO ARRAY /////////////////////////////////////////////////
    // const [courseSectionExString, setCourseSectionExString] = useState([""]);





















    ///////////////////////////////////// QUERY + ADD COMMENT ////////////////////////////////////////////////////

    const [commentLists, setCommentLists] = useState([]);
    const [currentCursor, setCurrentCursor] = useState("");

    //LIKE COMMENT
    const [commentID, setCommentID] = useState("");

    const [userID, setUserID] = useState("");

    const [likeCommentCount, setLikeCommentCount] = useState(0);
    const [userIDLikeComment, setUserIDLikeComment] = useState([""]);
    const [checkUserLikeComment, setCheckUserLikeComment] = useState(false);
    const [displayNameUserLikeComment, setDisplayNameUserLikeComment] = useState([""]);
    const [descriptionUserLikeComment, setDescriptionUserLikeComment] = useState([""]);
    const [profileUserImgLikeComment, setProfileUserImgLikeComment] = useState([""]);

    const [showAddComment, setShowAddComment] = useState(false);
    const handleCloseAddComment = () => setShowAddComment(false);

   

    useEffect(() => {
        const firsPageRef = firestore
            .collection("commentArticle")
            .orderBy("commentDate", "desc")
            .where("articleID", "==", articleId)
            .limit(8);
        firsPageRef.get().then((querySnapshot) => {
            let tempLists = [];
            querySnapshot.docs.forEach((doc) => {
                if (doc.exists) {

                    const currentArticle = {

                        articleID : doc.id,
                        articleID : doc.data().articleID ,
                        articleName: doc.data().articleName,

                        commentID: doc.data().commentID,

                        userID: doc.data().userID,

                        displayName: doc.data().displayName,

                        //writerName: doc.data().writerName,

                        email: doc.data().email,
                        comment: doc.data().comment,
                        rating: doc.data().rating,
                        commentDate: doc.data().commentDate,

                        likeCommentCount: doc.data().likeCommentCount,
                        userIDLikeComment: doc.data().userIDLikeComment,
                        displayNameUserLikeComment: doc.data().displayNameUserLikeComment,
                        descriptionUserLikeComment: doc.data().descriptionUserLikeComment,
                        profileUserImgLikeComment: doc.data().profileUserImgLikeComment,

                        

                    };

                    setCommentID(doc.data().commentID);

                    setUserID(doc.data().userID);

                    setCheckUserLikeComment(userIDLikeComment.includes(uid));

                    setLikeCommentCount(doc.data().likeCommentCount);
                    setUserIDLikeComment(doc.data().userIDLikeComment);
                    setDisplayNameUserLikeComment(doc.data().displayNameUserLikeComment);
                    setDescriptionUserLikeComment(doc.data().descriptionUserLikeComment);
                    setProfileUserImgLikeComment(doc.data().profileUserImgLikeComment);

                    tempLists = [...tempLists, currentArticle];
                }
            });
            setCommentLists((prv) => tempLists);
            const currentLenght = querySnapshot.docs.length;
            const currentCursorFromFirstPage = querySnapshot.docs[currentLenght - 1];
            setCurrentCursor(currentCursorFromFirstPage);

            
        })
    }, []);

    const onMoreComment = () => {
        const nextPageRef = firestore
            .collection("commentArticle")
            .orderBy("commentDate", "desc")
            .where("articleID", "==", articleId)
            .limit(8);
        nextPageRef.get().then((querySnapshot) => {
            const currentLenght = querySnapshot.docs.length;
            if (!currentCursor) {
                return;
            }
            const query = nextPageRef.startAfter(currentCursor);
            query.get().then((querySnapshot) => {
                let tempNewArray = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempNewArray = [
                            ...tempNewArray,
                            {
                                articleID : doc.id,
                                articleID : doc.data().articleID ,
                                articleName: doc.data().articleName,

                                commentID: doc.data().commentID,

                                userID: doc.data().userID,

                                displayName: doc.data().displayName,

                                //writerName: doc.data().writerName,

                                email: doc.data().email,
                                comment: doc.data().comment,
                                rating: doc.data().rating,
                                commentDate: doc.data().commentDate,

                                likeCommentCount: doc.data().likeCommentCount,
                                userIDLikeComment: doc.data().userIDLikeComment,
                                displayNameUserLikeComment: doc.data().displayNameUserLikeComment,
                                descriptionUserLikeComment: doc.data().descriptionUserLikeComment,
                                profileUserImgLikeComment: doc.data().profileUserImgLikeComment,


                            },
                        ];

                        setCommentID(doc.data().commentID);

                        setUserID(doc.data().userID);

                        setCheckUserLikeComment(userIDLikeComment.includes(uid));

                        setLikeCommentCount(doc.data().likeCommentCount);
                        setUserIDLikeComment(doc.data().userIDLikeComment);
                        setDisplayNameUserLikeComment(doc.data().displayNameUserLikeComment);
                        setDescriptionUserLikeComment(doc.data().descriptionUserLikeComment);
                        setProfileUserImgLikeComment(doc.data().profileUserImgLikeComment);

                    }

                });
                setCommentLists([...commentLists, ...tempNewArray]);
                const currentCursorForNextPage = querySnapshot.docs[currentLenght -1];
                setCurrentCursor(currentCursorForNextPage);


            });
        });
    };

    const unLikeCommentHandler = () => {
        /* setLikeIcon(<FaRegHeart/>);
        console.log(checkUserLike); */
        setCheckUserLikeComment(false);
        unLikeCommentCountHandler();

    };

    const likeCommentHandler = (commentid) => {
        setCheckUserLikeComment(true);
        addLikeCommentCountHandler(commentid);
    };


    useEffect(() => {

        if (userIDLikeComment.includes(uid)) {
            setCheckUserLikeComment(true);
        } else {
            setCheckUserLikeComment(false);
            // setLikeIcon(<FaRegHeart/>)
        }

    }, [userIDLikeComment]);

    const LikeCommentNotLoginHandler = () => {

    };


    

   
    ///////////////////////////////////////////////////////////////////////////////////////////
    const id = "cma" + Math.random(999).toString();

    const [comment, setComment] = useState("");
    // const [rating, setRating] = useState(0);

    const addCommentHandler = (obj) => {
           
        const ref = firestore.collection("commentArticle");
        ref
            
            .doc(id)
            .set(obj)
            .then(() => {
                console.log("เพิ่มข้อมูลสำเร็จ");
                refreshPage();

            })
            .catch((err) => console.log(err));
    };

    //RATING PRODUCT
   /*  const ratingArticleHandler = (obj) => {
        
        const ref = firestore.collection("product");
        ref
            .doc(articleId)
            .update(obj)
            .then(() => {
                console.log("แก้ไขข้อมูลสำเร็จ");
                refreshPage();
            })
            .catch((err) => console.log(err));
    }; */


    const submitCommentHandler = (e) => {
            
        e.preventDefault();
        
        const obj = {

            commentID: id,
            userID : uid,
            email : email,
            // storeName : storeName,

            displayName : displayName,

            writerID: writerID,
            articleID: articleID,
            articleName : articleName,

            // productImage : productImage,
            articleCategory: articleCategory,
            articleSubCategory: articleSubCategory,
            articleTypeCategory: articleTypeCategory,

            comment : comment,


            likeCommentCount: 0,
            userIDLikeComment: [""],

            displayNameUserLikeComment: [""],
            descriptionUserLikeComment: [""],
            profileUserImgLikeComment: [""],


            // rating : rating,

            

            commentDate: new Date(),
            


        };

        setComment("");


        if (user != null) {
            addCommentHandler(obj);

            // alert("เพิ่มความคิดเห็นสำเร็จ");

            setShowAddComment(true);

            

        } else {
            alert("เข้าสู่ระบบ/สมัครใหม่ เพื่อเพิ่มความคิดเห็น");

        }

        const objLikeArticle = {


            /* ratingSum: parseInt(ratingSum) + parseInt(rating),
            ratingCount: parseInt(ratingCount) + parseInt(1), */

        };

        // ratingArticleHandler(objLikeArticle);

    };




    /////////////////////////////////////////////////////////////////////////// ADD LIKE COMMENT
    const addLikeCommentCountHandler = (commentid) => {

        setLikeCommentCount(likeCommentCount + 1);

        // const adduserIDLike = removeDuplicateStrings(userIDLike.push(adduserIDLike));
        userIDLikeComment.push(uid);

        displayNameUserLikeComment.push(displayName);
        // descriptionUserLike.push(description);

        /*  if (profileImg != "") {
            profileUserImgLike.push(profileImg);
            
        } else {
            profileUserImgLike.push(uid);
        } */

        descriptionUserLikeComment.push(uid);

        profileUserImgLikeComment.push(uid);

        /*
        if (description == "") {
            descriptionUserLike.push(uid);
        } else {
            descriptionUserLike.push(description);
        } */

        // profileUserImgLike.push(profileImg);
        
        

        const obj = {
            likeCommentCount: likeCommentCount + 1,
            userIDLikeComment: removeDuplicateLike(userIDLikeComment),

            displayNameUserLikeComment: removeDuplicateLike(displayNameUserLikeComment),
            descriptionUserLikeComment: removeDuplicateLike(descriptionUserLikeComment),
            profileUserImgLikeComment: removeDuplicateLike(profileUserImgLikeComment), 

        }
       
        const ref = firestore.collection("commentArticle");
        ref
          
            .doc(commentid)
            .update(obj)
            .then(() => {
                
            })
            .catch((err) => console.log(err));
    };

    const unLikeCommentCountHandler = () => {

        // setLikeIcon(<FaRegHeart/>);
        setLikeCommentCount(likeCommentCount - 1);

       /*  if (profileImg != "") {
            setProfileImg(profileImg);
        } else {
            setProfileImg(uid);
        } */

        const obj = {
            likeCommentCount: likeCommentCount - 1,
            userIDLikeComment: userIDLikeComment.filter(e => e !== uid),
            displayNameUserLikeComment: displayNameUserLikeComment.filter(e => e !== displayName),
            descriptionUserLikeComment: descriptionUserLikeComment.filter(e => e !== uid),
            profileUserImgLikeComment: profileUserImgLikeComment.filter(e => e !==  uid),
        }
       
        const ref = firestore.collection("commentArticle");
        ref
          
            .doc(commentID)
            .update(obj)
            .then(() => {
                
            })
            .catch((err) => console.log(err));
    };
     
    









































    //////////////////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {

        if (userIDLike.includes(uid)) {
            setCheckUserLike(true);
        } else {
            setCheckUserLike(false);
            // setLikeIcon(<FaRegHeart/>)
        }

    }, [userIDLike]);


    useEffect(() => {

        if (userIDBookmark.includes(uid)) {
            setCheckUserBookmark(true);
        } else {
            setCheckUserBookmark(false);
        }

    }, [userIDBookmark]);

    /* useEffect(() => {
        if (profileImg != "") {
            setProfileImg(profileImg);
            
           
        } else {
            setProfileImg(uid);
        
            
        }

    }, [profileImg]); */


    //ALERT LIKE BOOKMARK NOT LOG IN
    const [showlikebookmarknotlogin, setShowlikebookmarknotlogin] = useState(false);
    const handleClose = () => setShowlikebookmarknotlogin(false);

    const LikeBookmarkNotLoginHandler = () => {

        setShowlikebookmarknotlogin(true);;

    };


    const unlikeHandler = () => {
        /* setLikeIcon(<FaRegHeart/>);
        console.log(checkUserLike); */
        setCheckUserLike(false);
        unLikeCountHandler();

    };

    const likeHandler = () => {
        setCheckUserLike(true);
        addLikeCountHandler();
    };

    ////////////////////////////////////////////////////////////////////

    const removeDuplicateLike = (DuplicateData) => {
        return [...new Set(DuplicateData)]
    }


    const addLikeCountHandler = () => {

        setLikeCount(likeCount + 1);

        // const adduserIDLike = removeDuplicateStrings(userIDLike.push(adduserIDLike));
        userIDLike.push(uid);

        displayNameUserLike.push(displayName);
        // descriptionUserLike.push(description);

        /*  if (profileImg != "") {
            profileUserImgLike.push(profileImg);
            
        } else {
            profileUserImgLike.push(uid);
        } */

        descriptionUserLike.push(uid);

        profileUserImgLike.push(uid);

        /*
        if (description == "") {
            descriptionUserLike.push(uid);
        } else {
            descriptionUserLike.push(description);
        } */

        // profileUserImgLike.push(profileImg);
        
        

        const obj = {
            likeCount: likeCount + 1,
            userIDLike: removeDuplicateLike(userIDLike),

            displayNameUserLike: removeDuplicateLike(displayNameUserLike),
            descriptionUserLike: removeDuplicateLike(descriptionUserLike),
            profileUserImgLike: removeDuplicateLike(profileUserImgLike), 

        }
       
        const ref = firestore.collection("article");
        ref
          
            .doc(articleId)
            .update(obj)
            .then(() => {
                
            })
            .catch((err) => console.log(err));
    };

    const unLikeCountHandler = () => {

        // setLikeIcon(<FaRegHeart/>);
        setLikeCount(likeCount - 1);

       /*  if (profileImg != "") {
            setProfileImg(profileImg);
        } else {
            setProfileImg(uid);
        } */

        const obj = {
            likeCount: likeCount - 1,
            userIDLike: userIDLike.filter(e => e !== uid),
            displayNameUserLike: displayNameUserLike.filter(e => e !== displayName),
            descriptionUserLike: descriptionUserLike.filter(e => e !== uid),
            profileUserImgLike: profileUserImgLike.filter(e => e !==  uid),
        }
       
        const ref = firestore.collection("article");
        ref
          
            .doc(articleId)
            .update(obj)
            .then(() => {
                
            })
            .catch((err) => console.log(err));
    };

    ///////////////////////////////////////////////////////////////////


    const unbookmarkHandler = () => {
        /* setLikeIcon(<FaRegHeart/>);
        console.log(checkUserLike); */
        setCheckUserBookmark(false);
        unBookmarkCountHandler();

    };

    const bookmarkHandler = () => {
        setCheckUserBookmark(true);
        addBookmarkCountHandler();
    };

    const addBookmarkCountHandler = () => {

        setBookmarkCount(bookmarkCount + 1);

        userIDBookmark.push(uid);

        displayNameUserBookmark.push(displayName);

        /*  if (profileImg != "") {
            profileUserImgBookmark.push(profileImg);
            
        } else {
            profileUserImgBookmark.push(uid);
        } */

        descriptionUserBookmark.push(uid);
        profileUserImgLike.push(uid);


        const obj = {
            bookmarkCount: bookmarkCount + 1,
            userIDBookmark: removeDuplicateLike(userIDBookmark),

            displayNameUserBookmark: removeDuplicateLike(displayNameUserBookmark),
            descriptionUserBookmark: removeDuplicateLike(descriptionUserBookmark),
            profileUserImgBookmark: removeDuplicateLike(profileUserImgBookmark), 

        }
       
        const ref = firestore.collection("article");
        ref
          
            .doc(articleId)
            .update(obj)
            .then(() => {
                
            })
            .catch((err) => console.log(err));
    };


    const unBookmarkCountHandler = () => {

        // setLikeIcon(<FaRegHeart/>);
        setBookmarkCount(bookmarkCount - 1);

       /*  if (profileImg == "") {
            setProfileImg(uid);
        } else {
            setProfileImg(profileImg);
        } */

        const obj = {
            bookmarkCount: bookmarkCount - 1,
            userIDBookmark: userIDBookmark.filter(e => e !== uid),
            displayNameUserBookmark: displayNameUserBookmark.filter(e => e !== displayName),
            descriptionUserBookmark: descriptionUserBookmark.filter(e => e !== uid),
            profileUserImgBookmark: profileUserImgBookmark.filter(e => e !==  uid),
        }
       
        const ref = firestore.collection("article");
        ref
          
            .doc(articleId)
            .update(obj)
            .then(() => {
                
            })
            .catch((err) => console.log(err));
    };

    ////////////////////////////////////////////////////////////////////////////// ADD + LIKE COMMENT



    //////////////////////////////////////////////////////////////////////////////
    const [showFullImg, setShowFullImg] = useState(false);
   

    return (
        <div class='containercenter'>
        <br/>

        <Container>
 
            <Row>
 
            {articleData.map((item, index) => {
                
                            
                return (
                    
                    <div>
                
                        <p><strong><Link to={"/articleCategory"} state={articleCategory} style={{textDecoration: 'none', color: "darkblue"}}>{articleCategory}</Link> {">"} <Link to={"/articleSubCategory"} state={articleSubCategory} style={{textDecoration: 'none', color: "darkblue"}}>{articleSubCategory}</Link> {">"}  <Link to={"/articleTypeCategory"} state={articleTypeCategory} style={{textDecoration: 'none', color: "darkblue"}}>{articleTypeCategory}</Link></strong></p>
                        
                        <h3><strong>{articleName}</strong></h3> 
                        <p style={{fontSize: 12}}>{writerName} | {(item.articleDate).toDate().toLocaleDateString('th-TH', {year: 'numeric', month: 'long', day: 'numeric', weekday: 'long',})} | {(item.articleDate).toDate().toLocaleTimeString()} | &nbsp;&nbsp;&nbsp;<span style={{fontSize: 20}}><BsEye/> {viewCount + 1}</span></p>
                        <Link to={"/articleCategory"} state={articleCategory}><Button variant="outline-secondary" className="rounded-0" size="sm">{articleCategory}</Button></Link>{' '}
                        <Link to={"/articleSubCategory"} state={articleSubCategory}><Button variant="outline-secondary" className="rounded-0" size="sm">{articleSubCategory}</Button></Link>{' '}
                        <Link to={"/articleTypeCategory"} state={articleTypeCategory}><Button variant="outline-secondary" className="rounded-0" size="sm">{articleTypeCategory}</Button></Link>{' '}
                        <br/>
                        {/* <p style={{fontSize: 20}}><BsEye/> 165</p> */}
                        {/* <h3 className="pb-3 border-bottom"></h3> */}
                        {/* <p style={{fontSize: 12}}>{(item.articleDate).toDate().toLocaleDateString('th-TH', {year: 'numeric', month: 'long', day: 'numeric', weekday: 'long',})} | {(item.articleDate).toDate().toLocaleTimeString()}</p> */}


                                                                    {/* { !! ({itemcontent}) 
                                                                    ?  <img src={itemcontent} style={{marginBottom: 20}} /> 
                                                                    : <></> } */}

                                                                   

                                                                    {/* {!showFullImg ? (
                                                                        <img onClick={() => setShowFullImg(true)} class='contentimage' src={itemcontent} style={{marginBottom: 20}}/>
                                                                    ) : (
                                                                        <img onClick={() => setShowFullImg(false)} style={{height: 'auto', width: '100%', marginBottom: 20}} src={itemcontent} />
                                                                    )} */}

                        <br/>
      
                        

                        { (item.articleHeader) 
                                    ? (item.articleHeader).map((itemheader, keyheader) =>
                                        <>

                                        { (item.articleSection) 
                                            ? (item.articleSection).map((itemsection, keysection) =>

                                                <> 
                                                { (keyheader == keysection) 
                                                    ? (
                                                    <>
                                                     
                                                        { (item.articleContent) 
                                                            ? (item.articleContent).map((itemcontent, keycontent) =>

                                                                <> 

                                                                { (keyheader == keycontent) 
                                                                ? (
                                                                    <>


                                                                        { (item.articleRefText) 
                                                                        ? (item.articleRefText).map((itemreftext, keyreftext) =>

                                                                            <>
                                                                            { (keyheader == keyreftext) 
                                                                                ? (
                                                                                <>

                                                                                { (item.articleLinkRefText) 
                                                                                ? (item.articleLinkRefText).map((itemlinkreftext, keylinkreftext) =>

                                                                                <>
                                                                                { (keyheader == keylinkreftext) 
                                                                                ? (
                                                                                <>

                                                                                    
                                                                                    {(itemcontent && (itemheader || itemsection || itemreftext || itemlinkreftext)) ? (
                                                                                        <>
                                                                                            <img class='contentimage' src={itemcontent} style={{marginBottom: 20}}/>
                                                                                            <h3>{itemheader}</h3>
                                                                                            <p style={{whiteSpace: "pre-line"}}>{itemsection.replaceAll("<br/>", "\n")}</p>

                                                                                            <p>
                                                                                            {itemheader || itemsection ? (

                                                                                                <>
                                                                                                    {itemreftext || itemlinkreftext ? (
                                                                                                    <>
                                                                                                        {itemreftext} <a href={itemlinkreftext} target="_blank" rel="noreferrer">{itemlinkreftext}</a><br/><br/>
                                                                                                    </>
                                                                                                    ) : (<><br/></>)}
                                                                                                </>

                                                                                            ) : (

                                                                                                <>
                                                                                                    {itemreftext} <a href={itemlinkreftext} target="_blank" rel="noreferrer">{itemlinkreftext}</a><br/><br/>
                                                                                                </>
                                                                                                
                                                                                            )}
                                                                                        
                                                                                            </p>
                                                                                        </>

                                                                                    ) : (itemcontent && !(itemheader || itemsection || itemreftext || itemlinkreftext)) ? (
                                                                                        <>
                                                                                        <img class='contentimage' src={itemcontent} style={{marginBottom: 20}}/>
                                                                                        
                                                                                        </>

                                                                                    ) : (
                                                                                        <p>
                                                                                        {itemheader || itemsection ? (

                                                                                            <>
                                                                                            <h3>{itemheader}</h3>
                                                                                            <p style={{whiteSpace: "pre-line"}}>{itemsection.replaceAll("<br/>", "\n")}</p>
                                                                                           

                                                                                            {itemreftext || itemlinkreftext ? (
                                                                                            <>
                                                                                                {itemreftext} <a href={itemlinkreftext} target="_blank" rel="noreferrer">{itemlinkreftext}</a>
                                                                                            </>
                                                                                            ) : (<><br/></>)}

                                                                                            </>

                                                                                            ) : (
                                                                                            <>{itemreftext} <a href={itemlinkreftext} target="_blank" rel="noreferrer">{itemlinkreftext}</a><br/></>
                                                                                            )}
                                                                                    
                                                                                        </p>
                                                                                    )  }


                                                                                 
                                                                                  


                                                                                </>) : (<></>)}


                                                                            </>
                                                                        ) : <></>}



                                                                                </>) : (<></>)}

                                                                            </>
                                                                        ) : <></>} 


                                                                    </>

                                                                ) : <></> }

                                                                </>
                                                            ) : <></> }


                                                    </>
                                                
                                                    ) : <></>
                                                }
                                                </>
                            


                                            )
                                            : <></>
                                        }

                                        </>
                                    ) 
                                    : <></>
                                }
                                
          

                        </div>
                        
                );


                })}

             

                <Row>
                    <Col>

                    <div className="text-center">
                    <ButtonGroup aria-label="Basic example">

                    {uid ? (
                                        <>
                                            {checkUserLike ? (
                                            <div>
                                                <Button onClick={unlikeHandler} variant="none" size="lg">
                                                    <span style={{color: "red", fontSize: 25}}><strong><FaHeart/></strong></span>
                                                </Button> 
                                                
                                                <Button variant="none">
                                                    <span style={{fontSize: 15, marginLeft: -25}}>{likeCount}</span>
                                                </Button>

                                            </div>
                                            ) : (

                                            <div>
                                                <Button onClick={likeHandler} variant="none" size="lg">
                                                    <span style={{fontSize: 25}}><strong><FaRegHeart/></strong></span>
                                                </Button> 
                                                
                                                <Button variant="none">
                                                    <span style={{fontSize: 15, marginLeft: -25}}>{likeCount}</span>
                                                </Button>

                                            </div>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                <Button onClick={LikeBookmarkNotLoginHandler} variant="none" size="lg">
                                                    <span style={{fontSize: 25}}><strong><FaRegHeart/></strong></span>
                                                </Button>
                                                
                                                <Button variant="none">
                                                    <span style={{fontSize: 15, marginLeft: -25}}>{likeCount}</span>
                                                </Button>
                                                
                                            </div>
                                        </>
                                    )}



                                    
                                    {uid ? (
                                        <>

                                            {checkUserBookmark ? (
                                            <div>
                                                <Button onClick={unbookmarkHandler} variant="none" size="lg">
                                                <span style={{fontSize: 25}}><strong><BsBookmarkFill/></strong></span>
                                                </Button> 

                                                <Button variant="none">
                                                <span style={{fontSize: 15, marginLeft: -25}}>{bookmarkCount}</span>
                                                </Button>
                                            </div>
                                            ) : (
                                            <div>
                                                <Button onClick={bookmarkHandler} variant="none" size="lg">
                                                <span style={{fontSize: 25}}><strong><BsBookmark/></strong></span>
                                                </Button> 
                                                
                                                <Button variant="none">
                                                    <span style={{fontSize: 15, marginLeft: -25}}>{bookmarkCount}</span>
                                                </Button>
                                            </div>
                                            )}
                                            
                                        </>
                                    ) : (
                                            <div>
                                                    <Button onClick={LikeBookmarkNotLoginHandler} variant="none" size="lg">
                                                        <span style={{fontSize: 25}}><strong><BsBookmark/></strong></span>
                                                    </Button> 
                                                    
                                                    <Button variant="none">
                                                        <span style={{fontSize: 15, marginLeft: -25}}>{bookmarkCount}</span>
                                                    </Button>
                                            </div>
                                    )}

                        </ButtonGroup>

                    </div>


                    </Col>
                </Row>

                
                <br/>
                <br/>
                <br/>

                <h3>ผู้เขียน</h3><br/><br/>
                        <Row className="borderwriter">

                            <Modal 
                                show={showlikebookmarknotlogin} 
                                onHide={handleClose} 
                                animation={false} 
                                size="sm"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered>
                                    <Modal.Header>
                                    <Modal.Title>เข้าสู่ระบบ/สมัครสมาชิก</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body><Link to={"/LogIn"} style={{textDecoration: 'none'}}>เข้าสู่ระบบ</Link> หรือ <Link to={"/SignUp"} style={{textDecoration: 'none'}}>สมัครสมาชิก</Link> เพื่อแสดงความรู้สึกหรือบุ๊คมาร์คบทความ</Modal.Body>
                                    <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        ปิด
                                    </Button>
                                    </Modal.Footer>
                            </Modal>

                            <Col md={8}>


                            <Row xs='auto'>
                                       
                                    <Link to={'/WriterPage/'+ writerID} style={{textDecoration: 'none', color: "black"}}>
                                        {!!writerImg ? (

                                            <Col md="auto"><img src={writerImg} alt="profile Image" className="mr-3 mt-3" style={{ borderRadius: '50%', objectFit: "cover", objectPosition: 'center', width: 60, height: 60, marginTop: 7, marginLeft: -20}} /> </Col>
                                            
                                        ) : (
                                            <Col><Button variant="dark" style={{width: 50, height: 50, marginTop: 20, marginLeft: -20, fontSize: 20}} className="rounded-circle"><strong>{(writerName).slice("0", "1")}</strong></Button></Col>
                                        )}
                                    </Link>
                                      
                                        <Col xs={9} md={7} style={{marginTop: 18}}>
                                            <strong>{writerName}</strong>
                                            <p style={{lineHeight: 1.5}}>{writerDescription}</p>
                                    
      
                                       </Col>
                            </Row>


                            </Col>
                            <Col md={4}>
                                
                                { socialPublic == "สาธารณะ" ? (
                                     <div className="float-end" style={{marginTop: 20}}>

                                        { facebook != "" ? (
                                            <>
                                                <a href={facebook} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'gray', fontSize: 25}}><BsFacebook/></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                            </>
                                        ) : (
                                            <></>
                                        ) }

                                        { instagram != "" ? (
                                            <>
                                                <a href={instagram} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'gray', fontSize: 25}}><BsInstagram/></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                            </>
                                        ) : (
                                            <></>
                                        ) }

                                        { youtube != "" ? (
                                            <>
                                                <a href={youtube} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'gray', fontSize: 25}}><BsYoutube/></a>
                                            </>
                                        ) :  (
                                            <></>
                                        ) }

                                        
                                        { tiktok != "" ? (
                                            <>
                                                <a href={tiktok} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'gray', fontSize: 25, marginLeft: 12}}><BsTiktok/></a>
                                            </>
                                        ) :  (
                                            <></>
                                        ) }
                                        

                                        

                                        
                                        {/* <Link to={facebook}><strong><BsFacebook/>&nbsp;&nbsp;&nbsp;{writerName}</strong><br/></Link> */}
                                    </div>
                                ) : (
                                <>
                                
                                </>
                                ) }



                                {/*  <div className="float-end" style={{marginTop: 10}}>
                                    <strong><BsChatRightQuote/>&nbsp;&nbsp;&nbsp;15 บทความ</strong><br/>
                                </div>  */}
                                
                            </Col>
                        </Row>

                        <br/>
                        <br/>

            {/* <h3 className="pb-3 border-bottom"></h3>
                <br/>
                <br/> */}

                        <br/>
                        <br/>
                

                <h3 style={{marginTop: 50}}>ความคิดเห็น</h3>

                    <br/>
                  
                    
                            {commentLists.map((item) => {
                                    
                                    return (
                                        <div key={item.id}>
                                        <Row xs='auto'>
                                       
                                        {/* {profileUserImgLikeComment ? (
                                            
                                            <Col><Button variant="dark" style={{width: 50, height: 50, marginTop: 7, fontSize: 20}} className="rounded-circle"><strong><img src={profileUserImgLikeComment} alt="profile Image" className="mr-3 mt-3" style={{ borderRadius: '50%', objectFit: "cover", objectPosition: 'center', width: 60, height: 60, marginTop: 7, marginLeft: -20}}/></strong></Button></Col>
                                        ) : (

                                            <Col><Button variant="dark" style={{width: 50, height: 50, marginTop: 7, fontSize: 20}} className="rounded-circle"><strong>{(item.displayName).slice("0", "1")}</strong></Button></Col>

                                        )} */}

                                        <Link to={'/WriterPage/'+ userID} style={{textDecoration: 'none', color: "black"}}>
                                            <Col><Button variant="dark" style={{width: 50, height: 50, marginTop: 7, fontSize: 20}} className="rounded-circle"><strong>{(item.displayName).slice("0", "1")}</strong></Button></Col>
                                        </Link>
                                        
                                        
                                        <Col xs={9} md={7} style={{marginTop: 5}}><strong>{item.displayName}</strong>


                                            <p style={{position: "relative", fontSize: 12}}>{(item.commentDate).toDate().toLocaleDateString('th-TH', {year: 'numeric', month: 'short', day: 'numeric', weekday: 'short',})} | {(item.commentDate).toDate().toLocaleTimeString()}</p>
                                            
       
                                      
                                            <p style={{whiteSpace: "pre-line", wordWrap: "break-word"}}>{(item.comment).replaceAll("<br/>", "\n")} </p>
                                            {/* <p style={{whiteSpace: "pre-line", wordWrap: "break-word"}}>{(item.commentID).replaceAll("<br/>", "\n")} </p> */}
                                     

                                            {/* <Col>

                                            {uid ? (
                                                <>
                                                    {checkUserLikeComment ? (
                                                    <div>
                                                        <Button onClick={unLikeCommentHandler} variant="none" size="lg">
                                                            <span style={{color: "red", fontSize: 25, marginLeft: -18}}><strong><FaHeart/></strong></span>
                                                        </Button> 
                                                        
                                                        <Button variant="none">
                                                            <span style={{fontSize: 15, marginLeft: -18}}>{likeCommentCount}</span>
                                                        </Button>

                                                    </div>
                                                    ) : (

                                                    <div>
                                                        <Button onClick={() => likeCommentHandler(item.commentID)} variant="none" size="lg">
                                                            <span style={{fontSize: 25, marginLeft: -18}}><strong><FaRegHeart/></strong></span>
                                                        </Button> 
                                                        
                                                        <Button variant="none">
                                                            <span style={{fontSize: 15, marginLeft: -18}}>{item.likeCommentCount}</span>
                                                        </Button>

                                                    </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <div>
                                                        <Button onClick={LikeBookmarkNotLoginHandler} variant="none" size="lg">
                                                            <span style={{fontSize: 25, marginLeft: -18}}><strong><FaRegHeart/></strong></span>
                                                        </Button>
                                                        
                                                        <Button variant="none">
                                                            <span style={{fontSize: 15, marginLeft: -18}}>{item.likeCommentCount}</span>
                                                        </Button>
                                                        
                                                    </div>
                                                </>
                                            )}
                                                
                                         
                                            </Col> */}
                                            
                            
                                        </Col>
                                        </Row>
                                        <h3 className="pb-3 border-bottom"></h3>
                                        <br/>
                                        </div>

                                )
                            })}

                            

                            {currentCursor ? (
                                <Col className='float-start'>

                                    <Button variant="warning" className="rounded-0" onClick={onMoreComment}>อ่านเพิ่มเติม</Button>
                                    
                                </Col>
                                ) : (
                                <Col className='float-start'>

                                    <Button variant="outline-secondary" className="rounded-0" disabled>ไม่มีความคิดเห็น</Button>
                                    
                                </Col>
                            )}

                            <br/>
                            <br/>
                            <br/>
                            <br/>

                            <div style={{marginBottom: 50}}>
                            {!!user ? (
                            <>
                        

                                <h3>เขียนความเห็น</h3>

                                <form onSubmit={submitCommentHandler}>
                                
                                <p><strong>{displayName}</strong></p>

                                {/* <Col><Button variant="dark" style={{width: 50, height: 50, marginTop: 7, fontSize: 20}} className="rounded-circle"><strong>{(displayName).slice("0", "1")}</strong></Button> <strong>{displayName}</strong></Col>
                                        <Col xs={9} md={7} style={{marginTop: 5}}><strong>{displayName}</strong></Col> */}

                                    
                                        {/* {displayNameShown ? (
                                            
                                            <p onClick={toggleDisplayName}>{displayName} <span style={{fontSize: 24}}>| <FaRegEye/></span> แสดง</p>
                            
                                        ) : (
                                            <>
                                            <p onClick={toggleDisplayName}>{displayName} <span style={{fontSize: 24}}>| <FaRegEyeSlash/></span> ไม่แสดง</p>
                                            </>
                                        )} */}

                                        <Modal 
                                            show={showAddComment} 
                                            onHide={handleCloseAddComment} 
                                            animation={false} 
                                            size="sm"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered>
                                            {/* <Modal.Header>
                                            <Modal.Title>เพิ่มความคิดเห็นสำเร็จ</Modal.Title>
                                            </Modal.Header> */}
                                            <Modal.Body>เพิ่มความคิดเห็นสำเร็จ</Modal.Body>
                                            {/* <Modal.Footer>
                                                <Button variant="secondary" onClick={handleCloseAddComment}>
                                                    ปิด
                                                </Button>
                                            </Modal.Footer> */}
                                        </Modal>
                                    

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        {/* <Form.Label>Example textarea</Form.Label> */}
                                        {/* <Form.Control className="rounded-0" as="textarea" value={comment} rows={3} placeholder="รีวิว..." /> */}
                                        <Form.Control 
                                            as="textarea" 
                                            className="rounded-0"
                                            style={{ whiteSpace: 'pre-line'}}
                                            placeholder="ความคิดเห็น..."
                                            rows={3} 
                                            value={comment}
                                            onChange={(e) => {
                                                setComment(e.target.value);
                                            }}
                                                    // placeholder={item.address} 
                                        />
                                    </Form.Group>

                                    <Button variant="dark" className="rounded-0" type="submit">ส่งความเห็น</Button>
                                </form>
                                    </>
                                ) : (
                                    <Link to={"/LogIn"}><Button variant="dark" className="rounded-0">เข้าสู่ระบบเพื่อเขียนความเห็น</Button></Link>
                                )}

                                </div>
                                



                                


                </Row>
        </Container>


         {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      

        </div>
    );
}

export default ReadArticle;