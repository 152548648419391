import React, {useEffect} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from "react-bootstrap";

import { NavLink, Link, useNavigate } from "react-router-dom";

import { FaFacebookF, FaInstagram, FaPinterest, FaTwitter, FaYoutube, FaCheck, FaSketch } from "react-icons/fa";
import { BsSendCheck, BsFill1CircleFill, BsFill2CircleFill, BsCheckLg, BsFillRecordFill, BsFacebook, BsInstagram, BsTelephone, BsSend } from "react-icons/bs";
// import Contact from "../Router_TestFlight/Contact";


function Contact() {

    

    return (

        <Container>

            <br/>

            <h3><strong><BsSend/> ติดต่อเรา</strong></h3>
          
            <h3 className="pb-3 border-bottom"></h3>
            <br/>
            {/* <h4><strong>ที่อยู่ EnqueX</strong></h4>
            <p>เลขที่ 199/288 แขวงสีกัน เขตดอนเมือง กรุงเทพมหานคร รหัสไปรษณีย์ 10210</p>

            
          
            <br/> */}

            <h4><strong>ช่องทางติดต่อเรา</strong></h4>
            <p>Facebook Messenger -  <a href="https://www.facebook.com/profile.php?id=61552083667050" target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}><BsFacebook/> EnqueX</a><br/>
            Instagram Direct Messages - <a href="https://www.instagram.com/enquex.official/" target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}><BsInstagram/> EnqueX</a><br/>
            </p>
            {/* โทรศัพท์ - <BsTelephone/> 0805514821</p> */}

            

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

        </Container>

    );

}

export default Contact;