import React, { useState, useEffect } from "react";
// import { Children } from "react";

import { auth } from "../../database/firebase";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {

    
    // const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);

    // const [displayName, setDisplayName] = useState("");

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            // setDisplayName(user.displayName);
            // setLoading(false);
            
                /* if(user) {
                    console.log(user.displayName)
                }
           */
          
        })
    }, [])

   

    /* if (loading) {
        return <p>Loading ...</p>;
    } */

    return (
        <AuthContext.Provider value={{currentUser}}>
            {children}
        </AuthContext.Provider>
    );
};

