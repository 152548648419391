import React, { useState, useEffect, useContext } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./ProductDetail.css";

import { Container, Image, Row, Col, Card, Tabs, Tab, Table, Button, Form, Alert, ButtonGroup, Badge, Modal } from 'react-bootstrap';

import { auth, firestore } from "../../database/firebase";
import { AuthContext } from "../Authentication/AuthProvider";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";

import product_sample01 from '../images_s/product_sample01.jpeg';
import product_sample02 from '../images_s/product_sample02.jpg';
import product_sample03 from '../images_s/product_sample03.jpg';

import { FaHeart, FaTruck, FaFacebookMessenger, FaStar, FaRegEye, FaRegEyeSlash, FaRegThumbsUp, FaThumbsUp, FaReply, FaRegHeart, FaRegCommentAlt } from "react-icons/fa";
import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";

//Test useParams
import { useParams, Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";



import Computer from "./Book/Computer";
import Business from "./Book/Business";
import Law from "./Book/Law";

function ProductDetail() {

    //Test useParams
    let { productId } = useParams();

    // let productID = "bz0HfOnJEwc8tZE3m6qO";

    const formatter = new Intl.NumberFormat('th', {
        style: 'currency',
        currency: 'THB',
        maximumSignificantDigits: 3,
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });


        let location = useLocation();

        /* TEST QUERY DA WITH productID */
        const [data, setData] = useState([]);

        const [merchantID, setMerchantID] = useState("");
        const [storeName, setStoreName] = useState("");
        const [productCategory, setProductCategory] = useState("");
        const [productSubCategory, setProductSubCategory] = useState("");
        const [productTypeCategory, setProductTypeCategory] = useState("");
        const [productRelease, setProductRelease] = useState("");
        const [productID, setProductID] = useState("");
        const [productName, setProductName] = useState("");
        const [productBadge, setProductBadge] = useState("");
        const [productFullPrice, setProductFullPrice] = useState("");
        const [productPrice, setProductPrice] = useState("");
        const [productImage, setProductImage] = useState("");
        const [productDetail, setProductDetail] = useState("");
        const [productIconDiscount, setProductIconDiscount] = useState("");
        const [productDiscount, setProductDiscount] = useState("");

        const [productQuantity, setProductQuantity] = useState("");

        const [numberOfPages, setNumberOfPages] = useState("");

        const [productSold, setProductSold] = useState("");
        const [productShippingCost, setProductShippingCost] = useState("");

        const [productWeight, setProductWeight] = useState("");
        const [productDoc, setProductDoc] = useState("");

        //RATING
        const [ratingSum, setRatingSum] = useState(0);
        const [ratingCount, setRatingCount] = useState(0);

        //SELECT PRODUCT QUANTITY
        const [count, setCount] = useState(1);
        const [calculation, setCalculation] = useState(0);

        //COMMENT
        //SHOW DISPLAY NAME
        const [displayNameShown, setDisplayNameShown] = useState(true);
        
        //READ MORE
        const [showMore, setShowMore] = useState(true);

        const [userID, setUserID] = useState("");

        const [currentUser, setCurrentUser] = useState(null);
        const authContext = useContext(AuthContext);

        const [displayName, setDisplayName] = useState("");
    
        var user = auth.currentUser;
        var email, uid, name;
        var hiddenEmail;
        var hiddenDisplayName;


        if (user != null) {
          
            user.reload();
            // user.updateProfile(auth.currentUser, {displayName});
            uid = user.uid;
            email = user.email;
            name = user.displayName;
            console.log(name);
            // setUserID(uid);
            
           /*  const auth = authContext.currentUser;
            console.log(auth.displayName); */
            /* setUserID(uid);
            console.log(userID); */

            hiddenEmail = email.charAt(0) + email.replace(/./g, '*') + email.charAt(email.length-1)
            
            // console.log(uid);

            // console.log(user.displayName);
            
        } else {
            // signUpPage();
        }

      /* const docRef = firestore.collection("user"); 
      const query = docRef
          .where("uid", "==", uid);
      query
          .get()
          .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                  if (doc.exists) {
                      console.log(doc.data().displayName);
                  }
              });
          })
          .catch((err) => {
              console.error( 'ERROR LOAD DISPLAYNAME' +(err));
          }); */



          useEffect(() => {
        
            if (!!user) {
                setDisplayName(email);
            } else {
                
            }
         
          }, [user]); 






        useEffect(() => {
        const productRef = firestore.collection("product");
        const query = productRef
        
            .where("productID", "==", productId);
                query
                .get()
                .then((querySnapshot) => {
                    let tempDataArray =[];
                    querySnapshot.forEach((doc) => {
                        if (doc.exists) {
                            tempDataArray = [...tempDataArray,
                            {
                                id: doc.id,
                                merchantID: doc.data().merchantID,
                                storeName: doc.data().storeName,
                                productCategory: doc.data().productCategory,
                                productSubCategoty: doc.data().productSubCategory,
                                productTypeCategory: doc.data().productTypeCategory,
                                productRelease: doc.data().productRelease,
                                productName: doc.data().productName,
                                productBadge: doc.data().productBadge,
                                productFullPrice: doc.data().productFullPrice,
                                productPrice: doc.data().productPrice,   
                                productImage: doc.data().productImage,
                                productDetail: doc.data().productDetail,
                                productIconDiscount: doc.data().productIconDiscount,
                                productDiscount: doc.data().productDiscount,
                                productSold: doc.data().productSold,
                                productQuantity: doc.data().productQuantity,
                                productShippingCost: doc.data().productShippingCost,
                                productWeight: doc.data().productWeight,
                                productDoc: doc.data().productDoc,
                                numberOfPages: doc.data().numberOfPages,

                                //RATING
                                ratingSum: doc.data().ratingSum,
                                ratingCount: doc.data().ratingCount,
                            },
                        ];


                        setMerchantID(doc.data().merchantID);
                        setStoreName(doc.data().storeName);
                        setProductCategory(doc.data().productCategory);
                        setProductSubCategory(doc.data().productSubCategory);
                        setProductTypeCategory(doc.data().productTypeCategory);
                        setProductRelease(doc.data().productRelease);
                        setProductName(doc.data().productName);
                        setProductBadge(doc.data().productBadge);
                        setProductFullPrice(doc.data().productFullPrice);
                        setProductPrice(doc.data().productPrice);
                        setProductImage(doc.data().productImage);
                        setProductDetail(doc.data().productDetail);
                        setProductIconDiscount(doc.data().productIconDiscount);
                        setProductDiscount(doc.data().productDiscount);
                        setProductSold(doc.data().productSold);
                        setProductShippingCost(doc.data().productShippingCost);
                        setProductWeight(doc.data().productWeight);
                        setProductDoc(doc.data().productDoc);
                        setNumberOfPages(doc.data().numberOfPages);

                        setProductQuantity(doc.data().productQuantity);

                        //RATING
                        setRatingSum(doc.data().ratingSum);
                        setRatingCount(doc.data().ratingCount);
                        };

                    });

                setData((oldDataArray) => tempDataArray);

            },

                (err) => {
                    console.log(err);
                }
                
            );

        }, []);




        const navigate = useNavigate();

        const refreshPage = () => {
            navigate(0);
        }
    
        const signUpPage = () => {
            navigate("/SignUp");
        }
    
    
        

        

        /* const docRef = firestore.collection("user");
        const query = docRef
        .where("uid", "==", uid);
        query
          .get()
          .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                  if (doc.exists) { */
                      /* if (doc.data().displayName != "") {
                        setDisplayName(doc.data().displayName);
                      } else {
                        setDisplayName(doc.data().email)
                      } */
                      /* console.log(doc.data().displayName)

                      if (!!displayName) {
                        setDisplayName(doc.data().displayName);
                    } else {
                        setDisplayName(doc.data().email);
                    }
                       
                  }
              });
          })
          .catch((err) => {
              console.error(err);
          });
 */




        //ADD TO CART/////////////////////////////////////////////////////////////////////////////////

        const [show, setShow] = useState(false);

        const addDataCartHandler = (obj) => {
           
            const ref = firestore.collection("cart");
            ref
                //.doc(ref)
                //.doc(id)
                .add(obj)
                .then(() => {
                    console.log("เพิ่มข้อมูลสำเร็จ");
                    //console.log(ref.doc().id)
                    // refreshPage();
                })
                .catch((err) => console.log(err));
        };



        const submitCartHandler = (e) => {
            
            e.preventDefault();
            //const id = firestore.collection("product").doc();
            
            const obj = {

                userID : uid,

                merchantID: merchantID,
                productID: productId,
                productName : productName,
                productPrice : productPrice,
                productFullPrice : productFullPrice,
                productDetail: productDetail,
                productShippingCost: productShippingCost,
                productQuantity: count,

                productCategory: productCategory,
                productSubCategory: productSubCategory,

                productWeight: parseInt(productWeight) * parseInt(count),

                // merchantID: uid,
                storeName: storeName,

                productImage : productImage,
                


            };

/* 
            setUserID("");
            setProductID("");
            setProductName("");
            setProductPrice("");
            setProductFullPrice("");
            setProductDetail("");
            setProductCategory("");
            setProductSubCategory("");
            setStoreName(""); */

            // setMerchantID("");
            // setProductImage([""]);

            if (user != null) {
                addDataCartHandler(obj);
                setShow(true);
                // alert("เพิ่มสินค้าในตะกร้าสำเร็จ");
            } else {
                alert("เข้าสู่ระบบ/สมัครใหม่ เพื่อเพิ่มสินค้าในตะกร้า");
                // console.log("LOG IN PLEASE");
                // refreshPage();
            }

            


        };

    

    const cartAlert = () => {
        setShow(true);
    }


    // SELECT PRODUCT QUANTITY
    useEffect(() => {
        
        if (count <= 0) {
            setCount(1);
        } else {
            setCount(count);
        }
        // setCalculation(() => count * 2);
      }, [count]); // <- add the count variable here



    //   const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      useEffect(() => {
        //J&T EXPRESS RATE

        if (parseInt(productWeight * count) <= 2000) {
            setProductShippingCost(38);
        } else if (parseInt(productWeight * count) <= 3000) {
            setProductShippingCost(58);
        } else if (parseInt(productWeight * count) <= 4000) {
            setProductShippingCost(64);
        } else if (parseInt(productWeight * count) <= 5000) {
            setProductShippingCost(74);
        } else if (parseInt(productWeight * count) <= 6000) {
            setProductShippingCost(84);
        } else if (parseInt(productWeight * count) <= 7000) {
            setProductShippingCost(94);
        } else if (parseInt(productWeight * count) <= 8000) {
            setProductShippingCost(104);
        } else if (parseInt(productWeight * count) <= 9000) {
            setProductShippingCost(110);
        } else if (parseInt(productWeight * count) <= 10000) {
            setProductShippingCost(125);
        } else if (parseInt(productWeight * count) <= 11000) {
            setProductShippingCost(150);
        } else if (parseInt(productWeight * count) <= 12000) {
            setProductShippingCost(150);
        } else if (parseInt(productWeight * count) <= 15000) {
            //KERRY RATE
            setProductShippingCost(220);
        } else if (parseInt(productWeight * count) <= 20000) {
            setProductShippingCost(350);
        } else {
            //IDC RATE
            setProductShippingCost(0);
        }
    }, [count]);


    /////////////////////////////////////// COMMENT //////////////////////////////////////////////

    /* const docRef = firestore.collection("commentProduct");
    const query = docRef
        .where("productID", "==", productId)
        .orderBy("productID", "asc");
    query
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if (doc.exists) {
                    console.log(doc.id);
                    console.log(doc.data().comment);
                    console.log(doc.data().rating);
                }
            });
        })
        .catch((err) => {
            console.error(err);
        }); */





    const [commentLists, setCommentLists] = useState([]);
    const [currentCursor, setCurrentCursor] = useState("");

    useEffect(() => {
        const firsPageRef = firestore
            .collection("commentProduct")
            .orderBy("commentDate", "desc")
            .where("productID", "==", productId)
            
            .limit(4);
        firsPageRef.get().then((querySnapshot) => {
            let tempLists = [];
            querySnapshot.docs.forEach((doc) => {
                if (doc.exists) {

                    const currentProduct = {

                        productID: doc.id,
                        productID: doc.data().productID,
                        productName: doc.data().productName,

                        displayName: doc.data().displayName,
                        // .slice("0", "30")
                        // productImage: doc.data().productImage,
                        // productPrice: doc.data().productPrice,
                        storeName: doc.data().storeName,
                        /* productFullPrice: doc.data().productFullPrice,
                        productDiscount: doc.data().productDiscount, 
                        productSold: doc.data().productSold,
                        productBadge: doc.data().productBadge, */

                        // productTypeCategory: doc.data().productTypeCategory,
                        email: doc.data().email,
                        comment: doc.data().comment,
                        rating: doc.data().rating,
                        commentDate: doc.data().commentDate,

                    };
                    tempLists = [...tempLists, currentProduct];
                }
            });
            setCommentLists((prv) => tempLists);
            const currentLenght = querySnapshot.docs.length;
            const currentCursorFromFirstPage = querySnapshot.docs[currentLenght - 1];
            setCurrentCursor(currentCursorFromFirstPage);
        })
    }, []);

    const onMoreComment = () => {
        const nextPageRef = firestore
            .collection("commentProduct")
            .orderBy("commentDate", "desc")
            .where("productID", "==", productId)
            
            .limit(4);
        nextPageRef.get().then((querySnapshot) => {
            const currentLenght = querySnapshot.docs.length;
            if (!currentCursor) {
                return;
            }
            const query = nextPageRef.startAfter(currentCursor);
            query.get().then((querySnapshot) => {
                let tempNewArray = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempNewArray = [
                            ...tempNewArray,
                            {
                                productID: doc.id,
                                productID: doc.data().productID,
                                productName: doc.data().productName,

                                displayName: doc.data().displayName,
                                // .slice("0", "30")
                                // productImage: doc.data().productImage,
                                // productPrice: doc.data().productPrice,
                                storeName: doc.data().storeName,
                                /* productFullPrice: doc.data().productFullPrice,
                                productDiscount: doc.data().productDiscount, 
                                productSold: doc.data().productSold,
                                productBadge: doc.data().productBadge, */

                                // productTypeCategory: doc.data().productTypeCategory,
                                email: doc.data().email,
                                comment: doc.data().comment,
                                rating: doc.data().rating,
                                commentDate: doc.data().commentDate,


                            },
                        ];
                    }
                });
                setCommentLists([...commentLists, ...tempNewArray]);
                const currentCursorForNextPage = querySnapshot.docs[currentLenght -1];
                setCurrentCursor(currentCursorForNextPage);
            });
        });
    };


    //QUERY DISPLAY NAME
    
   
    const id = "cmp" + Math.random(999).toString();

    const [comment, setComment] = useState("");
    const [rating, setRating] = useState(0);

   

    const addCommentHandler = (obj) => {
           
        const ref = firestore.collection("commentProduct");
        ref
            //.doc(ref)
            .doc(id)
            .set(obj)
            .then(() => {
                console.log("เพิ่มข้อมูลสำเร็จ");
                //console.log(ref.doc().id)
                // refreshPage();
            })
            .catch((err) => console.log(err));
    };

    //RATING PRODUCT
    const ratingProductHandler = (obj) => {
        
        const ref = firestore.collection("product");
        ref
            .doc(productId)
            .update(obj)
            .then(() => {
                console.log("แก้ไขข้อมูลสำเร็จ");
                refreshPage();
            })
            .catch((err) => console.log(err));
    };


    const submitCommentHandler = (e) => {
            
        e.preventDefault();
        //const id = firestore.collection("product").doc();
        
        const obj = {

            commentID: id,
            userID : uid,
            email : email,
            storeName : storeName,

            displayName : displayName,

            merchantID: merchantID,
            productID: productId,
            productName : productName,
            // productPrice : productPrice,
            // productFullPrice : productFullPrice,
            // productDetail: productDetail,
            // productShippingCost: productShippingCost,
            // productQuantity: count,
            productImage : productImage,
            productCategory: productCategory,
            productSubCategory: productSubCategory,
            productTypeCategory: productTypeCategory,

            // productWeight: parseInt(productWeight) * parseInt(count),

            comment : comment,
            rating : rating,

            commentDate: new Date(),
            


        };

        setComment("");

       

/* 
        setUserID("");
        setProductID("");
        setProductName("");
        setProductPrice("");
        setProductFullPrice("");
        setProductDetail("");
        setProductCategory("");
        setProductSubCategory("");
        setStoreName(""); */

        // setMerchantID("");
        // setProductImage([""]);

        if (user != null) {
            addCommentHandler(obj);
            // setRating(0);
            alert("เพิ่มความคิดเห็นสำเร็จ");
            // setShow(true);
            // alert("เพิ่มสินค้าในตะกร้าสำเร็จ");

            

        } else {
            alert("เข้าสู่ระบบ/สมัครใหม่ เพื่อเพิ่มความคิดเห็น");
            // console.log("LOG IN PLEASE");
            // refreshPage();
        }

        const objProduct = {


            ratingSum: parseInt(ratingSum) + parseInt(rating),
            ratingCount: parseInt(ratingCount) + parseInt(1),

        };

        ratingProductHandler(objProduct);

    };


        
    //SET DISPLAY NAME
     
    const toggleDisplayName = () => {

        setDisplayNameShown(!displayNameShown);

    };

    useEffect(() => {

        
        if (displayNameShown) {
        
            setDisplayName(email);

        } else {

            setDisplayName(hiddenEmail);

        }

    }, [displayNameShown]);

    /* useEffect(() => {
        
        if (displayNameShown) {

            if (!!displayName) {
                
                hiddenDisplayName = displayName.charAt(0) + '***' + displayName.charAt(displayName.length-1);
                setDisplayName(hiddenDisplayName)
                
            } else {

                setDisplayName(hiddenEmail);
            }

        } else {
            
            if (!!displayName) {
                setDisplayName(displayName);
                
            } else {
                setDisplayName(email);
            }

        }

      }, [displayNameShown]); */

    
      /* const docRef = firestore.collection("user"); 
      const query = docRef
          .where("uid", "==", uid);
      query
          .get()
          .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                  if (doc.exists) {
                      console.log(doc.data().displayName);
                  }
              });
          })
          .catch((err) => {
              console.error(err);
          }); */

          /* const [userData, setUserData] = useState([]);
          useEffect(() => {
          const userRef = firestore.collection("user");
          const query = userRef
          
              .where("uid", "==", uid);
                  query
                  .get()
                  .then((querySnapshot) => {
                      let tempDataArray =[];
                      querySnapshot.forEach((doc) => {
                          if (doc.exists) {
                              console.log(doc.data());
                              tempDataArray = [...tempDataArray,
                              {

      
                                  displayName: doc.data().displayName,
                                  
                              },
                              
                          ];
      

                          setDisplayName(doc.data().displayName);
                          console.log(displayName);
                          
      
                          };
      
                      });
      
                  setUserData((oldDataArray) => tempDataArray);
                  
      
              },
      
                  (err) => {
                      console.log(err);
                  }
                  
              );
      
          }, [uid]);  */
 

    return (

        <>
        
        {data.map((item, index) => {

            
        return (
                        
            <div key={index}>


            <Container w-75x>

                <br/>

                <p><strong><Link to={"/ProductCategory"} state={productCategory} style={{textDecoration: 'none', color: "darkblue"}}>{productCategory}</Link> {">"} <Link to={"/ProductSubCategory"} state={productSubCategory} style={{textDecoration: 'none', color: "darkblue"}}>{productSubCategory}</Link> {">"}  <Link to={"/ProductTypeCategory"} state={productTypeCategory} style={{textDecoration: 'none', color: "darkblue"}}>{productTypeCategory}</Link></strong></p>

                {/* Test useParams */}
                {/* <h3>This is Params {productId}</h3> */}

                <br/>

                <Row>

                    {/* Row 1 Product Image Left */}
                    <Col>
                    <img class='productimage' src={productImage[0]} rounded />
                    </Col>
                    

                    {/* Row 1 Detail Right */}
                    <Col sm>
                    <br/>
                        <h4><strong>{productName}</strong></h4>
                        <p>ร้านค้า : {storeName}<br/>

                           

                                                    { (item.ratingSum / item.ratingCount) <= 0 ? (
                                                            <>
                                                            
                                                            </>
                                                        ) : (item.ratingSum / item.ratingCount) <= 1 ? (
                                                            <>
                                                                <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStar/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 3, fontSize: 16}}>({item.ratingCount})</span>{' '}
                                                            </>
                                                        ) : (item.ratingSum / item.ratingCount) <= 1.5 ? (
                                                            <>
                                                                <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod', fontSize: 16}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarHalf/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 2, fontSize: 15}}>({item.ratingCount})</span>{' '}
                                                            </>
                                                        ) : (item.ratingSum / item.ratingCount) <= 2 ? (
                                                            <>
                                                                <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod', fontSize: 16}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 2, fontSize: 15}}>({item.ratingCount})</span>{' '}
                                                            </>
                                                        ) : (item.ratingSum / item.ratingCount) <= 2.5 ? (
                                                            <>
                                                                <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod', fontSize: 16}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarHalf/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 2, fontSize: 15}}>({item.ratingCount})</span>{' '}
                                                            </>
                                                        ) : (item.ratingSum / item.ratingCount) <= 3 ? (
                                                            <>
                                                                <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod', fontSize: 16}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 2, fontSize: 15}}>({item.ratingCount})</span>{' '}
                                                            </>
                                                        ) : (item.ratingSum / item.ratingCount) <= 3.5 ? (
                                                            <>
                                                                <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod', fontSize: 16}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarHalf/> <BsStar/></span> <span style={{position: "relative", top: 2, fontSize: 15}}>({item.ratingCount})</span>{' '}
                                                            </>
                                                        ) : (item.ratingSum / item.ratingCount) <= 4 ? (
                                                            <>
                                                                <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod', fontSize: 16}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStar/></span> <span style={{position: "relative", top: 2, fontSize: 15}}>({item.ratingCount})</span>{' '}
                                                            </>
                                                        ) : (item.ratingSum / item.ratingCount) <= 4.5 ? (
                                                            <>
                                                                <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod', fontSize: 16}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarHalf/></span> <span style={{position: "relative", top: 2, fontSize: 15}}>({item.ratingCount})</span>{' '}
                                                            </>
                                                        ) : (item.ratingSum / item.ratingCount) > 4.5 ? (
                                                            <>
                                                                <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod', fontSize: 16}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/></span> <span style={{position: "relative", top: 2, fontSize: 15}}>({item.ratingCount})</span>{' '}
                                                            </>
                                                        ) : (
                                                            <>
                                                                
                                                            </>
                                                        ) }
                            
                        </p>


                                                    {item.productBadge ? (
                                                        <div> 
                                                            { item.productDiscount <= 9 ? ( 
                                                                <Badge className="rounded-0" bg="warning">{item.productBadge}</Badge> 
                                                            ) : (
                                                                <><Badge className="rounded-0" bg="warning">{item.productBadge}</Badge> <Badge className="rounded-0" bg="danger">-{item.productDiscount}%</Badge></> 
                                                            ) } 
                                                        </div>
                                                    ) : (
                                                       
                                                        <div> 
                                                            { item.productDiscount <= 9 ? ( 
                                                    
                                                                <>
                                                                </>
                                                                // <Badge className="rounded-0" bg="warning">{item.productBadge}</Badge>
                                                                
                                                            ) : (
                                                                <><Badge className="rounded-0" bg="warning">{item.productBadge}</Badge> <Badge className="rounded-0" bg="danger">- {item.productDiscount}%</Badge></> 
                                                            ) } 
                                                        </div>
                                                        
                                                    )}

                                                    <br/>
                    

                        

                                                        <h4>{formatter.format(productPrice)} <del style={{color: "gray"}}>{formatter.format(productFullPrice)}</del></h4>
                        
                           
                        <p>
                        <strong>ค่าจัดส่ง:</strong> {productShippingCost}<br/>
                        <strong>จัดส่งภายใน:</strong> 1-2 วัน<br/>
                        <strong>จำนวนสินค้า:</strong><span style={{color:"green"}}> {productQuantity}</span><br/>
                        <strong>สถานะ:</strong><span style={{color:"green"}}> สินค้าพร้อมส่ง</span><br/>
                        <strong>ขายแล้ว:</strong><span style={{color:"green"}}> {productSold}</span><br/>
                        </p>


                        <Modal 
                            show={show} 
                            onHide={handleClose} 
                            animation={false} 
                            size="sm"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>
                                <Modal.Header>
                                <Modal.Title>เพิ่มสินค้าในตะกร้าสำเร็จ</Modal.Title>
                                </Modal.Header>
                                <Modal.Body><Link to="/User/Profile" style={{textDecoration: 'none'}}>สินค้าในตะกร้าของฉัน</Link></Modal.Body>
                                <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    ปิด
                                </Button>
                                </Modal.Footer>
                        </Modal>

                        {productQuantity != 0 ? (

                            <>
                            <ButtonGroup className="mb-2">
                                <Button onClick={() => setCount((c) => c - 1)} variant="light" className="rounded-0"><strong> - </strong></Button>
                                <Button variant="" className="rounded-0" style={{width: 80}}>{count}</Button>
                                <Button onClick={() => setCount((c) => c + 1)} variant="light" className="rounded-0"><strong> + </strong></Button>
                            </ButtonGroup>

                            <br/>
                            <br/>

                            <Link to={'/CheckoutDetail/Cart/'+ productId} state={count}><Button variant="dark" className="rounded-0"><span style={{color:"white"}}>ซื้อสินค้า</span></Button></Link>{' '}
                    
                            <Button onClick={submitCartHandler} variant="light" className="btn btn-outline-dark rounded-0">เพิ่มใส่ตระกร้า</Button>{' '}

                            </>

                        ) : (

                            <Button variant="outline-danger" className="rounded-0" disabled>สินค้าหมด</Button>
                            
                        )}

                        {/* {!show && <Button onClick={() => setShow(true)}>Show Alert</Button>} */}

                        <br/>
                        <br/>

                        <h3 className="pb-3 border-bottom"></h3>
                        <a href="https://www.facebook.com/people/SelPage/100085795429971/" target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: "salmon"}}><p><FaFacebookMessenger/> แชทกับร้านค้า</p></a>
                        
                    </Col>

                </Row>

                <br/>
            

                <Container>
                    <br/>

                    <Tabs defaultActiveKey="detail" id="uncontrolled-tab-example" className="mb-3">
                        <Tab eventKey="detail" title="รายละเอียด">
                            
                                {/* Row 2 Product Detail */}
                                <h3><strong>รายละเอียดสินค้า</strong></h3>

                                
                                {!!productDoc[0] ? (
                                    <>
                                    <br/>
                                    <a href={productDoc[0]} target="_blank" rel="noreferrer"><Button variant="outline-info" className="rounded-0">ตัวอย่างหนังสือ/ทดลองอ่าน</Button></a>
                                    <br/>
                                    </>
                                ) : (
                                    <></>
                                )}
                                
                                <br/>

                                <strong>วันวางจำหน่าย:</strong> {productRelease}<br/>
                                <strong>จำนวนหน้า:</strong> {numberOfPages} <br/><br/>
                                
                                <p style={{whiteSpace: "pre-line"}}>{showMore ? <>{productDetail.replaceAll("<br/>", "\n").substring(0, 500)} ...</> : productDetail.replaceAll("<br/>", "\n") }  </p>
                                    <Button variant="outline-dark" className="rounded-0" onClick={() => setShowMore(!showMore)}>
                                        {showMore ? "อ่านเพิ่มเติม" : "อ่านน้อยลง"}
                                    </Button>

                                <br/>
                                <br/>
                            
                            
                        </Tab>

                        <Tab eventKey="sample" title="ภาพตัวอย่างสินค้า">
        
                            <h3><strong>ภาพตัวอย่างสินค้า</strong></h3>
                            <br/>

                            {!!productImage[1] ? (
                                <>
                                <img src={productImage[1]} style={{width: '100%', height: 'auto', marginBottom: 30}} rounded />
                                <img src={productImage[2]} style={{width: '100%', height: 'auto', marginBottom: 30}} rounded />
                                <img src={productImage[3]} style={{width: '100%', height: 'auto', marginBottom: 30}} rounded />
                                <img src={productImage[4]} style={{width: '100%', height: 'auto', marginBottom: 30}} rounded />
                                <img src={productImage[5]} style={{width: '100%', height: 'auto', marginBottom: 30}} rounded />
                                </>
                            ) : (
                                <p>ไม่มีภาพตัวอย่างสินค้า</p>
                            )}
                            
                            <br/>
                        </Tab>

                    </Tabs>

                    <h3 className="pb-3 border-bottom"></h3>
                    <br/>

                    <h3><strong>รีวิวสินค้า</strong></h3>

                    <br/>
                  
                    
                            {commentLists.map((item) => {
                                    
                                    return (
                                        <div key={item.id}>
                                    <Row xs='auto'>
                                        {/* <Col><img src={product_sample01} alt="John Doe" className="mr-3 mt-3 rounded-circle" style={{width: 60}} /> </Col> */}
                                        <Col><Button variant="dark" style={{width: 50, height: 50, marginTop: 7, fontSize: 20}} className="rounded-circle"><strong>{(item.email).slice("0", "1")}</strong></Button></Col>
                                        <Col xs={9} md={7} style={{marginTop: 5}}><strong>{item.displayName}</strong>
                                            <br/>
                                            { item.rating == 5 ? (
                                            <p><span style={{color: "orange", fontSize: 15}}><BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/></span></p>
                                            ) : item.rating == 4 ? (
                                            <p><span style={{color: "orange", fontSize: 15}}><BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStar/></span></p>
                                            ) : item.rating == 3 ? (
                                            <p><span style={{color: "orange", fontSize: 15}}><BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStar/> <BsStar/></span></p>
                                            ) : item.rating == 2 ? (
                                            <p><span style={{color: "orange", fontSize: 15}}><BsStarFill/> <BsStarFill/> <BsStar/> <BsStar/> <BsStar/></span></p>
                                            ) : (
                                            <p><span style={{color: "orange", fontSize: 15}}><BsStarFill/> <BsStar/> <BsStar/> <BsStar/> <BsStar/></span></p>
                                            )}

                                            <p style={{position: "relative", top: -10, fontSize: 12}}>{(item.commentDate).toDate().toDateString()} | {(item.commentDate).toDate().toLocaleTimeString()}</p>
                                
       
                                            {/* <p>{item.rating}</p> */}
                                            <p style={{whiteSpace: "pre-line", wordWrap: "break-word"}}>{(item.comment).replaceAll("<br/>", "\n")} </p>
                                            {/* <p>{item.comment}</p> */}

                                            <Col>
                                                {/* <Button variant="light" style={{width: 50, height: 50, marginTop: 7, fontSize: 20}} className="rounded-circle"><strong><FaRegThumbsUp/></strong></Button> */}
                                                {/* <p style={{fontSize: 18}}><strong><FaRegHeart/></strong> &nbsp;&nbsp;&nbsp;<strong><FaRegCommentAlt/></strong></p> */}
                                                <p style={{fontSize: 18}}><strong><FaRegHeart/></strong></p>
                                            </Col>
                                            
                            
                                        </Col>
                                        </Row>
                                        <h3 className="pb-3 border-bottom"></h3>
                                        <br/>
                                        </div>

                                )
                            })}

                            {currentCursor ? (
                                <Col className='float-start'>

                                    <Button variant="warning" className="rounded-0" onClick={onMoreComment}>อ่านรีวิวเพิ่มเติม</Button>
                                    
                                </Col>
                                ) : (
                                <Col className='float-start'>

                                    <Button variant="outline-secondary" className="rounded-0" disabled>ไม่มีรีวิวสินค้า</Button>
                                    
                                </Col>
                            )}

                            <br/>
                            <br/>
                            <br/>
                            <br/>


                    {!!user ? (
                        <>
                        

                    <h3><strong>เขียนรีวิว</strong></h3>

                    <form onSubmit={submitCommentHandler}>
                    { rating == 0 ? (
                        <>
                        <Button onClick={() => setRating(1)} variant="none" style={{color: "darkgray", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(2)} variant="none" style={{color: "darkgray", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(3)} variant="none" style={{color: "darkgray", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(4)} variant="none" style={{color: "darkgray", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(5)} variant="none" style={{color: "darkgray", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        </>
                    ) : rating == 1 ? (

                        <>
                        <Button onClick={() => setRating(1)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(2)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(3)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(4)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(5)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        </>

                    ) : rating == 2 ? (

                        <>
                        <Button onClick={() => setRating(1)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(2)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(3)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(4)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(5)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        </>

                    ) : rating == 3 ? (

                        <>
                        <Button onClick={() => setRating(1)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(2)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(3)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(4)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(5)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        </>

                    ) : rating == 4 ? (

                        <>
                        <Button onClick={() => setRating(1)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(2)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(3)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(4)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(5)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        </>

                    ) : (

                        <>
                        <Button onClick={() => setRating(1)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(2)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(3)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(4)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(5)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        </>

                    ) }
                        <br/>
                        <br/>

                        
                            {displayNameShown ? (
                                
                                <p onClick={toggleDisplayName}>{displayName} <span style={{fontSize: 24}}>| <FaRegEye/></span> แสดง</p>
                
                            ) : (
                                <>
                                <p onClick={toggleDisplayName}>{displayName} <span style={{fontSize: 24}}>| <FaRegEyeSlash/></span> ไม่แสดง</p>
                                </>
                            )}
                        

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            {/* <Form.Label>Example textarea</Form.Label> */}
                            {/* <Form.Control className="rounded-0" as="textarea" value={comment} rows={3} placeholder="รีวิว..." /> */}
                            <Form.Control 
                                as="textarea" 
                                className="rounded-0"
                                style={{ whiteSpace: 'pre-line'}}
                                placeholder="รีวิว..."
                                rows={3} 
                                value={comment}
                                onChange={(e) => {
                                    setComment(e.target.value);
                                }}
                                        // placeholder={item.address} 
                            />
                        </Form.Group>

                        <Button variant="dark" className="rounded-0" type="submit">ส่งรีวิว</Button>
                    </form>
                        </>
                    ) : (
                        <Link to={"/LogIn"}><Button variant="dark" className="rounded-0">เข้าสู่ระบบเพื่อรีวิว</Button></Link>
                    )}
                    



                    <br/>
                    <br/>
                    <br/>

                </Container>
                    
               {/*  <h3><strong>สินค้าใกล้เคียง</strong></h3>
                <br/>

                    <Tabs defaultActiveKey="business" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="technology" title="เทคโนโลยี">

                        <Computer/>
                    </Tab>
                    <Tab eventKey="business" title="ธุรกิจการลงทุน">

                        <Business/>
                    </Tab>
                    <Tab eventKey="law" title="กฎหมาย">

                
                        <Law/>
                    </Tab>
                    </Tabs>
                    
                    <br/>


                <br/>
                <br/> */}

            
                {/* <h3 className="pb-3 border-bottom">รีวิว (4)</h3>

                <br/>

                <Card border="secondary" >
                    <Card.Header>
                        wirat wisakasard<br/>
                        <FontAwesomeIcon icon={faStar} size="6px"/>
                        <FontAwesomeIcon icon={faStar} size="6px"/>
                        <FontAwesomeIcon icon={faStar} size="6px"/>
                        <FontAwesomeIcon icon={faStar} size="6px"/>
                        <FontAwesomeIcon icon={faStar} size="6px"/>
                    </Card.Header>
                    <Card.Body>
                    <Card.Title>แนะนำครับ</Card.Title>
                    <Card.Text>
                        หนังสือดีมาก จัดส่งเร็วแนะนำเลยครับ
                    </Card.Text>
                    <p>,3 พฤษภาคม 2566</p>
                    </Card.Body>
                </Card>
                <br />

                <Card border="secondary" >
                    <Card.Header>
                        Theerawat F.<br/>
                        <FontAwesomeIcon icon={faStar} size="6px"/>
                        <FontAwesomeIcon icon={faStar} size="6px"/>
                        <FontAwesomeIcon icon={faStar} size="6px"/>
                        <FontAwesomeIcon icon={faStar} size="6px"/>
                        <FontAwesomeIcon icon={faStar} size="6px"/>
                    </Card.Header>
                    <Card.Body>
                    <Card.Title>แนะนำครับ</Card.Title>
                    <Card.Text>
                        หนังสือดีมาก จัดส่งเร็วแนะนำเลยครับ
                    </Card.Text>
                    <p>,3 พฤษภาคม 2566</p>
                    </Card.Body>
                </Card>
                <br />

                <Card border="secondary" >
                    <Card.Header>
                        F - 22<br/>
                        <FontAwesomeIcon icon={faStar} size="6px"/>
                        <FontAwesomeIcon icon={faStar} size="6px"/>
                        <FontAwesomeIcon icon={faStar} size="6px"/>
                        <FontAwesomeIcon icon={faStar} size="6px"/>
                        <FontAwesomeIcon icon={faStar} size="6px"/>
                    </Card.Header>
                    <Card.Body>
                    <Card.Title>แนะนำครับ</Card.Title>
                    <Card.Text>
                        หนังสือดีมาก จัดส่งเร็วแนะนำเลยครับ
                    </Card.Text>
                    <p>,3 พฤษภาคม 2566</p>
                    </Card.Body>
                </Card>
                <br /> */}

            </Container>

            

            </div>

            );
        })}

        </>
        

    );



}


export default ProductDetail;