import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Card, Button } from 'react-bootstrap';

function Order() {

  
  return (

    <Container>
    <br/>
    <br/>

    <h3><strong>การสั่งซื้อ</strong></h3>
    {/* <p>จำนวนสั่งซื้อ: 3 รายการ | ยอดรวม: 1,350 บาท </p> */}
    <h3 className="pb-3 border-bottom"></h3>
    <br/>
    <Card className="rounded-0">
      <Card.Header>
        {/* <Card.Title>ข้อมูลคำสั่งซื้อ</Card.Title> */}
        <strong>คำสั่งซื้อที่:</strong> xx785dfsfsfsfsfsfsdfsd<br/>
        <strong>รหัสผู้ซื้อ:</strong> hfghfghfhfghfhfhfh<br/>
        <strong>รหัสผู้ขาย:</strong> gfdgfdfdhgfdfggdfgd<br/>
        <strong>ชื่อสินค้า:</strong> ต้นมอนสเตอร่า<br/>
        <strong>จำนวนเงิน:</strong> 450 บาท<br/>
        11 - 05 - 2566 | 9.57 AM<br/><br/>
        <a href="">imageTranferSlip01.jpg</a><br/><br/>
        <Button className="btn btn-success rounded-0">จัดส่งแล้ว</Button>{' '}
        <Button className="btn btn-danger rounded-0">ลบรายการ</Button><br/><br/>
      </Card.Header>
      <Card.Body>
        <Card.Title>ชื่อที่อยู่ผู้รับ:</Card.Title>
        <blockquote className="blockquote mb-0">
          <p>
            {' '}
            นายวิรัช  วิสาขศาสตร์, 0805514821, <br/>199/288 แขวงสีกัน เขตดอนเมือง กรุงเทพฯ 10210{' '}
          </p>
          {/* <footer className="blockquote-footer">
            Someone famous in <cite title="Source Title">Source Title</cite>
          </footer> */}
        </blockquote>
      </Card.Body>
      <Card.Footer className="text-muted">
        <strong>คำสั่งซื้อที่:</strong> xx785dfsfsfsfsfsfsdfsd<br/>
        <strong>ร้านค้า:</strong> SelPage Store<br/>
        {/* <strong>ชื่อสินค้า:</strong> ต้นมอนสเตอร่า<br/> */}
      </Card.Footer>
    </Card>

    <br/>
    <br/>

    <Card className="rounded-0">
      <Card.Header>
        {/* <Card.Title>ข้อมูลคำสั่งซื้อ</Card.Title> */}
        <strong>คำสั่งซื้อที่:</strong> xx785dfsfsfsfsfsfsdfsd<br/>
        <strong>รหัสผู้ซื้อ:</strong> hfghfghfhfghfhfhfh<br/>
        <strong>รหัสผู้ขาย:</strong> gfdgfdfdhgfdfggdfgd<br/>
        <strong>ชื่อสินค้า:</strong> ต้นมอนสเตอร่า<br/>
        <strong>จำนวนเงิน:</strong> 450 บาท<br/>
        11 - 05 - 2566 | 9.57 AM<br/><br/>
        <a href="">imageTranferSlip01.jpg</a><br/><br/>
        <Button className="btn btn-success rounded-0">จัดส่งแล้ว</Button>{' '}
        <Button className="btn btn-danger rounded-0">ลบรายการ</Button><br/><br/>
      </Card.Header>
      <Card.Body>
        <Card.Title>ชื่อที่อยู่ผู้รับ:</Card.Title>
        <blockquote className="blockquote mb-0">
          <p>
            {' '}
            นายวิรัช  วิสาขศาสตร์, 0805514821, <br/>199/288 แขวงสีกัน เขตดอนเมือง กรุงเทพฯ 10210{' '}
          </p>
          {/* <footer className="blockquote-footer">
            Someone famous in <cite title="Source Title">Source Title</cite>
          </footer> */}
        </blockquote>
      </Card.Body>
      <Card.Footer className="text-muted">
        <strong>คำสั่งซื้อที่:</strong> xx785dfsfsfsfsfsfsdfsd<br/>
        <strong>ร้านค้า:</strong> SelPage Store<br/>
        {/* <strong>ชื่อสินค้า:</strong> ต้นมอนสเตอร่า<br/> */}
      </Card.Footer>
    </Card>

    <br/>
    <br/>

    <Card className="rounded-0">
      <Card.Header>
        {/* <Card.Title>ข้อมูลคำสั่งซื้อ</Card.Title> */}
        <strong>คำสั่งซื้อที่:</strong> xx785dfsfsfsfsfsfsdfsd<br/>
        <strong>รหัสผู้ซื้อ:</strong> hfghfghfhfghfhfhfh<br/>
        <strong>รหัสผู้ขาย:</strong> gfdgfdfdhgfdfggdfgd<br/>
        <strong>ชื่อสินค้า:</strong> ต้นมอนสเตอร่า<br/>
        <strong>จำนวนเงิน:</strong> 450 บาท<br/>
        11 - 05 - 2566 | 9.57 AM<br/><br/>
        <a href="">imageTranferSlip01.jpg</a><br/><br/>
        <Button className="btn btn-success rounded-0">จัดส่งแล้ว</Button>{' '}
        <Button className="btn btn-danger rounded-0">ลบรายการ</Button><br/><br/>
      </Card.Header>
      <Card.Body>
        <Card.Title>ชื่อที่อยู่ผู้รับ:</Card.Title>
        <blockquote className="blockquote mb-0">
          <p>
            {' '}
            นายวิรัช  วิสาขศาสตร์, 0805514821, <br/>199/288 แขวงสีกัน เขตดอนเมือง กรุงเทพฯ 10210{' '}
          </p>
          {/* <footer className="blockquote-footer">
            Someone famous in <cite title="Source Title">Source Title</cite>
          </footer> */}
        </blockquote>
      </Card.Body>
      <Card.Footer className="text-muted">
        <strong>คำสั่งซื้อที่:</strong> xx785dfsfsfsfsfsfsdfsd<br/>
        <strong>ร้านค้า:</strong> SelPage Store<br/>
        {/* <strong>ชื่อสินค้า:</strong> ต้นมอนสเตอร่า<br/> */}
      </Card.Footer>
    </Card>

    <br/>
    <br/>
    </Container>
  );
}

export default Order;