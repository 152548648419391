import React, { useState, useEffect, useContext } from "react";
import { Container, Tabs, Tab, Form, Col, Row, Button, Card } from "react-bootstrap";
import { Link, redirect, useNavigate, useLocation } from "react-router-dom";

import { FaSketch, FaPlusCircle } from "react-icons/fa";

import { auth, firestore } from "../../database/firebase";
// import OrderCustomer from "./OrderCustomer";
// import Cart from "./Cart";

// import OrderHistory from "../../component_m/Order/OrderHistory";

// import OrderHistory from "./OrderHistory";

import { AuthContext } from "../../component_s/Authentication/AuthProvider";
/* import OrderCustomerCart from "./OrderCustomerCart";
import OrderHistoryCart from "./OrderHistoryCart"; */

function EditProductDetail() {

    const navigate = useNavigate();

    let location = useLocation();

    const refreshPage = () => {
        navigate(0);
    }

    /* TEST QUERY DATA WITH productID */

    // const [uid, setUid] = useState("");

    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

   //const [uid, setUid] = useState("");

    var user = auth.currentUser;
    // var uid = "";
    var uid = "";

    if (user != null) {
        //email = user.email;
        uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                         // this value to authenticate with your backend server, if
                         // you have one. Use User.getToken() instead.
        console.log(uid);
        
        //TEST AUTH
        // refreshPage();
    } else {
        navigate("/");
    }
    



    

    // const [emailDefault, setEmailDefault] = useState("");

    // const [loading, setLoading] = useState(false);

    const [productID, setProductID] = useState("");
    const [productName, setProductName] = useState("");
    const [productBadge, setProductBadge] = useState("");
    const [productFullPrice, setProductFullPrice] = useState("");
    const [productDiscount, setProductDiscount] = useState("");
    const [productPrice, setProductPrice] = useState("");
    const [productSold, setProductSold] = useState("");
    const [productQuantity, setProductQuantity] = useState("");
    const [productDetail, setProductDetail] = useState("");

    const [ratingCount, setRatingCount] = useState("");
    const [ratingSum, setRatingSum] = useState("");
    

  /*   const [role, setRole] = useState(""); */
    // const [sPoint, setSPoint] = useState(""); 

    const [data, setData] = useState([]);
    useEffect(() => {
    const productRef = firestore.collection("product");
    const query = productRef
    
        .where("productID", "==", location.state);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {

                            productID: doc.data().productID,
                            productName: doc.data().productName,
                            productBadge: doc.data().productBadge,
                            productFullPrice: doc.data().productFullPrice,
                            productDiscount: doc.data().productDiscount,
                            productPrice: doc.data().productPrice,
                            productQuantity: doc.data().productQuantity,
                            productSold: doc.data().productSold,   
                            productDetail: doc.data().productDetail,

                            ratingCount: doc.data().ratingcount,
                            ratingSum: doc.data().ratingSum,
                            
                        },
                        
                    ];

                    setProductID(doc.data().productID);
                    setProductName(doc.data().productName);
                    setProductBadge(doc.data().productBadge);
                    setProductFullPrice(doc.data().productFullPrice);
                    setProductDiscount(doc.data().productDiscount)
                    setProductPrice(doc.data().productPrice);
                    setProductQuantity(doc.data().productQuantity);
                    setProductSold(doc.data().productSold);
                    setProductDetail(doc.data().productDetail);

                    setRatingCount(doc.data().ratingCount);
                    setRatingSum(doc.data().ratingSum);
                    

                    };

                });

            setData((oldDataArray) => tempDataArray);
            

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [productID]); 

    

    const editHandler = (obj) => {
        
        const ref = firestore.collection("product");
        ref
            .doc(productID)
            .update(obj)
            .then(() => {
                console.log("แก้ไขข้อมูลสำเร็จ");
                navigate(-1);
            })
            .catch((err) => console.log(err));
    };

    const submitHandler = (e) => {
        e.preventDefault();
        const obj = {
            
            productName : productName,
            productBadge : productBadge,
            productFullPrice : productFullPrice,
            productDiscount: productDiscount,
            productPrice: productPrice,
            productQuantity: productQuantity,
            productSold: productSold,

            ratingCount: ratingCount,
            ratingSum: ratingSum,

        };
      
        setProductName("");
        setProductBadge("");
        setProductFullPrice("");
        setProductDiscount("");
        setProductPrice("");
        setProductQuantity("");
        setProductSold("");

        setRatingCount("");
        setRatingSum("");

        editHandler(obj);
    };

    /* const myStore = () => {
        if (role == "merchant") {
            navigate("/MerchantView")
        } else {
            alert("ร้านค้าของฉันพร้อมเปิดให้บริการเร็วๆ นี้, ขออภัยในความไม่สะดวก");
        }
    } */

    const signOutHandler = () => {
        auth.signOut().then(() => {
            console.log("Log Out Ok");
            setCurrentUser(null);
            navigate("/");
        })
        .catch((err) => {
            console.log("Log Out Not Ok." + err);
        })
    };

    useEffect(() => {
        setProductPrice(() => parseInt(productFullPrice / 100 * (100 - productDiscount)));
      }, [productDiscount]); // <- add the count variable here

    

    return (
        <>
        <br/>

        {data.map((item, index) => {

            

            return (
                            
                <div key={index}>    

                

                <form onSubmit={submitHandler}>
                                
                    <Container>

                        <br/>
                        <Link to="/User/Profile" style={{textDecoration: 'none', color: "black"}}> <Button variant="outline-secondary">ข้อมูลของฉัน</Button>{' '}</Link>
                        
                        <Button onClick={signOutHandler} variant="outline-danger" type="submit">ออกจากระบบ</Button>
                        <br/>
                        <br/>
                        <br/>
                        
                        <h3><strong>ข้อมูลสินค้า</strong></h3>
                        <p><Link to= "/productList" style={{textDecoration: 'none'}}>สินค้าทั้งหมด</Link> | <Link to= "/AddProduct" style={{textDecoration: 'none'}}> <FaPlusCircle /> เพิ่มสินค้า</Link> </p>
                        {/* <Link to="/MerchantView" style={{textDecoration: 'none', color: "black"}}> <Button variant="outline-secondary">ร้านค้าของฉัน</Button>{' '}</Link> */}
                        <br/>
                        {/* <Link to="/AboutUs/SelpagePoint" style={{textDecoration: 'none', color: "black"}}><h4><span style={{color: "black"}}><FaSketch/></span> S-Point: <span style={{color: "limegreen"}}>{sPoint}</span></h4></Link> */}

                        {/* Test Order View */}
                        {/* <Link to="/Order">ร้านค้าของฉัน</Link> */}

                        <h3 className="pb-3 border-bottom"></h3>

                        <br/>
                        <Tabs defaultActiveKey="productdetail" id="uncontrolled-tab-example" className="mb-3">
                                    
                            <Tab eventKey="productdetail" title="แก้ไขสินค้า">

                                <Row>
                                        
                                    <Col sm={6}>
                                        <h4>ชื่อสินค้า</h4>
                                        <Form.Control 
                                            type="text"
                                            className="rounded-0" 
                                            value={productName}
                                            onChange={(e) => {
                                                setProductName(e.target.value);
                                            }}
                                            // placeholder={item.name}
                                        />
                                        <br />
                                        
                                        <h4>ป้ายสินค้า</h4>
                                        <Form.Control 
                                            type="text" 
                                            className="rounded-0"
                                            value={productBadge}
                                            onChange={(e) => {
                                                setProductBadge(e.target.value);
                                            }}
                                            // placeholder={item.phonenumber} 
                                            
                                        />
                                        <br />

                                        <h4>ราคาเต็ม</h4>
                                        <Form.Control 
                                            type="text" 
                                            className="rounded-0"
                                            value={productFullPrice}
                                            onChange={(e) => {
                                                setProductFullPrice(e.target.value);
                                            }}
                                            // placeholder={item.email} 
                                        />
                                        <br />

                                        <h4>ส่วนลด -%</h4>
                                        <Form.Control 
                                            type="text" 
                                            className="rounded-0"
                                            value={productDiscount}
                                            onChange={(e) => {
                                                setProductDiscount(e.target.value);
                                            }}
                                            // placeholder={item.email} 
                                        />
                                        <br />

                                        <h4>ราคาขายจริง</h4>
                                        <Form.Control 
                                            type="text" 
                                            className="rounded-0"
                                            value={productPrice}
                                            onChange={(e) => {
                                                setProductPrice(e.target.value);
                                            }}
                                            // placeholder={item.email} 
                                        />
                                        <br />

                                        <h4>จำนวนที่วางขาย</h4>
                                        <Form.Control 
                                            type="text" 
                                            className="rounded-0"
                                            value={productQuantity}
                                            onChange={(e) => {
                                                setProductQuantity(e.target.value);
                                            }}
                                            // placeholder={item.email} 
                                        />
                                        <br />

                                        <h4>ขายแล้ว</h4>
                                        <Form.Control 
                                            type="text" 
                                            className="rounded-0"
                                            value={productSold}
                                            onChange={(e) => {
                                                setProductSold(e.target.value);
                                            }}
                                            // placeholder={item.email} 
                                        />
                                        <br />

                                        <h4>จำนวนรีวิว</h4>
                                        <Form.Control 
                                            type="text" 
                                            className="rounded-0"
                                            value={ratingCount}
                                            onChange={(e) => {
                                                setRatingCount(e.target.value);
                                            }}
                                            // placeholder={item.email} 
                                        />
                                        <br />

                                        <h4>คะแนนรีวิว</h4>
                                        <Form.Control 
                                            type="text" 
                                            className="rounded-0"
                                            value={ratingSum}
                                            onChange={(e) => {
                                                setRatingSum(e.target.value);
                                            }}
                                            // placeholder={item.email} 
                                        />
                                        <br />
                                    </Col>

                                    <Col sm={6}>
                                        <h4>รายละเอียดสินค้า</h4>
                                        <Form.Control 
                                            as="textarea" 
                                            className="rounded-0"
                                            rows={5} 
                                            value={productDetail}
                                            onChange={(e) => {
                                                setProductDetail(e.target.value);
                                            }}
                                            // placeholder={item.address} 
                                        />
                                        <br />

                                        {/* <Card className="text-center">
                                            
                                            <Card.Body>
                                                <Card.Title><h4><FaSketch/> S-Points: <span style={{color: "limegreen"}}>40</span></h4></Card.Title>
                                            </Card.Body>
                                            
                                        </Card> */}

                                    </Col>

                                </Row>

                                    <br/>
                                    {/* <Button className="btn btn-success rounded-0">บันทึก</Button> */}

                                    {/* <Button onClick={((e) => (setName(item.name), setPhonenumber(item.phonenumber), setEmail(item.email), setAddress(item.address) ))} className="btn btn-success rounded-0" type="submit">บันทึก</Button> */}
                                
                                    <Button className="btn btn-success rounded-0" type="submit">บันทึก</Button>
                                    
                                    <br/>
                            </Tab>
                            

                            {/* <Tab eventKey="order" title="การซื้อของฉัน">
                                <OrderCustomer />
                                <OrderCustomerCart />
                                <OrderHistory />
                                <OrderHistoryCart />
                                
                            </Tab>

                            <Tab eventKey="cart" title="ตะกร้าสินค้า">
                                <Cart />
                            </Tab> */}

                        </Tabs>
                                    
                    </Container>

                </form>
                    
                
                               
                </div>
                            
                             
                            
            );
        })}


        <br/>
        <br/>
      

        </>
    );
}

export default EditProductDetail;