import React, { useState, useEffect, useContext } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./coursepreview.css";

import { Container, Image, Row, Col, Card, Tabs, Tab, Table, Button, Form, Alert, ButtonGroup, Badge, Modal, Accordion } from 'react-bootstrap';

import { auth, firestore } from "../../database/firebase";
import { AuthContext } from "../../component_s/Authentication/AuthProvider";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";

import product_sample01 from '../../component_s/images_s/product_sample01.jpeg';
/* import product_sample01 from '../images_s/product_sample01.jpeg';
import product_sample02 from '../images_s/product_sample02.jpg';
import product_sample03 from '../images_s/product_sample03.jpg'; */

import { FaHeart, FaTruck, FaFacebookMessenger, FaStar, FaRegEye, FaRegEyeSlash, FaRegThumbsUp, FaThumbsUp, FaReply, FaRegHeart, FaRegCommentAlt } from "react-icons/fa";
import { BsStarFill, BsStarHalf, BsStar, BsPatchCheck, BsPlayBtn, BsPhone, BsFolder2Open, BsInfinity, BsCheckLg, BsPeopleFill, BsFillPlayBtnFill, BsChatLeftDotsFill } from "react-icons/bs";

//Test useParams
import { useParams, Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";



/* import Computer from "./Book/Computer";
import Business from "./Book/Business";
import Law from "./Book/Law"; */

function CoursePreview() {

    //Test useParams
    let { courseId } = useParams();

    // let productID = "bz0HfOnJEwc8tZE3m6qO";

    const formatter = new Intl.NumberFormat('th', {
        style: 'currency',
        currency: 'THB',
        maximumSignificantDigits: 3,
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });


        let location = useLocation();

        const [data, setData] = useState([]);

        const [merchantID, setMerchantID] = useState("");
        const [storeName, setStoreName] = useState("");
        const [courseCategory, setCourseCategory] = useState("");
        const [courseSubCategory, setCourseSubCategory] = useState("");
        const [courseTypeCategory, setCourseTypeCategory] = useState("");
        const [courseCaption, setCourseCaption] = useState("");
        const [courseID, setCourseID] = useState("");
        const [courseName, setCourseName] = useState("");
        const [courseBadge, setCourseBadge] = useState("");
        const [courseFullPrice, setCourseFullPrice] = useState("");
        const [coursePrice, setCoursePrice] = useState("");
        const [courseImage, setCourseImage] = useState("");

        //
        const [courseIntro, setCourseIntro] = useState("");

        const [courseSectionExVideo, setCourseSectionExVideo] = useState("");
        const [videoSrc, setVideoSrc] = useState("");

        const [courseExVideo, setCourseExVideo] = useState("");
        const [courseDate, setCourseDate] = useState("");
        const [courseTopic, setCourseTopic] = useState("");
        const [courseStatus, setCourseStatus] = useState("");
        //
        const [courseDetail, setCourseDetail] = useState("");
        const [courseDiscount, setCourseDiscount] = useState("");

        const [courseSold, setCourseSold] = useState("");
        const [courseDoc, setCourseDoc] = useState("");

        //RATING
        const [ratingSum, setRatingSum] = useState(0);
        const [ratingCount, setRatingCount] = useState(0);

        //COMMENT
        //SHOW DISPLAY NAME
        const [displayNameShown, setDisplayNameShown] = useState(true);
        
        //READ MORE
        const [showMore, setShowMore] = useState(true);

        const [userID, setUserID] = useState("");

        const [currentUser, setCurrentUser] = useState(null);
        const authContext = useContext(AuthContext);

        const [displayName, setDisplayName] = useState("");
    
        var user = auth.currentUser;
        var email, uid, name;
        var hiddenEmail;
        var hiddenDisplayName;


        if (user != null) {
          
            user.reload();
            // user.updateProfile(auth.currentUser, {displayName});
            uid = user.uid;
            email = user.email;
            /* name = user.displayName;
            console.log(name); */
            // setUserID(uid);

            hiddenEmail = email.charAt(0) + email.replace(/./g, '*') + email.charAt(email.length-1)

            
        } else {
            // signUpPage();
        }

          /* useEffect(() => {
        
            if (!!user) {
                setDisplayName(email);
            } else {
                
            }
         
          }, [user]);  */

        useEffect(() => {
        const productRef = firestore.collection("course");
        const query = productRef
        
            .where("courseID", "==", courseId);
                query
                .get()
                .then((querySnapshot) => {
                    let tempDataArray =[];
                    querySnapshot.forEach((doc) => {
                        if (doc.exists) {
                            tempDataArray = [...tempDataArray,
                            {
                                id: doc.id,
                                merchantID: doc.data().merchantID,
                                storeName: doc.data().storeName,
                                courseCategory: doc.data().courseCategory,
                                courseSubCategoty: doc.data().courseSubCategory,
                                courseTypeCategory: doc.data().courseTypeCategory,
                                //
                                // courseDate: doc.data().courseDate,
                                //
                                courseName: doc.data().courseName,
                                courseCaption: doc.data().courseCaption,
                                courseTopic: doc.data().courseTopic,
                                courseBadge: doc.data().courseBadge,
                                courseFullPrice: doc.data().courseFullPrice,
                                coursePrice: doc.data().coursePrice,   
                                courseImage: doc.data().courseImage,
                                courseDetail: doc.data().courseDetail,
                                courseDiscount: doc.data().courseDiscount,
                                courseSold: doc.data().courseSold,
                                courseDoc: doc.data().courseDoc,

                                courseIntro: doc.data().courseIntro,
                                courseExVideo : doc.data().courseExVideo,
                                courseSectionExVideo : doc.data().courseSectionExVideo,
                                courseStatus: doc.data().courseStatus,

                                //RATING
                                ratingSum: doc.data().ratingSum,
                                ratingCount: doc.data().ratingCount,
                            },
                        ];


                        setMerchantID(doc.data().merchantID);
                        setStoreName(doc.data().storeName);
                        setCourseCategory(doc.data().courseCategory);
                        setCourseSubCategory(doc.data().courseSubCategory);
                        setCourseTypeCategory(doc.data().courseTypeCategory);
                        setCourseName(doc.data().courseName);
                        setCourseCaption(doc.data().courseCaption);
                        // setCourseDate(doc.data().courseDate);
                        setCourseTopic(doc.data().courseTopic);
                        setCourseBadge(doc.data().courseBadge);
                        setCourseFullPrice(doc.data().courseFullPrice);
                        setCoursePrice(doc.data().coursePrice);
                        setCourseImage(doc.data().courseImage);
                        setCourseDetail(doc.data().courseDetail);
                        setCourseDiscount(doc.data().courseDiscount);
                        setCourseSold(doc.data().courseSold);
                        setCourseDoc(doc.data().courseDoc);

                        setCourseIntro(doc.data().courseIntro);
                        setCourseExVideo(doc.data().courseExVideo);
                        setVideoSrc(doc.data().courseExVideo[0]);
                        setCourseSectionExVideo(doc.data().courseSectionExVideo);
                        setCourseStatus(doc.data().courseStatus);

                        //RATING
                        setRatingSum(doc.data().ratingSum);
                        setRatingCount(doc.data().ratingCount);
                        };

                    });

                setData((oldDataArray) => tempDataArray);

            },

                (err) => {
                    console.log(err);
                }
                
            );

        }, []);




        const navigate = useNavigate();

        const refreshPage = () => {
            navigate(0);
        }
    
        const signUpPage = () => {
            navigate("/SignUp");
        }


        //ADD TO CART/////////////////////////////////////////////////////////////////////////////////

        const [show, setShow] = useState(false);

        const addDataCartHandler = (obj) => {
           
            const ref = firestore.collection("cart");
            ref

                .add(obj)
                .then(() => {
                    console.log("เพิ่มข้อมูลสำเร็จ");

                })
                .catch((err) => console.log(err));
        };



        /* const submitCartHandler = (e) => {
            
            e.preventDefault();
     
            
            const obj = {

                userID : uid,

                merchantID: merchantID,
                productID: productId,
                productName : productName,
                productPrice : productPrice,
                productFullPrice : productFullPrice,
                productDetail: productDetail,
                productShippingCost: productShippingCost,
                productQuantity: count,

                productCategory: productCategory,
                productSubCategory: productSubCategory,

                productWeight: parseInt(productWeight) * parseInt(count),

         
                storeName: storeName,

                productImage : productImage,
                


            };


            if (user != null) {
                addDataCartHandler(obj);
                setShow(true);
     
            } else {
                alert("เข้าสู่ระบบ/สมัครใหม่ เพื่อเพิ่มสินค้าในตะกร้า");

            }


        }; */

    

    const cartAlert = () => {
        setShow(true);
    }





    //   const [show, setShow] = useState(false);

      /* const handleClose = () => setShow(false);
      const handleShow = () => setShow(true); */

      /* useEffect(() => {

        if (parseInt(productWeight * count) <= 2000) {
            setProductShippingCost(38);
        } else if (parseInt(productWeight * count) <= 3000) {
            setProductShippingCost(58);
        } else if (parseInt(productWeight * count) <= 4000) {
            setProductShippingCost(64);
        } else if (parseInt(productWeight * count) <= 5000) {
            setProductShippingCost(74);
        } else if (parseInt(productWeight * count) <= 6000) {
            setProductShippingCost(84);
        } else if (parseInt(productWeight * count) <= 7000) {
            setProductShippingCost(94);
        } else if (parseInt(productWeight * count) <= 8000) {
            setProductShippingCost(104);
        } else if (parseInt(productWeight * count) <= 9000) {
            setProductShippingCost(110);
        } else if (parseInt(productWeight * count) <= 10000) {
            setProductShippingCost(125);
        } else if (parseInt(productWeight * count) <= 11000) {
            setProductShippingCost(150);
        } else if (parseInt(productWeight * count) <= 12000) {
            setProductShippingCost(150);
        } else if (parseInt(productWeight * count) <= 15000) {
            setProductShippingCost(220);
        } else if (parseInt(productWeight * count) <= 20000) {
            setProductShippingCost(350);
        } else {
            setProductShippingCost(0);
        }
    }, [count]); */


    /////////////////////////////////////// COMMENT //////////////////////////////////////////////

    /* const [commentLists, setCommentLists] = useState([]);
    const [currentCursor, setCurrentCursor] = useState("");

    useEffect(() => {
        const firsPageRef = firestore
            .collection("commentProduct")
            .orderBy("commentDate", "desc")
            .where("productID", "==", productId)
            
            .limit(4);
        firsPageRef.get().then((querySnapshot) => {
            let tempLists = [];
            querySnapshot.docs.forEach((doc) => {
                if (doc.exists) {

                    const currentProduct = {

                        productID: doc.id,
                        productID: doc.data().productID,
                        productName: doc.data().productName,

                        displayName: doc.data().displayName,

                        storeName: doc.data().storeName,

                        email: doc.data().email,
                        comment: doc.data().comment,
                        rating: doc.data().rating,
                        commentDate: doc.data().commentDate,

                    };
                    tempLists = [...tempLists, currentProduct];
                }
            });
            setCommentLists((prv) => tempLists);
            const currentLenght = querySnapshot.docs.length;
            const currentCursorFromFirstPage = querySnapshot.docs[currentLenght - 1];
            setCurrentCursor(currentCursorFromFirstPage);
        })
    }, []);

    const onMoreComment = () => {
        const nextPageRef = firestore
            .collection("commentProduct")
            .orderBy("commentDate", "desc")
            .where("productID", "==", productId)
            
            .limit(4);
        nextPageRef.get().then((querySnapshot) => {
            const currentLenght = querySnapshot.docs.length;
            if (!currentCursor) {
                return;
            }
            const query = nextPageRef.startAfter(currentCursor);
            query.get().then((querySnapshot) => {
                let tempNewArray = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempNewArray = [
                            ...tempNewArray,
                            {
                                productID: doc.id,
                                productID: doc.data().productID,
                                productName: doc.data().productName,

                                displayName: doc.data().displayName,

                                storeName: doc.data().storeName,

                                email: doc.data().email,
                                comment: doc.data().comment,
                                rating: doc.data().rating,
                                commentDate: doc.data().commentDate,


                            },
                        ];
                    }
                });
                setCommentLists([...commentLists, ...tempNewArray]);
                const currentCursorForNextPage = querySnapshot.docs[currentLenght -1];
                setCurrentCursor(currentCursorForNextPage);
            });
        });
    };


    const id = "cmp" + Math.random(999).toString();

    const [comment, setComment] = useState("");
    const [rating, setRating] = useState(0); */

   

   /*  const addCommentHandler = (obj) => {
           
        const ref = firestore.collection("commentProduct");
        ref
            //.doc(ref)
            .doc(id)
            .set(obj)
            .then(() => {
                console.log("เพิ่มข้อมูลสำเร็จ");

            })
            .catch((err) => console.log(err));
    };


    const editProductHandler = (obj) => {
        
        const ref = firestore.collection("product");
        ref
            .doc(productId)
            .update(obj)
            .then(() => {
                console.log("แก้ไขข้อมูลสำเร็จ");
                refreshPage();
            })
            .catch((err) => console.log(err));
    };


    const submitCommentHandler = (e) => {
            
        e.preventDefault();
        
        const obj = {

            commentID: id,
            userID : uid,
            email : email,
            storeName : storeName,

            displayName : displayName,

            merchantID: merchantID,
            productID: productId,
            productName : productName,

            productImage : productImage,
            productCategory: productCategory,
            productSubCategory: productSubCategory,
            productTypeCategory: productTypeCategory,

            comment : comment,
            rating : rating,

            commentDate: new Date(),
            


        };

        setComment("");


        if (user != null) {
            addCommentHandler(obj);

            alert("เพิ่มความคิดเห็นสำเร็จ");


        } else {
            alert("เข้าสู่ระบบ/สมัครใหม่ เพื่อเพิ่มความคิดเห็น");

        }

        const objProduct = {


            ratingSum: parseInt(ratingSum) + parseInt(rating),
            ratingCount: parseInt(ratingCount) + parseInt(1),

        };

        editProductHandler(objProduct);

    }; */


        
    //SET DISPLAY NAME
     
    /* const toggleDisplayName = () => {

        setDisplayNameShown(!displayNameShown);

    };

    useEffect(() => {

        
        if (displayNameShown) {
        
            setDisplayName(email);

        } else {

            setDisplayName(hiddenEmail);

        }

    }, [displayNameShown]); */


    /* /////////////////////////////////////////// TEST QUERY COURSE CONTENT //////////////////////////////////////////////////// */

    // course Content

    const [courseData, setCourseData] = useState([]);
    var courseSectionArray = [""];
    var courseSectionSrcArray = [""];
    // const [exVideo, setExvideo] = useState("");

    const [courseSectionArrayToString, setCourseSectionArrayToString] = useState("");
        useEffect(() => {
            const userRef = firestore.collection("course").doc(courseId).collection("courseContent").orderBy("sortTopic", "asc");
            userRef.onSnapshot((snapshot) => {

                
                let tempDataArray =[];
                snapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempDataArray = [...tempDataArray,
                        {
                            id: doc.id,
                            topicID: doc.data().topicID,
                            // courseTag: doc.data().courseTag,
                            courseTopic: doc.data().courseTopic,
                            courseSrc : doc.data().courseSrc,

                            courseSection : doc.data().courseSection,

                            courseSectionSrc : doc.data().courseSectionSrc,

                            courseSectionType: doc.data().courseSectionType,

                            sortTopic: doc.data().sortTopic,
                            
                            

                        },

                        
                    ];

                    // setExvideo(doc.data().courseSectionSrc);

                    //TEST QUERY DATA
                    console.log(doc.data());

                    // console.log(doc.data().courseSectionSrc);

                    /* courseSectionArray.push(doc.data().courseSection);
                    courseSectionSrcArray.push(doc.data().courseSectionSrc);
                    console.log(courseSectionArray);
                    console.log(courseSectionSrcArray); */

                    /* courseSectionArray.push(doc.data().courseSection);
                    console.log(courseSectionArray);
                    
                    setCourseSectionArrayToString(courseSectionArray.toString());
                    console.log(courseSectionArrayToString); */

                    
                       

                            

                  
                    
                    
                    };

        });
            setCourseData((oldDataArray) => tempDataArray);
            /* courseSectionArray.forEach(function(value, index) {
                console.log(value, index);
            }); */
    },
        (err) => {
            console.log(err);
        }
    );

    }, []);
    


    

    // TOPIC
    /* const [topicID, setTopicID] = useState("");
    const [courseTopicData, setCourseTopicData] = useState([]);
        useEffect(() => {
            const userRef = firestore.collection("course").doc(courseId).collection("courseTopic");
            userRef.onSnapshot((snapshot) => {
                let tempDataArray =[];
                snapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempDataArray = [...tempDataArray,
                        {
                            id: doc.id,
                            topicID: doc.data().topicID,
                            courseTag: doc.data().courseTag,
                            courseTopic: doc.data().courseTopic, 

                        },
                    ];

                    
                    console.log(doc.data());
                    setTopicID(doc.data().topicID);
                    
                    };

        });
            setCourseTopicData((oldDataArray) => tempDataArray);
    },
        (err) => {
            console.log(err);
        }
    );

    }, []); */

    // SECTION
    /* const [section, setSection] = useState("");
    var sectionArray = [""];
    const [courseSectionData, setCourseSectionData] = useState([]);
        useEffect(() => {
            const userRef = firestore.collection("course").doc(courseId).collection("courseSection").orderBy("topicID");
            userRef.onSnapshot((snapshot) => {
                let tempDataArray =[];
                snapshot.forEach((doc) => {
                    if (doc.exists) {

                        sectionArray.push(doc.data().courseSection);
                        setSection(sectionArray.toString());

                        tempDataArray = [...tempDataArray,
                        {
                            id: doc.id,
                            topicID: doc.data().topicID,
                            courseTag: doc.data().courseTag,
                            courseSection: doc.data().courseSection,

                           

                        },
                    ];

                    
                    console.log(doc.data());
                    
                    
                    };

        });
            setCourseSectionData((oldDataArray) => tempDataArray);
    },
        (err) => {
            console.log(err);
        }
    );

    }, []); */


    ///////////////////////////////////////////// EXAMPLE VIEW COURSE ///////////////////////////////////////////////////////////
    const [showExVideo, setShowExVideo] = useState(false);

    const handleClose = () => setShowExVideo(false);
    const handleShow = () => setShowExVideo(true);

    const exVideoHandler = () => {
        setShowExVideo(true);
    }

    
    
    // setVideoSrc(courseExVideo[0]);

    /* useEffect(() => {
        
        if (videoSrc == courseExVideo[1]) {
            setVideoSrc(courseExVideo[1])
        }

    }, [videoSrc]); 

    const playExVideoHandler = () => {
        setVideoSrc(courseExVideo[1]);
    } */

 

    return (

        <>
        
        {data.map((item, index) => {

            
        return (
                        
            <div key={index}>


            <Container w-75x>

                <br/>

                <p><strong><Link to={"/ProductCategory"} state={courseCategory} style={{textDecoration: 'none', color: "darkblue"}}>{courseCategory}</Link> {">"} <Link to={"/ProductSubCategory"} state={courseSubCategory} style={{textDecoration: 'none', color: "darkblue"}}>{courseSubCategory}</Link> {">"}  <Link to={"/ProductTypeCategory"} state={courseTypeCategory} style={{textDecoration: 'none', color: "darkblue"}}>{courseTypeCategory}</Link></strong></p>

                <br/>

                <Row>

         
                    <Col xs={12} md={8}>
                    {/* <h4 class="coursenamecolright"><strong>{courseName}</strong></h4>
                    <p>ผู้สอน selpage store</p><br/> */}

                    <div class='responsivedetail'>

                        <img class='courseimage' src={courseImage[0]} rounded />
                        <br/>
                        <br/>
                        <br/>

                        <h3><strong>ผู้สอน</strong></h3><br/>
                        <Row className="borderwhattolearn">
                            <Col>
                                <Row style={{marginBottom: 10}}>
                                    <Col md="auto"><img src={product_sample01} alt="John Doe" className="mr-3 mt-3 rounded-circle" style={{width: 80}} /> </Col>
                                    <Col style={{lineHeight: 1.8}}>
                                        <h5>selpage instructor</h5>
                                        <p style={{lineHeight: 1.5}}>นักวิทยาศาสตร์คอมพิวเตอร์, นักกฎหมาย, นักรัฐศาสตร์</p>
                                        <BsStarFill/>&nbsp;&nbsp;&nbsp;4.8 คะแนน<br/>
                                        <BsChatLeftDotsFill/>&nbsp;&nbsp;&nbsp;150 รีวิว<br/>
                                        <BsPeopleFill/>&nbsp;&nbsp;&nbsp;56 จำนวนผู้เรียน<br/>
                                        <BsFillPlayBtnFill/>&nbsp;&nbsp;&nbsp;15 จำนวนคอร์ส<br/>
                                    </Col>
                                        
                                </Row> 
                            </Col>
                            <Col>
                                <h5>เกี่ยวกับผู้สอน</h5>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </Col>
                        </Row>

                        <br/>
                        <br/>
            
                        <h3><strong>สิ่งที่จะได้เรียนรู้</strong></h3><br/>
                        <Row className="borderwhattolearn">
                            {/* <Col sm={6}><BsCheckLg/> Master HTML & CSS and build massive real-world projects, with no prior knowledge needed!</Col>
                            <Col sm={6}><BsCheckLg/> Master tricky CSS concepts including the cascade, inheritance, and specificity</Col> */}
                            <Col>
                                {/* USE ARRAY [0-10] */}
                                <Row style={{marginBottom: 10}}>
                                    <Col md="auto"><BsCheckLg/></Col>
                                    <Col sm={5}>1. Master HTML & CSS and build massive real-world projects, with no prior knowledge needed! knowledge needed!</Col>
                                    <Col md="auto"><BsCheckLg/></Col>
                                    <Col sm={5}>2. Master HTML & CSS and build massive real-world projects, with no prior knowledge needed!</Col>
                                </Row> 

                                <Row style={{marginBottom: 10}}>
                                    <Col md="auto"><BsCheckLg/></Col>
                                    <Col sm={5}>3. Master HTML & CSS and build massive real-world projects, with no prior knowledge needed!</Col>
                                    <Col md="auto"><BsCheckLg/></Col>
                                    <Col sm={5}>4. Master HTML & CSS and build massive real-world projects, with no prior knowledge needed!</Col>
                                </Row> 

                                <Row style={{marginBottom: 10}}>
                                    <Col md="auto"><BsCheckLg/></Col>
                                    <Col sm={5}>Master HTML & CSS and build massive real-world projects, with no prior knowledge needed!</Col>
                                    <Col md="auto"><BsCheckLg/></Col>
                                    <Col sm={5}>Master HTML & CSS and build massive real-world projects, with no prior knowledge needed!</Col>
                                </Row>

                                <Row style={{marginBottom: 10}}>
                                    <Col md="auto"><BsCheckLg/></Col>
                                    <Col sm={5}>Master HTML & CSS and build massive real-world projects, with no prior knowledge needed!</Col>
                                    <Col md="auto"><BsCheckLg/></Col>
                                    <Col sm={5}>Master HTML & CSS and build massive real-world projects, with no prior knowledge needed!</Col>
                                </Row> 

                                <Row style={{marginBottom: 10}}>
                                    <Col md="auto"><BsCheckLg/></Col>
                                    <Col sm={5}>Master HTML & CSS and build massive real-world projects, with no prior knowledge needed!</Col>
                                    <Col md="auto"><BsCheckLg/></Col>
                                    <Col sm={5}>Master HTML & CSS and build massive real-world projects, with no prior knowledge needed!</Col>
                                </Row> 

                            </Col>

                        </Row>


                        {/* /////////////////////////////////////////// TEST QUERY COURSE CONTENT //////////////////////////////////////////////////// */}
                        <br/>
                        <br/>
                        <br/>
                        <h3><strong>เนื้อหาคอร์ส</strong> 
                        <span className="float-end"><Button variant="outline" className="rounded-0" size="sm" style={{textDecoration: 'none', borderColor: "darkblue", color: "darkblue"}} 
                        onClick={exVideoHandler}><span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>&nbsp;&nbsp;&nbsp;ตัวอย่างการสอน</Button>{' '}</span></h3><br/>

  
                                                <Modal 
                                                    show={showExVideo} 
                                                    onHide={handleClose} 
                                                    animation={false} 
                                                    size="md"
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    >
                                                        <Modal.Header>
                                                        <Modal.Title><strong>{courseName}</strong></Modal.Title>
                                                        
                                                        </Modal.Header>
                                                        <Modal.Body> 
                                                            <video src={videoSrc} style={{width: '100%'}} controls autoPlay controlsList="nodownload" />
                                                                
                                                            <Accordion style={{lineHeight: 2.5}} defaultActiveKey={['0']} alwaysOpen>
                                                                <Accordion.Item className="rounded-0" eventKey="0">
                                                                    <Accordion.Header><strong>ตัวอย่างการสอน</strong></Accordion.Header>
                                                                    <Accordion.Body>

                                                                    { (courseSectionExVideo) 
                                                                        ? (courseSectionExVideo).map((itemexvideo, keyexvideo) =>
                                                                            <>

                                                                                <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>
                                                                                &nbsp;&nbsp;&nbsp;<Link onClick={() => setVideoSrc(courseExVideo[keyexvideo])}>{itemexvideo}</Link><span className="float-end">6.50</span>
                                                                                <br/>

                                                                            </>
                                                                        ) 
                                                                        : <>- ไม่มีเนื้อหา -</>
                                                                    }
                                                                        
                                                                    </Accordion.Body>
                                                                    </Accordion.Item>  
                                                            </Accordion>
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                        <Button variant="secondary" onClick={handleClose}>
                                                            ปิด
                                                        </Button>
                                                        </Modal.Footer>
                                                </Modal>

                        
                                    {courseData.map((item, index) => {

                                        
                                        
                                        return (
                                            
                                            <div>
                                                {/* <td>{item.id}</td> */}
                                                <Accordion style={{lineHeight: 2.5}} defaultActiveKey={['0']} alwaysOpen>
                                                    <Accordion.Item className="rounded-0" eventKey="0">
                                                        <Accordion.Header><strong>{item.courseTopic}</strong></Accordion.Header>
                                                        <Accordion.Body>
                                                        { (item.courseSection) 
                                                            ? (item.courseSection).map((itemsection, keysec) =>
                                                                <> 
                                                                    { (item.courseSectionSrc) 
                                                                        ? item.courseSectionSrc.map((itemsrc, keysrc) => 
                                                                            <> 
                                                                                { (keysec == keysrc) 
                                                                                    ? (
                                                                                    <>
                                                                                        { (item.courseSectionType) 
                                                                                        ? item.courseSectionType.map((itemtype, keytype) => 
                                                                                            <> 
                                                                                                { (keysec == keytype) 
                                                                                                ? (
                                                                                                    <>
                                                                                                        { (itemtype == "video")

                                                                                                            ? (
                                                                                                                <>
                                                                                                                    {/* <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>
                                                                                                                    &nbsp;&nbsp;&nbsp;<Link onClick={exVideoHandler}>{itemsection}</Link><span className="float-end">6.50</span>
                                                                                                                    <br/> */}
                                                                                                                    <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>
                                                                                                                    &nbsp;&nbsp;&nbsp;{itemsection}<span className="float-end">6.50</span>
                                                                                                                    <br/>
                                                                                                                </>
                                                                                                            ) : (itemtype == "doc") 
                                                                                                            ? (
                                                                                                                <>
                                                                                                                    {/* <span style={{fontWeight: "bolder"}}><BsFolder2Open/></span>
                                                                                                                    &nbsp;&nbsp;&nbsp;<Link to={'/TEST/' + itemsrc} style={{textDecoration: 'none', color: "darkblue"}}>{itemsection}<span className="float-end"></span></Link>                                                       
                                                                                                                    <br/> */}
                                                                                                                    <span style={{fontWeight: "bolder"}}><BsFolder2Open/></span>
                                                                                                                    &nbsp;&nbsp;&nbsp;{itemsection}<span className="float-end"></span>                                                       
                                                                                                                    <br/>
                                                                                                                </>
                                                                                                            ) 
                                                                                                            : (<>- ไม่มีเนื้อหา -</>)  
                                                                                                        }
                                                                                                    </>) : (<></>)}
                                                                                            </>
                                                                                        ) 
                                                                                        : <>- ไม่มีเนื้อหา -</>
                                                                                    }
                                                                                    </>
                                                                                    ) : (<></>)  
                                                                                }
                                                                            </>
                                                                        ) 
                                                                        : <>- ไม่มีเนื้อหา -</>
                                                                    }
                                                                </>
                                                            ) 
                                                            : <>- ไม่มีเนื้อหา -</>
                                                        }

                                                        </Accordion.Body>
                                                    </Accordion.Item>  
                                                </Accordion>

                                                </div>
                                                
                                        );


                                    })}
                        

                        <br/>
                        <br/>
                        <h3><strong>รายละเอียดคอร์ส</strong></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        

                
                  
                        <br/>
                        <h3><strong>ความต้องการ</strong></h3>
                        <p>* อุปกรณ์ที่เข้าถึงอินเตอร์เน็ต เช่น สมาร์ทโฟน คอมพิวเตอร์ แท็ปเล็ต ฯ</p>
                        <p>* ไม่จำเป็นต้องมีความรู้พื้นฐาน Javascript</p>

  
                        {/* <br/>
                        <Accordion style={{lineHeight: 2.5}} defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><strong>บทนำเกี่ยวกับ javascript</strong></Accordion.Header>
                            <Accordion.Body>
                                <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>&nbsp;&nbsp;&nbsp;เบื้องต้นเกี่ยวกับ JavaScript<span className="float-end">6.50</span><br/>
                                <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>&nbsp;&nbsp;&nbsp;พื้นฐานของ javascript<span className="float-end">3.50</span><br/>
                                <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>&nbsp;&nbsp;&nbsp;javascript เบื้องต้น<span className="float-end">4.50</span><br/>
                                <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>&nbsp;&nbsp;&nbsp;สิ่งที่ต้องรู้ก่อนเริ่มเรียน<span className="float-end">3.50</span><br/>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><strong>พื้นฐาน Javascript</strong></Accordion.Header>
                            <Accordion.Body>
                                <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>&nbsp;&nbsp;&nbsp;เบื้องต้นเกี่ยวกับ JavaScript<span className="float-end">6.50</span><br/>
                                <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>&nbsp;&nbsp;&nbsp;พื้นฐานของ javascript<span className="float-end">3.50</span><br/>
                                <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>&nbsp;&nbsp;&nbsp;javascript เบื้องต้น<span className="float-end">4.50</span><br/>
                                <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>&nbsp;&nbsp;&nbsp;สิ่งที่ต้องรู้ก่อนเริ่มเรียน<span className="float-end">3.50</span><br/>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><strong>การเขียน Javascript</strong></Accordion.Header>
                            <Accordion.Body>
                                <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>&nbsp;&nbsp;&nbsp;เบื้องต้นเกี่ยวกับ JavaScript<span className="float-end">6.50</span><br/>
                                <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>&nbsp;&nbsp;&nbsp;พื้นฐานของ javascript<span className="float-end">3.50</span><br/>
                                <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>&nbsp;&nbsp;&nbsp;javascript เบื้องต้น<span className="float-end">4.50</span><br/>
                                <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>&nbsp;&nbsp;&nbsp;สิ่งที่ต้องรู้ก่อนเริ่มเรียน<span className="float-end">3.50</span><br/>
                            </Accordion.Body>
                        </Accordion.Item>
                        </Accordion> */}
                        

                        <br/>
                        <br/>


                    </div>
                    </Col>

                    
                    
                    

                    <Col className="rightbarcol" xs={12} md={4}>
                   
                        <video style={{width: '100%'}} controls controlsList="nodownload">
                            <source src={courseIntro[0]} type="video/mp4"/>
                        </video>
                        <h4 class="coursenamecolright"><strong>{courseName}</strong></h4>
                        <p>ร้านค้า : {storeName}<br/>

                           
                            { (item.ratingSum / item.ratingCount) <= 0 ? (
                                <>
                                                            
                                </>
                            ) : (item.ratingSum / item.ratingCount) <= 1 ? (
                                <>
                                    <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStar/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 3, fontSize: 16}}>({item.ratingCount})</span>{' '}
                                </>
                            ) : (item.ratingSum / item.ratingCount) <= 1.5 ? (
                                <>
                                    <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod', fontSize: 16}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarHalf/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 2, fontSize: 15}}>({item.ratingCount})</span>{' '}
                                </>
                            ) : (item.ratingSum / item.ratingCount) <= 2 ? (
                                <>
                                    <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod', fontSize: 16}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 2, fontSize: 15}}>({item.ratingCount})</span>{' '}
                                </>
                            ) : (item.ratingSum / item.ratingCount) <= 2.5 ? (
                                <>
                                    <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod', fontSize: 16}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarHalf/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 2, fontSize: 15}}>({item.ratingCount})</span>{' '}
                                </>
                            ) : (item.ratingSum / item.ratingCount) <= 3 ? (
                                <>
                                    <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod', fontSize: 16}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 2, fontSize: 15}}>({item.ratingCount})</span>{' '}
                                </>
                            ) : (item.ratingSum / item.ratingCount) <= 3.5 ? (
                                <>
                                    <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod', fontSize: 16}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarHalf/> <BsStar/></span> <span style={{position: "relative", top: 2, fontSize: 15}}>({item.ratingCount})</span>{' '}
                                </>
                            ) : (item.ratingSum / item.ratingCount) <= 4 ? (
                                <>
                                    <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod', fontSize: 16}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStar/></span> <span style={{position: "relative", top: 2, fontSize: 15}}>({item.ratingCount})</span>{' '}
                                </>
                            ) : (item.ratingSum / item.ratingCount) <= 4.5 ? (
                                <>
                                    <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod', fontSize: 16}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarHalf/></span> <span style={{position: "relative", top: 2, fontSize: 15}}>({item.ratingCount})</span>{' '}
                                </>
                            ) : (item.ratingSum / item.ratingCount) > 4.5 ? (
                                <>
                                    <span style={{position: "relative", top: 3, fontWeight: 'bolder', color: 'goldenrod', fontSize: 16}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/></span> <span style={{position: "relative", top: 2, fontSize: 15}}>({item.ratingCount})</span>{' '}
                                </>
                            ) : (
                                <>
                                                                
                                </>
                            ) }
                            
                        </p>


                                                    {item.courseBadge ? (
                                                        <div> 
                                                            { item.productDiscount <= 9 ? ( 
                                                                <Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> 
                                                            ) : (
                                                                <><Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> <Badge className="rounded-0" bg="danger">-{item.courseDiscount}%</Badge></> 
                                                            ) } 
                                                        </div>
                                                    ) : (
                                                       
                                                        <div> 
                                                            { item.courseDiscount <= 9 ? ( 
                                                    
                                                                <>
                                                                </>
                                                        
                                                                
                                                            ) : (
                                                                <><Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> <Badge className="rounded-0" bg="danger">- {item.courseDiscount}%</Badge></> 
                                                            ) } 
                                                        </div>
                                                        
                                                    )}

                                                    <br/>


                                                    {/* <video width="500" height="300" controls >
                                                        <source src={courseIntro[0]} type="video/mp4"/>
                                                    </video> */}

                        
                        {/* <strong>ค่าจัดส่ง:</strong> {productShippingCost}<br/>
                        <strong>จัดส่งภายใน:</strong> 1-2 วัน<br/>
                        <strong>จำนวนสินค้า:</strong><span style={{color:"green"}}> {productQuantity}</span><br/> */}

                        {/* <p> */}
                        {/* <strong>สถานะ:</strong><span style={{color:"green"}}> สินค้าพร้อมส่ง</span><br/> */}
                        {/* <strong>ขายแล้ว:</strong><span style={{color:"green"}}> {courseSold}</span><br/> */}
                        {/* <strong><BsPatchCheck/></strong><span style={{color:"green"}}> อัพเดทล่าสุด {courseSold}</span><br/>
                        <strong><BsPlayBtn/></strong><span style={{color:"green"}}>เนื้อหา {courseSold} ชั่วโมง 30 นาที</span><br/>
                        <strong><BsFolder2Open/></strong><span style={{color:"green"}}>จำนวนเอกสารในคอร์ส {courseSold}</span><br/>
                        <strong><BsPhone/></strong><span style={{color:"green"}}>เข้าถึงได้ทุกอุปกรณ์ {courseSold}</span><br/> */}
                        {/* <strong><BsInfinity/></strong><span style={{color:"green"}}> {courseSold}</span><br/> */}
                        {/* </p> */}
                        <p style={{lineHeight: 2}}><span><strong><BsPatchCheck/></strong>&nbsp;&nbsp;&nbsp;อัพเดทล่าสุด 5-8-66</span><br/>
                        <span><strong><BsPlayBtn/></strong>&nbsp;&nbsp;&nbsp;เนื้อหา {courseSold} ชั่วโมง 30 นาที</span><br/>
                        <span><strong><BsFolder2Open/></strong>&nbsp;&nbsp;&nbsp;จำนวนเอกสารในคอร์ส {courseSold}</span><br/>
                        <span><strong><BsPhone/></strong>&nbsp;&nbsp;&nbsp;เข้าถึงได้ทุกอุปกรณ์</span><br/>
                        <span><strong><BsInfinity/></strong>&nbsp;&nbsp;&nbsp;ดูได้ไม่จำกัดจำนวนครั้ง</span></p>
                        
                        <Modal 
                            show={show} 
                            onHide={handleClose} 
                            animation={false} 
                            size="sm"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>
                                <Modal.Header>
                                <Modal.Title>เพิ่มสินค้าในตะกร้าสำเร็จ</Modal.Title>
                                </Modal.Header>
                                <Modal.Body><Link to="/User/Profile" style={{textDecoration: 'none'}}>สินค้าในตะกร้าของฉัน</Link></Modal.Body>
                                <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    ปิด
                                </Button>
                                </Modal.Footer>
                        </Modal>

                        <br/>

                        <h4>{formatter.format(coursePrice)} <del style={{color: "gray"}}>{formatter.format(courseFullPrice)}</del></h4><br/>

                            <Link to={'/CheckoutDetail/Cart/'+ courseId} ><Button variant="dark" className="rounded-0"><span style={{color:"white"}}>ซื้อสินค้า</span></Button></Link>{' '}
                    
                            <Button variant="light" className="btn btn-outline-dark rounded-0">เพิ่มใส่ตระกร้า</Button>{' '}

                        
                        <br/>
                        <br/>

                        {/* <h3 className="pb-3 border-bottom"></h3> */}
                        <a href="https://www.facebook.com/people/SelPage/100085795429971/" target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: "salmon"}}><p><FaFacebookMessenger/> แชทกับร้านค้า</p></a>
                        
                    </Col>

                </Row>

                <br/>
            

                <Container>
                    <br/>

                    {/* <Tabs defaultActiveKey="detail" id="uncontrolled-tab-example" className="mb-3">
                        <Tab eventKey="detail" title="รายละเอียด">
                            
                        
                                <h3><strong>รายละเอียดคอร์ส</strong></h3> */}

                                
                                {/* {!!courseDoc[0] ? (
                                    <>
                                    <br/>
                                    <a href={courseDoc[0]} target="_blank" rel="noreferrer"><Button variant="outline-info" className="rounded-0">ตัวอย่างหนังสือ/ทดลองอ่าน</Button></a>
                                    <br/>
                                    </>
                                ) : (
                                    <></>
                                )} */}
                                
                                <br/>

                                {/* <strong>วันวางจำหน่าย:</strong> {courseRelease}<br/> */}
                                
                                {/* <p style={{whiteSpace: "pre-line"}}>{showMore ? <>{courseDetail.replaceAll("<br/>", "\n").substring(0, 500)} ...</> : courseDetail.replaceAll("<br/>", "\n") }  </p>
                                    <Button variant="outline-dark" className="rounded-0" onClick={() => setShowMore(!showMore)}>
                                        {showMore ? "อ่านเพิ่มเติม" : "อ่านน้อยลง"}
                                    </Button> */}

                                <br/>
                                <br/>
                            
                        {/*     
                        </Tab>

                        <Tab eventKey="sample" title="ภาพตัวอย่างสินค้า"> */}
        
                            {/* <h3><strong>ภาพตัวอย่างสินค้า</strong></h3>
                            <br/>

                            {!!productImage[1] ? (
                                <>
                                <img src={productImage[1]} style={{width: '100%', height: 'auto', marginBottom: 30}} rounded />
                                <img src={productImage[2]} style={{width: '100%', height: 'auto', marginBottom: 30}} rounded />
                                <img src={productImage[3]} style={{width: '100%', height: 'auto', marginBottom: 30}} rounded />
                                <img src={productImage[4]} style={{width: '100%', height: 'auto', marginBottom: 30}} rounded />
                                <img src={productImage[5]} style={{width: '100%', height: 'auto', marginBottom: 30}} rounded />
                                </>
                            ) : (
                                <p>ไม่มีภาพตัวอย่างสินค้า</p>
                            )}
                            
                            <br/> */}
                        {/* </Tab>

                    </Tabs> */}

                    {/* <h3 className="pb-3 border-bottom"></h3>
                    <br/>

                    <h3><strong>รีวิวสินค้า</strong></h3>

                    <br/> */}
                  
                    
                            {/* {commentLists.map((item) => {
                                    
                                    return (
                                        <div key={item.id}>
                                    <Row xs='auto'>
                                      
                                        <Col><Button variant="dark" style={{width: 50, height: 50, marginTop: 7, fontSize: 20}} className="rounded-circle"><strong>{(item.email).slice("0", "1")}</strong></Button></Col>
                                        <Col xs={9} md={7} style={{marginTop: 5}}><strong>{item.displayName}</strong>
                                            <br/>
                                            { item.rating == 5 ? (
                                            <p><span style={{color: "orange", fontSize: 15}}><BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/></span></p>
                                            ) : item.rating == 4 ? (
                                            <p><span style={{color: "orange", fontSize: 15}}><BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStar/></span></p>
                                            ) : item.rating == 3 ? (
                                            <p><span style={{color: "orange", fontSize: 15}}><BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStar/> <BsStar/></span></p>
                                            ) : item.rating == 2 ? (
                                            <p><span style={{color: "orange", fontSize: 15}}><BsStarFill/> <BsStarFill/> <BsStar/> <BsStar/> <BsStar/></span></p>
                                            ) : (
                                            <p><span style={{color: "orange", fontSize: 15}}><BsStarFill/> <BsStar/> <BsStar/> <BsStar/> <BsStar/></span></p>
                                            )}

                                            <p style={{position: "relative", top: -10, fontSize: 12}}>{(item.commentDate).toDate().toDateString()} | {(item.commentDate).toDate().toLocaleTimeString()}</p>
                                
       
                                            <p style={{whiteSpace: "pre-line", wordWrap: "break-word"}}>{(item.comment).replaceAll("<br/>", "\n")} </p>
             

                                            <Col>
                                               
                                                <p style={{fontSize: 18}}><strong><FaRegHeart/></strong> &nbsp;&nbsp;&nbsp;<strong><FaRegCommentAlt/></strong></p>
                                            </Col>
                                            
                            
                                        </Col>
                                        </Row>
                                        <h3 className="pb-3 border-bottom"></h3>
                                        <br/>
                                        </div>

                                )
                            })} */}

                            {/* {currentCursor ? (
                                <Col className='float-start'>

                                    <Button variant="warning" className="rounded-0" onClick={onMoreComment}>อ่านรีวิวเพิ่มเติม</Button>
                                    
                                </Col>
                                ) : (
                                <Col className='float-start'>

                                    <Button variant="outline-secondary" className="rounded-0" disabled>ไม่มีรีวิวสินค้า</Button>
                                    
                                </Col>
                            )} */}

                            <br/>
                            <br/>
                            <br/>
                            <br/>


                    {/* {!!user ? (
                        <>
                        

                        <h3><strong>เขียนรีวิว</strong></h3> */}

                    {/* <form onSubmit={submitCommentHandler}>
                    { rating == 0 ? (
                        <>
                        <Button onClick={() => setRating(1)} variant="none" style={{color: "darkgray", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(2)} variant="none" style={{color: "darkgray", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(3)} variant="none" style={{color: "darkgray", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(4)} variant="none" style={{color: "darkgray", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(5)} variant="none" style={{color: "darkgray", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        </>
                    ) : rating == 1 ? (

                        <>
                        <Button onClick={() => setRating(1)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(2)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(3)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(4)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(5)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        </>

                    ) : rating == 2 ? (

                        <>
                        <Button onClick={() => setRating(1)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(2)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(3)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(4)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(5)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        </>

                    ) : rating == 3 ? (

                        <>
                        <Button onClick={() => setRating(1)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(2)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(3)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(4)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        <Button onClick={() => setRating(5)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        </>

                    ) : rating == 4 ? (

                        <>
                        <Button onClick={() => setRating(1)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(2)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(3)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(4)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(5)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStar/></Button>
                        </>

                    ) : (

                        <>
                        <Button onClick={() => setRating(1)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(2)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(3)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(4)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        <Button onClick={() => setRating(5)} variant="none" style={{color: "orange", fontSize: 25, marginLeft: -7}} size="sm"><BsStarFill/></Button>
                        </>

                    ) }
                        <br/>
                        <br/>

                        
                            {displayNameShown ? (
                                
                                <p onClick={toggleDisplayName}>{displayName} <span style={{fontSize: 24}}>| <FaRegEye/></span> แสดง</p>
                
                            ) : (
                                <>
                                <p onClick={toggleDisplayName}>{displayName} <span style={{fontSize: 24}}>| <FaRegEyeSlash/></span> ไม่แสดง</p>
                                </>
                            )}
                        

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">

                            <Form.Control 
                                as="textarea" 
                                className="rounded-0"
                                style={{ whiteSpace: 'pre-line'}}
                                placeholder="รีวิว..."
                                rows={3} 
                                value={comment}
                                onChange={(e) => {
                                    setComment(e.target.value);
                                }}
                                        // placeholder={item.address} 
                            />
                        </Form.Group>

                        <Button variant="dark" className="rounded-0" type="submit">ส่งรีวิว</Button>
                    </form> */}
                        {/* </>
                     ) : (
                        <Link to={"/LogIn"}><Button variant="dark" className="rounded-0">เข้าสู่ระบบเพื่อรีวิว</Button></Link>
                    )}  */}
                    



                    <br/>
                    <br/>
                    <br/>

                </Container>
                    

            </Container>

            

            </div>

            );
        })}

        </>
        

    );



}


export default CoursePreview;