import React, { useState, useEffect, useContext } from "react";
import { Container, Tabs, Tab, Form, Col, Row, Button, Card, InputGroup, ProgressBar } from "react-bootstrap";
import { Link, redirect, useNavigate } from "react-router-dom";

import { FaSketch } from "react-icons/fa";
import { AiOutlineSketch } from "react-icons/ai";

import { auth, firestore } from "../../database/firebase";
import { storage } from "../../database/firebase";
import OrderCustomer from "./OrderCustomer";
import Cart from "./Cart";

// import OrderHistory from "../../component_m/Order/OrderHistory";

import OrderHistory from "./OrderHistory";

import { AuthContext } from "../Authentication/AuthProvider";
import OrderCustomerCart from "./OrderCustomerCart";
import OrderHistoryCart from "./OrderHistoryCart";

import "./roundprofileimg.css";

import { BsStarFill, BsStarHalf, BsStar, BsPatchCheck, BsPlayBtn, BsPhone, BsFolder2Open, BsInfinity, BsCheckLg, BsPeopleFill, BsFillPlayBtnFill, BsChatLeftDotsFill, BsPersonCircle, BsCameraReels, BsRecordFill, BsEye, BsImage, BsEarFill, BsEyeFill, BsDisplay } from "react-icons/bs";

import product_sample01 from '../images_s/product_sample01.jpeg'

import MyArticle from "../../component_m/Article/MyArticle";
import BookmarkArticle from "../../component_m/Article/BookmarkArticle";

function Profile() {

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    /* TEST QUERY DATA WITH productID */

    // const [uid, setUid] = useState("");

    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

   //const [uid, setUid] = useState("");

    var user = auth.currentUser;
    // var uid = "";
    var uid = "";

    if (user != null) {
        //email = user.email;
        uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                         // this value to authenticate with your backend server, if
                         // you have one. Use User.getToken() instead.
        console.log(uid);
        
        //TEST AUTH
        // refreshPage();
    } else {
        navigate("/");
    }
    



    

    // const [emailDefault, setEmailDefault] = useState("");

    // const [loading, setLoading] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [address, setAddress] = useState("");

    const [role, setRole] = useState("");
    const [sPoint, setSPoint] = useState("");

    const [displayName, setDisplayName] = useState("");

    var displayname = displayName;

    const [profession, setProfession] = useState("");
    const [description, setDescription] = useState("");

    const [socialPublic, setSocialPublic] = useState("");

    const socialPublicOption = ["สาธารณะ", "เฉพาะฉัน"];
    const [socialPublicString, setSocialPublicString] = useState(socialPublicOption[0]);


    const [facebook, setFacebook] = useState("");
    const [youtube, setYoutube] = useState("");
    const [instagram, setInstagram] = useState("");
    const [tiktok, setTiktok] = useState("");

    const [profileImg, setProfileImg] = useState("");

    const [file, setFile] = useState("");
    const [link, setLink] = useState("");

    const [resizefileUrl, setResizefileUrl] = useState("");

    const [data, setData] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
    
        .where("uid", "==", uid);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            uid: doc.id,
                            name: doc.data().name,
                            email: doc.data().email,
                            phonenumber: doc.data().phonenumber,
                            address: doc.data().address,
                            sPoint: doc.data().sPoint,   
                            role: doc.data().role,

                            displayName: doc.data().displayName,

                            profession: doc.data().profession,
                            description: doc.data().description,

                            socialPublic: doc.data().socialPublic,
                            facebook: doc.data().facebook,
                            youtube: doc.data().youtube,
                            instagram: doc.data().instagram,
                            tiktok: doc.data().tiktok,

                            profileImg: doc.data().profileImg,

                            
                        },
                        
                    ];

                    setName(doc.data().name);
                    setPhonenumber(doc.data().phonenumber);
                    setEmail(doc.data().email);
                    setAddress(doc.data().address);

                    setRole(doc.data().role)
                    setSPoint(doc.data().sPoint);

                    setDisplayName(doc.data().displayName);

                    setProfession(doc.data().profession);
                    setDescription(doc.data().description);

                    setSocialPublic(doc.data().socialPublic);
                    setFacebook(doc.data().facebook);
                    setYoutube(doc.data().youtube);
                    setInstagram(doc.data().instagram);
                    setTiktok(doc.data().tiktok);

                    setProfileImg(doc.data().profileImg);

                    setResizefileUrl(doc.data().profileImg);
                    

                    };

                });

            setData((oldDataArray) => tempDataArray);
            

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [uid]); 



    /////////////////////////////////////// CHECK ALREADY USER NAME /////////////////////////

    const [dataDisplayName, setDataDisplayName] = useState([]);
    const [checkDisplayName, setCheckDisplayName] = useState("");

    useEffect(() => {
        const userRef = firestore.collection("user");
        const query = userRef
        
            //.where("uid", "==", uid)
            .where("displayName", "==", displayName);
                query
                .get()
                .then((querySnapshot) => {
                    let tempDataArray =[];
                    querySnapshot.forEach((doc) => {
                        if (doc.exists) {
                            console.log(doc.data());
                            tempDataArray = [...tempDataArray,
                            {
                                uid: doc.id,
                                displayName: doc.data().displayName,
                          
                            },
                            
                        ];
    
                        //setDisplayName(doc.data().displayName);
                        setCheckDisplayName(doc.data().displayName);
            
    
                        };
    
                    });
    
                setDataDisplayName((oldDataArray) => tempDataArray);
                
    
            },
    
                (err) => {
                    console.log(err);
                }
                
            );
    
        }, [displayName]);

    ////////////////////////////////////////////////////////////////////////////////////////



    const [progress, setProgress] = useState(1);
    const [progressStatus, setProgressStatus] = useState(false);

    const subBucketRef = storage.child("mainBucket/profileImage/" + uid);

    

    useEffect(() => {

        if (resizefileUrl == profileImg) {

            setResizefileUrl(profileImg);
            
        } else {
            setResizefileUrl(resizefileUrl);
           
        }

    }, [resizefileUrl]);

    
    const uploadHandler = (e) => {

        e. preventDefault();

        if (!!file) {

            const fileName = file.name;
            /* const metadata = {
                contentType: 'image/jpeg',
                firebaseStorageDownloadTokens: uid,
              }; */
            const targetRef = subBucketRef.child(fileName);

            // const extension = fileName.split(".").pop();
            const name = fileName.split(".").slice(0, -1).join(".");
            setResizefileUrl("https://firebasestorage.googleapis.com/v0/b/selpage-9425f.appspot.com/o/mainBucket%2FprofileImage%2F" + uid +"%2F" + name + "_" + "1000x1000" + "." + "jpeg" + "?alt=media");
            console.log(resizefileUrl);

           

            
            /* setLink(resizefileName);
            console.log(link); */
            
            

            const uploadTask = targetRef.put(file);
            // .putString(your_base64_image, ‘base64’, {contentType:’image/jpg’});
            setProgressStatus(true);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                },
                (error) => {
                    console.log(error);
                },
                () => {
                    setProgressStatus(false);
                    uploadTask.snapshot.ref.getDownloadURL().then((photoURL) => {
                        console.log(photoURL);

                        setLink(photoURL);

                       /*  setLink(resizefileName);
                        console.log(link); */


                    });
                }
            );

            /* targetRef.put(file).then((response) => {
                console.log(response);
                response.ref.getDownloadURL().then((photoURL) => {
                    console.log(photoURL);
                    setLink(photoURL);
                }); */

/*             });

        } */

        } else {
                console.log("no file upload!!");
        }

    };

    

    

    

    const editHandler = (obj) => {
        
        const ref = firestore.collection("user");
        ref
            .doc(uid)
            .update(obj)
            .then(() => {
                console.log("แก้ไขข้อมูลสำเร็จ");
                refreshPage();
            })
            .catch((err) => console.log(err));
    };

    const submitHandler = (e) => {
        e.preventDefault();

        /* if (resizefileUrl == profileImg) {

        } else {
            setResizefileUrl(displayName);
        } */


        const obj = {
            
            name : name,
            email : email,
            phonenumber : phonenumber,
            address: address,
            displayName: displayName,

            profession: profession,
            description: description,

            socialPublic: socialPublicString,
            facebook: facebook,
            youtube: youtube,
            instagram: instagram,
            tiktok: tiktok,

            

            profileImg: resizefileUrl,

        };

     
       /*  if (dataDisplayName == "") {
            editHandler(obj);
     
        } else {
            alert("ชื่อนี้มีผู้ใช้งานแล้ว");
            setDisplayName(displayName);
        
        } */
      
        setName("");
        setEmail("");
        setPhonenumber("");
        setAddress("");
        setDisplayName("");

        setProfession("");
        setDescription("");


        setFacebook("");
        setYoutube("");
        setInstagram("");
        setTiktok("");

        editHandler(obj);
    };

    const myStore = () => {
        if (role == "merchant") {
            navigate("/MerchantView")
        } else {
            alert("ร้านค้าของฉันพร้อมเปิดให้บริการเร็วๆ นี้, ขออภัยในความไม่สะดวก");
        }
    }

    const signOutHandler = () => {
        auth.signOut().then(() => {
            console.log("Log Out Ok");
            setCurrentUser(null);
            navigate("/");
        })
        .catch((err) => {
            console.log("Log Out Not Ok." + err);
        })
    };

    

    return (
        <>
        <br/>

        {data.map((item, index) => {

            

            return (
                            
                <div key={index}>    

                

                <form onSubmit={submitHandler}>
                                
                    <Container>

                        {/* <Link to="/MerchantView" style={{textDecoration: 'none', color: "black"}}> <Button variant="outline-secondary">ร้านค้าของฉัน</Button>{' '}</Link> */}
                        {/* <Link to={"/MyArticle/" + uid} style={{textDecoration: 'none', color: "black"}}><Button className="rounded-0" variant="outline-secondary" size="sm">บทความของฉัน</Button></Link>{' '} */}
                        <Link to={"/MyOrder"} style={{textDecoration: 'none', color: "black"}}><Button className="rounded-0" variant="outline-secondary" size="sm">การซื้อของฉัน</Button></Link>{' '}
                        <Button onClick={myStore} className="rounded-0" variant="outline-secondary" size="sm">ร้านค้าของฉัน</Button>{' '}
                        
                        <br/>
                        <br/>
                        <br/>
                        {/* <h3><strong>ข้อมูลของฉัน</strong></h3> */}
                        <h3><strong>โปรไฟล์ของฉัน</strong></h3>
                        {/* <Link to="/MerchantView" style={{textDecoration: 'none', color: "black"}}> <Button variant="outline-secondary">ร้านค้าของฉัน</Button>{' '}</Link> */}
                        <br/>
                        <Link to="/AboutUs/SelpagePoint" style={{textDecoration: 'none', color: "black"}}><h4><span style={{fontSize: 35 ,color: "skyblue"}}><AiOutlineSketch/></span> คะแนนสะสม: <span style={{color: "limegreen"}}>{parseInt(sPoint)}</span></h4></Link>
                        
                        {/* Test Order View */}
                        {/* <Link to="/Order">ร้านค้าของฉัน</Link> */}

                        <br/>
                        <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
                                    
                            <Tab eventKey="profile" title="โปรไฟล์">

                                <Row>
                                        
                                    <Col sm={6}>
                                        
                                        <h4>ชื่อโปรไฟล์</h4>
                                        <Form.Control 
                                            type="text"
                                            className="rounded-0" 
                                            value={displayName}
                                            /* onChange={(e) => {
                                                setDisplayName(e.target.value);
                                            }} */
                                            plaintext
                                            readOnly
                                            
                                        />
                                        <br />

                                        <h4>เกี่ยวกับฉัน</h4>
                                        <Form.Control 
                                            as="textarea" 
                                            className="rounded-0"
                                            rows={5} 
                                            value={description}
                                            onChange={(e) => {
                                                setDescription(e.target.value);
                                            }}
                                            // placeholder={item.address} 
                                        />
                                        <br />

                                        <h4>ชื่อ - นามสกุล</h4>
                                        <Form.Control 
                                            type="text"
                                            className="rounded-0" 
                                            value={name}
                                            onChange={(e) => {
                                                setName(e.target.value);
                                            }}
                                            // placeholder={item.name}
                                        />
                                        <br />
                                        
                                        <h4>หมายเลขโทรศัพท์</h4>
                                        <Form.Control 
                                            type="text" 
                                            className="rounded-0"
                                            value={phonenumber}
                                            onChange={(e) => {
                                                setPhonenumber(e.target.value);
                                            }}
                                            // placeholder={item.phonenumber} 
                                            
                                        />
                                        <br />

                                        <h4>ที่อยู่จัดส่งสินค้า</h4>
                                        <Form.Control 
                                            as="textarea" 
                                            className="rounded-0"
                                            rows={5} 
                                            value={address}
                                            onChange={(e) => {
                                                setAddress(e.target.value);
                                            }}
                                            // placeholder={item.address} 
                                        />
                                        <br />

                                        <h4>อีเมลล์</h4>
                                        <Form.Control 
                                            type="text" 
                                            className="rounded-0"
                                            value={user.email}
                                            /* onChange={(e) => {
                                                setEmail(e.target.value);
                                            }} */
                                            plaintext
                                            readOnly
                                            // placeholder={item.email} 
                                        />
                                        <br />

                                        <h4>การทำงาน</h4>
                                        <Form.Control 
                                            
                                            type="text"  
                                            className="rounded-0"
                                            value={profession}
                                            onChange={(e) => {
                                                setProfession(e.target.value);
                                            }}
                                            // placeholder={item.address} 
                                        />
                                        <br />

                                    
                                    </Col>

                                    <Col sm={6}>

                                    <h4>รูปโปรไฟล์</h4>

                                    <br/>


                                    {/* <Col style={{textAlign: "center"}}><img src={profileImg} alt="John Doe" className="mr-3 mt-3 rounded-circle" style={{width: 155, height: 155}} /> </Col> */}

                                    {/* <Col style={{textAlign: "center"}}><img class="image-cropper" src={link} style={{marginBottom: 20}}/> </Col> */}

                                    {!!profileImg ? (
                                        <>
                                            {!!link ? (
                                                <Col style={{textAlign: "center"}}><img class="image-cropper" src={link} style={{marginBottom: 20}}/> </Col>
                                            ) : (
                                                <Col style={{textAlign: "center"}}><img class="image-cropper" src={profileImg} style={{marginBottom: 20}}/> </Col>
                                            )} 
                                        </>
                                    ) : (
                                        <>
                                            {!!link ? (
                                                <Col style={{textAlign: "center"}}><img class="image-cropper" src={link} style={{marginBottom: 20}}/> </Col>
                                            ) : (
                                                //<Col style={{textAlign: "center", fontSize: 120, color: 'gray'}}><BsPersonCircle/></Col>
                                                <Col style={{textAlign: "center", fontSize: 120, color: 'gray'}}><Button variant="dark" style={{marginBottom: 20, width: 200, height: 200}} className="rounded-circle"><strong style={{fontSize: 75}}>{(item.displayName).slice("0", "1")}</strong></Button></Col>
                                            )} 
                                        </>
                                        
                                    )}


                                
                                    <br/>

                                    {progressStatus ? (
                                        

                                        <ProgressBar striped variant="success" now={progress} />
                                        
                                    ) : null}

                              

                                    <br/>
                                    <InputGroup className="mb-3 rounded-0">
                                        <Form.Control
                                            aria-label="Example text with button addon"
                                            aria-describedby="basic-addon1"
                                            type="file"
                                            className="custom-file-label rounded-0"  
                                            id="customFile" 
                                            placeholder="" 
                                            name="customFile" 
                                            onChange={ (e) => {
                                                const file = e.target.files[0];
                                                setFile(file);
                                                setLink("");
                                            }}

                                        />

                                        <Button 
                                        type="submit"
                                        onClick={uploadHandler}
                                        className="btn btn-success rounded-0"
                                        >
                                        อัลโหลด
                                        </Button>
                                    </InputGroup>

{/* 
                                    {!!link ? (

                                    <div className="text-center mt-4">

                                        <hr/>

                                        <a className="text-secondary" href={link} target="_blank" rel="noreferrer">
                                            คลิกไฟล์ {file.name}
                                        </a>

                                    <img src={link} width={350} height={450} fluid/>

                                    </div>

                                    ) : null} */}

                      
                                    <br/>
                                    <br/>

                                        {/* <Card className="text-center">
                                            
                                            <Card.Body>
                                                <Card.Title><h4><FaSketch/> S-Points: <span style={{color: "limegreen"}}>40</span></h4></Card.Title>
                                            </Card.Body>
                                            
                                        </Card> */}

                                    <Form.Group className="mb-3">
                                            <Form.Label>การตั้งค่าการมองเห็นของลิ้งค์โซเชียล</Form.Label>
                                            {/* <Form.Select className="rounded-0" aria-label="Default select example">
                                                <option value="video">วิดีโอ</option>
                                                <option value="pdf">ไฟล์เอกสาร pdf</option>
                                            </Form.Select> */}

                                            <Form.Select className="rounded-0" aria-label="Default select example"
                                                onChange={(e) => setSocialPublicString(e.target.value)}
                                                defaultValue={socialPublic}
                                            >
                                                {socialPublicOption.map((option, index) => (
                                                <option key={index}>{option}</option>
                                                ))}
                                            </Form.Select>
                                    </Form.Group>

                                    <h4>Facebook</h4>
                                    <InputGroup>
                                        <InputGroup.Text className="rounded-0">Link :</InputGroup.Text>
                                        <Form.Control 
                                            type="text"  
                                            className="rounded-0"
                                            value={facebook}
                                            onChange={(e) => {
                                                setFacebook(e.target.value);
                                            }}
                                            // placeholder={item.address} 
                                        />

                                    </InputGroup>
                                    <br/>

                                    
                                    <h4>Instagram</h4>
                                    <InputGroup>
                                        <InputGroup.Text className="rounded-0">Link :</InputGroup.Text>
                                        <Form.Control 
                                            type="text"  
                                            className="rounded-0"
                                            value={instagram}
                                            onChange={(e) => {
                                                setInstagram(e.target.value);
                                            }}
                                            // placeholder={item.address} 
                                        />

                                    </InputGroup>
                                    <br/>

                                    <h4>Youtube</h4>
                                    <InputGroup>
                                        <InputGroup.Text className="rounded-0">Link :</InputGroup.Text>
                                        <Form.Control 
                                            type="text"  
                                            className="rounded-0"
                                            value={youtube}
                                            onChange={(e) => {
                                                setYoutube(e.target.value);
                                            }}
                                            // placeholder={item.address} 
                                        />

                                    </InputGroup>
                                    <br/>

                                    <h4>TikTok</h4>
                                    <InputGroup>
                                        <InputGroup.Text className="rounded-0">Link :</InputGroup.Text>
                                        <Form.Control 
                                            type="text"  
                                            className="rounded-0"
                                            value={tiktok}
                                            onChange={(e) => {
                                                setTiktok(e.target.value);
                                            }}
                                            // placeholder={item.address} 
                                        />

                                    </InputGroup>
                                    <br/>


                                    </Col>

                                    

                                </Row>

                                    <br/>
                                    {/* <Button className="btn btn-success rounded-0">บันทึก</Button> */}

                                    {/* <Button onClick={((e) => (setName(item.name), setPhonenumber(item.phonenumber), setEmail(item.email), setAddress(item.address) ))} className="btn btn-success rounded-0" type="submit">บันทึก</Button> */}
                                
                                    <><Button className="btn btn-success rounded-0" type="submit">บันทึกข้อมูลของฉัน</Button> <Button onClick={signOutHandler} className="rounded-0 float-end" variant="outline-danger" type="submit">ออกจากระบบ</Button></>
                                    
                                    <br/>
                            </Tab>

                            <Tab eventKey="profileimage" title="บทความ">

                                <MyArticle/>

                                {/* <ProfileImage/> */}

                                {/* <OrderCustomer />
                                <OrderCustomerCart />
                                <OrderHistory />
                                <OrderHistoryCart /> */}
                                
                            </Tab>

                            {/* <Tab eventKey="cart" title="บทความที่บันทึก">
                                <BookmarkArticle/>
                                
                            </Tab> */}

                            

                           {/*  <Tab eventKey="order" title="การซื้อของฉัน">

                                <OrderCustomer />
                                <OrderCustomerCart />
                                <OrderHistory />
                                <OrderHistoryCart />
                                
                            </Tab>

                            <Tab eventKey="cart" title="ตะกร้าสินค้า">
                                <Cart />
                            </Tab> */}

                        </Tabs>
                                    
                    </Container>

                </form>
                    
                
                               
                </div>
                            
                             
                            
            );
        })}


        <br/>
        <br/>
      

        </>
    );
}

export default Profile;








