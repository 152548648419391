import React, { useState, useEffect, useContext } from "react";
import { Container, Tabs, Tab, Form, Col, Row, Button, Card, InputGroup, Accordion, Modal, ProgressBar, Badge } from "react-bootstrap";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";

import { FaSketch, FaHeart, FaRegHeart, FaRegCommentAlt, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { AiOutlineSketch } from "react-icons/ai";

import { auth, firestore } from "../../database/firebase";
import { storage } from "../../database/firebase";


import { AuthContext } from "../../component_s/Authentication/AuthProvider";

import { BsStarFill, BsStarHalf, BsStar, BsPatchCheck, BsPlayBtn, BsPhone, BsFolder2Open, BsInfinity, BsCheckLg, BsPeopleFill, BsFillPlayBtnFill, BsChatLeftDotsFill, BsPersonCircle, BsCameraReels, BsRecordFill, BsEye, BsBookmarkPlus, BsBookmark, BsJournalCheck, BsChatRightQuote, BsBookmarkFill} from "react-icons/bs";


import product_sample01 from '../../component_s/images_s/product_sample01.jpeg';

import "./ReadArticle.css";

function BookmarkArticle() {


    let { userId } = useParams();

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    /* TEST QUERY DATA WITH productID */

    // const [uid, setUid] = useState("");

    // const [emailDefault, setEmailDefault] = useState("");
   
    const [displayNameShown, setDisplayNameShown] = useState(true);
    var hiddenDisplayName;

    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

   //const [uid, setUid] = useState("");

    var user = auth.currentUser;
    // var displayname = "";
    var uid = "";

    if (user != null) {

        user.reload();
       
        uid = user.uid;
       /*  email = user.email;
        name = user.displayName; */
        // console.log(name);
        // displayname = user.displayName;
       
        

    } else {
        // navigate("/");
    }

    /* useEffect(() => {
        
        if (!!displayName) {
            setDisplayName(displayName);
        } else {
            
        }
     
      }, [user]); */
    

    
    // const [loading, setLoading] = useState(false);
    const [section, setSection] = useState([""]);
    const [about, setAbout] = useState([""]);

    const [displayName, setDisplayName] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [address, setAddress] = useState("");

    const [role, setRole] = useState("");
    const [sPoint, setSPoint] = useState("");

    
    const [description, setDescription] = useState("");
    const [profileImg, setProfileImg] = useState("");

    

    const [data, setData] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
       
        .where("uid", "==", uid);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            uid: doc.id,
                            name: doc.data().name,
                            email: doc.data().email,
                            phonenumber: doc.data().phonenumber,
                            address: doc.data().address,
                            sPoint: doc.data().sPoint,   
                            role: doc.data().role,

                            displayName: doc.data().displayName,
                            description: doc.data().description,
                            profileImg: doc.data().profileImg,
                            
                        },
                        
                    ];

                    setName(doc.data().name);
                    setPhonenumber(doc.data().phonenumber);
                    setEmail(doc.data().email);
                    setAddress(doc.data().address);

                    setRole(doc.data().role)
                    setSPoint(doc.data().sPoint);

                    setDisplayName(doc.data().displayName);
                    setDescription(doc.data().description);
                    setProfileImg(doc.data().profileImg);

                    

                    /*
                    if (description != "") {
                        setDescription(doc.data().description);
                    } else {
                        setDescription(uid);
                    } */
                    

                    };

                    

                });
                // hiddenDisplayName = displayName.charAt(0) + displayName.replace(/./g, '*') + displayName.charAt(displayName.length-1);

            setData((oldDataArray) => tempDataArray);

            

            console.log(description);
            
            

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [uid]); 

    

    const [articleData, setArticleData] = useState([]);
    const [articleID, setArticleID] = useState("");
    // const [merchantID, setMerchantID] = useState("");
    const [writerID, setWriterID] = useState("");
    const [storeName, setStoreName] = useState("");

    const [writerName, setWriterName] = useState("");

    const [articleName, setArticleName] = useState("");
    const [articleDate, setArticleDate] = useState("");

    const [articleHeader, setArticleHeader] = useState([""]);
    const [articleContent, setArticleContent] = useState([""]);
    const [articleContentType, setArticleContentType] = useState([""]);
    const [articleSection, setArticleSection] = useState([""]);

    const [articleCategory, setArticleCategory] = useState("");
    const [articleSubCategory, setArticleSubCategory] = useState("");
    const [articleTypeCategory, setArticleTypeCategory] = useState("");


    const [updateCount, setUpdateCount] = useState("");
    const [viewCount, setViewCount] = useState(0);
    // const [addViewCount, setAddViewCount] = useState(0);


    //LIKE
    const [likeCount, setLikeCount] = useState(0);
    const [userIDLike, setUserIDLike] = useState([""]);
    const [checkUserLike, setCheckUserLike] = useState(false);
    const [displayNameUserLike, setDisplayNameUserLike] = useState([""]);
    const [descriptionUserLike, setDescriptionUserLike] = useState([""]);
    const [profileUserImgLike, setProfileUserImgLike] = useState([""]);

    //BOOKMARK
    const [bookmarkCount, setBookmarkCount] = useState(0);
    const [userIDBookmark, setUserIDBookmark] = useState([""]);
    const [checkUserBookmark, setCheckUserBookmark] = useState(false);
    const [displayNameUserBookmark, setDisplayNameUserBookmark] = useState([""]);
    const [descriptionUserBookmark, setDescriptionUserBookmark] = useState([""]);
    const [profileUserImgBookmark, setProfileUserImgBookmark] = useState([""]);


    //RATING
    const [ratingSum, setRatingSum] = useState(0);
    const [ratingCount, setRatingCount] = useState(0);
   

    //////////////////////////////////////////////////////
    var courseSectionExArray = [""];

    

    useEffect(() => {
        const productRef = firestore.collection("article");
        const query = productRef
            .orderBy("articleDate", "desc")
            .where("userIDBookmark", "array-contains", uid)
            .where("publicStatus", "==", "เผยแพร่")
            .where("status", "==", "public");
                query
                .get()
                .then((querySnapshot) => {
                    let tempDataArray =[];
                    querySnapshot.forEach((doc) => {
                        if (doc.exists) {
                            tempDataArray = [...tempDataArray,
                            {
                                

                                articleID: doc.id,
                                articleID: doc.data().articleID,
                                writerID: doc.data().writerID,
                        

                                writerName: doc.data().writerName,
                                articletName: doc.data().articleName,
                                articleDate: doc.data().articleDate,

                                articleCategory: doc.data().articleCategory,
                                articleSubCategory: doc.data().articleSubCategory,
                                articleTypeCategory: doc.data().articleTypeCategory,

                                articleHeader: doc.data().articleHeader,
                                articleContent: doc.data().articleContent,
                                articleContentType: doc.data().articleContentType,
                                articleSection: doc.data().articleSection,

                                articleBadge: doc.data().articleBadge,

                                updateCount: doc.data().updateCount,
                                viewCount: doc.data().viewCount,

                                likeCount: doc.data().likeCount,
                                userIDLike: doc.data().userIDLike,
                                displayNameUserLike: doc.data().displayNameUserLike,
                                descriptionUserLike: doc.data().descriptionUserLike,
                                profileUserImgLike: doc.data().profileUserImgLike,


                                bookmarkCount: doc.data().bookmarkCount,
                                userIDBookmark: doc.data().userIDBookmark,
                                displayNameUserBookmark: doc.data().displayNameUserBookmark,
                                descriptionUserBookmark: doc.data().descriptionUserBookmark,
                                profileUserImgBookmark: doc.data().profileUserImgBookmark,

                                //RATING
                                /* ratingSum: doc.data().ratingSum,
                                ratingCount: doc.data().ratingCount, */


                            },
                        ]; 


                        setArticleID(doc.data().articleID);
                        // setMerchantID(doc.data().merchantID);

                        setWriterID(doc.data().writerID);
                        setWriterName(doc.data().writerName);
                        setStoreName(doc.data().storeName);

                        setArticleCategory(doc.data().articleCategory);
                        setArticleSubCategory(doc.data().articleSubCategory);
                        setArticleTypeCategory(doc.data().articleTypeCategory);

                        setArticleName(doc.data().articleName);
                        
                        setArticleHeader(doc.data().articleHeader);
                        setArticleContent(doc.data().articleContent);
                        setArticleContentType(doc.data().articleContentType);
                        setArticleSection(doc.data().articleSection);

                        setUpdateCount(doc.data().updateCount);
                        setViewCount(doc.data().viewCount);

                        setLikeCount(doc.data().likeCount);
                        setUserIDLike(doc.data().userIDLike);
                        setDisplayNameUserLike(doc.data().displayNameUserLike);
                        setDescriptionUserLike(doc.data().descriptionUserLike);
                        setProfileUserImgLike(doc.data().profileUserImgLike);
                        
                        setBookmarkCount(doc.data().bookmarkCount);
                        setUserIDBookmark(doc.data().userIDBookmark);
                        setDisplayNameUserBookmark(doc.data().displayNameUserBookmark);
                        setDescriptionUserBookmark(doc.data().descriptionUserBookmark);
                        setProfileUserImgBookmark(doc.data().profileUserImgBookmark);

                        };

                        

                        setCheckUserLike(userIDLike.includes(uid));
                        setCheckUserBookmark(userIDBookmark.includes(uid));
                        // console.log(userIDLike.includes(uid));

                        courseSectionExArray.push(doc.data().courseSectionExVideo);

                        

                    });

                setArticleData((oldDataArray) => tempDataArray); 



                ///////////////////////////////////////////////////////////
                
                
                ///////////////////////////////////////////////////////////

                
            },

                (err) => {
                    console.log(err);    
                }
                
            );

            

        }, [uid]);    

        

    return (
        <div>
   
        <br/>
        {/* <Container> */}

        <h3><strong><BsBookmarkFill/> บทความที่บันทึก</strong></h3>

        <br/>
        <br/>

           {(articleData == "") ? (
                <>
                    <br/>
                    <h4>ยังไม่มีบทความที่บันทึก</h4>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
              
                </>
            ) : (
                <></>
            )} 
      
 
            <Row xs={2} md={4} className="g-4">

            

            {articleData.map((item) => {
                            
                return (
                               
                    <div key={item.id}>



                                    <div className='card'>
                                        <Link to={'/ReadArticle/'+ item.articleID} style={{textDecoration: 'none', color: "black"}}>
                                            <Card className="cardarticle">

                                                {!!item.articleContent[0] ? (
                                                    <Card.Img variant="top" class='cardarticledetailimg' src={item.articleContent[0]} />
                                                ) : (
                                                    <Col style={{backgroundColor: '#FFFAFA'}}><div class='cardarticledetailnoimg'><strong>{(item.writerName)}</strong></div></Col>
                                                )}
                                                
                                                
                                                <Card.Body class='cardarticledetail'>
                                                    <Card.Title>
                                                    </Card.Title>

                                                    <p class='articlename' style={{fontWeight: 500}}>{item.articletName}</p>
                                            
                                                    <p class='cardarticledetailnamedatetime'>{item.writerName} <br/> {(item.articleDate).toDate().toLocaleDateString('th-TH', {year: 'numeric', month: 'short', day: 'numeric', weekday: 'short',})} | {(item.articleDate).toDate().toLocaleTimeString()} 
                                                    <br/>
                                                
                                                    {/* <span class='cardarticledetaillikebookmark'><strong><FaRegHeart/> {item.likeCount}</strong> &nbsp;&nbsp;&nbsp;<strong><BsBookmark/> {item.bookmarkCount} &nbsp;&nbsp;&nbsp;<BsEye/> {item.viewCount + 1}</strong></span></p> */}
                                                    <span class='cardarticledetaillikebookmark'><strong><FaRegHeart/> {item.likeCount}</strong><strong>&nbsp;&nbsp;<BsEye/> {item.viewCount + 1}</strong> <Badge style={{fontWeight: 'bold', marginTop: 4}} className="float-end rounded-pill" bg="light text-secondary">{item.articleCategory}</Badge></span></p>

                                                        

                                                </Card.Body>
                                            </Card>
                                        </Link> 
                                    </div>

                        <br/>

                    </div>

                )
            })}

            </Row>

        {/* </Container> */}


         {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      

        </div>
    );
}

export default BookmarkArticle;