import React, { useState, useEffect, useContext } from "react";
import { Container, Tabs, Tab, Form, Col, Row, Button, Card, InputGroup, Accordion, Modal, ProgressBar, FormGroup } from "react-bootstrap";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";

import { FaSketch } from "react-icons/fa";
import { AiOutlineSketch } from "react-icons/ai";

import { auth, firestore } from "../../database/firebase";
import { storage } from "../../database/firebase";


import { AuthContext } from "../../component_s/Authentication/AuthProvider";

import { BsStarFill, BsStarHalf, BsStar, BsPatchCheck, BsPlayBtn, BsPhone, BsFolder2Open, BsInfinity, BsCheckLg, BsPeopleFill, BsFillPlayBtnFill, BsChatLeftDotsFill, BsPersonCircle, BsCameraReels, BsRecordFill, BsEye, BsImage, BsEarFill, BsEyeFill, BsDisplay, BsPencil } from "react-icons/bs";

import "./CreateArticle.css";

//import { uuid } from 'uuidv4';

// import "./UploadVideo.css";

// import Resizer from "react-image-file-resizer";


function CreateArticle() {


    // let { courseId } = useParams();

    let { articleId } = useParams();

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    /* TEST QUERY DATA WITH productID */

    // const [uid, setUid] = useState("");

    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

   //const [uid, setUid] = useState("");

    var user = auth.currentUser;
    // var uid = "";
    var uid = "";

    if (user != null) {
        //email = user.email;
        uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                         // this value to authenticate with your backend server, if
                         // you have one. Use User.getToken() instead.
        console.log(uid);
        
        //TEST AUTH
        // refreshPage();
    } else {
        navigate("/");
    }

    
    

    

    /* const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(file, 500, 500, "JPEG", 50, 0, (uri) => {
        resolve(uri);
        });
    }); */


    

    // const [emailDefault, setEmailDefault] = useState("");

    // const [loading, setLoading] = useState(false);
    const [section, setSection] = useState([""]);
    const [about, setAbout] = useState([""]);


    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [address, setAddress] = useState("");

    const [role, setRole] = useState("");
    const [sPoint, setSPoint] = useState("");

    const [displayName, setDisplayName] = useState("");

    const [data, setData] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
    
        .where("uid", "==", uid);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            uid: doc.id,
                            name: doc.data().name,
                            email: doc.data().email,
                            phonenumber: doc.data().phonenumber,
                            address: doc.data().address,
                            sPoint: doc.data().sPoint,   
                            role: doc.data().role,

                            displayName: doc.data().displayName,
                            
                        },
                        
                    ];

                    setName(doc.data().name);
                    setPhonenumber(doc.data().phonenumber);
                    setEmail(doc.data().email);
                    setAddress(doc.data().address);

                    setRole(doc.data().role)
                    setSPoint(doc.data().sPoint);

                    setDisplayName(doc.data().displayName);
                    

                    };

                });

            setData((oldDataArray) => tempDataArray);
            

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [uid]); 


    const [articleData, setArticleData] = useState([]);


    const [articleID, setArticleID] = useState("");

    const [writerID, setWriterID] = useState("");
    // const [merchantID, setMerchantID] = useState("");
    const [storeName, setStoreName] = useState("");
    const [writerName, setWriterName] = useState("");

    const [articleName, setArticleName] = useState("");
    const [articleDate, setArticleDate] = useState("");

    const [articleCategory, setArticleCategory] = useState("");
    const [articleSubCategory, setArticleSubCategory] = useState("");
    const [articleTypeCategory, setArticleTypeCategory] = useState("");

    const [articleHeader, setArticleHeader] = useState([""]);
    const [articleContent, setArticleContent] = useState([""]);
    const [articleContentType, setArticleContentType] = useState([""]);
    const [articleSection, setArticleSection] = useState([""]);

    const [articleRefText, setArticleRefText] = useState([""]);
    const [articleLinkRefText, setArticleLinkRefText] = useState([""]);

    //VIEW COUNT
    const [viewCount, setViewCount] = useState("");

    //STATUS
    const [status, setStatus] = useState("");
    const [publicStatus, setPublicStatus] = useState("");

    //RATING
    const [ratingSum, setRatingSum] = useState(0);
    const [ratingCount, setRatingCount] = useState(0);

    //////// Article Content
    const articleContentTypeOption = ["ประเภทไฟล์", "รูปภาพ", "วิดีโอ"];
    const [articleContentTypeString, setArticleContentTypeString] = useState(articleContentTypeOption[0]);
    // const [courseExType, setCourseExType] = useState([""]);

    /////// Public Status
    // const publicOption = ["เผยแพร่", "หยุดชั่วคราว"];
    const [publicString, setPublicString] = useState("");



    //////////////////////////////////////////////////////
    var courseSectionExArray = [""];

    useEffect(() => {
        const articleRef = firestore.collection("article");
        const query = articleRef
        
            .where("articleID", "==", articleId);
                query
                .get()
                .then((querySnapshot) => {
                    let tempDataArray =[];
                    querySnapshot.forEach((doc) => {
                        if (doc.exists) {
                            tempDataArray = [...tempDataArray,
                            {

                                articleID: doc.id,
                                articleID: doc.data().articleID,
                                writerID: doc.data().writerID,
                        

                                writerName: doc.data().writerName,
                                articletName: doc.data().articleName,
                                articleDate: doc.data().articleDate,

                                articleCategory: doc.data().articleCategory,
                                articleSubCategory: doc.data().articleSubCategory,
                                articleTypeCategory: doc.data().articleTypeCategory,

                                articleHeader: doc.data().articleHeader,
                                articleContent: doc.data().articleContent,
                                articleContentType: doc.data().articleContentType,
                                articleSection: doc.data().articleSection,

                                articleRefText: doc.data().articleRefText,
                                articleLinkRefText: doc.data().articleLinkRefText,

                                articleBadge: doc.data().articleBadge,

                                status: doc.data().status,
                                publicStatus: doc.data().publicStatus,

                                updateCount: doc.data().updateCount,
                                viewCount: doc.data().viewCount,
                                
                            },
                        ];


                            setWriterID(doc.data().writerID);
                            setArticleID(doc.data().articleID);

                            setArticleName(doc.data().articleName);

                            setArticleHeader(doc.data().articleHeader);
                            setArticleContent(doc.data().articleContent);
                            setArticleContentType(doc.data().articleContentType);
                            setArticleSection(doc.data().articleSection);

                            setArticleRefText(doc.data().articleRefText);
                            setArticleLinkRefText(doc.data().articleLinkRefText);

                            setViewCount(doc.data().viewCount);

                            setStatus(doc.data().status);
                            setPublicStatus(doc.data().publicStatus);
                        
                        };

                        /* courseSectionExArray.push(doc.data().courseSectionExVideo);
                        console.log(courseSectionExArray); */


                    });

                setArticleData((oldDataArray) => tempDataArray);

                
            },

                (err) => {
                    console.log(err);
                }
                
            );

        }, []);

        /* useEffect(() => {

            if (checkDeleteArticle == true) {
                navigate("/MyArticle/" + uid);
            } else {
                
            }
    
        }, [checkDeleteArticle]);  */

        /* useEffect(() => {

        if (!articleId) {
            navigate("/MyArticle/" + uid);
        } else {
            
        }

        }, [articleId]); */



    const myStore = () => {
        if (role == "merchant") {
            navigate("/MerchantView")
        } else {
            alert("ร้านค้าของฉันพร้อมเปิดให้บริการเร็วๆ นี้, ขออภัยในความไม่สะดวก");
        }
    }

    const signOutHandler = () => {
        auth.signOut().then(() => {
            console.log("Log Out Ok");
            setCurrentUser(null);
            navigate("/");
        })
        .catch((err) => {
            console.log("Log Out Not Ok." + err);
        })
    };


    //TEST Upload Video/Doc
    const [file, setFile] = useState("");
    const [link, setLink] = useState("");

    const [progress, setProgress] = useState(1);
    const [progressStatus, setProgressStatus] = useState(false);

    //EDIT IMG
    const [fileEdit, setFileEdit] = useState("");
    const [linkEdit, setLinkEdit] = useState("");

    const [progressEdit, setProgressEdit] = useState(1);
    const [progressStatusEdit, setProgressStatusEdit] = useState(false);

    /////////////////////

    const subBucketRef = storage.child("mainBucket/article/" + articleId);

    //////////////////////////////////////////// RESIZE
    
    

    const [resizefileUrl, setResizefileUrl] = useState("");
    
    const uploadHandler = (e) => {

        e. preventDefault();

        if (!!file) {

            const fileName = file.name;
           
            const targetRef = subBucketRef.child(fileName);

            const name = fileName.split(".").slice(0, -1).join(".");
            setResizefileUrl("https://firebasestorage.googleapis.com/v0/b/selpage-9425f.appspot.com/o/mainBucket%2Farticle%2F" + articleId +"%2F" + name + "_" + "1000x1000" + "." + "jpeg" + "?alt=media");
            console.log(resizefileUrl);

            const uploadTask = targetRef.put(file);
         
            setProgressStatus(true);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                },
                (error) => {
                    console.log(error);
                },
                () => {
                    setProgressStatus(false);
                    uploadTask.snapshot.ref.getDownloadURL().then((photoURL) => {
                        console.log(photoURL);

                        setLink(photoURL);


                    });
                }
            );

    

        } else {
                console.log("no file upload!!");
        }

    };

    //////////////////////////////////////////////// EDIT IMAGE ///////////////////////////////////////////

    const [resizefileUrlEdit, setResizefileUrlEdit] = useState("");
    
    const uploadHandlerEdit = (e) => {

        e. preventDefault();

        if (!!fileEdit) {

            const fileName = fileEdit.name;
           
            const targetRef = subBucketRef.child(fileName);

            const name = fileName.split(".").slice(0, -1).join(".");
            setResizefileUrlEdit("https://firebasestorage.googleapis.com/v0/b/selpage-9425f.appspot.com/o/mainBucket%2Farticle%2F" + articleId +"%2F" + name + "_" + "1000x1000" + "." + "jpeg" + "?alt=media");
            console.log(resizefileUrlEdit);

            const uploadTask = targetRef.put(fileEdit);
         
            setProgressStatusEdit(true);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    setProgressEdit((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                },
                (error) => {
                    console.log(error);
                },
                () => {
                    setProgressStatusEdit(false);
                    uploadTask.snapshot.ref.getDownloadURL().then((photoURL) => {
                        console.log(photoURL);

                        setLinkEdit(photoURL);
                        setArticleImgStringEdit("");


                    });
                }
            );

    

        } else {
                console.log("no file upload!!");
        }

    };



    ///////////////////////////////////////////////////////////////////////////////////////////////////////



    const [show, setShow] = useState(false);
    const [showdeletesectioncontent, setShowdeletesectioncontent] = useState(false);
    const [showdeleteArticle, setShowdeleteArticle] = useState(false);
    const [showdeleteImg, setShowdeleteImg] = useState("");

    const [showeditarticleName, setShoweditarticleName] = useState("");
    //////
    // const [keyToShowModal, setKeyToShowModal] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseDeleteSelectContent = () => setShowdeletesectioncontent(false);
    const handleCloseDeleteArticle = () => setShowdeleteArticle(false);
    const handleCloseDeleteImg = () => setShowdeleteImg(false);

    const handleCloseEditArticleName = () => setShoweditarticleName(false);

    
   

      ///////////////////////////////////// TEST PUSH DATA TO ARRAY /////////////////////////////////////////////////
      const [articleHeaderString, setArticleHeaderString] = useState("");
      //const [articleContentString, setArticleContentString] = useState([""]);

      const [articleSectionString, setArticleSectionString] = useState("");

      const [articleRefTextString, setArticleRefTextString] = useState("");
      const [articleLinkRefTextString, setArticleLinkRefTextString] = useState("");

      //////////////////////////////////// TEST EDIT DATA IN ARRAY ////////////////////////////////////////////////
     /*  const [editArticleHeader, setEditArticleHeader] = useState([""]);
      const [editArticleSection, setEditArticleSection] = useState(""); */
      const [articleNameEdit, setArticleNameEdit] = useState("");

      const editArticleName = (e) => {

        e.preventDefault();
       
        const obj = {
            articleName: articleNameEdit,
           
        };

        

        editArticleNameHandler(obj);

    }

    

    const editArticleNameHandler = (obj) => {
           
        const ref = firestore.collection("article");
        ref
          
            .doc(articleId)
            .update(obj)
            .then(() => {
                console.log("แก้ไขข้อมูลสำเร็จ");
                refreshPage();
            })
            .catch((err) => console.log(err));
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        // alert(JSON.stringify(formValues));

        if ((articleHeader[0] == "") && (articleContent[0] == "") && (articleSection[0] == "") && (articleRefText[0] == "") && (articleLinkRefText[0] == "")) {

            articleHeader.splice(0, 1);
            articleContent.splice(0, 1);
            articleContentType.splice(0, 1);
            articleSection.splice(0, 1);

            articleRefText.splice(0, 1);
            articleLinkRefText.splice(0, 1);

            articleHeader.push(articleHeaderString);
            articleContent.push(resizefileUrl);
            articleContentType.push("image");
            articleSection.push(articleSectionString);

            articleRefText.push(articleRefTextString);
            articleLinkRefText.push(articleLinkRefTextString);


        } else {

            articleHeader.push(articleHeaderString);
            articleContent.push(resizefileUrl);
            articleContentType.push("image");
            articleSection.push(articleSectionString);

            articleRefText.push(articleRefTextString);
            articleLinkRefText.push(articleLinkRefTextString);

        }

        

        /* courseSectionExVideo.push(courseSectionExString);
        courseExVideo.push(link);
        courseExType.push(courseExTypeString) */

        const obj = {

            articleHeader: articleHeader,
            articleContent: articleContent,
            articleContentType: articleContentType,
            articleSection: articleSection,

            articleRefText: articleRefText,
            articleLinkRefText: articleLinkRefText,

            articleDate: new Date(),


            
        };

            // setCourseSectionExString("");

        
            ////////////////////////////////////////////////////////////////////
            uploadContentHandler(obj);

    }

    /* const modalDeleteSelectContentHandler = (index) => { 

        setKeyToShowModal(index);
        setShow(true);


        deleteSelectContentHandler(index);

    } */


    const deleteSelectContentHandler = (index) => {
        //index.preventDefault(index);
        

        articleHeader.splice(index, 1);
        articleContent.splice(index, 1);
        articleContentType.splice(index, 1);
        articleSection.splice(index, 1);

        articleRefText.splice(index, 1);
        articleLinkRefText.splice(index, 1);

        const obj = {
            articleHeader: articleHeader,
            articleContent: articleContent,
            articleContentType: articleContentType,
            articleSection: articleSection,

            articleRefText: articleRefText,
            articleLinkRefText: articleLinkRefText,
        }

        uploadContentHandler(obj);


    }



    const deleteImgHandler = (index) => {
        //index.preventDefault(index);
        

        setArticleImgStringEdit("");

        articleContent.splice(index, 1, "");
    


        const obj = {
      
            articleContent: articleContent,

        }

        uploadContentHandler(obj);


    }

    ////////////////////////////////////////////////////////******************************************* */

    /////////////////////////////////////////////////////////////////////////
    const [headerKey, setHeaderKey] = useState("");
    const [articleHeaderStringEdit, setArticleHeaderStringEdit] = useState("");
    const [articleSectionStringEdit, setArticleSectionStringEdit] = useState("");

    const [articleRefTextStringEdit, setArticleRefTextStringEdit] = useState("");
    const [articleLinkRefTextStringEdit, setArticleLinkRefTextStringEdit] = useState("");

    const [articleImgStringEdit, setArticleImgStringEdit] = useState("");

    const [newImg, setNewImg] = useState("");


    const editHandler = (index, editHeaderString, editSectionString, editRefTextString, editLinkRefTextString, editFileUrl) => {
        // index.preventDefault();
        

        articleHeader.splice(index, 1, editHeaderString);
        articleSection.splice(index, 1, editSectionString);
        /* articleContent.splice(index, 1);
        articleContentType.splice(index, 1);
         */
        articleRefText.splice(index, 1, editRefTextString);
        articleLinkRefText.splice(index, 1, editLinkRefTextString);

        if (resizefileUrlEdit == "") {
            articleContent.splice(index, 1, editFileUrl);
        } else {
            articleContent.splice(index, 1, resizefileUrlEdit);
        }
        

        const obj = {
            articleHeader: articleHeader,
            articleSection: articleSection,
            /* articleContent: articleContent,
            articleContentType: articleContentType,
             */
            articleRefText: articleRefText,
            articleLinkRefText: articleLinkRefText,

            articleContent: articleContent
        };

        setHeaderKey("");
        setArticleHeaderStringEdit("");
        setArticleSectionStringEdit("");

        setArticleRefTextStringEdit("");
        setArticleLinkRefTextStringEdit("");

        uploadContentHandler(obj);

        


    }
    ////////////////////////////////////////////////////////******************************************* */

    const uploadContentHandler = (obj) => {
           
        const ref = firestore.collection("article");
        ref
          
            .doc(articleId)
            .update(obj)
            .then(() => {
                console.log("แก้ไขข้อมูลสำเร็จ");
                refreshPage();
            })
            .catch((err) => console.log(err));
    };

    //////////////////////////////////////////////////////////////// DELEATE ARTICLE
    const [checkDeleteArticle, setCheckDeleteArticle] = useState(false);

    const deleteArticleHandler = () => {
           
        const ref = firestore.collection("article");
        ref
          
            .doc(articleId)
            .delete()
            .then(() => {
                console.log("ลบบทความสำเร็จ");
                refreshPage();

                //setCheckDeleteArticle(true);
                //setArticleID(null);

                //navigate("/");
                
                //navigate("/");

            })
            .catch((err) => console.log(err));
    };

 

        



    /* useEffect(() => {

        if (articleID == null) {
            navigate("/MyArticle/" + uid);
        } else {
            
        }

    }, [articleID]); */
    



    //////////////////////////////////////////////////////////////// SELECT PUBLIC STATUS
    const [showEditStatus, setShowEditStatus] = useState(false);
    
    const handleShowEditStatusClose = () => setShowEditStatus(false);
        // setPublicString(publicStatus);
    

    const handleEditStatusShow = () => setShowEditStatus(true);

    /////////////////////////////////////////////////////////////
    const [showPublicTopicStatus, setShowPublicTopicStatus] = useState(false);

    const handleShowPublicTopicClose = () => setShowPublicTopicStatus(false);

    ////////////////////////////////////////////////////////////
    const [showDeleteArticle, setShowDeleteArticle] = useState(false);

    const handlShowDeleteArticleClose = () => setShowDeleteArticle(false);


    ////////////////////////////////////////////////////////////
    const [showSampleTopic, setShowSampleTopic] = useState(false);

    const handleSampleTopicClose = () => setShowSampleTopic(false);





   /*  useEffect(() => {
        
        if (publicString != publicStatus) {
            setShowEditStatus(true);
        } else {
            
        }
      
      }, [publicString]); */


    ////////////////////////////////////////////// EDIT STATUS
    const editStatusHandler = (event) => {
        event.preventDefault();

        // publicStatus : publicString;

        if (publicStatus == "เผยแพร่") {
            const obj = {
                publicStatus : "หยุดชั่วคราว",
            }
            editNewStatusHandler(obj);
        } else if (publicStatus == "หยุดชั่วคราว") {
            const obj = {
                publicStatus : "เผยแพร่",
            }
            editNewStatusHandler(obj); 
        } else {

        }

    }

    const editNewStatusHandler = (obj) => {
           
        const ref = firestore.collection("article");
        ref
          
            .doc(articleId)
            .update(obj)
            .then(() => {
                console.log("แก้ไขข้อมูลสำเร็จ");
                refreshPage();
            })
            .catch((err) => console.log(err));
    };


    /////////////////////// Public Topic//////////////////////////////////////

    const publicTopicHandler = (event) => {
        event.preventDefault();

        const obj = {
            publicStatus : "เผยแพร่",
            status : "public",
        }

        editNewStatusHandler(obj);
    
    }


    
    
    


    
    return (
        <>
        <br/>

    {data.map((item, index) => {

        return (
                    
            <div key={index}>    

            {/* <form onSubmit={submitHandler}> */}
                            
                <Container>
                    
                    {/* <Link to="/MerchantView" style={{textDecoration: 'none', color: "black"}}> <Button variant="outline-secondary">ร้านค้าของฉัน</Button>{' '}</Link> */}
                    

                    {/* <Button onClick={myStore} variant="outline-secondary">ร้านค้าของฉัน</Button>{' '}
                    <Button onClick={signOutHandler} variant="outline-danger" type="submit">ออกจากระบบ</Button>{' '} */}
                    {/* <Button variant="secondary" className="btn rounded-0" onClick={handleShowPublicTopicClose}>
                                ยกเลิก
                    </Button> */}
                    
                    {(articleID == "") ? (
                        <Link to={'/MyArticle/'+ uid} style={{textDecoration: 'none'}}>
                            <Button variant="success" className="btn rounded-0">
                                บทความของฉัน
                            </Button>
                        </Link>
                    ) : 
                    (

                        <>
                        </>

                    )}

                    
                   


                    <h3>
                   
                   
                        {publicStatus == "กำลังดำเนินการ" && status == "public" ? (
                            <div class='statusheaderfontsize'><strong>อัพโหลดเนื้อหา | สถานะ : <span style={{color: "salmon"}}>{publicStatus}</span></strong> 
                                <div className="float-end"></div>
                                </div>
                        ) : publicStatus == "เผยแพร่" && status == "public" ? (
                            <div class='statusheaderfontsize'><strong>แก้ไขเนื้อหา | สถานะ : <span style={{color: "green"}}>{publicStatus}</span></strong><br/><br/>
                                
                                <p style={{fontSize: 18}}>เปลี่ยนสถานะการเผยแพร่ : &nbsp;&nbsp;
                                <Button className="btn rounded-0" variant="outline-warning" size="sm" onClick={() => setShowEditStatus(true)}>หยุดชั่วคราว</Button></p>
                                
                            </div>
                        ) : publicStatus == "หยุดชั่วคราว" && status == "public" ? (
                            <div class='statusheaderfontsize'><strong>แก้ไขเนื้อหา | สถานะ : <span style={{color: "goldenrod"}}>{publicStatus}</span></strong><br/><br/>
                                
                                <p style={{fontSize: 18}}>เปลี่ยนสถานะการเผยแพร่ : &nbsp;&nbsp; 
                                <Button className="btn rounded-0" variant="outline-success" size="sm" onClick={() => setShowEditStatus(true)}>เผยแพร่</Button></p>
                                
                            </div>
                        ) : publicStatus == "กำลังดำเนินการ" && status == "inprogress" ? (
                            <div class='statusheaderfontsize'><strong>แก้ไขเนื้อหา | สถานะ : <span style={{color: "goldenrod"}}>{publicStatus}</span></strong><br/><br/>
                                
                                {/* <Button className="btn rounded-0" variant="danger">ลบเนื้อหาทั้งหมด</Button>&nbsp;&nbsp; */}
                                <Button onClick={() => setShowPublicTopicStatus(true)} className="btn rounded-0" size="sm" variant="success">เผยแพร่</Button>
                            
                            </div> 
                        ) : (
                            <>
                            </>
                        )}



                        {/* {publicStatus == "กำลังดำเนินการ" && status == "public" ? (
                            <div className="float-end"></div>
                        ) : publicStatus == "เผยแพร่" && status == "public" ? (
                            <div className="float-end">
                            <p style={{fontSize: 18}}>เปลี่ยนสถานะการเผยแพร่ : &nbsp;&nbsp;
                            <Button className="btn rounded-0" variant="outline-warning" onClick={() => setShowEditStatus(true)}>หยุดชั่วคราว</Button></p>
                            </div>
                        ) : publicStatus == "หยุดชั่วคราว" && status == "public" ? (
                            <div className="float-end">
                            <p style={{fontSize: 18}}>เปลี่ยนสถานะการเผยแพร่ : &nbsp;&nbsp; 
                            <Button className="btn rounded-0" variant="outline-success" onClick={() => setShowEditStatus(true)}>เผยแพร่</Button></p>
                            </div>
                        ) : (
                            <>
                            </>
                        )}

                        {publicStatus == "กำลังดำเนินการ" && status == "inprogress" ? (

                            <div className="float-end">
                                
                                <Button onClick={() => setShowPublicTopicStatus(true)} className="btn rounded-0" variant="success">เผยแพร่</Button>
                            </div>

                        ) : (
                            <>

                            </>
                        )} */}
             


                    <Modal  
                        show={showEditStatus} 
                        onHide={handleShowEditStatusClose} 
                        animation={false} 
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        
                        <Form>

                            <Modal.Header>
                            <Modal.Title>เปลี่ยนสถานะการเผยแพร่</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>

                            <p>เปลี่ยนสถานะการเผยแพร่ใช่หรือไม่ ?</p>
                                                                
                            </Modal.Body>

                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleShowEditStatusClose}>
                                ยกเลิก
                            </Button>
                                                                        
                            <Button variant="success" type="submit" onClick={editStatusHandler}>
                                บันทึกการแก้ไข
                            </Button>
                            </Modal.Footer>

                        </Form>

                    </Modal>


                    <Modal  
                        show={showPublicTopicStatus} 
                        onHide={handleShowPublicTopicClose} 
                        animation={false} 
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        
                        <Form>

                            <Modal.Header>
                            <Modal.Title>เผยแพร่บทความ</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>

                            <p>เผยแพร่บทความใช่หรือไม่ ?</p>
                                                                
                            </Modal.Body>

                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleShowPublicTopicClose}>
                                ยกเลิก
                            </Button>
                                                                        
                            <Button variant="success" type="submit" onClick={publicTopicHandler}>
                                บันทึกการแก้ไข
                            </Button>
                            </Modal.Footer>

                        </Form>

                    </Modal>

                    <Modal  
                        show={showPublicTopicStatus} 
                        onHide={handleShowPublicTopicClose} 
                        animation={false} 
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        
                        <Form>

                            <Modal.Header>
                            <Modal.Title>เผยแพร่บทความ</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>

                            <p>เผยแพร่บทความใช่หรือไม่ ?</p>
                                                                
                            </Modal.Body>

                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleShowPublicTopicClose}>
                                ยกเลิก
                            </Button>
                                                                        
                            <Button variant="success" type="submit" onClick={publicTopicHandler}>
                                บันทึกการแก้ไข
                            </Button>
                            </Modal.Footer>

                        </Form>

                    </Modal>


                    <Modal  
                        show={showDeleteArticle} 
                        onHide={handlShowDeleteArticleClose} 
                        animation={false} 
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        
                        <Form>

                            <Modal.Header>
                            <Modal.Title>ลบบทความ</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>

                            <p>ลบบทความใช่หรือไม่ ?</p>
                                                                
                            </Modal.Body>

                            <Modal.Footer>
                            <Button variant="secondary" onClick={handlShowDeleteArticleClose}>
                                ยกเลิก
                            </Button>
                                                                        
                            <Button variant="success" type="submit" onClick={deleteArticleHandler}>
                                ลบบทความ
                            </Button>
                            </Modal.Footer>

                        </Form>

                    </Modal>

                    {/* {publicStatus == "กำลังดำเนินการ" ? (
                        <></>
                    ) : publicStatus == "เผยแพร่" ? (
                        <span className="float-end">
                            <Form.Select className="rounded-0" aria-label="Default select example"
                                onChange={(e) => setPublicString(e.target.value)}
                                defaultValue={setPublicString}
                            >
                                {publicOption.map((option, index) => (
                                <option key={index}>{option}</option>
                                ))}
                                
                            </Form.Select>
                        </span>
                    ) : publicStatus == "หยุดชั่วคราว" ? (
                        <span className="float-end">
                            <Form.Select className="rounded-0" aria-label="Default select example"
                                onChange={(e) => setPublicString(e.target.value)}
                                defaultValue={setPublicString}
                            >
                                {publicOption.map((option, index) => (
                                <option key={index}>{option}</option>
                                ))}
                                
                            </Form.Select>
                        </span>
                    ) : (
                        <>
                        </>
                    )} */}

                    <Modal  
                        show={showeditarticleName} 
                        onHide={handleCloseEditArticleName} 
                        animation={false} 
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        
                        <Form>

                            <Modal.Header>
                            <Modal.Title>การเปลี่ยนชื่อบทความ</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>

                   

                            <Form.Label>เปลี่ยนชื่อบทความใหม่</Form.Label>
                            <Form.Control 
                                type="text"
                                className="rounded-0" 
                                style={{ paddingLeft: 10 }}

                                value={articleNameEdit}

                                onChange={(e) => {
                                setArticleNameEdit(e.target.value);
                                }}

                                placeholder={"ชื่อบทความใหม่ ..."}
                                
                            />
                            <br />
                                                                
                            </Modal.Body>

                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseEditArticleName}>
                                ยกเลิก
                            </Button>
                                                                        
                            <Button variant="success" type="submit" onClick={editArticleName}>
                                เปลี่ยนชื่อบทความ
                            </Button>
                            </Modal.Footer>

                        </Form>

                    </Modal>


                    

                    

                    </h3>

                    <h3 className="pb-3 border-bottom"></h3>
                
                </Container>

            {/* </form> */}
        
            </div>
            
            );
        })}




        {/* /////////////////////////////////////////////////// Display TOPIC/SECTION //////////////////////////////////////////////////////////////////// */}
        <Container>

            

        <br/>

        <Row>

        <Col sm={6}>
        <h3><strong>{articleName}</strong> &nbsp;&nbsp;<span style={{color: "gray", fontSize: 20}} onClick={() => (setShoweditarticleName(true))}><BsPencil/></span></h3>

        {/* <Col style={{textAlign: "center"}}><img src={resizefileName} style={{marginBottom: 20, width: 590}}/> </Col> */}

        {(articleID == "") ? (
                        <>
                        </>
                    ) : 
                    (

                        <>
                            {!!link ? (
                        
                                <Col style={{textAlign: "center"}}><img class='contentimage' src={link} style={{marginBottom: 20}}/> </Col>
                            ) : (
                                <Col style={{textAlign: "center", fontSize: 120, color: 'gray'}}><BsImage/></Col>
                            )}
                        
                            <br/>
            
                            {progressStatus ? (
                                
            
                                <ProgressBar striped variant="success" now={progress} />
                                
                            ) : null}
        
                        <br/>

                        <form>

                            

                            
                            <Form.Label>อัพโหลดไฟล์รูปภาพ</Form.Label>
                                <InputGroup className="mb-3 rounded-0 was-validated">
                                    <Form.Control
                                        aria-label="Example text with button addon"
                                        aria-describedby="basic-addon1"
                                        type="file"
                                        className="custom-file-label rounded-0"  
                                        id="customFile" 
                                        placeholder="" 
                                        name="customFile" 

                                        onChange={ (e) => {
                                            const file = e.target.files[0];
                                                setFile(file);
                                                setLink(link);
                                        }}


                                    />

                                    <Button 
                                        type="submit"
                                        onClick={uploadHandler}
                                        className="btn btn-success rounded-0"
                                    >
                                        อัลโหลด
                                    </Button>
                                </InputGroup>

                                <br/>


                                {/* <Form.Label>ประเภทไฟล์ที่อัพโหลด</Form.Label>
                                    <Form.Select className="rounded-0" aria-label="Default select example"
                                    onChange={(e) => setArticleContentTypeString(e.target.value)}
                                    defaultValue={articleContentTypeString}
                                >
                                    {articleContentTypeOption.map((option, index) => (
                                    <option key={index}>{option}</option>
                                    ))}
                                    </Form.Select> */}
                                {/* <h2>
                                    {" "}
                                    You selected{" "}
                                    <span style={{ backgroundColor: "yellow" }}>{courseExTypeString}</span>
                                </h2> 
                                <br/> */}

                            <Form.Label>ชื่อหัวข้อย่อย</Form.Label>
                            <Form.Control 
                                type="text"
                                className="rounded-0" 
                                style={{ paddingLeft: 10 }}

                                value={articleHeaderString}

                                onChange={(e) => {
                                setArticleHeaderString(e.target.value);
                                }}

                                placeholder={"หัวข้อย่อย ..."}
                                
                            />
                            <br />

                                <Form.Label>เนื้อหาหัวข้อย่อย</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    className="rounded-0"
                                    rows={8} 
                                    value={articleSectionString}
                                    onChange={(e) => {
                                    setArticleSectionString(e.target.value);
                                    }}

                                    placeholder={"เนื้อหา ..."}
                                                        
                                />
                                <br />

                                <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>ที่มา (หากมี)</Accordion.Header>
                                        <Accordion.Body>
                                            <Form.Label>ข้อความอ้างอิง</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                className="rounded-0" 
                                                style={{ paddingLeft: 10 }}

                                                value={articleRefTextString}

                                                onChange={(e) => {
                                                setArticleRefTextString(e.target.value);
                                                }}

                                                placeholder={"ที่มา/อ้างอิง/ขอบคุณเนื้อหา/พิกัด ..."}
                                                
                                            />
                                            <br />
                                            <Form.Label>ลิ้งค์แหล่งอ้างอิง</Form.Label>
                                           
                                            <Form.Control 
                                                type="text"
                                                className="rounded-0" 
                                                style={{ paddingLeft: 10 }}

                                                value={articleLinkRefTextString}

                                                onChange={(e) => {
                                                setArticleLinkRefTextString(e.target.value);
                                                }}

                                                placeholder={"ลิ้งค์แหล่งอ้างอิง ..."}
                                                
                                            />
                                     
                                            <br />
                                        </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>

                                <br/>



                                {/* <Button onClick={handleSubmit} variant="outline-success" className="btn rounded-0" type="submit">เพิ่มเนื้อหา</Button> */}

                                <Button onClick={handleSubmit} className="btn btn-success btn rounded-0" type="submit">เพิ่มเนื้อหา</Button>

                                {/* {!link ? 
                                        <>
                                            <div>
                                                <Button variant="outline-success" className="btn rounded-0" disabled>เพิ่มเนื้อหา</Button>
                                            </div>
                                            <br/> 
                                        </>
                                    :
                                        <>
                                            <div>
                                                <Button onClick={handleSubmit} className="btn btn-success btn rounded-0" type="submit">เพิ่มเนื้อหา</Button>
                                            </div>
                                            <br/>
                                                            
                                        </>
                                } */}

                        </form>
                        </>

                    )}



        </Col>

        

        <Col sm={6}>

        <div class='bordersmalldisplay'></div>
         

            {articleData.map((item, index) => {

                        
                            
                return (
                    
                    <div>
                        

                        
                            <div class="displaysampleposition"><h3><strong>การแสดงผล</strong>
                            <span className="float-end"><Button onClick={() => setShowSampleTopic(true)} variant="outline" className="rounded-0" size="sm" style={{textDecoration: 'none', borderColor: "darkblue", color: "darkblue"}} 
                            ><span style={{fontWeight: "bolder"}}><BsDisplay/></span>&nbsp;&nbsp;&nbsp;ตัวอย่างการแสดงผล</Button>{' '}</span></h3></div>
                      
                        
                        <br/>
                      


                        <Modal
                                show={showSampleTopic}
                                onHide={handleSampleTopicClose} 
                                size="lg"
                                aria-labelledby="example-modal-sizes-title-lg"
                            >
                                <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    ตัวอย่างการแสดงผลบทความ
                                </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                <h3><strong>{articleName}</strong></h3> 
                                <p style={{fontSize: 12}}>{item.writerName} | {(item.articleDate).toDate().toLocaleDateString('th-TH', {year: 'numeric', month: 'long', day: 'numeric', weekday: 'long',})} | {(item.articleDate).toDate().toLocaleTimeString()} | &nbsp;&nbsp;&nbsp;<span style={{fontSize: 20}}><BsEye/> {viewCount + 1}</span></p>

                                { (item.articleHeader) 
                                    ? (item.articleHeader).map((itemheader, keyheader) =>
                                        <>

                                        { (item.articleSection) 
                                            ? (item.articleSection).map((itemsection, keysection) =>

                                                <> 
                                                { (keyheader == keysection) 
                                                    ? (
                                                    <>
                                                     
                                                        { (item.articleContent) 
                                                            ? (item.articleContent).map((itemcontent, keycontent) =>

                                                                <> 

                                                                { (keyheader == keycontent) 
                                                                ? (
                                                                    <>


                                                                        { (item.articleRefText) 
                                                                        ? (item.articleRefText).map((itemreftext, keyreftext) =>

                                                                            <>
                                                                            { (keyheader == keyreftext) 
                                                                                ? (
                                                                                <>

                                                                                { (item.articleLinkRefText) 
                                                                                ? (item.articleLinkRefText).map((itemlinkreftext, keylinkreftext) =>

                                                                                <>
                                                                                { (keyheader == keylinkreftext) 
                                                                                ? (
                                                                                <>

                                                                                    {(itemcontent && (itemheader || itemsection || itemreftext || itemlinkreftext)) ? (
                                                                                        <>
                                                                                            <img class='contentimage' src={itemcontent} style={{marginBottom: 20}}/>
                                                                                            <h3>{itemheader}</h3>
                                                                                            <p style={{whiteSpace: "pre-line"}}>{itemsection.replaceAll("<br/>", "\n")}</p>

                                                                                            <p>
                                                                                            {itemheader || itemsection ? (

                                                                                                <>
                                                                                                    {itemreftext || itemlinkreftext ? (
                                                                                                    <>
                                                                                                        {itemreftext} <a href={itemlinkreftext} target="_blank" rel="noreferrer">{itemlinkreftext}</a><br/><br/>
                                                                                                    </>
                                                                                                    ) : (<><br/></>)}
                                                                                                </>

                                                                                            ) : (

                                                                                                <>
                                                                                                    {itemreftext} <a href={itemlinkreftext} target="_blank" rel="noreferrer">{itemlinkreftext}</a><br/><br/>
                                                                                                </>
                                                                                                
                                                                                            )}
                                                                                        
                                                                                            </p>
                                                                                        </>

                                                                                    ) : (itemcontent && !(itemheader || itemsection || itemreftext || itemlinkreftext)) ? (
                                                                                        <>
                                                                                        <img class='contentimage' src={itemcontent} style={{marginBottom: 20}}/>
                                                                                        
                                                                                        </>

                                                                                    ) : (
                                                                                        <p>
                                                                                        {itemheader || itemsection ? (

                                                                                            <>
                                                                                            <h3>{itemheader}</h3>
                                                                                            <p style={{whiteSpace: "pre-line"}}>{itemsection.replaceAll("<br/>", "\n")}</p>
                                                                                           

                                                                                            {itemreftext || itemlinkreftext ? (
                                                                                            <>
                                                                                                {itemreftext} <a href={itemlinkreftext} target="_blank" rel="noreferrer">{itemlinkreftext}</a>
                                                                                            </>
                                                                                            ) : (<><br/></>)}

                                                                                            </>

                                                                                            ) : (
                                                                                            <>{itemreftext} <a href={itemlinkreftext} target="_blank" rel="noreferrer">{itemlinkreftext}</a><br/></>
                                                                                            )}
                                                                                    
                                                                                        </p>
                                                                                    )  }
                                                                    
                                        
                                                                                    






                                                                                    


                                                                                </>) : (<></>)}


                                                                            </>
                                                                        ) : <></>}



                                                                                </>) : (<></>)}

                                                                            </>
                                                                        ) : <></>} 


                                                                    </>

                                                                ) : <></> }

                                                                </>
                                                            ) : <></> }


                                                    </>
                                                
                                                    ) : <></>
                                                }
                                                </>
                            


                                            )
                                            : <></>
                                        }

                                        </>
                                    ) 
                                    : <></>
                                }


                                                                        


                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSampleTopicClose}>
                                        ปิด
                                    </Button>
                                </Modal.Footer>
                        </Modal>

                                                                    

                        
                        <Accordion style={{lineHeight: 2.5}} defaultActiveKey={['0']} alwaysOpen>
                            <Accordion.Item className="rounded-0" eventKey="0">
                                <Accordion.Header><strong>ลำดับการแสดงผล</strong></Accordion.Header>
                                <Accordion.Body>

                                <h3>{articleName}</h3>

                                {(status == "public")
                                    ? (<p style={{fontSize: 12}}>{item.writerName} | {(item.articleDate).toDate().toLocaleDateString('th-TH', {year: 'numeric', month: 'long', day: 'numeric', weekday: 'long',})} | {(item.articleDate).toDate().toLocaleTimeString()} | &nbsp;&nbsp;&nbsp;<span style={{fontSize: 20}}><BsEye/> {viewCount}</span></p>
                                ) : (<p style={{fontSize: 12}}>{item.writerName} | วันเวลาที่เผยแพร่ | &nbsp;&nbsp;&nbsp;<span style={{fontSize: 20}}><BsEye/> 0</span></p>)}
                                

                                { (item.articleHeader) 
                                    ? (item.articleHeader).map((itemheader, keyheader) =>
                                        <>

                                        { (item.articleSection) 
                                            ? (item.articleSection).map((itemsection, keysection) =>

                                                <> 
                                                { (keyheader == keysection) 
                                                    ? (
                                                    <>
                                                     
                                                        { (item.articleContent) 
                                                            ? (item.articleContent).map((itemcontent, keycontent) =>

                                                                <> 

                                                                { (keyheader == keycontent) 
                                                                ? (
                                                                    <>


                                                                        { (item.articleRefText) 
                                                                        ? (item.articleRefText).map((itemreftext, keyreftext) =>

                                                                            <>
                                                                            { (keyheader == keyreftext) 
                                                                                ? (
                                                                                <>

                                                                                { (item.articleLinkRefText) 
                                                                                ? (item.articleLinkRefText).map((itemlinkreftext, keylinkreftext) =>

                                                                                <>
                                                                                { (keyheader == keylinkreftext) 
                                                                                ? (
                                                                                <>

                                                                                    {(itemcontent && (itemheader || itemsection || itemreftext || itemlinkreftext)) ? (
                                                                                        <img class='contentimage' src={itemcontent} style={{marginBottom: 20}}/>
                                                                                    ) : (
                                                                                        <img class='contentimage' src={itemcontent} style={{marginBottom: -50}}/>
                                                                                    )}
                                                                                
                                                                                <h3>{itemheader}</h3>
                                                                                <p style={{whiteSpace: "pre-line", lineHeight: 1.5}}>{itemsection.replaceAll("<br/>", "\n")}

                                                                                {itemreftext || itemlinkreftext ? (
                                                                                        <>
                                                                                            <br/><br/>{itemreftext} <a href={itemlinkreftext} target="_blank" rel="noreferrer">{itemlinkreftext}</a><br/>
                                                                                        </>
                                                                                    ) : (<><br/><br/></>)}
                                                                                </p>

                                                                                <div className="float-end"><Button style={{color: "#87CEFA", textDecoration: 'none'}} variant="link" size="sm" onClick={() => (setShow(true), setArticleHeaderStringEdit(itemheader), setArticleSectionStringEdit(itemsection), setArticleRefTextStringEdit(itemreftext), setArticleLinkRefTextStringEdit(itemlinkreftext), setArticleImgStringEdit(itemcontent), setHeaderKey(keyheader))}>แก้ไขเนื้อหา</Button>{' '}<Button style={{color: '#F08080', textDecoration: 'none'}} variant="link" onClick={() => (setShowdeletesectioncontent(true), setHeaderKey(keyheader))} size="sm">ลบเนื้อหาส่วนนี้</Button></div>

                                                                                <br/>
                                                                                <h3 className="pb-3 border-bottom"></h3>
                                                                                <br/>


                                                                                </>) : (<></>)}


                                                                            </>
                                                                        ) : <></>}



                                                                                </>) : (<></>)}

                                                                            </>
                                                                        ) : <></>} 


                                                                    </>

                                                                ) : <></> }

                                                                </>
                                                            ) : <></> }


                                                    </>
                                                
                                                    ) : <></>
                                                }
                                                </>
                            


                                            )
                                            : <></>
                                        }

                                        </>
                                    ) 
                                    : <></>
                                }
                                
                             
                                <br/>

                                </Accordion.Body>
                            </Accordion.Item> 
                        </Accordion>

                        </div>
                        
                );


                })}






                {/* ///////////////////////////////////////////////////////************************************* */ }
                <Modal
                        show={show} 
                        onHide={handleClose} 
                        animation={false} 
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                    <Form>

                        <Modal.Header closeButton>
                        <Modal.Title>แก้ไขเนื้อหา</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>




                        {!!articleImgStringEdit ? (
                            <>
                            <Col style={{textAlign: "center"}}><img class='contentimage' src={articleImgStringEdit} style={{marginBottom: 20}}/> </Col>
                            <div className="float-end"><Button style={{color: '#F08080', textDecoration: 'none'}} variant="link" onClick={() => (setShowdeleteImg(true), setHeaderKey(headerKey))} size="sm">ลบรูปภาพนี้</Button></div>
                            <br/>
                            <br/>
                            </> 
                        ) : !!linkEdit ? (
                            <>
                            <Col style={{textAlign: "center"}}><img class='contentimage' src={linkEdit} style={{marginBottom: 20}}/> </Col>
                            <div className="float-end"><Button style={{color: '#F08080', textDecoration: 'none'}} variant="link" onClick={() => (setShowdeleteImg(true), setHeaderKey(headerKey))} size="sm">ลบรูปภาพนี้</Button></div>
                            <br/>
                            <br/>
                            </>
                        ) : (
                            <Col style={{textAlign: "center", fontSize: 120, color: 'gray'}}><BsImage/></Col>
                        ) }
                
                

                    
    
                    {progressStatusEdit ? (
                        
    
                        <ProgressBar striped variant="success" now={progressEdit} />
                        
                    ) : null}

         
                    
                    <Form.Label>เปลี่ยนรูปภาพ</Form.Label>
                        <InputGroup className="mb-3 rounded-0 was-validated">
                            <Form.Control
                                aria-label="Example text with button addon"
                                aria-describedby="basic-addon1"
                                type="file"
                                className="custom-file-label rounded-0"  
                                id="customFile" 
                                placeholder="" 
                                name="customFile" 

                                onChange={ (e) => {
                                    const file = e.target.files[0];
                                        setFileEdit(file);
                                        setLinkEdit(linkEdit);
                                        setArticleImgStringEdit(linkEdit);
                                }}


                            />

                            <Button 
                                type="submit"
                                onClick={uploadHandlerEdit}
                                className="btn btn-success rounded-0"
                            >
                                อัลโหลด
                            </Button>
                        </InputGroup>

                        <br/>



                                                            
                                                                            
                        <Form.Group className="mb-3">
                            <Form.Label>ชื่อหัวข้อย่อย</Form.Label>

                            <Form.Control 
                            type="text"
                            className="rounded-0" 
                            style={{ paddingLeft: 10 }}
                            value={articleHeaderStringEdit}
                            onChange={(e) => {
                                setArticleHeaderStringEdit(e.target.value);
                            }}
                                                                        
                            />
                                                                    
                        </Form.Group>


                 
                            <Form.Label>เนื้อหาหัวข้อย่อย</Form.Label>
                                    <Form.Control 
                                    as="textarea" 
                                    className="rounded-0"
                                    rows={8} 
                                    value={articleSectionStringEdit}
                                    onChange={(e) => {
                                    setArticleSectionStringEdit(e.target.value);
                                }}
                                                                                                        
                            />

                            <br/>


                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>ที่มา (หากมี)</Accordion.Header>
                                        <Accordion.Body>
                                            <Form.Label>ข้อความอ้างอิง</Form.Label>
                                                    <Form.Control 
                                                    type="text"
                                                    className="rounded-0" 
                                                    style={{ paddingLeft: 10 }} 
                                                    value={articleRefTextStringEdit}
                                                    onChange={(e) => {
                                                    setArticleRefTextStringEdit(e.target.value);
                                                }}
                                                                                                                        
                                            />
                                            <br/>
                                            <Form.Label>ลิ้งค์แหล่งอ้างอิง</Form.Label>
                                                    <Form.Control 
                                                    type="text"
                                                    className="rounded-0" 
                                                    style={{ paddingLeft: 10 }} 
                                                    value={articleLinkRefTextStringEdit}
                                                    onChange={(e) => {
                                                    setArticleLinkRefTextStringEdit(e.target.value);
                                                }}
                                                                                                                        
                                            />
                                            <br/>
                                        </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>    


                        </Modal.Body>

                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    ยกเลิก
                                </Button>
                                                                            
                                <Button variant="success" onClick={() => editHandler(headerKey, articleHeaderStringEdit, articleSectionStringEdit, articleRefTextStringEdit, articleLinkRefTextStringEdit, articleImgStringEdit)}>
                                    บันทึกการแก้ไข
                                </Button>
                            </Modal.Footer>

                            </Form>

                        </Modal>

                        {/* ////////////////////////////////////////////////////////////////////// */}
                         <Modal  
                            show={showdeletesectioncontent} 
                            onHide={handleCloseDeleteSelectContent} 
                            animation={false} 
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            class='modal-backdrop'
                        >

                        <Form>

                            <Modal.Header closeButton>
                            <Modal.Title>ลบเนื้อหา</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>

                            <p>ลบเนื้อหาส่วนนี้ใช่หรือไม่ ?</p>
                                                            
                                </Modal.Body>

                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseDeleteSelectContent}>
                                            ยกเลิก
                                        </Button>
                                                                    
                                                                                
                                        <Button variant="danger" onClick={() => deleteSelectContentHandler(headerKey)}>
                                            ลบ
                                        </Button>
                                    </Modal.Footer>

                        </Form>

                    </Modal>

                    {/* ////////////////////////////////////////////////////////////////////// */}
                    <Modal  
                            show={showdeleteImg} 
                            onHide={handleCloseDeleteImg} 
                            animation={false} 
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            class='modal-backdrop'
                        >

                        <Form>

                            <Modal.Header closeButton>
                            <Modal.Title>ลบรูปภาพ</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>

                            <p>ลบรูปภาพนี้ใช่หรือไม่ ?</p>
                                                            
                                </Modal.Body>

                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseDeleteImg}>
                                            ยกเลิก
                                        </Button>
                                                                    
                                                                                
                                        <Button variant="danger" onClick={() => deleteImgHandler(headerKey)}>
                                            ลบ
                                        </Button>
                                    </Modal.Footer>

                        </Form>

                    </Modal>



                    {/* ////////////////////////////////////////////////////////////////////// */}
                    <Modal  
                            show={showdeleteArticle} 
                            onHide={handleCloseDeleteArticle} 
                            animation={false} 
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            class='modal-backdrop'
                        >

                        <Form>

                            <Modal.Header closeButton>
                            <Modal.Title>ลบบทความ</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>

                            <p>ลบบทความนี้ใช่หรือไม่ ?</p>
                                                            
                                </Modal.Body>

                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseDeleteArticle}>
                                            ยกเลิก
                                        </Button>
                                                                    
                                                                                
                                        <Button variant="danger" onClick={deleteArticleHandler}>
                                            ลบ
                                        </Button>
                                    </Modal.Footer>

                        </Form>

                    </Modal>


                <br/>
                <br/>

                </Col>
            </Row>

            <br/>

     

                {publicStatus == "กำลังดำเนินการ" && status == "public" ? (
                        <div className="float-end">
                            <Link to={'/MyArticle/'+ uid} style={{textDecoration: 'none'}}>
                                    <Button variant="outline-success" className="btn rounded-0">
                                        บทความของฉัน
                                    </Button>&nbsp;&nbsp;
                            </Link>
                            {/* <Button onClick={() => setShowDeleteArticle(true)} className="btn rounded-0" variant="danger">ลบเนื้อหาทั้งหมด</Button>&nbsp;&nbsp; */}
                            <Button onClick={() => setShowdeleteArticle(true)} className="btn rounded-0" variant="danger">ลบบทความ</Button>&nbsp;&nbsp;
                            {/* <Button onClick={() => setShowPublicTopicStatus(true)} className="btn rounded-0" variant="success">เผยแพร่</Button> */}
                        </div>
                    ) : publicStatus == "เผยแพร่" && status == "public" ? (
                        <div className="float-end">
                            <Link to={'/MyArticle/'+ uid} style={{textDecoration: 'none'}}>
                                    <Button variant="outline-success" className="btn rounded-0">
                                        บทความของฉัน
                                    </Button>&nbsp;&nbsp;
                            </Link>
                            <Button onClick={() => setShowdeleteArticle(true)} className="btn rounded-0" variant="danger">ลบบทความ</Button>&nbsp;&nbsp;
                            {/* <Button onClick={() => setShowEditStatus(true)} className="btn rounded-0" variant="outline-warning">หยุดชั่วคราว</Button> */}
                        </div>
                    ) : publicStatus == "หยุดชั่วคราว" && status == "public" ? (
                        <div className="float-end">
                            <Link to={'/MyArticle/'+ uid} style={{textDecoration: 'none'}}>
                                    <Button variant="outline-success" className="btn rounded-0">
                                        บทความของฉัน
                                    </Button>&nbsp;&nbsp;
                            </Link>
                             <Button onClick={() => setShowdeleteArticle(true)} className="btn rounded-0" variant="danger">ลบบทความ</Button>&nbsp;&nbsp;
                            {/* <Button onClick={() => setShowEditStatus(true)} className="btn rounded-0" variant="outline-success">เผยแพร่</Button> */}
                        </div>
                    ) : (
                    <>
                    </>
                )}

                {publicStatus == "กำลังดำเนินการ" && status == "inprogress" ? (

                    
                    <>
                    <div className="float-end">
                            <Link to={'/MyArticle/'+ uid} style={{textDecoration: 'none'}}>
                                    <Button variant="outline-success" className="btn rounded-0">
                                        บทความของฉัน
                                    </Button>&nbsp;&nbsp;
                            </Link>
                        <Button onClick={() => setShowdeleteArticle(true)} className="btn rounded-0" variant="danger">ลบบทความ</Button>&nbsp;&nbsp;
                        {/* <Button onClick={() => setShowPublicTopicStatus(true)} className="btn rounded-0" variant="success">เผยแพร่</Button> */}
                    </div>
                    </>

                ) : (
                    <>
                    
                    </>
                )}

                
                {/* <span className="float-end"><Button className="btn btn-success rounded-0" type="submit">บันทึก</Button>&nbsp;&nbsp;&nbsp; 
                <Link to={"/UploadTopic/" + articleId}>
                    <Button className="rounded-0" variant="success" style={{textDecoration: 'none'}} >
                <span style={{fontWeight: "bolder"}}><BsPlayBtn/></span>&nbsp;&nbsp;&nbsp;โพสเนื้อหา</Button>
                </Link>{' '}</span> */}

            <br/>

        </Container>

        <br/>
        <br/>
      

        </>
    );
}

export default CreateArticle;