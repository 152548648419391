import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';


const firebaseConfig = {
    apiKey: "AIzaSyDFVN2KUF2ZK2ROZDe4dm4CDlLvL5qXlew",
    authDomain: "selpage-9425f.firebaseapp.com",
    projectId: "selpage-9425f",
    storageBucket: "selpage-9425f.appspot.com",
    messagingSenderId: "558091687977",
    appId: "1:558091687977:web:738ee95983a2d8f4846438",
    measurementId: "G-KK42CT8JFQ"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);

  
  // const database = firebaseApp.firestore();

  export const firestore = firebaseApp.firestore();

  export const storage = firebase.storage().ref();
  export const auth = firebaseApp.auth();
  export const googleProvider = new firebase.auth.GoogleAuthProvider();

  export default firebaseApp;