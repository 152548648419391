import React from "react";
import { useNavigate } from "react-router";

function Home({ login }) {

    let navigate = useNavigate();

    return (
        <div>
            <p>Home Page</p>
            {/* {location.state} */}
            <button onClick={() => {
                login();
                navigate('dashboard/')
            }}>
                Login
            </button>
        </div>
    );
}

export default Home;