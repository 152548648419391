import React, { useState, useEffect, useContext } from "react";
import { Container, Tabs, Tab, Form, Col, Row, Button, Card, InputGroup } from "react-bootstrap";
import { Link, redirect, useNavigate } from "react-router-dom";

import { FaSketch } from "react-icons/fa";
import { AiOutlineSketch } from "react-icons/ai";

import { auth, firestore } from "../../database/firebase";
import { storage } from "../../database/firebase";


import { AuthContext } from "../../component_s/Authentication/AuthProvider";

// import "./UploadVideo.css";


function UploadVideo() {

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    /* TEST QUERY DATA WITH productID */

    // const [uid, setUid] = useState("");

    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

   //const [uid, setUid] = useState("");

    var user = auth.currentUser;
    // var uid = "";
    var uid = "";

    if (user != null) {
        //email = user.email;
        uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                         // this value to authenticate with your backend server, if
                         // you have one. Use User.getToken() instead.
        console.log(uid);
        
        //TEST AUTH
        // refreshPage();
    } else {
        navigate("/");
    }
    



    

    // const [emailDefault, setEmailDefault] = useState("");

    // const [loading, setLoading] = useState(false);
    const [section, setSection] = useState([""]);
    const [about, setAbout] = useState([""]);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [address, setAddress] = useState("");

    const [role, setRole] = useState("");
    const [sPoint, setSPoint] = useState("");

    const [displayName, setDisplayName] = useState("");

    const [data, setData] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
    
        .where("uid", "==", uid);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            uid: doc.id,
                            name: doc.data().name,
                            email: doc.data().email,
                            phonenumber: doc.data().phonenumber,
                            address: doc.data().address,
                            sPoint: doc.data().sPoint,   
                            role: doc.data().role,

                            displayName: doc.data().displayName,
                            
                        },
                        
                    ];

                    setName(doc.data().name);
                    setPhonenumber(doc.data().phonenumber);
                    setEmail(doc.data().email);
                    setAddress(doc.data().address);

                    setRole(doc.data().role)
                    setSPoint(doc.data().sPoint);

                    setDisplayName(doc.data().displayName);
                    

                    };

                });

            setData((oldDataArray) => tempDataArray);
            

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [uid]); 

    

    const editHandler = (obj) => {
        
        const ref = firestore.collection("user");
        ref
            .doc(uid)
            .update(obj)
            .then(() => {
                console.log("แก้ไขข้อมูลสำเร็จ");
                refreshPage();
            })
            .catch((err) => console.log(err));
    };

    /* const submitHandler = (e) => {
        e.preventDefault();
        const obj = {
            
            name : name,
            email : email,
            phonenumber : phonenumber,
            address: address,
            displayName: displayName,

        };
      
        setName("");
        setEmail("");
        setPhonenumber("");
        setAddress("");
        setDisplayName("");

        editHandler(obj);
    }; */

    const myStore = () => {
        if (role == "merchant") {
            navigate("/MerchantView")
        } else {
            alert("ร้านค้าของฉันพร้อมเปิดให้บริการเร็วๆ นี้, ขออภัยในความไม่สะดวก");
        }
    }

    const signOutHandler = () => {
        auth.signOut().then(() => {
            console.log("Log Out Ok");
            setCurrentUser(null);
            navigate("/");
        })
        .catch((err) => {
            console.log("Log Out Not Ok." + err);
        })
    };


    //////////////////////////////////// ADD/REMOVE TEXT FIELD /////////////////////////////////////////////////////

    /* const uploadHandler = (e) => {

        e. preventDefault();

        if (!!file) {

            const fileName = file.name;
            const targetRef = subBucketRef.child(fileName);

            targetRef.put(file).then((response) => {
                console.log(response);
                response.ref.getDownloadURL().then((photoURL) => {
                    console.log(photoURL);
                    setLink(photoURL);
                });

            });

        } else {
                console.log("no file upload!!");
        }

    }; */

    const [formValues, setFormValues] = useState([{ section: "", links : ""}])

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
      }
    
    let addFormFields = () => {
        setFormValues([...formValues, { section: "", links: "" }])
      }
    
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }
    
    //////////////////////////////////////// UPLOAD DATA ///////////////////////////////////////////////////////////
    /* const [courseTopic, setCourseTopic] = useState("");*/

    //TEST Upload Video/Doc
    const [file, setFile] = useState("");
    const [link, setLink] = useState("");

    const subBucketRef = storage.child("mainBucket/course");
    const uploadHandler = (e) => {

        e. preventDefault();

        if (!!file) {

            const fileName = file.name;
            const targetRef = subBucketRef.child(fileName);

            targetRef.put(file).then((response) => {
                console.log(response);
                response.ref.getDownloadURL().then((photoURL) => {
                    console.log(photoURL);
                    setLink(photoURL);
                });

            });

        } else {
                console.log("no file upload!!");
        }

    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // alert(JSON.stringify(formValues));
 
        const obj = {

            //++ courseTopic, .......
            
            section : formValues.map((item, key) => formValues[key].section),
            // about : formValues.map((item, key) => formValues[key].about),

            //++ Upload Video Input
            links : formValues.map((item, key) => formValues[key].links),
            
        };

            /* setProductID("");
            setProductSortNumber("");

            setProductName(""); */
        
            ////////////////////////////////////////////////////////////////////
            uploadContentHandler(obj);

    }

    const uploadContentHandler = (obj) => {
           
        const ref = firestore.collection("course").doc("ce0J3diaDdF1glsCbc0N").collection("courseContent");
        ref
            // .doc()
            .add(obj)
            .then(() => {
                console.log("เพิ่มข้อมูลสำเร็จ");
                refreshPage();
            })
            .catch((err) => console.log(err));
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <>
        <br/>

        {data.map((item, index) => {

            

            return (
                            
                <div key={index}>    

                

                {/* <form onSubmit={submitHandler}> */}
                                
                    <Container>

                        
                        {/* <Link to="/MerchantView" style={{textDecoration: 'none', color: "black"}}> <Button variant="outline-secondary">ร้านค้าของฉัน</Button>{' '}</Link> */}
                        <Button onClick={myStore} variant="outline-secondary">ร้านค้าของฉัน</Button>{' '}
                        <Button onClick={signOutHandler} variant="outline-danger" type="submit">ออกจากระบบ</Button>
                        <br/>
                        <br/>
                        <br/>
                        <h3><strong>อัพโหลดวิดีโอ</strong></h3>
                        {/* <Link to="/MerchantView" style={{textDecoration: 'none', color: "black"}}> <Button variant="outline-secondary">ร้านค้าของฉัน</Button>{' '}</Link> */}
                        <br/>
                        <Link to="/AboutUs/SelpagePoint" style={{textDecoration: 'none', color: "black"}}><h4><span style={{fontSize: 35 ,color: "skyblue"}}><AiOutlineSketch/></span> คะแนนสะสม: <span style={{color: "limegreen"}}>{parseInt(sPoint)}</span></h4></Link>
                        <br/>
                        <h3 className="pb-3 border-bottom"></h3>
            
                        {/* Test Order View */}
                        {/* <Link to="/Order">ร้านค้าของฉัน</Link> */}

                        
                                    
                    </Container>

                {/* </form> */}
                    
                
                               
                </div>
                            
                             
                            
            );
        })}




        {/* /////////////////////////////////////////////////// ADD/REMOVE TEXT FIELD //////////////////////////////////////////////////////////////////// */}
        <Container>

        <form onSubmit={handleSubmit}>

            {/* <Form.Control 
                type="text"
                className="rounded-0" 
                style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                value={topicName}
                onChange={(e) => {
                setTopicName(e.target.value);
                }}
                placeholder={"*กรุณากรอกข้อมูล"}
            />
            <br /> */}

          {formValues.map((element, index) => (
            <div className="form-inline" key={index}>
              <label>Section</label>
              <input type="text" name="section" value={element.section || ""} onChange={e => handleChange(index, e)} />
              <label>About</label>
              {/* <input type="file" name="links" value={element.links || ""} onChange={e => handleChange(index, e)} /> */}
              <InputGroup className="mb-3 rounded-0 was-validated">
                    <Form.Control
                    aria-label="Example text with button addon"
                    aria-describedby="basic-addon1"
                    type="file"
                    className="custom-file-label rounded-0"  
                    // placeholder="" 
                    name="links" 
                    value={element.links || ""}
                        onChange={ (e) => {
                            
                        handleChange(index, e)

                        const file = e.target.files[0];
                        setFile(file);
                        setLink("");
                    }}
                    required

                    />

                        <Button 
                            type="submit"
                            onClick={uploadHandler}
                            className="btn btn-success rounded-0"
                            >
                            อัลโหลด
                        </Button>
                </InputGroup>

              {
                index ? 
                  <button type="button"  className="button remove" onClick={() => removeFormFields(index)}>Remove</button> 
                : null
              }

            </div>
            ))}
            <div className="button-section">
                <button className="button add" type="button" onClick={() => addFormFields()}>Add</button>
                <button className="button submit" type="submit">Submit</button>
            </div>

        </form>

        </Container>
         {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}


        <br/>
        <br/>
      

        </>
    );
}

export default UploadVideo;