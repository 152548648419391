import React, {useContext, useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./productcardview.css";


import ads_sample01 from '../images_s/ads_sample01.png';

import { Container, Nav, Navbar, NavDropdown, Form, Button, Image, Carousel, Row, Col, Card, Tabs, Tab, Spinner, Badge } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";

import product_sample01 from '../images_s/product_sample01.jpeg';
import product_sample02 from '../images_s/product_sample02.jpg';

import ads_testflight02 from '../images_s/ads_testflight02.jpg';
import ads_aboutus_02 from '../images_s/ads_aboutus_02.jpg';
import ads_aboutus_03 from '../images_s/ads_aboutus_03.jpg';
import ads_sample03 from '../images_s/ads_sample03.jpg';

import { firestore } from '../../database/firebase';
import { auth } from '../../database/firebase';
import { AuthContext } from '../Old_Test/Auth';

import { Link, Navigate } from "react-router-dom";

import { FaHeart, FaTruck, FaFacebookMessenger } from "react-icons/fa";
import { BsStarFill, BsStarHalf, BsStar, BsBagHeart } from "react-icons/bs";

import { text } from '@fortawesome/fontawesome-svg-core';
import Law from '../Product/Book/Law';
import Business from '../Product/Book/Business';
import Computer from '../Product/Book/Computer';
import CivilExamination from '../Product/Book/CivilExamination';
import Linguistics from '../Product/Book/Linguistics';
import Education from '../Product/Book/Education';

import BadgeSubCategory from '../Product/BadgeSubCategory';

import FlashSale from '../Product/FlashSale';
import ComputerArticleCategory from '../../component_m/Article/ArticleCategory/ComputerArticleCategory';
import NewestArticle from '../../component_m/Article/NewestArticle';
import PlantArticleCategory from '../../component_m/Article/ArticleCategory/PlantArticleCategory';
import TravelArticleCategory from '../../component_m/Article/ArticleCategory/TravelArticleCategory';
import GameArticleCategory from '../../component_m/Article/ArticleCategory/GameArticleCategory';
import FoodArticleCategory from '../../component_m/Article/ArticleCategory/FoodArticleCategory';
import GeneralArticleCategory from '../../component_m/Article/ArticleCategory/GeneralArticleCategory';
import AllArticle from '../../component_m/Article/AllArticle';


function HomeView() {
    

    var user = auth.currentUser;
    // var uid = "";
    var uid = "";

    if (user != null) {
       
        uid = user.uid;  
                     
        console.log(uid);

    } else {
        // navigate("/");
    }
    /* const {currentUser} = useContext(AuthContext);

    if (!currentUser) {
        return <Navigate to="/LogIn" replace />
    } */

    //Test useParams
    // let productId = "MIGhY5tyTiuRPeSWxDXC";

    const formatter = new Intl.NumberFormat('th', {
        style: 'currency',
        currency: 'THB',
        maximumSignificantDigits: 3,
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
    
    // const [productPriceCart, setProductPriceCart] = useState("");


  <Image src={[ads_sample01, product_sample01, product_sample02]} fluid />




    const [data, setData] = useState([]);
    const [productName, setProductName] = useState("");
    const [productDiscount, setProductDiscount] = useState("");

    const [loading, setLoading] = useState(false);


    var sliceProductName = "";

    var part = "";

    const [productLists, setProductLists] = useState([]);
    const [currentCursor, setCurrentCursor] = useState("");

    // var random = Math.floor(9000 + Math.random() * 999);

    useEffect(() => {
        const firsPageRef = firestore
            .collection("product")
            //.orderBy("productName")
            .orderBy("productSortNumber", "asc")
            .limit(4);
        firsPageRef.get().then((querySnapshot) => {
            let tempLists = [];
            querySnapshot.docs.forEach((doc) => {
                if (doc.exists) {

                    const currentProduct = {

                        productID: doc.id,
                        productID: doc.data().productID,
                        productName: doc.data().productName,
                        // .slice("0", "30")
                        productImage: doc.data().productImage,
                        productPrice: doc.data().productPrice,
                        storeName: doc.data().storeName,
                        productFullPrice: doc.data().productFullPrice,
                        productDiscount: doc.data().productDiscount, 
                        productSold: doc.data().productSold,
                        productBadge: doc.data().productBadge,

                        //RATING
                        ratingSum: doc.data().ratingSum,
                        ratingCount: doc.data().ratingCount,

                    };
                    tempLists = [...tempLists, currentProduct];
                }
            });
            setProductLists((prv) => tempLists);
            const currentLenght = querySnapshot.docs.length;
            const currentCursorFromFirstPage = querySnapshot.docs[currentLenght - 1];
            setCurrentCursor(currentCursorFromFirstPage);
        })
    }, []);

    const onMoreProducts = () => {
        const nextPageRef = firestore
            .collection("product")
            .orderBy("productSortNumber", "asc")
            .limit(4);
        nextPageRef.get().then((querySnapshot) => {
            const currentLenght = querySnapshot.docs.length;
            if (!currentCursor) {
                return;
            }
            const query = nextPageRef.startAfter(currentCursor);
            query.get().then((querySnapshot) => {
                let tempNewArray = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempNewArray = [
                            ...tempNewArray,
                            {
                                productID: doc.id,
                                productID: doc.data().productID,
                                productName: doc.data().productName,
                                // slice("0", "40")
                                productImage: doc.data().productImage,
                                productPrice: doc.data().productPrice,
                                storeName: doc.data().storeName,
                                productFullPrice: doc.data().productFullPrice,
                                productDiscount: doc.data().productDiscount, 
                                productSold: doc.data().productSold,
                                productBadge: doc.data().productBadge,

                                //RATING
                                ratingSum: doc.data().ratingSum,
                                ratingCount: doc.data().ratingCount, 
                            },
                        ];
                    }
                });
                setProductLists([...productLists, ...tempNewArray]);
                const currentCursorForNextPage = querySnapshot.docs[currentLenght -1];
                setCurrentCursor(currentCursorForNextPage);
            });
        });
    };
    

    return (
        
    <div>

    {/* <button onClick={()=> auth.signOut()} class="btn btn-danger">ออกจากระบบ</button> */}
      
      {/* ADS IMAGE */}
        {/* <img
        className="d-block w-100"
        src={ads_testflight02} fluid
        alt="First slide" /> */}


    {/* <Carousel>
              <Carousel.Item interval={1000}>
                  <img
                      className="d-block w-100"
                      src={ads_sample03} fluid
                      alt="First slide" />
              </Carousel.Item>
              <Carousel.Item interval={500}>
                  <img
                      className="d-block w-100"
                      src={ads_sample01} fluid
                      alt="Second slide" />
                  <Carousel.Caption>
                      <h3></h3>
                      <p></p>
                  </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                  <img
                      className="d-block w-100"
                      src={ads_testflight02} fluid
                      alt="Third slide" />
                  <Carousel.Caption>
                      <h3></h3>
                      <p></p>
                  </Carousel.Caption>
              </Carousel.Item>
    </Carousel> */}
    <Container>

    <FlashSale/>
    
    </Container>

    <Container>
        {/* <img style={{borderRadius: 5}}
        className="d-block w-100"
        src={ads_aboutus_03} fluid
        alt="First slide" /> */}
        <h3 className="pb-3 border-bottom" style={{marginTop: -20, marginBottom: 10}}></h3>
        <br/>
            
            
            {/* <br/>
            <h3><strong>พื้นที่แห่งการเรียนรู้</strong></h3>
            <p>เพิ่มพูนทักษะที่สำคัญ ค้นพบและแบ่งปันความรู้</p> */}

            {/* <Link to={'/Course/'+ "ce0J3diaDdF1glsCbc0N"} style={{textDecoration: 'none', color: "black"}}>TestFlight_Course</Link><br/>
            <Link to={'/UploadContent/'+ "ce0J3diaDdF1glsCbc0N"} style={{textDecoration: 'none', color: "black"}}>TestFlight_UploadVideo</Link> */}

           {/* TEST Instructor */}
            {/* <Link to={'/BookmarkArticle/'+ uid} style={{textDecoration: 'none', color: "black"}}>
                TestFlight_BookmarkArticle
            </Link><br/>

            <Link to={'/Instructor'} style={{textDecoration: 'none', color: "black"}}>
                TestFlight_Instructor
            </Link><br/> */}
       
            
            {/* <BadgeSubCategory/> */}
            {/* <h3 className="pb-3 border-bottom"></h3> */}
            {/* <br/>
            <br/> */}

            <NewestArticle/>

            <br/>
            <br/>

            <div id="grad1" class="line"></div>

            <br/>
            <br/>

            <div className="borderarticle">
                <TravelArticleCategory/>
            </div>

            <br/>
            <br/>

            <div id="grad1" class="line"></div>

            <br/>
            <br/>

            <div className="borderarticle">
                <FoodArticleCategory/>
            </div>

            <br/>
            <br/>

            <div id="grad1" class="line"></div>

            <br/>
            <br/>

            <div className="borderarticle">
                <GameArticleCategory/>
            </div>
            

            <br/>
            <br/>

            <div id="grad1" class="line"></div>

            <br/>
            <br/>

            <div className="borderarticle">
                
            <PlantArticleCategory/>
            </div>

            {/* <div className="borderarticle">
                <PlantArticleCategory/>
            </div> */}
            

            <br/>
            <br/>

            <div id="grad1" class="line"></div>

            <br/>
            <br/>

            <div className="borderarticle">
                <ComputerArticleCategory/>
            </div>
            
            <br/>
            <br/>

            <div id="grad1" class="line"></div>

            <br/>
            <br/>

            <div className="borderarticle">
                <GeneralArticleCategory/>
            </div>

            <br/>
            <br/>

            <div id="grad1" class="line"></div>

           <br/>

     
                <AllArticle/>
        

            <br/>
            <br/>
            
            

    </Container>
            
            
            {/* <h3><strong>เทคโนโลยีและธุรกิจ</strong></h3>
            <br/>
            <Tabs defaultActiveKey="technology" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="technology" title="เทคโนโลยี">

                <Computer/>
            </Tab>
            <Tab eventKey="business" title="ธุรกิจและการตลาด">

                <Business/>
            </Tab>

            </Tabs>

                
                
            
    </Container>

    <Container>
            <br/>

            <h3><strong>สอบเข้าทำงาน</strong></h3>
            <br/>
            <Tabs defaultActiveKey="civilexamination" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="civilexamination" title="สอบบรรจุ/สอบเข้าทำงาน">

                <CivilExamination/>
            </Tab>
            <Tab eventKey="law" title="กฎหมาย">
                <Law/>
            </Tab>

            </Tabs>

            
            
    </Container>

    <Container>
            <br/>

            <h3><strong>ภาษาศาสตร์ การศึกษา</strong></h3>
            <br/>
            <Tabs defaultActiveKey="Linguistics" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="Linguistics" title="ภาษาศาสตร์">

                <Linguistics/>
            </Tab>
            <Tab eventKey="law" title="การศึกษา">
                <Education/>
            </Tab>

            </Tabs>
            
          
    </Container> */}





    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
    
    
    {/* <Container>

            

            <div id="grad1" class="line"></div>

        
        <br/>
        <h3><strong><BsBagHeart/> ร้านค้า</strong></h3>
        <br/>
        <BadgeSubCategory/>
        <br/>
        <br/>

        <Row xs={2} md={4} className="g-4">

                        

                        {productLists.map((item) => {
                            
                            return (
                               
                                <div key={item.id}>

                                    <div className='card'>
                                    <Link to={'/ProductDetail/'+ item.productID} style={{textDecoration: 'none', color: "black"}}>
                                        <Card>
                                            <Card.Img variant="top" style={{width: "100%", height: "auto"}} src={item.productImage[0]} />
                                            
                                            <Card.Body>
                                                <Card.Title>
                                                </Card.Title>

                                                <p class='productname' style={{marginTop: -14, fontWeight: 500}}>{item.productName}</p>


                                                    { (item.ratingSum / item.ratingCount) <= 0 ? (
                                                            <>
                                                            
                                                            </>
                                                        ) : (item.ratingSum / item.ratingCount) <= 1 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStar/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 1.5 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarHalf/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 2 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStar/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 2.5 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarHalf/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 3 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStar/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 3.5 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarHalf/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 4 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStar/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) <= 4.5 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarHalf/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (item.ratingSum / item.ratingCount) > 4.5 ? (
                                                            <p>
                                                                <span style={{position: "relative", top: 2, fontWeight: 'bolder', color: 'goldenrod'}}>{(parseFloat(item.ratingSum) / parseFloat(item.ratingCount)).toFixed(1)}</span> <span style={{color: "orange", fontSize: 13}}> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/> <BsStarFill/></span> <span style={{position: "relative", top: 1, fontSize: 12}}>({item.ratingCount})</span>{' '}
                                                            </p>
                                                        ) : (
                                                            <p>
                                                                <br/>
                                                               
                                                            </p>
                                                        ) }

                                                    

                                                    <div class='price' style={{marginTop: -14, marginBottom: -15}}>{formatter.format(item.productPrice)} <del style={{color: "gray"}}>{formatter.format(item.productFullPrice)}</del></div>

                                                    {item.productBadge ? (
                                                        <div class='bageanddiscount'> 
                                                            { item.productDiscount <= 9 ? ( 
                                                                <Badge className="rounded-0" bg="warning">{item.productBadge}</Badge> 
                                                            ) : (
                                                                <><Badge className="rounded-0" bg="warning">{item.productBadge}</Badge> <Badge className="rounded-0" bg="danger">-{item.productDiscount}%</Badge></> 
                                                            ) } <span style={{marginTop: 1}} className="float-end"><p>ขายแล้ว {item.productSold}</p></span>
                                                        </div>
                                                    ) : (
                                                       
                                                        <div class='bageanddiscount'> 
                                                            { item.productDiscount <= 9 ? ( 
                                                    
                                                                <>
                                                                </>
                                                                // <Badge className="rounded-0" bg="warning">{item.productBadge}</Badge>
                                                                
                                                            ) : (
                                                                <><Badge className="rounded-0" bg="warning">{item.productBadge}</Badge> <Badge className="rounded-0" bg="danger">- {item.productDiscount}%</Badge></> 
                                                            ) } <span style={{marginTop: 1}} className="float-end"><p>ขายแล้ว {item.productSold}</p></span>
                                                        </div>
                                                        
                                                    )}
                                                    

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                    </div>
                                    
                                </div>
                            )
                        })}
                </Row>

            <br/>

            {currentCursor ? (
                <Col className='float-end'>

                    <Button variant="warning" className="rounded-0" onClick={onMoreProducts}>สินค้าเพิ่มเติม</Button>
        
                </Col>
            ) : (
                <Col className='float-end'>

                    <Button variant="outline-secondary" className="rounded-0" disabled>ไม่มีสินค้าเพิ่มเติม</Button>
        
                </Col>
            )}

        <br/>
        <br/>
        <br/>
        <br/>
        

    </Container> */}


    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

    
    </div>

  );
}

export default HomeView;

