import React, {useEffect} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from "react-bootstrap";

import { NavLink, Link, useNavigate } from "react-router-dom";

import { FaFacebookF, FaInstagram, FaPinterest, FaTwitter, FaYoutube, FaCheck, FaSketch } from "react-icons/fa";
import { BsSendCheck, BsFill1CircleFill, BsFill2CircleFill, BsCheckLg } from "react-icons/bs";
import { AiOutlineSketch } from "react-icons/ai";


function QPoint() {

    

    return (

        <Container>

            <br/>

            <h3><strong><span style={{fontSize: 35 ,color: "skyblue"}}><AiOutlineSketch/></span> คะแนนสะสม</strong></h3>
          
            <h3 className="pb-3 border-bottom"></h3>
            <br/>
            <h4><strong><BsCheckLg/>  รับคะแนนสะสม</strong></h4>
            <p>รับคะแนนสะสมจากการซื้อสินค้า 1 คะแนน ในทุกการซื้อสินค้า 100 บาท</p><br/>

            <h4><strong><BsCheckLg/> การใช้คะแนน</strong></h4>
            <p>สิทธิพิเศษสำหรับสมาชิก สามารถนำคะแนนมาใช้เพื่อเป็นส่วนลดสินค้าใน EnqueX.com</p><br/>

            <h4><strong><BsCheckLg/> โปรโมชั่น</strong></h4>
            <p>ใช้คะแนนเพื่อรับโปรโมชั่นพิเศษใน EnqueX.com ที่จัดให้สมาชิก</p><br/>

            <h4><strong><BsCheckLg/> ตรวจสอบคะแนน</strong></h4>
            <p>สามารถตรวจสอบคะแนนได้ในข้อมูลของสมาชิก </p><br/>

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

        </Container>

    );

}

export default QPoint;