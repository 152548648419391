import React, {useContext, useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import Carousel from 'react-bootstrap/Carousel';

import ads_sample01 from '../images_s/ads_sample01.png';

import { Row, Col, Card, Tabs, Tab, Badge } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";

import product_sample01 from '../images_s/product_sample01.jpeg';
import product_sample02 from '../images_s/product_sample02.jpg';

import ads_testflight02 from '../images_s/ads_testflight02.jpg';

import { auth } from '../../database/firebase';
import { AuthContext } from '../Old_Test/Auth';

import { Link, Navigate } from "react-router-dom";

import { firestore } from '../../database/firebase';

import { FaHeart, FaTruck, FaFacebookMessenger } from "react-icons/fa";

function Test_Home() {

    const [productLists, setProductLists] = useState([]);
    const [currentCursor, setCurrentCursor] = useState("");

    useEffect(() => {
        const firsPageRef = firestore
            .collection("product")
            .orderBy("productID", "asc")
            .limit(4);
        firsPageRef.get().then((querySnapshot) => {
            let tempLists = [];
            querySnapshot.docs.forEach((doc) => {
                if (doc.exists) {

                    const currentProduct = {

                        productID: doc.id,
                        productID: doc.data().productID,
                        productName: doc.data().productName.slice("0", "30"),
                        productImage: doc.data().productImage,
                        productPrice: doc.data().productPrice,
                        storeName: doc.data().storeName,
                        productFullPrice: doc.data().productFullPrice,
                        productDiscount: doc.data().productDiscount, 
                        productSold: doc.data().productSold,
                        productBadge: doc.data().productBadge,

                    };
                    tempLists = [...tempLists, currentProduct];
                }
            });
            setProductLists((prv) => tempLists);
            const currentLenght = querySnapshot.docs.length;
            const currentCursorFromFirstPage = querySnapshot.docs[currentLenght - 1];
            setCurrentCursor(currentCursorFromFirstPage);
        })
    }, []);

    const onMoreProducts = () => {
        const nextPageRef = firestore
            .collection("product")
            .orderBy("productID", "asc")
            .limit(4);
        nextPageRef.get().then((querySnapshot) => {
            const currentLenght = querySnapshot.docs.length;
            if (!currentCursor) {
                return;
            }
            const query = nextPageRef.startAfter(currentCursor);
            query.get().then((querySnapshot) => {
                let tempNewArray = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        tempNewArray = [
                            ...tempNewArray,
                            {
                                productID: doc.id,
                                productID: doc.data().productID,
                                productName: doc.data().productName.slice("0", "30"),
                                productImage: doc.data().productImage,
                                productPrice: doc.data().productPrice,
                                storeName: doc.data().storeName,
                                productFullPrice: doc.data().productFullPrice,
                                productDiscount: doc.data().productDiscount, 
                                productSold: doc.data().productSold,
                                productBadge: doc.data().productBadge,
                            },
                        ];
                    }
                });
                setProductLists([...productLists, ...tempNewArray]);
                const currentCursorForNextPage = querySnapshot.docs[currentLenght -1];
                setCurrentCursor(currentCursorForNextPage);
            });
        });
    };
    

    

  return (

    <Container>

        <br/>
        <br/>
        <h3><strong>สินค้าใหม่</strong></h3>
        <br/>

        <Row xs={2} md={4} className="g-4">
                        {productLists.map((item) => {
                            
                            return (
                                <div key={item.id}>

                                    <div className='card'>
                                    <Link to={'/ProductDetail/'+ item.productID} style={{textDecoration: 'none', color: "black"}}>
                                        <Card>
                                            <Card.Img variant="top" style={{width: "100%", height: "auto"}} src={item.productImage[0]} />
                                            
                                            <Card.Body>
                                                <Card.Title>
                                                </Card.Title>

                                                <p><strong>{item.productName}</strong></p>

                                                {/* { (item.productName).length < 35 ? (
                                                    <>
                                                    <p><strong>{item.productName}</strong></p><br/>
                                                    </>
                                                ) : (
                                                    <p><strong>{(item.productName).substring(0, 35) + '...'}</strong></p>
                                                )} */}
                                                    
                                                    {/* {item.storeName} */}
                                                    {/* <br/>ขายแล้ว {item.productSold} */}
                                                    
                                                    {/* <p style={{color: "gray", fontSize: "12px", marginTop: "1px"}}>{item.storeName}</p> */}
                                                    

                                                    { item.productDiscount == 5 ? (
                                                        <p><span style={{fontSize: 18, color: "red"}}><FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) :
                                                        item.productDiscount == 10 ? (
                                                        <p><span style={{fontSize: 18, color: "red"}}><FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : item.productDiscount == 15 ? (
                                                        <p><span style={{fontSize: 18, color: "red"}}><FaHeart/> <FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : item.productDiscount == 20 ? (
                                                        <p><span style={{fontSize: 18, color: "red"}}><FaHeart/> <FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : item.productDiscount == 25 ? (
                                                        <p><span style={{fontSize: 18, color: "red"}}><FaHeart/> <FaHeart/> <FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : item.productDiscount == 30 ? (
                                                        <p><span style={{fontSize: 18, color: "red"}}><FaHeart/> <FaHeart/> <FaHeart/></span> -{item.productDiscount}%</p>
                                                    ) : (
                                                        <>
                                                        <p style={{marginTop: 58}}></p>
                                                        </>
                                                    )}

        {/* <p numberOfLines={1}>
                    {item.productName.length < 35
                        ? (item.productName)
                        : ((item.productName).substring(0, 5))}
                    </p> */}

                                                    {/* {item.productDiscount > 10 ? (
                                                        <p><span style={{fontSize: 20, color: "red"}}><FaHeart/> <FaHeart/></span> ส่วนลด {item.productDiscount}%</p>
                                                    ) : (
                                                        <>
                                                        <p style={{marginTop: 47}}></p>
                                                        </>
                                                    )} */}
                                                    


                                                    <h5><strong>฿{item.productPrice} <del style={{color: "gray"}}>฿{item.productFullPrice}</del></strong></h5>

                                                    {item.productBadge ? (
                                                        <Badge className="rounded-0" bg="warning">{item.productBadge}</Badge>
                                                    ) : (
                                                        <>
                                                        <p style={{marginTop: 33}}></p>
                                                        </>
                                                    )}
                                                    
                                                    
                                                    
            
                                                    {/* <p><strong>{item.productName}</strong></p>
                                                    <p><strong>฿{item.productPrice} <del style={{color: "gray"}}>฿{item.productFullPrice}</del></strong></p> */}

                                                <Card.Text> 
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                    </div>
                                    
                                </div>
                            )
                        })}
                </Row>

            <br/>

            {currentCursor ? (
                <Col className='float-end'>

                    <Button variant="outline-warning" className="rounded-0" onClick={onMoreProducts}>สินค้าเพิ่มเติม</Button>
        
                </Col>
            ) : (
                <Col className='float-end'>

                    <Button variant="outline-secondary" className="rounded-0" disabled>ไม่มีสินค้าเพิ่มเติม</Button>
        
                </Col>
            )}

        <br/>
        <br/>
        <br/>
        <br/>

    </Container>

  );
}

export default Test_Home;

