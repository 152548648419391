
import './App.css';

import React, { useState, useLayoutEffect } from 'react';
import firestore from './database/firebase';

import { BrowserRouter, NavLink, Routes, Route, Navigate, useLocation} from 'react-router-dom';
import { Nav } from 'react-bootstrap';

//Router_TestFlight
// import Home from './component_s/Router_TestFlight/Home';
import About from './component_s/Router_TestFlight/About';
import Contact from './component_s/Router_TestFlight/Contact';
import Error from './component_s/Router_TestFlight/Error';
import Dashboard from './component_s/Router_TestFlight/Dashboard';
import Profile from './component_s/Router_TestFlight/Profile';

//Router
import Header from './component_s/Header/Header';


import StorageTest from './database/TestStorage';
// import Test from './component_s/Authentication/Test';
// import { AuthProvider } from './component_s/TestFlight_EmailLogIn/Auth';

import { AuthProvider } from './component_s/Authentication/AuthProvider';


import TestUpdateOnTexbox from './database/TestUpdateOnTexbox';
import AddDataFirestore from './database/TestAddData';

function App() {

  const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  } 


  /* const [loggedIn, setLoggedIn] = useState(null);

  function hanlerLogin() {
      setLoggedIn(true)
  }

  function hanlerLogout() {
      setLoggedIn(false)
  }

  console.log(loggedIn) */

  /* const userRef = firestore.collection("user");
  userRef.get().then((snapshot) => {
    snapshot.forEach((doc) => {
      if (doc.data) {
        console.log(doc.id);
        console.log(doc.data().age);
        console.log(doc.data().userName);
      }
    });
  });

  return (
    <div className="App"></div>

  ); */

  return (


    <AuthProvider>
    <BrowserRouter>
      <Wrapper>

      {/* <Profile /> */}

      {/* <AddDataFirestore /> */}

      {/* <GoogleLogIn/> */}
      <Header/>
      {/* <StorageTest/> */}
      
      {/* <TestUpdateOnTexbox /> */}
      </Wrapper>
    </BrowserRouter>
    </AuthProvider>

    
  );

  }

  export default App;

   {/* <UserProvider>
      <div>
        <Test/>
      </div>
    </UserProvider> */}

  /* <div className='container'>
      /* <StorageTest/> */
      /* <GoogleLogIn/> */
      /* <LonInForm/> */
    

  {/* <AuthProvider>
      <BrowserRouter>
      <Routes>
          <Route exact path='/' element={<Home/>} />
          <Route exact path='/Dashboard' element={<DashBoard/>} />
          <Route exact path='/LogIn' element={<LogInForm/>} />
          <Route exact path='/SignUp' element={<SignUpForm/>} />
      </Routes>
      </BrowserRouter>
    </AuthProvider> */}
  

  // <AuthProvider>
    // <BrowserRouter>

      // <Header/>

    {/* Router_TestFlight */}
      {/* <header>
        <h1>Hello Router</h1>
      </header>
      <nav>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/About">About</NavLink>
        <NavLink to="/Contact">Contact</NavLink>
        <NavLink to="/Dashboard">Dashboard</NavLink>
        {/* <NavLink to="/Profile">Profile</NavLink> 
      </nav>
      <Routes>
        <Route path='/' element={loggedIn ? <Navigate to="/dashboard" /> : <Home login={hanlerLogin} />} />
        <Route path='/About' element={<About/>} />
        <Route path='/Contact' element={<Contact/>} />
        <Route path='/Dashboard' element={loggedIn ? <Dashboard logout={hanlerLogout} /> : <Navigate to="/" state={"From Dashboard"}/>}>
          <Route path='setting' element={<p>nested setting routes</p>} />
        </Route>
        <Route path='/Profile'>
          <Route path=':userId' element={<Profile/>} />
        </Route>
        <Route path='*' element={<Error/>} />
      </Routes> */}
    // </BrowserRouter>
    // </AuthProvider>

  




