import React, {useEffect} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from "react-bootstrap";

import { NavLink, Link, useNavigate } from "react-router-dom";

import { FaFacebookF, FaInstagram, FaPinterest, FaTwitter, FaYoutube, FaCheck, FaSketch, FaOpencart } from "react-icons/fa";
import { BsSendCheck, BsFill1CircleFill, BsFill2CircleFill, BsFill3CircleFill, BsFill4CircleFill, BsFill5CircleFill, BsCheckLg } from "react-icons/bs";


function HowPurchase() {

    

    return (

        <Container>

            <br/>

            <h3><strong>วิธีการซื้อ</strong></h3>
        
            <h3 className="pb-3 border-bottom"></h3>
            <br/>
            <h4><strong>เข้าสู่ระบบ/สมัครใหม่</strong></h4>
            <p>กดเข้าสู่ระบบหรือสมัครใหม่ โดยใช้ Email หรือ Google Login เพื่อซื้อสินค้าในราคาพิเศษ</p><br/>

            <h4><strong>เลือกสินค้า</strong></h4>
            <p>เลือกสินค้าที่สนใจ โดยกดซื้อสินค้าในทันที หรือเพิ่มใส่ตะกร้า เพื่อซื้อสินค้าในตะกร้าภายหลัง</p><br/>

            <h4><strong>การชำระเงิน</strong></h4>
            <p>เมื่อกดซื้อสินค้าในทันที หรือซื้อสินค้าในตะกร้า จะปรากฏหน้ารายละเอียดชำระเงินเพื่อตรวจสอบความถูกต้องของรายการ เพื่อให้ชำระเงินผ่าน QrCode ชื่อร้าน Selpage Store</p><br/>

         {/*    <h4><strong>อัพโหลดหลักฐานการชำระเงิน</strong></h4>
            <p>หลังจากชำระเงินแล้ว อัพโหลดหลักฐานการชำระเงิน เพื่อให้ร้านค้าสามารถตรวจสอบคำสั่งซื้อได้</p><br/> */}

            <h4><strong>สั่งซื้อสินค้า</strong></h4>
            <p>กดสั่งซื้อสินค้า เพื่อส่งข้อมูลการสั่งซื้อสินค้า โดยสามารถติดตามสถานะการสั่งซื้อได้ในช่องทาง <Link to="/User/Profile" style={{textDecoration: 'none'}}>การซื้อของฉัน</Link> หรือ <Link to="/AboutUs/Tracking" style={{textDecoration: 'none'}}>ช่องทางอื่น ๆ</Link></p><br/>

            
           
            
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

        </Container>

    );

}

export default HowPurchase;