import React, { useState, useEffect, useContext } from "react";
import { Container, Tabs, Tab, Form, Col, Row, Button, Card } from "react-bootstrap";
import { Link, redirect, useNavigate } from "react-router-dom";

import { FaSketch, FaPlusCircle } from "react-icons/fa";

import { auth, firestore } from "../../database/firebase";

import AddProduct from "./AddProduct";


import { AuthContext } from "../../component_s/Authentication/AuthProvider";
import Order from "../Order/Order";

function ProductList() {

    // let { productCategory } = useParams();
    // let location = useLocation();

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    const formatter = new Intl.NumberFormat('th', {
        style: 'currency',
        currency: 'THB',
        maximumSignificantDigits: 3,

      });

    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

   //const [uid, setUid] = useState("");

    var user = auth.currentUser;
    // var uid = "";
    var uid = "";

    if (user != null) {
        //email = user.email;
        uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                         // this value to authenticate with your backend server, if
                         // you have one. Use User.getToken() instead.
        console.log(uid);
        
        //TEST AUTH
        // refreshPage();
    }

    // const [producrFlashSale, setProducrFlashSale] = useState("");
    
    
/*     const [productName, setProductName] = useState("");
    const [productDiscount, setProductDiscount] = useState("");

    const [loading, setLoading] = useState(false); */


/*     const [productLists, setProductLists] = useState([]);
    const [currentCursor, setCurrentCursor] = useState(""); */

    const [totalProducts, setTotalProducts] = useState("");

    const [productID, setProductID] = useState("");


    const [data, setData] = useState([]);

    useEffect(() => {

        const productRef = firestore.collection("product").orderBy("productTypeCategory");

        
        productRef.onSnapshot((snapshot) => {

            let tempDataArray =[];
            snapshot.forEach((doc) => {
                //LOADING ...
                // setLoading(true);
                    if (doc.exists) {
                        tempDataArray = [...tempDataArray,
                        {
                            productID: doc.id,
                            productID: doc.data().productID,
                            productTypeCategory: doc.data().productTypeCategory,
                            productName: doc.data().productName,
                            productImage: doc.data().productImage,
                            productPrice: doc.data().productPrice,
                            storeName: doc.data().storeName,
                            productFullPrice: doc.data().productFullPrice,
                            productDiscount: doc.data().productDiscount, 
                            productSold: doc.data().productSold,
                            productBadge: doc.data().productBadge,

                            ratingCount: doc.data().ratingCount,
                            ratingSum: doc.data().ratingSum,
                            
                            
                        },
                    ];

                    // setTotalProducts(doc.data().count);
                    setProductID(doc.data().productID);


                    };

            });
            
            setData((oldDataArray) => tempDataArray);
            
 
  
    },
        (err) => {
            console.log(err);
        }
    );
    }, []);

    const signOutHandler = () => {
        auth.signOut().then(() => {
            console.log("Log Out Ok");
            setCurrentUser(null);
            navigate("/");
        })
        .catch((err) => {
            console.log("Log Out Not Ok." + err);
        })
    };

    const deleteProductHandler = () => {
           
        const ref = firestore.collection("product");
        ref
          
            .doc(productID)
            .delete()
            .then(() => {
                console.log("ลบสำเร็จ");
                refreshPage();

                //setCheckDeleteArticle(true);
                //setArticleID(null);

                //navigate("/");
                
                //navigate("/");

            })
            .catch((err) => console.log(err));
    };
    

    return (


    <Container>

    {!!data ? 
      (

        <>

            <br/>
            <Link to="/User/Profile" style={{textDecoration: 'none', color: "black"}}> <Button variant="outline-secondary">ข้อมูลของฉัน</Button>{' '}</Link>
                        
            <Button onClick={signOutHandler} variant="outline-danger" type="submit">ออกจากระบบ</Button>
            <br/>
            <br/>
            <br/>

            <h3><strong>สินค้าทั้งหมด : {totalProducts}</strong></h3>
            <p><Link to= "/MerchantView" style={{textDecoration: 'none'}}>ร้านค้าของฉัน</Link> | <Link to= "/AddProduct" style={{textDecoration: 'none'}}> <FaPlusCircle /> เพิ่มสินค้า</Link> </p>
            {/* <Link to="/User/Profile" style={{textDecoration: 'none', color: "black"}}> <Button variant="outline-secondary">ข้อมูลของฉัน</Button>{' '}</Link> */}

            <h3 className="pb-3 border-bottom"></h3>
            <br/>


                <div>
                    <table>
                        <tbody>
                            {data.map((item, index) => {
                                return (
                                    <tr
                                        key={index}
                                        style={
                                            index % 2 === 0 ? {backgroundColor: "lightgray"} : null
                                        }
                                    >
                                        <td><Link to={"/EditProductDetail"} state={item.productID} style={{textDecoration: 'none'}}>{item.productID}</Link><br/>{item.productName} <br/><Button onClick={deleteProductHandler}>ลบ {item.productID}</Button></td>
                                      
                                        {/* <td>{(item.productName).slice(0, 25)}</td> */}
                                        <td>{item.productTypeCategory}</td>
                                        <td>{item.productPrice}</td>
                                        <td>{item.productDiscount}</td>
                                        <td>{item.ratingCount} </td>
                                        <td>{item.ratingSum}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>



                <br/>


            <br/>
            <br/>
            <br/>

        </>

      ) : (

        <>



        </>

      )}

    </Container>


  );
}

export default ProductList;