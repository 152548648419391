import React, { useState, useEffect, useContext } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Image, InputGroup, Modal } from "react-bootstrap";

import { firestore } from "../../database/firebase";
import { storage } from "../../database/firebase";

import { useParams, Link, redirect, useNavigate, useLocation } from "react-router-dom";

import { auth } from "../../database/firebase";
import { AuthContext } from "../Authentication/AuthProvider";

import { FaPencilAlt, FaDiaspora, FaPlus, FaSketch } from "react-icons/fa";
import { AiOutlineSketch } from "react-icons/ai";

import QrPayment01 from '../images_s/QrPayment01.jpg';
import QrPayment02 from '../images_s/QrPayment02.jpg';


function CheckoutDetail() {

    // <Image src={[QrPayment01]} />

    //Test useParams
    let { productId } = useParams();

    // RECIEVE PRODUCT QUANTITY
    let location = useLocation();
    var productAmount = location.state;

    console.log("จำนวนสินค้า" + location.state);

    //TEST Upload OrderPayin Image
    const [file, setFile] = useState("");
    const [link, setLink] = useState("");
    const subBucketRef = storage.child("mainBucket/transferSlip");

    const uploadHandler = (e) => {

        e. preventDefault();

        if (!!file) {

            const fileName = file.name;
            const targetRef = subBucketRef.child(fileName);

            targetRef.put(file).then((response) => {
                console.log(response);
                response.ref.getDownloadURL().then((photoURL) => {
                    console.log(photoURL);
                    setLink(photoURL);
                });

            });

        } else {
            console.log("no file upload!!");
        }

    };



    /* TEST QUERY USER-DATA WITH productID */
    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    const signUpPage = () => {
        navigate("/SignUp");
    }

    


    /* TEST QUERY DATA WITH productID */

    
    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

    var user = auth.currentUser;
    var uid = "";

    if (user != null) {
        //email = user.email;
        uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                         // this value to authenticate with your backend server, if
                         // you have one. Use User.getToken() instead.
        console.log(uid);
        
        //TEST AUTH
        // refreshPage();
    } else {
        signUpPage();
    }

   
    const [loading, setLoading] = useState(false);

    const [userID, setUserId] = useState("");
    const [usernameOrder, setUsernameOrder] = useState("");
    const [userphonenumberOrder, setUserphonenumberOrder] = useState("");
    const [useraddressOrder, setUseraddressOrder] = useState("");


    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [address, setAddress] = useState("");


    const [sPoint, setSPoint] = useState("");

    const [sPointUse, setSPointUse] = useState(0);

    const [dataUser, setDataUser] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
    
        .where("uid", "==", uid);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            id: doc.id,
                            uid: doc.data().uid,
                            name: doc.data().name,
                            email: doc.data().email,
                            phonenumber: doc.data().phonenumber,
                            address: doc.data().address,
                            sPoint: doc.data().sPoint,   
                            
                            
                        },
                        
                    ];


                    setUserId(doc.data().uid)
                    setUsernameOrder(doc.data().name);
                    setUserphonenumberOrder(doc.data().phonenumber);
                    setUseraddressOrder(doc.data().address);

                    setEmail(doc.data().email);

                    setSPoint(doc.data().sPoint);
                    

                    };

                });

            setDataUser((oldDataArray) => tempDataArray);
            

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [uid]); 


    
    /* TEST QUERY PRODUCT-DATA WITH productID */

        const [productFullPrice, setProductFullPrice] = useState("");
        const [productPrice, setProductPrice] = useState("");
        const [productShippingCost, setProductShippingCost] = useState("");

        const [productWeight, setProductWeight] = useState("");

        const formatter = new Intl.NumberFormat('th', {
            style: 'currency',
            currency: 'THB',
          
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          });
    
        const [data, setData] = useState([]);
        useEffect(() => {
        const productRef = firestore.collection("product");
        const query = productRef
        
            .where("productID", "==", productId);
                query
                .get()
                .then((querySnapshot) => {
                    let tempDataArray =[];
                    querySnapshot.forEach((doc) => {
                        if (doc.exists) {
                            tempDataArray = [...tempDataArray,
                            {
                                id: doc.id,
                                merchantID: doc.data().merchantID,
                                storeName: doc.data().storeName,
                                productName: doc.data().productName,
                                productPrice: doc.data().productPrice,
                                productFullPrice: doc.data().productFullPrice,
                                productShippingCost: doc.data().productShippingCost,
                                productPriceTotal: doc.data().productPriceTotal,
                                productWeight: doc.data().productWeight,
                                
                            },
                        ];

                        setProductFullPrice(doc.data().productFullPrice);
                        setProductPrice(doc.data().productPrice);
                        // setProductShippingCost(doc.data().productShippingCost);

                        setProductWeight(doc.data().productWeight);


                        
                        };

                    });


                /* setproductPriceTotal(doc.data().productPriceTotal);
                console.log(productPriceTotal); */
                setData((oldDataArray) => tempDataArray);

            },

                (err) => {
                    console.log(err);
                }
                
            );

        }, []); 

        //TEST ADD PRODUCT-DATA WITH Button AND Form
        const [productID, setProductID] = useState("");

        const [merchantID, setMerchantID] = useState("");
        const [storeName, setStoreName] = useState("");

        const [productName, setproductName] = useState("");
        // const [productPrice, setproductPrice] = useState("");
        const [productPriceTotal, setproductPriceTotal] = useState("");
        const [orderPayin, setorderPayin] = useState("");

        // const [usernameOrder, setUsernameOrder] = useState("");
        /* const [userphonenumberOrder, setUserphonenumberOrder] = useState("");
        const [useraddressOrder, setUseraddressOrder] = useState(""); */

        const addOrderHandler = (obj) => {
            const ref = firestore.collection("order");
            ref
                .add(obj)
                .then(() => {
                    console.log("เพิ่มข้อมูลสำเร็จ");
                    navigate("/CheckoutSuccess");
                })
                .catch((err) => console.log(err));
        };

        const editUserHandler = (obj) => {
        
            const ref = firestore.collection("user");
            ref
                .doc(uid)
                .update(obj)
                .then(() => {
                    console.log("แก้ไขข้อมูลสำเร็จ");
                    refreshPage();
                })
                .catch((err) => console.log(err));
        };

        const submitHandler = (e) => {
            e.preventDefault();
            const obj = {
                productID : productID,

                merchantID: merchantID,
                storeName: storeName,

                productName : productName,
                productPrice : productPrice,
                productQuantity: productAmount,
                productShippingCost: productShippingCost,
                productPriceTotal : (parseFloat(productPrice) * parseFloat(productAmount) + parseFloat(productShippingCost) - parseFloat(sPointUse)),
                orderPayin : orderPayin,

                orderDate: new Date(),
                orderStatus: "กำลังดำเนินการ",

                userID: userID,
                usernameOrder: usernameOrder,
                userphonenumberOrder: userphonenumberOrder,
                useraddressOrder: useraddressOrder,

                sPointUse: sPointUse,

                




            };
            setProductID("");

            setMerchantID("");
            setStoreName("");

            setproductName("");
            setProductPrice("");
            setproductPriceTotal("");

            setUsernameOrder("");
            setUserphonenumberOrder("");
            setUseraddressOrder("");

            setproductPriceTotal("");

            setSPointUse("");


            setorderPayin("");

            addOrderHandler(obj);


            const objUser = {

                name : usernameOrder,
                phonenumber : userphonenumberOrder,
                address: useraddressOrder,
                sPoint: parseFloat(sPoint) - parseFloat(sPointUse),

            };

            editUserHandler(objUser);

        };


    /* useEffect(() => {
       setproductPriceTotal(() => formatter.format(parseFloat(productPrice) * parseFloat(productAmount) + parseFloat(productShippingCost) - parseFloat(sPointUse)));

    }, [sPoint]);  */

    // useEffect(() => {
        
        /* if (sPointUse == "") {
            // setSPointUse("");
        } else if (sPointUse >= sPoint) {
            setSPointUse(sPoint);
        } else {
            setSPointUse(sPointUse);
        } */
        /* if (sPointUse >= 0) {
            setproductPriceTotal(() => formatter.format(parseFloat(productPrice) * parseFloat(productAmount) + parseFloat(productShippingCost) - 0));
        } else if (sPointUse >= sPoint) {
            setSPointUse(sPoint);
            setproductPriceTotal(() => formatter.format(parseFloat(productPrice) * parseFloat(productAmount) + parseFloat(productShippingCost) - parseFloat(sPoint)));
        } else {
            setSPointUse(sPointUse);
            setproductPriceTotal(() => formatter.format(parseFloat(productPrice) * parseFloat(productAmount) + parseFloat(productShippingCost) - parseFloat(sPointUse)));
        } */

    // }, [sPointUse]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const submitSPointHandler = (e) => {
            
        e.preventDefault();
        //const id = firestore.collection("product").doc();
        
/* 
        setUserID("");
        setProductID("");
        setProductName("");
        setProductPrice("");
        setProductFullPrice("");
        setProductDetail("");
        setProductCategory("");
        setProductSubCategory("");
        setStoreName(""); */

        // setMerchantID("");
        // setProductImage([""]);

        if (user != null) {
            
            setShow(true);

        } else {
            alert("โปรด LogIn เพื่อเพิ่มสินค้าในตะกร้า");

        }

        


    };

    useEffect(() => {
        
        if (sPointUse == "") {
            // setSPointUse("");
        } else if (sPointUse >= sPoint) {
            setSPointUse(sPoint);
        } else {
            setSPointUse(sPointUse);
        }
        /* if (sPointUse >= 0) {
            setproductPriceTotal(() => formatter.format(parseFloat(productPrice) * parseFloat(productAmount) + parseFloat(productShippingCost) - 0));
        } else if (sPointUse >= sPoint) {
            setSPointUse(sPoint);
            setproductPriceTotal(() => formatter.format(parseFloat(productPrice) * parseFloat(productAmount) + parseFloat(productShippingCost) - parseFloat(sPoint)));
        } else {
            setSPointUse(sPointUse);
            setproductPriceTotal(() => formatter.format(parseFloat(productPrice) * parseFloat(productAmount) + parseFloat(productShippingCost) - parseFloat(sPointUse)));
        } */

    }, [sPointUse]);

    useEffect(() => {
        //J&T EXPRESS RATE
    if (parseInt(productWeight * location.state) <= 2000) {
        setProductShippingCost(38);
        // console.log("This Is Product Weight Cost" + productWeightCost);
    } else if (parseInt(productWeight * location.state) <= 3000) {
        setProductShippingCost(58);
    } else if (parseInt(productWeight * location.state) <= 4000) {
        setProductShippingCost(64);
    } else if (parseInt(productWeight * location.state) <= 5000) {
        setProductShippingCost(74);
    } else if (parseInt(productWeight * location.state) <= 6000) {
        setProductShippingCost(84);
    } else if (parseInt(productWeight * location.state) <= 7000) {
        setProductShippingCost(94);
    } else if (parseInt(productWeight * location.state) <= 8000) {
        setProductShippingCost(104);
    } else if (parseInt(productWeight * location.state) <= 9000) {
        setProductShippingCost(110);
    } else if (parseInt(productWeight * location.state) <= 10000) {
        setProductShippingCost(125);
    } else if (parseInt(productWeight * location.state) <= 11000) {
        setProductShippingCost(150);
    } else if (parseInt(productWeight * location.state) <= 12000) {
        setProductShippingCost(150);
    } else if (parseInt(productWeight * location.state) <= 15000) {
        //KERRY RATE
        setProductShippingCost(220);
    } else if (parseInt(productWeight * location.state) <= 20000) {
        setProductShippingCost(350);
    } else {
        //IDC RATE
        setProductShippingCost(0);
    }
  }, [productWeight]);


    return (
        <>

        {/* Row 2  */}
        <br/>
        <br/>
        <Container className="">
            
            {/* Row 0 Header Checkout */}
            <h3><strong>รายละเอียดการชำระเงิน</strong></h3>
            {/* Test useParams */}
            {/* <h3>This is Params {productId}</h3> */}
            <h3 className="pb-3 border-bottom"></h3>
            <br/>

            <Row>

            <Col sm={7}>
            <h4><strong>ที่อยู่จัดส่งสินค้า</strong></h4>

            {dataUser.map((itemUser, index) => {
                return (
                            
                <div key={index}>  

                <br/>

                    <h5><strong>ชื่อ - นามสกุล</strong></h5>
                    {/* <FaDiaspora/> */}
                    <Form.Control 
                        type="text"
                        className="rounded-0" 
                        style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                        value={usernameOrder}
                        onChange={(e) => {
                            setUsernameOrder(e.target.value);
                        }}
                        placeholder={"*กรุณากรอกข้อมูล"}
                    />
                    <br />
                                                                        
                    <h5><strong>หมายเลขโทรศัพท์</strong></h5>
                    {/* <FaDiaspora/> */}
                    <Form.Control 
                        type="text" 
                        className="rounded-0"
                        style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                        value={userphonenumberOrder}
                        onChange={(e) => {
                            setUserphonenumberOrder(e.target.value);
                        }}
                        placeholder={"*กรุณากรอกข้อมูล"}                               
                    />
                    <br />

                    <h5><strong>ที่อยู่ผู้รับสินค้า</strong></h5>
                    {/* <FaDiaspora/> */}
                    <Form.Control 
                        as="textarea" 
                        className="rounded-0"
                        rows={3}  
                        style={{ borderLeftColor:"white", borderTopColor:"white", borderRightColor:"white", borderBottomColor: "white", paddingLeft: 0 }}
                        value={useraddressOrder}
                        onChange={(e) => {
                            setUseraddressOrder(e.target.value);
                        }}
                        placeholder={"*กรุณากรอกข้อมูล"}         
                    />
                                        
                    <h4><strong>ชำระเงิน</strong></h4>
            
                    <p>บัญชีธนาคารไทยพาณิชย์ นายวิรัช  วิสาขศาสตร์ เลขที่ 4150799367</p>
                                 
                    <Image src={QrPayment01} fluid width={300} height={400}/>
                            <br/>
                            <br/>
                            <br/>

                </div>

                );
            })}


            </Col>


                {/* <Col sm={7}>
                    <h4><strong>ที่อยู่จัดส่งสินค้า</strong></h4>
                    <p>นายวิรัช วิสาขศาสตร์, 0805514821 <br/>199/288 แขวงสีกัน เขตดอนเมือง กรุงเทพมหานคร 10210</p>
                    <br/>
                    <h4><strong>วิธีการชำระเงิน</strong></h4>
                    <p>• โอนผ่านบัญชีธนาคาร<br/>• Qr Code</p>
                </Col> */}

                <Col className="bg-light p-3" sm={5}>
                    <h4><strong>ยอดชำระเงินทั้งหมด</strong></h4>
                    <br/>

                    {data.map((item, index) => {
                        return (
                            
                            <div key={index}>   
                                <form onSubmit={submitHandler} className="was-validated">
                                สินค้า: <span className="float-end">{item.productName}</span><br/>
                                ราคา: <span className="float-end">฿{productPrice} <del>฿{item.productFullPrice}</del></span><br/>
                                จำนวน: <span className="float-end">{productAmount}</span><br/>
                                {/* น้ำหนักต่อชิ้น: <span className="float-end">{productWeight}</span><br/> */}
                                ค่าจัดส่ง: <span className="float-end">฿{productShippingCost}</span><br/>

                                <h3 className="pb-3 border-bottom"></h3>
                                <br/>
                                {/* <p>ยอดชำระเงินทั้งหมด: <span className="float-end"> {formatter.format(parseFloat(productPrice) * parseFloat(productAmount) + parseFloat(productShippingCost) - parseFloat(sPointUse))}</span> 
                                <br/><Link style={{textDecoration: 'none', color: "yellowgreen"}} onClick={submitSPointHandler}> ใช้คะแนนเป็นส่วนลดหรือไม่?</Link></p> */}
                                
                                {/* <p><span style={{color: "skyblue", fontSize: 30}}><AiOutlineSketch/></span> : {sPoint} ใช้คะแนนเป็นส่วนลด</p> */}
                                    {/* <Form>
                                        <Form.Control 
                                            type="text" 
                                            className="rounded-0"
                                            placeholder="กรอกคะแนนสะสม"
                                            value={sPointUse}
                                            onChange={(e) => {
                                                setSPointUse(e.target.value);
                                            }}
                                        />
                                    </Form> */} 
                                    <InputGroup size="sm" className="mb-3">
                                                <InputGroup.Text><span style={{color: "skyblue", fontSize: 18}}><AiOutlineSketch/></span> : {parseInt(sPoint)} ใช้ส่วนลด</InputGroup.Text>
                                                    <Form.Control 
                                                        type="text" 
                                                        className="rounded-0"
                                                        placeholder="กรอกคะแนนสะสม"
                                                        value={sPointUse}
                                                        onChange={(e) => {
                                                            setSPointUse(e.target.value);
                                                        }}
                                                    />
                                                <InputGroup.Text>฿</InputGroup.Text>
                                    </InputGroup>

                                    <br/>
                                    <p><strong>ยอดชำระเงินทั้งหมด: <span className="float-end"> {formatter.format(parseFloat(productPrice) * parseFloat(productAmount) + parseFloat(productShippingCost) - parseFloat(sPointUse))}</span></strong></p>
                                 

                                    {/* <Modal 
                                        show={show} 
                                        onHide={handleClose} 
                                        animation={false} 
                                        size="sm"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered>
                                            <Modal.Header>
                                            <Modal.Title><span style={{color: "skyblue", fontSize: 30}}><AiOutlineSketch/></span> : {sPoint}</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>

                                            <InputGroup className="mb-3">
                                                <InputGroup.Text><AiOutlineSketch/></InputGroup.Text>
                                                    <Form.Control 
                                                        type="text" 
                                                        className="rounded-0"
                                                        placeholder="กรอกคะแนนสะสม"
                                                        value={sPointUse}
                                                        onChange={(e) => {
                                                            setSPointUse(e.target.value);
                                                        }}
                                                    />
                                                <InputGroup.Text>฿</InputGroup.Text>
                                            </InputGroup>

                                            <p>ยอดชำระเงินทั้งหมด: <span className="float-end"> {formatter.format(parseFloat(productPrice) * parseFloat(productAmount) + parseFloat(productShippingCost) - parseFloat(sPointUse))}</span></p> 


                                           
                                            </Modal.Body>
                                            <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                ตกลง
                                            </Button>
                                            </Modal.Footer>
                                    </Modal> */}
                                <br />
                                 {/* <Form>
                                                <Form.Control 
                                                    type="text" 
                                                    className="rounded-0"
                                                    placeholder="กรอกคะแนนสะสม"
                                                    value={sPointUse}
                                                    onChange={(e) => {
                                                        setSPointUse(e.target.value);
                                                    }}
                                                />
                                            </Form> */}
                                {/* <p>{productShippingCost}</p> */}
                 


                            <div>

                            {/* <form className="was-validated"> */}

                            <Form.Label>อัพโหลดหลักฐานการชำระเงิน</Form.Label>
                                <InputGroup className="mb-3 rounded-0 was-validated">
                                    <Form.Control
                                        aria-label="Example text with button addon"
                                        aria-describedby="basic-addon1"
                                        type="file"
                                        className="custom-file-label rounded-0"  
                                        id="customFile" 
                                        placeholder="" 
                                        name="customFile" 
                                        onChange={ (e) => {
                                            const file = e.target.files[0];
                                            setFile(file);
                                            setLink("");
                                        }}
                                        required
                                    />
                                    {/* <div className="valid-feedback">Valid.</div>
                                    <div className="invalid-feedback">Please fill out this field.</div> */}
                                    <Button 
                                    type="submit"
                                    onClick={uploadHandler}
                                    className="btn btn-success rounded-0"
                                    >
                                    อัลโหลด
                                    </Button>
                                </InputGroup>

                                {/* </form> */}

                                {!!link ? (

                                <div className="text-center mt-4">

                                    <hr/>

                                    <a className="text-secondary" href={link} target="_blank" rel="noreferrer">
                                        คลิกไฟล์ {file.name}
                                    </a>

                                <img src={link} width={350} height={450} fluid/>

                                </div>

                                ) : null}

                                <br/>
                                <br/>

                                </div>

                                {/* {data.map((itemUser, index) => {
                                return (
                                    <>
                                        {itemUser.name}
                                       
                                    </>
                                    );
                                })} */}

                                {!link ? 
                                    <>
                                    <div className="d-grid gap-2">
                                        <Button variant="outline-success" className="btn-block rounded-0" disabled>สั่งซื้อสินค้า</Button>
                                    </div>
                                    <br/> 
                                    <p>การันตีรับสินค้าภายใน 1-2 วัน</p>
                                    </>
                                :
                                <>
                                    <div className="d-grid gap-2">
                                        <Button onClick={(e) => (setUserId(userID), setUsernameOrder(usernameOrder), setUserphonenumberOrder(userphonenumberOrder), setUseraddressOrder(useraddressOrder), setProductID(item.id), setMerchantID(item.merchantID), setStoreName(item.storeName), setorderPayin(link), setproductName(item.productName), setProductPrice(item.productPrice))} className="btn btn-success btn-block rounded-0" type="submit">สั่งซื้อสินค้า</Button>
                                    </div>
                                    <br/>
                                    <p>การันตีรับสินค้าภายใน 1-2 วัน</p>
                                </>
                                }




                                {/* <div className="d-grid gap-2">
                                    <Button onClick={(e) => (setorderID(item.id), setorderPayin(link), setproductName(item.productName), setproductPrice(item.productPrice), setproductPriceTotal(Number(item.productPrice) + 50))} className="btn btn-success btn-block rounded-0" type="submit">สั่งซื้อสินค้า</Button>
                                </div>
                                <br/>
                                <p>การันตีรับสินค้าภายใน 1-2 วัน</p> */}

                                </form>

                            </div>

                        );
                    })}


                    <br/>
                    
                </Col>

            </Row>
            
        </Container>

        <br/>
        <br/>

        </>
    );

}  

export default CheckoutDetail;








                                {/* <label className="custom-file-label" htmlFor="customFile">

                                    {!file ? <div>เลือกรูปภาพ</div> :

                                    <div>{file.name}</div>}

                                </label> */}

                                {/* <Link to="/CheckoutSuccess" style={{ textDecoration: 'none' }}> */}
                    {/* <div className="d-grid gap-2">
                        <Button className="btn btn-success btn-block rounded-0">สั่งซื้อสินค้า</Button>
                    </div> */}
                    {/* </Link>
                    <p>การันตีรับสินค้าภายใน 1-2 วัน</p> */}





















