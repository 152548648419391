import React, { useState, useEffect, useRef, useContext, useAuthState } from "react";
import { auth } from "../../../database/firebase";
import { Link, redirect, useNavigate } from "react-router-dom";

import { firestore, googleProvider } from "../../../database/firebase";

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
/* import { AuthContext } from "./AuthProvider"; */

import { AuthContext } from "../AuthProvider";

import { FaGoogle } from "react-icons/fa";
// import { FcGoogle } from "react-icons/fc";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";



const SignUp = () => {

    const [currentUser, setCurrentUser] = useState(null);

    const [message, setMessage] = useState("");

    const [passwordShown, setPasswordShown] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    const backPreviousPage = () => {
        navigate(-1);
    }

    var userAuth = auth.currentUser;
    var uid = "";

    if (userAuth != null) {
      
        navigate("/");
        
    }
    

    const onEmailSignUp = (e) => {
        e.preventDefault();
        const {email, password} = e.target.elements;
        auth
            .createUserWithEmailAndPassword(email.value, password.value)
            .then(async (result) => {
                setMessage("ลงทะเบียนเรียบร้อย");
                if (!!result) {
                    //const userId = "userID" + Math.random(999).toString();
                    const userRef = firestore.collection("user").doc(result.user.uid);
                    const doc = await userRef.get();
                    if (!doc.data()) {
                        await userRef.set({
                            uid: result.user.uid,
                            email: result.user.email.toLowerCase(),
                            // password: result.user.password,
                            created: new Date(),
                            name: "",
                            storeName: "",
                            role: "user",
                            address: "",
                            phonenumber: "",
                            payCount: "0",
                            payAmount: "0",
                            sPoint: "10",

                            //displayName: result.user.email.substring(0, result.user.email.lastIndexOf("@")),
                            displayName: "",

                            //
                            profession: "",
                            description: "",

                            facebook: "",
                            youtube: "",
                            instagram: "",
                            tiktok: "",

                            profileImg: "",

                        });
                    }
                //TEST AUTH PROVIDER
                setCurrentUser(true);
                ////////////////////
                // refreshPage();
                //backPreviousPage();

                // navigate("/", { replace: true });
                navigate("/SignUpDetail", { replace: true });
                

                }
                //backPreviousPage();

            })
            .catch((err) => {
                setMessage(err.code);
                setShow(true);
            });
    };


    const userRef = useRef(firestore.collection("user")).current;
    const [user, setUser] = useState(null);

    useEffect (() => {
        const authUnsubscribe = auth.onAuthStateChanged(
            (firebaseUser) => {
                if (!!firebaseUser) {
                    userRef.doc(firebaseUser.uid).onSnapshot((doc) => {
                        if (doc.data()) {
                            const userData = {
                                uid: doc.data().uid,
                                displayName: doc.data().displayName,
                                photoURL: doc.data().photoURL,
                                email: doc.data().email,
                                created: doc.data().created,
                                role: doc.data().role,
                                payCount: doc.data().payCount,
                                payAmount: doc.data().payAmount,
                                sPoint: doc.data().sPoint,

                                //
                                profession: doc.data().profession,
                                description: doc.data().description,

                                facebook: doc.data().facebook,
                                youtube: doc.data().youtube,
                                instagram: doc.data().instagram,
                                tiktok: doc.data().tiktok,

                                profileImg: doc.data().profileImg,
                            };
                        setUser(userData)
                        } else {
                            setUser(null);
                        }
                        
                    });
                } else {
                    setUser(null);
                }
    });
    return () => {
        authUnsubscribe();
    };
}, [userRef]);

    const googleLoginHandler = async () => {
        const result = await auth.signInWithPopup(googleProvider);
        if (result) {
            const userRef = firestore.collection("user").doc(result.user.uid);
            const doc = await userRef.get();
            
            if (!doc.data()) {
                userRef.set({
                    uid: result.user.uid,
                    displayName: result.user.displayName,
                    photoURL: result.user.photoURL
                ? result.user.photoURL
                : require({}),
                created: new Date(),
                name: "",
                storeName: "",
                role: "user",
                address: "",
                phonenumber: "",
                email: result.user.email,
                displayName: "",
                payCount: "0",
                payAmount: "0",
                sPoint: "10",

                profession: "",
                description: "",

                facebook: "",
                youtube: "",
                instagram: "",
                tiktok: "",

                profileImg: "",
                });
            }

            //navigate("/", { replace: true });
            navigate("/SignUpDetail", { replace: true });
        }
    };

    const togglePassword = () => {
        // When the handler is invoked
        // chnage inverse the boolean state passwordShown
        setPasswordShown(!passwordShown);
      };


    return (

        <>

        <Modal 
                show={show} 
                onHide={handleClose} 
                animation={false} 
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header>
                <Modal.Title>สมัครไม่สำเร็จ</Modal.Title>
                </Modal.Header>
                <Modal.Body>ยูสเซอร์เนมมีผู้ใช้แล้ว/หรือรหัสผ่านคาดเดาง่าย กรุณาติดต่อแอดมิน</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        ปิด
                    </Button>
                </Modal.Footer>
        </Modal>


<Container style={{ maxWidth: 400 }}>
        <Form onSubmit={onEmailSignUp} style={{ marginTop: 30, marginBottom: 110 }}>
        <h3>สมัครใหม่</h3>
        {/* <button onClick={() => navigate(-1)}>Go back</button> */}
        <br/>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label><strong>Email</strong></Form.Label>
            <Form.Control type="email" name="email" className="form-control rounded-0" id="exampleInputEmail1" placeholder="Email" />
            {/* <Form.Text className="text-muted">
            We'll never share your email with anyone else.
            </Form.Text> */}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label><strong>Password</strong></Form.Label>
            <Form.Control type={passwordShown ? "text" : "password"} name={passwordShown ? "text" : "password"} className="form-control rounded-0" id="exampleInputPassword1" placeholder="Password" />
        </Form.Group>
        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Check me out" />
        </Form.Group> */}


        {passwordShown ? (
            <p onClick={togglePassword}><span style={{fontSize: 24}}><BsFillEyeSlashFill/></span> ไม่แสดง</p>
            
        ) : (
            <p onClick={togglePassword}><span style={{fontSize: 24}}><BsFillEyeFill/></span> แสดง</p>
        )}
       
        <br/>


        <div className="d-grid">
        <Button onClick={(e) => (setPasswordShown(false))} variant="primary" className="rounded-0" type="submit">
            สร้างบัญชี
        </Button>
        </div>

   
        <br/>

        {/* {message ? (
            <p><strong>ยูสเซอร์เนมมีผู้ใช้แล้ว<br/>/หรือรหัสผ่านคาดเดาง่ายเกินไป<br/>กรุณาติดต่อแอดมิน</strong></p>
            
        ) : (
            <p></p>
            
        )} */}
        {/* <legend class ='text-center'>หรือ</legend>
        <p style={{textAlign: 'center', }}><del></del><span>- หรือ -</span><del></del></p> */}

        <Form.Label><strong>Google Login</strong></Form.Label>
        {/* <p style={ {borderBottom: '1px solid darkgray'} }></p> */}
        <div className="d-grid">
        <Button onClick={googleLoginHandler} variant="outline-dark" className="rounded-0" type="submit">
            <FaGoogle /> เข้าสู่ระบบโดย Google {/* Google <span style={{color: "red"}}> */}
        </Button>
        </div>

        <br/>

        <p style={ {borderBottom: '1px solid darkgray'} }></p>
        <p>มีบัญชีแล้วใช่หรือไม่ ? <Link to={"/LogIn"}>เข้าสู่ระบบ</Link></p>
        </Form>
        </Container>

        </>

    );

}

export default SignUp;