import React, { useState, useEffect, useContext } from "react";
import { Container, Tabs, Tab, Form, Col, Row, Button, Card, InputGroup, ProgressBar } from "react-bootstrap";
import { Link, redirect, useNavigate } from "react-router-dom";

import { FaSketch } from "react-icons/fa";
import { AiOutlineSketch } from "react-icons/ai";

import { auth, firestore } from "../../../database/firebase";
import { storage } from "../../../database/firebase";


// import OrderHistory from "../../component_m/Order/OrderHistory";



import { AuthContext } from "../AuthProvider";


import { BsStarFill, BsStarHalf, BsStar, BsPatchCheck, BsPlayBtn, BsPhone, BsFolder2Open, BsInfinity, BsCheckLg, BsPeopleFill, BsFillPlayBtnFill, BsChatLeftDotsFill, BsPersonCircle, BsCameraReels, BsRecordFill, BsEye, BsImage, BsEarFill, BsEyeFill, BsDisplay, BsDot, BsInfoCircle, BsInfo } from "react-icons/bs";

import "./signuplogin.css";


function SignUpDetail() {

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    /* TEST QUERY DATA WITH productID */

    // const [uid, setUid] = useState("");

    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

   //const [uid, setUid] = useState("");

    var user = auth.currentUser;
    // var uid = "";
    var uid = "";

    if (user != null) {
        //email = user.email;
        uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                         // this value to authenticate with your backend server, if
                         // you have one. Use User.getToken() instead.
        console.log(uid);
        
        //TEST AUTH
        // refreshPage();
    } else {
        navigate("/");
    }
    



    

    // const [emailDefault, setEmailDefault] = useState("");

    // const [loading, setLoading] = useState(false);

    const socialPublicOption = ["สาธารณะ", "เฉพาะฉัน"];
    const [socialPublicString, setSocialPublicString] = useState(socialPublicOption[0]);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [address, setAddress] = useState("");

    const [role, setRole] = useState("");
    const [sPoint, setSPoint] = useState("");

    const [displayName, setDisplayName] = useState("");

    const [profession, setProfession] = useState("");
    const [description, setDescription] = useState("");

    const [facebook, setFacebook] = useState("");
    const [youtube, setYoutube] = useState("");
    const [instagram, setInstagram] = useState("");
    const [tiktok, setTiktok] = useState("");

    const [profileImg, setProfileImg] = useState("");

    const [file, setFile] = useState("");
    const [link, setLink] = useState("");

    const [data, setData] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
    
        .where("uid", "==", uid);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            uid: doc.id,
                            name: doc.data().name,
                            email: doc.data().email,
                            phonenumber: doc.data().phonenumber,
                            address: doc.data().address,
                            sPoint: doc.data().sPoint,   
                            role: doc.data().role,

                            displayName: doc.data().displayName,

                            profession: doc.data().profession,
                            description: doc.data().description,

                            facebook: doc.data().facebook,
                            youtube: doc.data().youtube,
                            instagram: doc.data().instagram,
                            tiktok: doc.data().tiktok,

                            profileImg: doc.data().profileImg,
                            
                        },
                        
                    ];

                    setName(doc.data().name);
                    setPhonenumber(doc.data().phonenumber);
                    setEmail(doc.data().email);
                    setAddress(doc.data().address);

                    setRole(doc.data().role)
                    setSPoint(doc.data().sPoint);

                    setDisplayName(user.email.substring(0, user.email.lastIndexOf("@")));

                    setProfession(doc.data().profession);
                    setDescription(doc.data().description);

                    setFacebook(doc.data().facebook);
                    setYoutube(doc.data().youtube);
                    setInstagram(doc.data().instagram);
                    setTiktok(doc.data().tiktok);

                    setProfileImg(doc.data().profileImg);
                    

                    };

                });

            setData((oldDataArray) => tempDataArray);
            

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [uid]); 

    /////////////////////////////////////// CHECK ALREADY USER NAME /////////////////////////

    const [dataDisplayName, setDataDisplayName] = useState([]);
    const [checkDisplayName, setCheckDisplayName] = useState("");

    useEffect(() => {
        const userRef = firestore.collection("user");
        const query = userRef
        
            //.where("uid", "==", uid)
            .where("displayName", "==", displayName);
                query
                .get()
                .then((querySnapshot) => {
                    let tempDataArray =[];
                    querySnapshot.forEach((doc) => {
                        if (doc.exists) {
                            console.log(doc.data());
                            tempDataArray = [...tempDataArray,
                            {
                                uid: doc.id,
                                displayName: doc.data().displayName,
                          
                            },
                            
                        ];
    
                        //setDisplayName(doc.data().displayName);
                        setCheckDisplayName(doc.data().displayName);
            
    
                        };
    
                    });
    
                setDataDisplayName((oldDataArray) => tempDataArray);
                
    
            },
    
                (err) => {
                    console.log(err);
                }
                
            );
    
        }, [displayName]);

    ////////////////////////////////////////////////////////////////////////////////////////



    const [progress, setProgress] = useState(1);
    const [progressStatus, setProgressStatus] = useState(false);

    const subBucketRef = storage.child("mainBucket/profileImage/" + uid);

    const [resizefileUrl, setResizefileUrl] = useState("");
    
    const uploadHandler = (e) => {

        e. preventDefault();

        if (!!file) {

            const fileName = file.name;
            /* const metadata = {
                contentType: 'image/jpeg',
                firebaseStorageDownloadTokens: uid,
              }; */
            const targetRef = subBucketRef.child(fileName);

            // const extension = fileName.split(".").pop();
            const name = fileName.split(".").slice(0, -1).join(".");
            setResizefileUrl("https://firebasestorage.googleapis.com/v0/b/selpage-9425f.appspot.com/o/mainBucket%2FprofileImage%2F" + uid +"%2F" + name + "_" + "1000x1000" + "." + "jpeg" + "?alt=media");
            console.log(resizefileUrl);
            /* setLink(resizefileName);
            console.log(link); */
            
            

            const uploadTask = targetRef.put(file);
            // .putString(your_base64_image, ‘base64’, {contentType:’image/jpg’});
            setProgressStatus(true);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                },
                (error) => {
                    console.log(error);
                },
                () => {
                    setProgressStatus(false);
                    uploadTask.snapshot.ref.getDownloadURL().then((photoURL) => {
                        console.log(photoURL);

                        setLink(photoURL);

                       /*  setLink(resizefileName);
                        console.log(link); */


                    });
                }
            );

            /* targetRef.put(file).then((response) => {
                console.log(response);
                response.ref.getDownloadURL().then((photoURL) => {
                    console.log(photoURL);
                    setLink(photoURL);
                }); */

/*             });

        } */

        } else {
                console.log("no file upload!!");
        }

    };

    /* const checkUniqueName = (name) => {
        
        const ref = firestore.collection("user");
        ref
        .where("displayName", "==", name)
           
            .then(() => {
                console.log("ชื่อนี้มีผู้ใช้งานแล้ว");
             
            })
            .catch((err) => console.log(err));
    }; */

    

    

    

    const editHandler = (obj) => {
        
        const ref = firestore.collection("user");
        ref
            .doc(uid)
            .update(obj)
            .then(() => {
                console.log("แก้ไขข้อมูลสำเร็จ");
                navigate("/");
            })
            .catch((err) => console.log(err));
    };

    const submitHandler = (e) => {
        e.preventDefault();
        const obj = {
            
            name : name,
            email : email,
            phonenumber : phonenumber,
            address: address,
            displayName: displayName,

            profession: profession,
            description: description,

            socialPublic: socialPublicString,
            facebook: facebook,
            youtube: youtube,
            instagram: instagram,

            profileImg: resizefileUrl,

        };
      
        /* setName("");
        setEmail("");
        setPhonenumber("");
        setAddress("");
        setDisplayName("");

        setProfession("");
        setDescription("");

        setFacebook("");
        setYoutube("");
        setInstagram(""); */

        //ตรวจสอบ จะต้องไม่ซ้ำเลย รวมทั้งชื่อตัวเอง ถ้าไม่ซ้ำ จะ == ""
        if (dataDisplayName == "") {
            editHandler(obj);
        //หรือถ้าซ้ำ จะซ้ำได้เฉพาะชื่อตัวเอง
        } else {
            alert("ชื่อนี้มีผู้ใช้งานแล้ว");
            //setDisplayName("");
        } 


        //editHandler(obj);

        // Use it as follows (make sure this is in an async function) ///////////////////////////
        /* if (!!isUsernameUnique(name)) {
            editHandler(obj);
        } else {
            alert("Name is already taken. Show some more creativity ;)")
        } */
        ////////////////////////////////////////////////////////////////////////////////////////

        
    };

    ////////////////////////////////////////////////////////////////////

    /* useEffect(() => {

        const ref = firestore.collection("user");
        ref
        .where("displayName", "==", displayName)
            
        if (displayName != null) {
            alert("ชื่อซ้ำ");
        } else {
        
        } 
       
    }, [displayName]); */
    
    ///////////////////////////////////////////////////////////////////

 

    return (
        <>
        <br/>

        {data.map((item, index) => {

            

            return (
                            
                <div key={index}>    

                

                <form onSubmit={submitHandler}>
                                
                    <Container style={{ maxWidth: 500 }}>

              
                        <br/>
                        <h3><strong>ข้อมูลของฉัน</strong></h3>


                        <br/>
                 

                                <Row>
                                        
                                    <Col>
                                        
                                        <h4>ชื่อโปรไฟล์</h4>
                                        <Form.Group>
                                        <Form.Control 
                                            type="text"
                                            className="rounded-0" 
                                            value={displayName}
                                            onChange={(e) => {
                                                setDisplayName(e.target.value);
                                            }}
                                            required
                                            
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            ชื่อโปรไฟล์ของฉัน
                                        </Form.Control.Feedback>
                                        </Form.Group>
                                        <br />


                                        <div className="signuploginborder">

                                        

                                        <h5>เชื่อมต่อผู้คนให้มากขึ้น</h5>
                                        <p><BsDot/> เพิ่มลิ้งค์โซเชียลมีเดีย <br/><BsDot/> ตั้งค่าการมองเห็นเป็นสาธารณะ<br/><br/>*ข้อมูลส่วนนี้แก้ไขเพิ่มเติมภายหลังได้</p>
                                     
                                        <h4>เกี่ยวกับฉัน</h4>
                                        <Form.Control 
                                            as="textarea" 
                                            className="rounded-0"
                                            rows={5} 
                                            value={description}
                                            onChange={(e) => {
                                                setDescription(e.target.value);
                                            }}

                                            placeholder={"เกี่ยวกับฉัน ..."}
                                          
                                        />
                                        <br />



                                        <Form.Group className="mb-3">
                                            <Form.Label>การตั้งค่าการมองเห็นของลิ้งค์โซเชียล</Form.Label>
                                            {/* <Form.Select className="rounded-0" aria-label="Default select example">
                                                <option value="video">วิดีโอ</option>
                                                <option value="pdf">ไฟล์เอกสาร pdf</option>
                                            </Form.Select> */}

                                            <Form.Select className="rounded-0" aria-label="Default select example"
                                                onChange={(e) => setSocialPublicString(e.target.value)}
                                                defaultValue={socialPublicString}
                                            >
                                                {socialPublicOption.map((option, index) => (
                                                <option key={index}>{option}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>







                                        <h4>Facebook</h4>
                                        <InputGroup>
                                            <InputGroup.Text className="rounded-0">Link :</InputGroup.Text>
                                            <Form.Control 
                                                type="text"  
                                                className="rounded-0"
                                                value={facebook}
                                                onChange={(e) => {
                                                    setFacebook(e.target.value);
                                                }}
                                                placeholder={"Facebook ..."} 
                                            />

                                        </InputGroup>
                                        <br/>

                                        <h4>Youtube</h4>
                                        <InputGroup>
                                            <InputGroup.Text className="rounded-0">Link :</InputGroup.Text>
                                            <Form.Control 
                                                type="text"  
                                                className="rounded-0"
                                                value={youtube}
                                                onChange={(e) => {
                                                    setYoutube(e.target.value);
                                                }}
                                                placeholder={"Youtube ..."} 
                                            />

                                        </InputGroup>
                                        <br/>

                                        <h4>Instagram</h4>
                                        <InputGroup>
                                            <InputGroup.Text className="rounded-0">Link :</InputGroup.Text>
                                            <Form.Control 
                                                type="text"  
                                                className="rounded-0"
                                                value={instagram}
                                                onChange={(e) => {
                                                    setInstagram(e.target.value);
                                                }}
                                                placeholder={"Instagram ..."} 
                                            />

                                        </InputGroup>

                                        <br/>
                                        <h4>TikTok</h4>
                                        <InputGroup>
                                            <InputGroup.Text className="rounded-0">Link :</InputGroup.Text>
                                            <Form.Control 
                                                type="text"  
                                                className="rounded-0"
                                                value={tiktok}
                                                onChange={(e) => {
                                                    setTiktok(e.target.value);
                                                }}
                                                placeholder={"TikTok ..."} 
                                            />

                                        </InputGroup>
                                        <br/>
                                        </div>

                                        <br/>

                                        

                                        

                                        {/* <h4>ชื่อ - นามสกุล</h4>
                                        <Form.Control 
                                            type="text"
                                            className="rounded-0" 
                                            value={name}
                                            onChange={(e) => {
                                                setName(e.target.value);
                                            }}
                           
                                          
                                        />
                                        <br />
                                        
                                        <h4>หมายเลขโทรศัพท์</h4>
                                        <Form.Control 
                                            type="text" 
                                            className="rounded-0"
                                            value={phonenumber}
                                            onChange={(e) => {
                                                setPhonenumber(e.target.value);
                                            }}
                                           
                                            
                                        />
                                        <br />

                                        <h4>ที่อยู่จัดส่งสินค้า</h4>
                                        <Form.Control 
                                            as="textarea" 
                                            className="rounded-0"
                                            rows={5} 
                                            value={address}
                                            onChange={(e) => {
                                                setAddress(e.target.value);
                                            }}
                                            
                                        />
                                        <br />

                                        <h4>อีเมลล์</h4>
                                        <Form.Control 
                                            type="text" 
                                            className="rounded-0"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            plaintext
                                            readOnly
                                           
                                        />
                                        <br />

                                        <h4>การทำงาน</h4>
                                        <Form.Control 
                                            
                                            type="text"  
                                            className="rounded-0"
                                            value={profession}
                                            onChange={(e) => {
                                                setProfession(e.target.value);
                                            }}
                                            // placeholder={item.address} 
                                        />
                                        <br /> */}

                                    
                                    </Col>

                                    {/* <Col sm={6}>

                                    <h4>รูปโปรไฟล์</h4>

                                    <br/>


                                    

                                    {!!profileImg ? (
                                        <>
                                            {!!link ? (
                                                <Col style={{textAlign: "center"}}><img src={link} style={{marginBottom: 20, width: "100%"}}/> </Col>
                                            ) : (
                                                <Col style={{textAlign: "center"}}><img src={profileImg} style={{marginBottom: 20, width: "100%"}}/> </Col>
                                            )} 
                                        </>
                                    ) : (
                                        <>
                                            {!!link ? (
                                                <Col style={{textAlign: "center"}}><img src={link} style={{marginBottom: 20, width: "100%"}}/> </Col>
                                            ) : (
                                                <Col style={{textAlign: "center", fontSize: 120, color: 'gray'}}><BsImage/></Col>
                                            )} 
                                        </>
                                        
                                    )}


                                
                                    <br/>

                                    {progressStatus ? (
                                        

                                        <ProgressBar striped variant="success" now={progress} />
                                        
                                    ) : null}

                              

                                    <br/>
                                    <InputGroup className="mb-3 rounded-0">
                                        <Form.Control
                                            aria-label="Example text with button addon"
                                            aria-describedby="basic-addon1"
                                            type="file"
                                            className="custom-file-label rounded-0"  
                                            id="customFile" 
                                            placeholder="" 
                                            name="customFile" 
                                            onChange={ (e) => {
                                                const file = e.target.files[0];
                                                setFile(file);
                                                setLink("");
                                            }}

                                        />

                                        <Button 
                                        type="submit"
                                        onClick={uploadHandler}
                                        className="btn btn-success rounded-0"
                                        >
                                        อัลโหลด
                                        </Button>
                                    </InputGroup>



                      
                                    <br/>
                                    <br/>



                                    


                                    </Col> */}

                                    

                                </Row>

                                    <br/>
                                    {/* <Button className="btn btn-success rounded-0">บันทึก</Button> */}

                                    {/* <Button onClick={((e) => (setName(item.name), setPhonenumber(item.phonenumber), setEmail(item.email), setAddress(item.address) ))} className="btn btn-success rounded-0" type="submit">บันทึก</Button> */}
                                
                                    <Button className="btn btn-success rounded-0" type="submit">เสร็จสิ้น</Button>
                                    
                                    <br/>
                            
                                    
                    </Container>

                </form>
                    
                
                               
                </div>
                            
                             
                            
            );
        })}


        <br/>
        <br/>
      

        </>
    );
}

export default SignUpDetail;