import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { FaFacebookF, FaInstagram, FaPinterest, FaTwitter, FaYoutube, FaCheck, FaSketch } from "react-icons/fa";
import { BsSendCheck, BsFill1CircleFill, BsFill2CircleFill, BsCheckLg, BsFillRecordFill, BsFacebook, BsInstagram, BsTelephone, BsSend } from "react-icons/bs";

function CheckoutSuccess() {


    return (
        <>

        {/* Row 2  */}
        {/* Row 2  */}
        <br/>
        <br/>
        <Container className="" style={{marginBottom: 280}}>
            
            {/* Row 0 Header Checkout */}
            <h3><strong>ขอบคุณสำหรับการสั่งซื้อสินค้า</strong></h3>
            <h3 className="pb-3 border-bottom"></h3>
            <br/>
            <p><strong>สามารถตรวจสอบสถานะการสั่งซื้อได้ทาง</strong> - <Link to="/User/Profile" style={{textDecoration: 'none'}}>การซื้อของฉัน</Link></p><br/>
            <p><strong>สอบถามข้อมูล โปรดติดต่อร้านค้าได้ในช่องทาง</strong> <br/> 
            Facebook Messenger -  <a href="https://www.facebook.com/people/SelPage/100085795429971/" target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}><BsFacebook/> SelPage</a><br/>
            Instagram Direct Messages - <a href="https://www.instagram.com/selpage.express/" target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}><BsInstagram/> SelPage</a><br/>
            โทรศัพท์ - <BsTelephone/> 0805514821</p>

            {/* Row 1 Order Number */}
            
            {/* Row 2 About Tracking By Contract To Facebook/Instagram */}

            
        </Container>

        <br/>
        <br/>


        </>
    );

}  

export default CheckoutSuccess;