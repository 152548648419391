import React, { useState, useEffect, useContext } from "react";
import { Container, Tabs, Tab, Form, Col, Row, Button, Card, InputGroup,Accordion, Modal, Badge } from "react-bootstrap";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";

import { FaSketch } from "react-icons/fa";
import { AiOutlineSketch } from "react-icons/ai";

import { auth, firestore } from "../../database/firebase";
import { storage } from "../../database/firebase";


import { AuthContext } from "../../component_s/Authentication/AuthProvider";

import { BsStarFill, BsStarHalf, BsStar, BsPatchCheck, BsPlayBtn, BsPhone, BsFolder2Open, BsInfinity, BsCheckLg, BsPeopleFill, BsFillPlayBtnFill, BsChatLeftDotsFill, BsPersonVcard, BsJournalCheck, BsListCheck, BsCaretRightSquare, BsEye, BsCameraReels, BsPlusSquareDotted } from "react-icons/bs";
import UploadVideo from "./UploadVideo";

// import "./UploadVideo.css";

import product_sample01 from '../../component_s/images_s/product_sample01.jpeg';
import product_sample02 from '../../component_s/images_s/product_sample02.jpg';
import product_sample03 from '../../component_s/images_s/product_sample03.jpg';

import "./instructor.css";


function Instructor() {


    // let { courseId } = useParams();
    const formatter = new Intl.NumberFormat('th', {
        style: 'currency',
        currency: 'THB',
        maximumSignificantDigits: 3,
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    /* TEST QUERY DATA WITH productID */

    // const [uid, setUid] = useState("");

    const [currentUser, setCurrentUser] = useState(null);
    const authContext = useContext(AuthContext);

   //const [uid, setUid] = useState("");

    var user = auth.currentUser;
    // var uid = "";
    var uid = "";

    if (user != null) {
        //email = user.email;
        uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                         // this value to authenticate with your backend server, if
                         // you have one. Use User.getToken() instead.
        console.log(uid);
        
        //TEST AUTH
        // refreshPage();
    } else {
        navigate("/");
    }
    



    

    // const [emailDefault, setEmailDefault] = useState("");

    // const [loading, setLoading] = useState(false);
    const [section, setSection] = useState([""]);
    const [about, setAbout] = useState([""]);


    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [address, setAddress] = useState("");

    const [role, setRole] = useState("");
    const [sPoint, setSPoint] = useState("");

    const [displayName, setDisplayName] = useState("");

    const [data, setData] = useState([]);
    useEffect(() => {
    const userRef = firestore.collection("user");
    const query = userRef
    
        .where("uid", "==", uid);
            query
            .get()
            .then((querySnapshot) => {
                let tempDataArray =[];
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.data());
                        tempDataArray = [...tempDataArray,
                        {
                            uid: doc.id,
                            name: doc.data().name,
                            email: doc.data().email,
                            phonenumber: doc.data().phonenumber,
                            address: doc.data().address,
                            sPoint: doc.data().sPoint,   
                            role: doc.data().role,

                            displayName: doc.data().displayName,
                            
                        },
                        
                    ];

                    setName(doc.data().name);
                    setPhonenumber(doc.data().phonenumber);
                    setEmail(doc.data().email);
                    setAddress(doc.data().address);

                    setRole(doc.data().role)
                    setSPoint(doc.data().sPoint);

                    setDisplayName(doc.data().displayName);
                    

                    };

                });

            setData((oldDataArray) => tempDataArray);
            

        },

            (err) => {
                console.log(err);
            }
            
        );

    }, [uid]); 

    const [courseData, setCourseData] = useState([]);

    const [merchantID, setMerchantID] = useState("");
    const [storeName, setStoreName] = useState("");
    const [courseCategory, setCourseCategory] = useState("");
    const [courseSubCategory, setCourseSubCategory] = useState("");
    const [courseTypeCategory, setCourseTypeCategory] = useState("");
    const [courseCaption, setCourseCaption] = useState("");
    const [courseID, setCourseID] = useState("");
    const [courseName, setCourseName] = useState("");
    const [courseBadge, setCourseBadge] = useState("");
    const [courseFullPrice, setCourseFullPrice] = useState("");
    const [coursePrice, setCoursePrice] = useState("");
    const [courseImage, setCourseImage] = useState("");

    const [courseRequirement, setCourseRequirement] = useState("");

    //
    const [courseIntro, setCourseIntro] = useState("");

    const [courseSectionExVideo, setCourseSectionExVideo] = useState("");
    const [videoSrc, setVideoSrc] = useState("");

    const [courseExVideo, setCourseExVideo] = useState("");
    const [courseDate, setCourseDate] = useState("");
    const [courseTopic, setCourseTopic] = useState("");
    const [courseStatus, setCourseStatus] = useState("");
    //
    const [courseDetail, setCourseDetail] = useState("");
    const [courseDiscount, setCourseDiscount] = useState("");

    const [courseSold, setCourseSold] = useState("");
    const [courseDoc, setCourseDoc] = useState("");

    //RATING
    const [ratingSum, setRatingSum] = useState(0);
    const [ratingCount, setRatingCount] = useState(0);

    useEffect(() => {
        const productRef = firestore.collection("course");
        const query = productRef
        
            .where("merchantID", "==", uid);
                query
                .get()
                .then((querySnapshot) => {
                    let tempDataArray =[];
                    querySnapshot.forEach((doc) => {
                        if (doc.exists) {
                            tempDataArray = [...tempDataArray,
                            {
                                id: doc.id,
                                merchantID: doc.data().merchantID,
                                storeName: doc.data().storeName,

                                courseID: doc.data().courseID,
                                courseCategory: doc.data().courseCategory,
                                courseSubCategoty: doc.data().courseSubCategory,
                                courseTypeCategory: doc.data().courseTypeCategory,

                                courseName: doc.data().courseName,
                                courseCaption: doc.data().courseCaption,
                                courseTopic: doc.data().courseTopic,
                                courseBadge: doc.data().courseBadge,
                                courseFullPrice: doc.data().courseFullPrice,
                                coursePrice: doc.data().coursePrice,   
                                courseImage: doc.data().courseImage,
                                courseDetail: doc.data().courseDetail,
                                courseDiscount: doc.data().courseDiscount,
                                courseSold: doc.data().courseSold,
                                courseDoc: doc.data().courseDoc,

                                courseIntro: doc.data().courseIntro,
                                courseExVideo : doc.data().courseExVideo,
                                courseSectionExVideo : doc.data().courseSectionExVideo,
                                courseStatus: doc.data().courseStatus,

              
                                ratingSum: doc.data().ratingSum,
                                ratingCount: doc.data().ratingCount,
                            },
                        ];


                        setMerchantID(doc.data().merchantID);
                        setStoreName(doc.data().storeName);

                        setCourseID(doc.data().courseID);
                        setCourseCategory(doc.data().courseCategory);
                        setCourseSubCategory(doc.data().courseSubCategory);
                        setCourseTypeCategory(doc.data().courseTypeCategory);
                        setCourseName(doc.data().courseName);
                        setCourseCaption(doc.data().courseCaption);

                        setCourseTopic(doc.data().courseTopic);
                        setCourseBadge(doc.data().courseBadge);
                        setCourseFullPrice(doc.data().courseFullPrice);
                        setCoursePrice(doc.data().coursePrice);
                        setCourseImage(doc.data().courseImage);
                        setCourseDetail(doc.data().courseDetail);
                        setCourseDiscount(doc.data().courseDiscount);
                        setCourseSold(doc.data().courseSold);
                        setCourseDoc(doc.data().courseDoc);

                        setCourseIntro(doc.data().courseIntro);
                        setCourseExVideo(doc.data().courseExVideo);
                        setVideoSrc(doc.data().courseExVideo[0]);
                        setCourseSectionExVideo(doc.data().courseSectionExVideo);
                        setCourseStatus(doc.data().courseStatus);

 
                        setRatingSum(doc.data().ratingSum);
                        setRatingCount(doc.data().ratingCount);
                        };

                    });

                setCourseData((oldDataArray) => tempDataArray);

            },

                (err) => {
                    console.log(err);
                }
                
            );

        }, [uid]);


    const myStore = () => {
        if (role == "merchant") {
            navigate("/MerchantView")
        } else {
            alert("ร้านค้าของฉันพร้อมเปิดให้บริการเร็วๆ นี้, ขออภัยในความไม่สะดวก");
        }
    }

    const signOutHandler = () => {
        auth.signOut().then(() => {
            console.log("Log Out Ok");
            setCurrentUser(null);
            navigate("/");
        })
        .catch((err) => {
            console.log("Log Out Not Ok." + err);
        })
    };


    return (
        <>
        <br/>

        {data.map((item, index) => {

            return (
                            
                <div key={index}>    

                {/* <form onSubmit={submitHandler}> */}
                                
                    <Container>
                        
                        {/* <Link to="/MerchantView" style={{textDecoration: 'none', color: "black"}}> <Button variant="outline-secondary">ร้านค้าของฉัน</Button>{' '}</Link> */}
                        <Button onClick={myStore} variant="outline-secondary">ร้านค้าของฉัน</Button>{' '}
                        <Button onClick={signOutHandler} variant="outline-danger" type="submit">ออกจากระบบ</Button>
                        <br/>
                        <br/>
                        <br/>
                        <h3><strong>คอร์สของฉัน</strong></h3>
                        <h3 className="pb-3 border-bottom"></h3>
                      
                    </Container>

                {/* </form> */}
             
                </div>
              
            );
        })}

        <br/>
        
   

        <Container>

        
{/* <br/>
<h3><strong>สินค้าทั้งหมด</strong></h3>
<br/> */}

<Row xs={2} md={4} className="g-4">

                

                {courseData.map((item) => {
                    
                    return (
                       
                        <div key={item.id}>

                            <div className='card'>
                            <Link to={'/InstructorView/'+ item.courseID} style={{textDecoration: 'none', color: "black"}}>
                                <Card>
                                    <Card.Img variant="top" style={{width: "100%", height: "auto"}} src={item.courseImage[0]} />
                                    
                                    <Card.Body>
                                        <Card.Title>
                                        </Card.Title>

                                        <p class='coursename' style={{marginTop: -14, fontWeight: 500, lineHeight: 1.4}}>{item.courseName}</p>

                                            <div class='courseprice' style={{marginTop: 0, marginBottom: -15}}>{formatter.format(item.coursePrice)} <del style={{color: "gray"}}>{formatter.format(item.courseFullPrice)}</del></div>

                                            {item.courseBadge ? (
                                                <div class='coursebageanddiscount'> 
                                                    { item.courseDiscount <= 9 ? ( 
                                                        <Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> 
                                                    ) : (
                                                        <><Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> <Badge className="rounded-0" bg="danger">-{item.courseDiscount}%</Badge></> 
                                                    ) } <span style={{marginTop: 1}} className="float-end"><p>ผู้เรียน {item.courseSold}</p></span>
                                                </div>
                                            ) : (
                                               
                                                <div class='coursebageanddiscount'> 
                                                    { item.courseDiscount <= 9 ? ( 
                                            
                                                        <>
                                                        </>
                                                        
                                                        
                                                    ) : (
                                                        <><Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> <Badge className="rounded-0" bg="danger">- {item.courseDiscount}%</Badge></> 
                                                    ) } <span style={{marginTop: 1}} className="float-end"><p>ผู้เรียน {item.courseSold}</p></span>
                                                </div>
                                                
                                            )}
                                            

                                    </Card.Body>
                                </Card>
                            </Link>
                            </div>
                            
                        </div>
                    )
                })}

{/* {courseData.map((item) => {
                    
                    return (
                       
                        <div key={item.id}>

                            <div className='card'>
                            <Link to={'/InstructorView/'+ item.courseID} style={{textDecoration: 'none', color: "black"}}>
                                <Card>
                                    <Card.Img variant="top" style={{width: "100%", height: "auto"}} src={item.courseImage[0]} />
                                    
                                    <Card.Body>
                                        <Card.Title>
                                        </Card.Title>

                                        <p class='productname' style={{marginTop: -14, fontWeight: 500, lineHeight: 1.4}}>{item.courseName}</p>

                                            <div class='price' style={{marginTop: 0, marginBottom: -15}}>{formatter.format(item.coursePrice)} <del style={{color: "gray"}}>{formatter.format(item.courseFullPrice)}</del></div>

                                            {item.courseBadge ? (
                                                <div class='bageanddiscount'> 
                                                    { item.courseDiscount <= 9 ? ( 
                                                        <Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> 
                                                    ) : (
                                                        <><Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> <Badge className="rounded-0" bg="danger">-{item.courseDiscount}%</Badge></> 
                                                    ) } <span style={{marginTop: 1}} className="float-end"><p>ผู้เรียน {item.courseSold}</p></span>
                                                </div>
                                            ) : (
                                               
                                                <div class='bageanddiscount'> 
                                                    { item.courseDiscount <= 9 ? ( 
                                            
                                                        <>
                                                        </>
                                                        
                                                        
                                                    ) : (
                                                        <><Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> <Badge className="rounded-0" bg="danger">- {item.courseDiscount}%</Badge></> 
                                                    ) } <span style={{marginTop: 1}} className="float-end"><p>ผู้เรียน {item.courseSold}</p></span>
                                                </div>
                                                
                                            )}
                                            

                                    </Card.Body>
                                </Card>
                            </Link>
                            </div>
                            
                        </div>
                    )
                })}

{courseData.map((item) => {
                    
                    return (
                       
                        <div key={item.id}>

                            <div className='card'>
                            <Link to={'/InstructorView/'+ item.courseID} style={{textDecoration: 'none', color: "black"}}>
                                <Card>
                                    <Card.Img variant="top" style={{width: "100%", height: "auto"}} src={item.courseImage[0]} />
                                    
                                    <Card.Body>
                                        <Card.Title>
                                        </Card.Title>

                                        <p class='productname' style={{marginTop: -14, fontWeight: 500, lineHeight: 1.4}}>{item.courseName}</p>

                                            <div class='price' style={{marginTop: 0, marginBottom: -15}}>{formatter.format(item.coursePrice)} <del style={{color: "gray"}}>{formatter.format(item.courseFullPrice)}</del></div>

                                            {item.courseBadge ? (
                                                <div class='bageanddiscount'> 
                                                    { item.courseDiscount <= 9 ? ( 
                                                        <Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> 
                                                    ) : (
                                                        <><Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> <Badge className="rounded-0" bg="danger">-{item.courseDiscount}%</Badge></> 
                                                    ) } <span style={{marginTop: 1}} className="float-end"><p>ผู้เรียน {item.courseSold}</p></span>
                                                </div>
                                            ) : (
                                               
                                                <div class='bageanddiscount'> 
                                                    { item.courseDiscount <= 9 ? ( 
                                            
                                                        <>
                                                        </>
                                                        
                                                        
                                                    ) : (
                                                        <><Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> <Badge className="rounded-0" bg="danger">- {item.courseDiscount}%</Badge></> 
                                                    ) } <span style={{marginTop: 1}} className="float-end"><p>ผู้เรียน {item.courseSold}</p></span>
                                                </div>
                                                
                                            )}
                                            

                                    </Card.Body>
                                </Card>
                            </Link>
                            </div>
                            
                        </div>
                    )
                })}

{courseData.map((item) => {
                    
                    return (
                       
                        <div key={item.id}>

                            <div className='card'>
                            <Link to={'/InstructorView/'+ item.courseID} style={{textDecoration: 'none', color: "black"}}>
                                <Card>
                                    <Card.Img variant="top" style={{width: "100%", height: "auto"}} src={item.courseImage[0]} />
                                    
                                    <Card.Body>
                                        <Card.Title>
                                        </Card.Title>

                                        <p class='productname' style={{marginTop: -14, fontWeight: 500, lineHeight: 1.4}}>{item.courseName}</p>

                                            <div class='price' style={{marginTop: 0, marginBottom: -15}}>{formatter.format(item.coursePrice)} <del style={{color: "gray"}}>{formatter.format(item.courseFullPrice)}</del></div>

                                            {item.courseBadge ? (
                                                <div class='bageanddiscount'> 
                                                    { item.courseDiscount <= 9 ? ( 
                                                        <Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> 
                                                    ) : (
                                                        <><Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> <Badge className="rounded-0" bg="danger">-{item.courseDiscount}%</Badge></> 
                                                    ) } <span style={{marginTop: 1}} className="float-end"><p>ผู้เรียน {item.courseSold}</p></span>
                                                </div>
                                            ) : (
                                               
                                                <div class='bageanddiscount'> 
                                                    { item.courseDiscount <= 9 ? ( 
                                            
                                                        <>
                                                        </>
                                                        
                                                        
                                                    ) : (
                                                        <><Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> <Badge className="rounded-0" bg="danger">- {item.courseDiscount}%</Badge></> 
                                                    ) } <span style={{marginTop: 1}} className="float-end"><p>ผู้เรียน {item.courseSold}</p></span>
                                                </div>
                                                
                                            )}
                                            

                                    </Card.Body>
                                </Card>
                            </Link>
                            </div>
                            
                        </div>
                    )
                })}

{courseData.map((item) => {
                    
                    return (
                       
                        <div key={item.id}>

                            <div className='card'>
                            <Link to={'/InstructorView/'+ item.courseID} style={{textDecoration: 'none', color: "black"}}>
                                <Card>
                                    <Card.Img variant="top" style={{width: "100%", height: "auto"}} src={item.courseImage[0]} />
                                    
                                    <Card.Body>
                                        <Card.Title>
                                        </Card.Title>

                                        <p class='productname' style={{marginTop: -14, fontWeight: 500, lineHeight: 1.4}}>{item.courseName}</p>

                                            <div class='price' style={{marginTop: 0, marginBottom: -15}}>{formatter.format(item.coursePrice)} <del style={{color: "gray"}}>{formatter.format(item.courseFullPrice)}</del></div>

                                            {item.courseBadge ? (
                                                <div class='bageanddiscount'> 
                                                    { item.courseDiscount <= 9 ? ( 
                                                        <Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> 
                                                    ) : (
                                                        <><Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> <Badge className="rounded-0" bg="danger">-{item.courseDiscount}%</Badge></> 
                                                    ) } <span style={{marginTop: 1}} className="float-end"><p>ผู้เรียน {item.courseSold}</p></span>
                                                </div>
                                            ) : (
                                               
                                                <div class='bageanddiscount'> 
                                                    { item.courseDiscount <= 9 ? ( 
                                            
                                                        <>
                                                        </>
                                                        
                                                        
                                                    ) : (
                                                        <><Badge className="rounded-0" bg="warning">{item.courseBadge}</Badge> <Badge className="rounded-0" bg="danger">- {item.courseDiscount}%</Badge></> 
                                                    ) } <span style={{marginTop: 1}} className="float-end"><p>ผู้เรียน {item.courseSold}</p></span>
                                                </div>
                                                
                                            )}
                                            

                                    </Card.Body>
                                </Card>
                            </Link>
                            </div>
                            
                        </div>
                    )
                })} */}

                <Col>
                <Link to={'/InstructorProfile/' + "ce0J3diaDdF1glsCbc0N"}><Button variant="link" style={{textDecoration: 'none', color: 'black'}} size="lg">
                    <span class='addcourseicon'><BsPlusSquareDotted/></span><br/><br/><span style={{color: 'blue'}}>เพิ่มคอร์สเรียน</span>
                </Button></Link>
                </Col>

        </Row>

    <br/>

    {/* {currentCursor ? (
        <Col className='float-end'>

            <Button variant="warning" className="rounded-0" onClick={onMoreProducts}>สินค้าเพิ่มเติม</Button>

        </Col>
    ) : (
        <Col className='float-end'>

            <Button variant="outline-secondary" className="rounded-0" disabled>ไม่มีสินค้าเพิ่มเติม</Button>

        </Col>
    )} */}

<br/>
<br/>



</Container>


        <br/>
        <br/>
      

        </>
    );
}

export default Instructor;